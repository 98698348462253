import { Text } from "@chakra-ui/react";

const Traffic = () => {

    return (
        <>
            <Text color="brand.subtitle" fontSize={17}>
                Learn how students arrive at your course
            </Text>
        </>
    );
}

export default Traffic;