import { Image, Text, Box, Flex, AspectRatio } from "@chakra-ui/react";
import React from "react";
import { AiFillStar} from 'react-icons/ai';


interface ICourseCard{
    coverImage: string,
    title: string,
    author?: string,
    star: number,
    rating?: string,
    count?: number,
    price: number,
    children?: any
}


const CourseCardWRating: React.FC<ICourseCard> = (data) => {

    const {coverImage, title, author, star, rating, count } = data;

    return (
        <Box borderWidth="1px" borderRadius="md" mr={5} width="10em" >
            
            <AspectRatio maxW="10em" ratio={1.9/1} >
                <Image src={coverImage}></Image>
            </AspectRatio>

            <Box p={3}>
                <Text fontSize={14} isTruncated color="brand.3">
                    {title}
                </Text>
                <Text color="brand.subtitle" fontSize={11} mt={3}>
                    {author}
                </Text>
                <Flex justifyContent="space-between" mt={1} >
                    <Flex alignItems="center" fontSize={11} >
                        {
                            Array(5).fill("").map((val, index) => <AiFillStar key={index} color={star > index ? "#E9A426" : "#CACDD8"}/> )
                        }
                        <Box as="span" ml={2} color="brand.subtitle">
                            {rating} ({count})
                        </Box>
                    </Flex>
                </Flex>
            </Box>
        </Box>
    );
}

export default CourseCardWRating;