
export const TrainingMgrRoutes = {
    Main: "/trainingmgr",
    Student: "/trainingmgr/student",
    Profile: "/trainingmgr/profile",
    Security: "/trainingmgr/security",
    Group: {
        Fetch: "/trainingmgr/groups",
        Create: "/trainingmgr/group/create",
        Discussion: "/trainingmgr/group/discussion",
    },
    Course: {
        Fetch: "/trainingmgr/courses",
        Status: "/trainingmgr/courses/status",
        Assign: "/trainingmgr/courses/assign",
        Create: "/trainingmgr/course/create",
        PreviewPath: "/trainingmgr/course/:courseId/preview",
        PreviewLink: (courseId: string) => `/trainingmgr/course/${courseId}/preview`,
        EditPath: "/trainingmgr/course/:courseId/edit",
        EditLink: (courseId: string) => `/trainingmgr/course/${courseId}/edit`,
    }
}