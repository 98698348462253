import {Flex, Box, Container, HStack, Menu, MenuList, MenuItem, MenuButton,Text,Image, Avatar, IconButton, InputGroup, useDisclosure} from '@chakra-ui/react';
import blucanaryLogo2 from "../../img/logo2.png";
import {RiDashboardFill, RiUserSettingsLine} from 'react-icons/ri';
import {SiMoleculer} from 'react-icons/si';
import { BsBell, BsBook } from 'react-icons/bs';
import {MdKeyboardArrowDown, MdSecurity} from 'react-icons/md';
import {Route, Switch, useHistory, useRouteMatch} from 'react-router-dom';
import Dashboard from '../../pages/Management/Dashboard';
import React, { useContext, useEffect } from 'react';
import { StoreContext } from '../../store/StoreContext';
import { destroyUserSession, getLocalUserProfile } from '../../services/auth.service';
import { Error404 } from '../Common/Error404';
import { NavLink } from '../Common/NavLink';
import CourseTimeline from '../../pages/Student/CourseTimeline';
import { ManagementRoutes } from '../../pages/Management/routes';
import Report from '../../pages/Management/Report';


export const ManagementMain: React.FC<any> = () => {
    const { globalState, actions } = useContext(StoreContext);

 

  let { isExact } = useRouteMatch();

  let { location, push, replace } = useHistory();

  useEffect(() => {
    let user = getLocalUserProfile();

    if (user != null) {
      actions?.updateProfile(user);
    }
  }, []);

  const handleLogout = () => {
    destroyUserSession();
    replace("/");
  };

  const activeLink = (link: string) => {
    let style = {
      borderLeftColor: "white",
      bg: "white",
    };

    if (link === location.pathname) {
      style.bg = "brand.1";
      style.borderLeftColor = "brand.2";
    }

    return style;
  };

  return (
    <Box minHeight="100vh">
      <Flex>
        <Container
          width={"18%"}
          maxWidth={"18%"}
          height={"100%"}
          lineHeight={"100%"}
          pt={4}
          boxShadow="base"
          bg="white"
          position="fixed"
          px={0}
        >
          <Image pl={10} src={blucanaryLogo2} width="70%" height="auto" />

          <Flex direction="column" fontSize={13} color="brand.bodyText" pt={12}>
           
            
              <NavLink label="Dashboard" to={ManagementRoutes.Main} activeOnlyWhenExact={true} icon={<RiDashboardFill size={18} />} />
              <NavLink label="Report" to={ManagementRoutes.Report} activeOnlyWhenExact={true} icon={<SiMoleculer size={18} />} />

          </Flex>
        </Container>

        <Container
          width={"82%"}
          maxWidth={"82%"}
          pt={4}
          px={16}
          position="absolute"
          right={0}
        >
          <Flex justifyContent="space-between" alignItems="center">
            {!isExact ? (
              <Box></Box>
            ) : (
              <InputGroup color="brand.subtitle" bg="white" w="30%">
                {/* <Input placeholder="Search courses" fontSize={12} size="sm" /> */}

                {/* <InputRightElement h="1.9rem" pointerEvents="none" children={<RiSearch2Line size="0.9rem"  />} /> */}
              </InputGroup>
            )}

            <HStack spacing={6}>
              <IconButton
                variant="ghost"
                aria-label="bell"
                color="brand.2"
                borderRadius={20}
                icon={<BsBell />}
              />

              <Avatar
                size="sm"
                name={globalState.profile?.full_name}
                src={globalState.profile?.photo_url}
              />

              <Menu>
                <MenuButton>
                  <HStack color="brand.subtitle" fontSize={13}>
                    <Flex direction="column" alignItems="flex-end">
                      <Text whiteSpace="nowrap">
                        {" "}
                        {globalState?.profile?.full_name}{" "}
                      </Text>
                      <Text fontSize={11}>Management</Text>
                    </Flex>
                    <MdKeyboardArrowDown />
                  </HStack>
                </MenuButton>
                <MenuList fontSize={13} borderRadius={0} color="brand.bodyText">
                  <MenuItem onClick={() => push(ManagementRoutes.Main)}>
                    Go to dashboard
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </MenuList>
              </Menu>
            </HStack>
          </Flex>

          <Container maxW="container.xl" pt={isExact ? 6 : 10} px={0}>
            <Switch>
              <Route exact path={ManagementRoutes.Main}>
                <Dashboard/>
              </Route>
              <Route exact path={ManagementRoutes.Report}>
               <Report/>
              </Route>

             
              <Route path="*">
                <Error404 />
              </Route>
            </Switch>
          </Container>
        </Container>
      </Flex>
    </Box>
  );

}