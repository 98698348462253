import {Link as ReactLink, useRouteMatch} from 'react-router-dom';
import {Text, Link} from '@chakra-ui/react';

export const NavLink = ({ label, to, activeOnlyWhenExact, icon, pl = 14 }: {label: string, to: string, activeOnlyWhenExact?: boolean, icon?: JSX.Element, pl?: number}) => {

  let match = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact
  });

  return (
     <Link mb={5} py={2} pl={pl} bg={match? "brand.1" : "white"} borderLeftColor={match? "brand.2" : "white"} borderLeftWidth="4px" display="inline-flex" alignItems="center" as={ReactLink} to={to} > {icon} <Text pl={3}>{label}</Text></Link>
  );
}