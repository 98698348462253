import { Button } from "@chakra-ui/button";
import { FormControl, FormErrorMessage } from "@chakra-ui/form-control";
import { Table, Thead, Tbody, Tr, Td, Progress, Textarea, IconButton } from "@chakra-ui/react"
import { Input } from "@chakra-ui/input";
import { AspectRatio, Box, Flex, HStack, Text } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/toast";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { cancleRequest, deleteCourseLessonNote, submitCourseLessonNote, submitCourseLessonVideo } from "../../../../services/instructor/courses-http.service";
import { partial } from 'filesize'
import { AssetType } from "../../../../models/create_course.model";
import { deleteLessonNoteToStateService, getLessonFromStateService, removeLessonAssetFromStateService, updateLessonAssetToStateService, updateLessonNoteToStateService } from "../../../../services/instructor/course.service";
import { ILessonNoteDto } from "../../../../models/course.model";
import { BsPencilSquare } from "react-icons/bs";
import { VscTrash } from "react-icons/vsc";



export const AudioUpload: React.FC<any> = ({ goBack, lessonId, lessonIndex, lectureIndex }) => {

    const [isUploading, setIsUploading] = useState<boolean>(false);

    const [currentFile, setCurrentFile] = useState<File>();

    const [fileName, setFileName] = useState<string>('');

    const [uploadFileSize, setUploadFileSize] = useState('');

    const inputRef = useRef<HTMLInputElement | null>(null)

    const toast = useToast();

    const [progress, setProgress] = useState(0);

    const [isUploaded, setIsUploaded] = useState(false);

    const [uploadLink, setUploadLink] = useState<string>();

    const [addNote, setAddNote] = useState(false);
    const [updateNote, setUpdateNote] = useState(false);

    const [note, setNote] = useState<string>();


    useEffect(() => {

        let selectedLesson = getLessonFromStateService(lectureIndex, lessonIndex);

        let lessonUploaded = selectedLesson?.isUploaded;

        if (lessonUploaded) {

            setIsUploaded(lessonUploaded);

            setUploadLink(selectedLesson?.asset_link)

            setFileName(selectedLesson?.asset_file_name || '')

            if (selectedLesson?.description)
                setNote(selectedLesson.description)
        }
    }, []);


    const handleFileSelect = () => {
        inputRef?.current?.click();
    }

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;

        if (value) {

            let fileName = e.target.files?.item(0)?.name as string;

            let fileType = e.target.files?.item(0)?.type;

            let file = e.target.files?.item(0) as File;

            if (fileType?.split('/').at(0) !== 'audio') {
                formikAudio.setErrors({ audio_lesson: 'Only Audio file format is supported' });
                return;
            }

            setFileName(fileName);

            setCurrentFile(file);

            const fileSize = partial({ base: 2, standard: "jedec" });

            setUploadFileSize(fileSize(file.size));

        }
        else {
            setFileName('');
            setCurrentFile(undefined);
        }

        formikAudio.handleChange(e);
    }

    const formikAudio = useFormik({
        initialValues: {
            audio_lesson: undefined,
        },
        validate: (values: any) => {
            const errors: any = {};
            if (!values.audio_lesson) {
                errors.audio_lesson = 'An audio file is required';
            }
            return errors;
        },
        onSubmit: async (values) => {
            try {

                if (currentFile !== undefined) {

                    setIsUploading(true);

                    let formData = new FormData();

                    formData.append('lessonId', lessonId);
                    formData.append('video', currentFile, fileName);

                    const lessonVideo = await submitCourseLessonVideo(formData, (event) => {
                        setProgress(Math.round((100 * event.loaded) / event.total));
                    })

                    if (lessonVideo) {
                        setIsUploading(false);

                        updateLessonAssetToState(true, AssetType.AUDIO, lessonVideo.data.video_url || '');

                    }
                }

            } catch (error) {
                console.log(error)
                setIsUploading(false);
                if (error)
                    toast({
                        title: "Course Lesson",
                        description: "Something went Wrong. Try again later",
                        status: "error",
                    })
            }
        }
    })

    const updateLessonAssetToState = (isUploaded: boolean, assetType: AssetType, assetLink: string) => {
        setIsUploaded(true);
        setUploadLink(assetLink);
        updateLessonAssetToStateService(lectureIndex, lessonIndex, isUploaded, assetType, assetLink, fileName)
    }

    const removeLessonAssetFromState = () => {
        if (isUploading)
            cancleRequest.cancel("Upload Cancled");
        removeLessonAssetFromStateService(lectureIndex, lessonIndex);
        goBack()
    }

    const formikNote = useFormik({
        initialValues: {
            note: '',
        },

        validate: (values: any) => {
            const errors: any = {};
            return errors;
        },
        onSubmit: async (values) => {
            try {

                let lessonData: ILessonNoteDto = { lessonId, note: values.note }

                const lessonNote = await submitCourseLessonNote(lessonData);

                if (lessonNote){
                    updateLessonNoteToStateService(lectureIndex, lessonIndex, values.note);
                    toast({
                        title: "Course Lesson",
                        description: " note added successfully",
                        status: "success",
                    })
                }
                   
                setNote(values.note)

            } catch (error) {
                toast({
                    title: "Course Lesson",
                    description: "Something went Wrong. Try again later",
                    status: "error",
                })
            }
        }
    })
    const formikUpdateNote = useFormik({
        initialValues: {
            note: '',
        },

        validate: (values: any) => {
            const errors: any = {};
            return errors;
        },
        onSubmit: async (values) => {
            try {

                let lessonData: ILessonNoteDto = { lessonId, note: values.note }
                const lessonNote = await submitCourseLessonNote(lessonData);
                if (lessonNote){
                    updateLessonNoteToStateService(lectureIndex, lessonIndex, values.note);
                    toast({
                        title: "Course Lesson",
                        description: " note updated successfully",
                        status: "success",
                    })
                    setUpdateNote(false)
                }                   
                setNote(values.note)

            } catch (error) {
                toast({
                    title: "Course Lesson",
                    description: "Something went Wrong. Try again later",
                    status: "error",
                })
            }
        }
    })

    const editNote = (note: string) => {

        formikUpdateNote.setValues({ note: note })

        setNote('');
        setUpdateNote(true)
        setAddNote(false);
    }

    const deleteNote = async () => {

        try {

            const lessonNote = await deleteCourseLessonNote(lessonId);

            if (lessonNote) {
                deleteLessonNoteToStateService(lectureIndex, lessonIndex, '');
                toast({
                    title: "Course Lesson",
                    description: "Note deleted succesfully",
                    status: "success",
                })
                formikNote.setValues({ note: '' })

            }

            setNote('')

            setAddNote(false)

        } catch (error) {
            toast({
                title: "Course Lesson",
                description: "Something went Wrong. Try again later",
                status: "error",
            })
        }
    }


    return (
        <>
            <Box mt={2}>

                <Flex justifyContent="space-between" mb={4}>
                    <Text fontSize={13} color="brand.2" borderBottom="1px" borderBottomWidth={2.5} >Upload Audio</Text>
                    <Button color="danger.100" onClick={() => removeLessonAssetFromState()} borderColor="danger.100" variant="outline" type="submit" size="sm" fontSize={11} rightIcon={<FaTimes />} >Remove Audio</Button>
                </Flex>

                {
                    isUploaded ?

                        <Box>
                            <HStack alignItems="start" spacing={4} mb={4}>

                                <audio controls controlsList="nodownload">
                                    <source src={uploadLink} />
                                </audio>

                                <Text pt={4} fontSize={12} title={fileName} color={"brand.subtitle"} fontWeight="semibold" isTruncated maxWidth="30em">{fileName}</Text>

                            </HStack>

                            {
                                note ?
                               

                                    <Box fontSize={13} px={6} border={"1px"} borderRadius={3} py={4} borderColor={"brand.borderBox"} >

                                        <HStack spacing={2} justifyContent={"end"} mb={2} >
                                            <IconButton
                                                variant="ghost"
                                                aria-label="pencil"
                                                color="brand.subtitle"
                                                borderRadius={20}
                                                size="sm"
                                                icon={<BsPencilSquare />}
                                                onClick={() => editNote(note)}
                                            />
                                            <IconButton
                                                variant="ghost"
                                                aria-label="trash"
                                                color="brand.subtitle"
                                                borderRadius={20}
                                                size="sm"
                                                icon={<VscTrash />}
                                                onClick={() => deleteNote()}
                                            />
                                        </HStack>

                                        {note}

                                    </Box>
                                   

                                   :

                                    addNote ?

                                        <form onSubmit={formikNote.handleSubmit}>
                                            <FormControl id="note" isInvalid={(formikNote.errors.note && formikNote.touched.note) ? true : false}>
                                                <Textarea
                                                    p={4}
                                                    fontSize={12}
                                                    size="sm"
                                                    minH="10rem"
                                                    name="note"
                                                    onChange={formikNote.handleChange}
                                                    placeholder="Notes..."
                                                    value={formikNote.values.note}
                                                />
                                                <FormErrorMessage fontSize={12}> {formikNote.errors.note} </FormErrorMessage>
                                            </FormControl>

                                            <Flex justifyContent="flex-end">
                                                <Button mt={4} colorScheme="brand" type="submit" isLoading={formikNote.isSubmitting} size="sm" px={4} fontSize={11} > SUBMIT</Button>
                                            </Flex>
                                        </form>

                                        :

                                        <Button colorScheme="brand" onClick={() => setAddNote(true)} size="sm" fontSize={11} borderRadius={2} px={6}>ADD NOTES</Button>
                            }
                            {
                                 updateNote &&  
                                 <form onSubmit={formikUpdateNote.handleSubmit}>
                                        <FormControl id="note" isInvalid={(formikUpdateNote.errors.note && formikUpdateNote.touched.note) ? true : false}>
                                            <Textarea
                                                p={4}
                                                fontSize={12}
                                                size="sm"
                                                minH="10rem"
                                                name="note"
                                                onChange={formikUpdateNote.handleChange}
                                                placeholder="Notes..."
                                                value={formikUpdateNote.values.note}
                                            />
                                            <FormErrorMessage fontSize={12}> {formikUpdateNote.errors.note} </FormErrorMessage>
                                        </FormControl>

                                        <Flex justifyContent="flex-end">
                                            <Button mt={4} colorScheme="brand" type="submit" isLoading={formikUpdateNote.isSubmitting} size="sm" px={4} fontSize={11} > UPDATE</Button>
                                        </Flex>
                                    </form>

                            }

                        </Box>

                        :

                        isUploading ?

                            <Box>
                                <Table variant="unstyled">
                                    <Thead fontWeight="semibold" >
                                        <Tr color="brand.subtitle" fontSize={14} >
                                            <Td>Filename</Td>
                                            <Td>Type</Td>
                                            <Td>Status</Td>
                                            <Td>Size</Td>
                                        </Tr>
                                    </Thead>

                                    <Tbody>
                                        <Tr fontSize={13} color="brand.subtitle">
                                            <Td isTruncated maxW="20em" >{fileName}</Td>
                                            <Td>Audio</Td>
                                            <Td><Progress isIndeterminate={isUploading ? true : false} value={progress} /></Td>
                                            <Td>{uploadFileSize}</Td>
                                        </Tr>
                                    </Tbody>
                                </Table>
                            </Box>

                            :

                            <Box>
                                <form onSubmit={formikAudio.handleSubmit}>
                                    <FormControl mr={10} isInvalid={(formikAudio.errors.audio_lesson && formikAudio.touched.audio_lesson) ? true : false} >
                                        <Flex>
                                            <input accept="audio/*" type='file' name="audio_lesson" ref={inputRef} onChange={(e) => handleFileChange(e)} style={{ display: 'none' }}></input>
                                            <Input placeholder="No file selected" defaultValue={fileName} fontSize={14} onClick={handleFileSelect} borderRadius={1} />
                                            <Button colorScheme="brand" type="submit" isLoading={formikAudio.isSubmitting} size="md" fontSize={11} >UPLOAD AUDIO</Button>
                                        </Flex>
                                        <FormErrorMessage fontSize={12}> {formikAudio.errors.audio_lesson} </FormErrorMessage>
                                    </FormControl>
                                </form>
                            </Box>

                }

            </Box>
        </>
    );
}