import { IStore } from '../models/store.model';
import {actionTypes} from '../store/types';
import { IActionModel } from './actions';


const initialState: IStore = {};


const reducer = (state: IStore, action: IActionModel): IStore => {

    switch (action.type) {
        case actionTypes.UPDATE_PROFILE:
            let profile = {profile: action.payload};
            return ({
                ...state, ...profile
            })
        default:
            return state;
    }
}

export {initialState, reducer};