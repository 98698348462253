import { Avatar, Box, Button, Container, Flex, HStack, IconButton, Image, Input, InputGroup, Menu, MenuButton, MenuList, MenuItem, InputRightElement, Link, Text, Collapse, useDisclosure } from "@chakra-ui/react";
import {Link as ReactLink, Route, Switch, useRouteMatch, useHistory} from 'react-router-dom';
import {RiDashboardFill, RiSearch2Line, RiUserSettingsLine} from 'react-icons/ri';
import { BsBell, BsStar} from 'react-icons/bs';
import { HiOutlineAcademicCap} from 'react-icons/hi';
import {BiBookAdd, BiBookBookmark} from 'react-icons/bi';
import {MdKeyboardArrowDown, MdSecurity} from 'react-icons/md';
import {InstructorRoutes} from '../../pages/Instructor/routes';
import { FiBarChart2, FiUsers } from "react-icons/fi";
import blucanaryLogo2 from '../../img/logo2.png'
import Dashboard from "../../pages/Instructor/Dashboard";
import MyCourses from "../../pages/Instructor/MyCourses";
import AssignCourse from "../../pages/Instructor/AssignCourse";
import Student from "../../pages/Instructor/Student";
import Reviews from "../../pages/Instructor/Reviews";
import Groups from "../../pages/Instructor/Groups";
import Report from "../../pages/Instructor/Report";
import Profile from "../../pages/Instructor/Profile";
import Security from "../../pages/Instructor/Security";
import CreateCourse from "../../pages/Instructor/CreateCourse";
import { destroyUserSession, getLocalUserProfile } from "../../services/auth.service";
import { StoreContext } from "../../store/StoreContext";
import { useContext, useEffect } from "react";
import { NavLink } from "../Common/NavLink";
import EditCourse from "../../pages/Instructor/EditCourse";
import { Error404 } from "../Common/Error404";


export const InstructorMain: React.FC<any> = () => {

    const {globalState, actions} = useContext(StoreContext);

    const { isOpen: isOpenCourse, onToggle: onToggleCourse } = useDisclosure()

    let {isExact} = useRouteMatch();

    let {location, push, replace} = useHistory();

    useEffect(() => {
        let user = getLocalUserProfile();

        if(user != null){
            actions?.updateProfile(user);
        }
    }, []);


    const handleLogout = () => {
        destroyUserSession();
        replace('/');
    }

    const activeLink = (link: string) => {

        let style = {
            borderLeftColor: "white",
            bg: "white"
        }

        if(link === location.pathname ){
            style.bg = "brand.1";
            style.borderLeftColor = "brand.2"
        }

        return style;
    }

    return (
        <Box minHeight="100vh">

            <Flex>

                <Container maxWidth="18%"  >
                </Container>

                <Container maxWidth="18%" minHeight="100%" pt={4} boxShadow="base" bg="white" position="fixed" px={0}>
                    
                    <Image pl={10}  src={blucanaryLogo2} width="70%" height="auto"  />

                    <Flex direction="column" fontSize={13} color="brand.bodyText"  pt={12} >

                        <NavLink pl={9} label="Dashboard" to={InstructorRoutes.Main} activeOnlyWhenExact={true} icon={<RiDashboardFill size={18} />} />
                        
                        <Box py={2} borderLeftWidth="4px">

                            <Box cursor="pointer" pb={5} onClick={onToggleCourse} > <Box pl={9} display="flex" alignItems="end" > <BiBookBookmark size={18} /> <Text mr={2} pl={3}>Courses</Text> <MdKeyboardArrowDown fontSize={18} /></Box></Box>
                            
                            <Collapse in={isOpenCourse}  >
                                <Link  as={ReactLink} to={InstructorRoutes.MyCourses} > <Box mb={5} py={2} borderLeftWidth="4px" bg={activeLink(InstructorRoutes.MyCourses).bg} borderLeftColor={activeLink(InstructorRoutes.MyCourses).borderLeftColor} pl="5em" > My Courses</Box> </Link>
                                <Link  as={ReactLink} to={InstructorRoutes.Course.Assign} > <Box mb={5} py={2} borderLeftWidth="4px" bg={activeLink(InstructorRoutes.Course.Assign).bg} borderLeftColor={activeLink(InstructorRoutes.Course.Assign).borderLeftColor}  pl="5em" > Assign Course</Box > </Link>
                            </Collapse>
                        </Box>

                        <NavLink pl={9} label="Students" to={InstructorRoutes.Student} icon={<HiOutlineAcademicCap size={18} />} />
                        <NavLink pl={9} label="Reviews" to={InstructorRoutes.Reviews} icon={<BsStar size={18} />} />
                        <NavLink pl={9} label="Group" to={InstructorRoutes.Groups} icon={<FiUsers size={18} />} />
                        <NavLink pl={9} label="Report" to={InstructorRoutes.Report} icon={<FiBarChart2 size={18} />} />
                        <NavLink pl={9} label="Edit Profile" to={InstructorRoutes.Profile} icon={<RiUserSettingsLine size={18} />} />
                        <NavLink pl={9} label="Security" to={InstructorRoutes.Security} icon={<MdSecurity size={18} />} />
                    </Flex>

                </Container>

                <Container maxW="container.xl" ml={16} mr={16} pt={4} >

                    <Flex justifyContent="flex-end" alignItems="center">
                       

                        <HStack spacing={6}>
                            <Button colorScheme="brand" leftIcon={<BiBookAdd />} onClick={() => push(InstructorRoutes.Course.Create)} size="sm" fontSize={12}>New Course</Button>
                            
                            <IconButton
                                variant="ghost"
                                aria-label="bell"
                                color="brand.2"
                                borderRadius={20}
                                icon={<BsBell />}
                            />
                            
                            <Avatar size="sm" name={globalState.profile?.full_name} src={globalState.profile?.photo_url} />

                            <Menu>
                                <MenuButton >
                                    <HStack color="brand.subtitle" fontSize={13}>
                                        <Flex direction="column" alignItems="flex-end">
                                            <Text whiteSpace="nowrap" > {globalState?.profile?.full_name}  </Text>
                                            <Text fontSize={11}>Instructor</Text>
                                        </Flex>
                                        <MdKeyboardArrowDown />
                                    </HStack> 
                                </MenuButton>
                                <MenuList fontSize={13} borderRadius={0} color="brand.bodyText" >
                                    <MenuItem onClick={() => push(InstructorRoutes.Main)} >Go to dashboard</MenuItem>
                                    <MenuItem onClick={() => push(InstructorRoutes.MyCourses)} >My Courses</MenuItem>
                                    <MenuItem onClick={() => push(InstructorRoutes.Report)} >Reports</MenuItem>
                                    <MenuItem onClick={handleLogout} >Logout</MenuItem>         
                                </MenuList>
                            </Menu>
                        </HStack>
                    </Flex>

                    <Container maxW="container.xl" pt={isExact? 6: 10} px={0}>
                        <Switch>
                            <Route exact path={InstructorRoutes.Main}>
                                <Dashboard/>
                            </Route>
                            <Route exact path={InstructorRoutes.MyCourses}>
                                <MyCourses />
                            </Route>
                            <Route exact path={InstructorRoutes.Course.Assign}>
                                <AssignCourse />
                            </Route>
                            <Route exact path={InstructorRoutes.Student}>
                                <Student />
                            </Route>
                            <Route exact path={InstructorRoutes.Reviews}>
                                <Reviews />
                            </Route>
                            <Route exact path={[InstructorRoutes.Groups, InstructorRoutes.Group.Create]}>
                                <Groups />
                            </Route>
                            <Route exact path={InstructorRoutes.Report}>
                                <Report />
                            </Route>
                            <Route exact path={InstructorRoutes.Profile}>
                                <Profile />
                            </Route>
                            <Route exact path={InstructorRoutes.Security}>
                                <Security />
                            </Route>
                            <Route exact path={InstructorRoutes.Course.Create}>
                                <CreateCourse />
                            </Route>
                            <Route exact path={InstructorRoutes.Course.EditPath}>
                                <EditCourse />
                            </Route>
                            <Route  path="*" >
                                <Error404 />
                            </Route>
                        
                        </Switch>                     

                    </Container>
                    
                </Container>

            </Flex>

        </Box>
    );
}