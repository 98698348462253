import {
  Container,
  FormControl,
  FormLabel,
  Input,
  Text,
  Box,
  HStack,
  Textarea,
  Flex,
  Button,
  RadioGroup,
  Radio,
  useToast,
  FormErrorMessage,
  Portal,
  useDisclosure,
  Spacer,
  Select,
  InputGroup,
  InputLeftElement,
  IconButton,
} from '@chakra-ui/react'
import { useFormik } from 'formik'
import { IUserCreateDto } from '../../../models/user.model'
import {
  createUser,
  //   uploadBatchUser,
  uploadBatchUserUpload,
} from '../../../services/hr/users-http.service'
import { Category } from '../../../components/Common/Category'
import { Role } from '../../../components/Common/Role'
import {
  IoIosCloudUpload,
  IoMdCall,
  IoMdContact,
  IoMdLock,
  IoMdMail,
} from 'react-icons/io'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useHistory } from 'react-router-dom'
import { HrAdminRoutes } from '../routes'
import * as XLSX from 'xlsx'
import { ICategory } from '../../../models/course.model'
import { fetchCategories } from '../../../services/shared.service'

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column' as 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}

const activeStyle = {
  borderColor: '#2196f3',
}

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

const CreateUser = () => {
  let history = useHistory()
  const [FileUploaded, setFileUploaded] = useState('')
  const toast = useToast()
  let { push } = useHistory()
  const [batchUpload, setBatchUpload] = useState<boolean>(false)
  const [batchFile, setbatchFile] = useState<FormData | null>(null)
  const [categories, setCategories] = useState<ICategory[]>([]);

  useEffect(() => {
    fetchCategories()
      .then((data) => {
        setCategories(data)
      })
      .catch((err) => err)

  }, []);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (!acceptedFiles.length) {
      toast({
        title: 'File Upload',
        description: 'Invalid file uploaded',
        status: 'error',
      })
      setbatchFile(null)
    } else {
      const formData = new FormData()
      const file = acceptedFiles[0]
      formData.append('batchfile', file)
      setbatchFile(formData)
      toast({
        title: 'File Upload',
        description: 'Your file is ready for upload',
        status: 'success',
      })
    }
  }, [])

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone({
    onDrop,
    accept: '.xlsx,.xls',
    multiple: false,
  })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  )

  const formik = useFormik({
    validate: (values: any) => {
      const errors: any = {}
      if (!values.fullname) {
        errors.fullname = 'Fullname is required'
      }
      if (!values.email) {
        errors.email = 'Email address is required'
      }

      if (!values.phone) {
        errors.phone = 'Phone is required'
      } else {
        const re = /^[0-9\b]+$/
        if (!re.test(values.phone) || String(values.phone).length > 11) {
          errors.phone = 'Please input a valid phone number'
        }
      }
      if (!values.password) {
        errors.course = 'Password is required'
      }

      if (!values.cpassword) {
        errors.cpassword = 'Confirm Password is required'
      }

      if (values.cpassword !== values.password) {
        errors.cpassword = 'Password does not match'
      }

      if (!values.role) {
        errors.role = 'Profile is required'
      }
      if (!values.unit) {
        errors.unit = 'Department is required'
      }
      return errors
    },
    initialValues: {
      fullname: '',
      email: '',
      role: '',
      phone: '',
      unit: '',
      password: '',
      cpassword: '',
    },
    onSubmit: async (values) => {
      try {
        let userDto: IUserCreateDto = {
          fullName: values.fullname,
          role: Number(values.role),
          phone: values.phone,
          password: values.password,
          unit: values.unit,
          email: values.email,
        }


        const user = await createUser(userDto)

        if (user) {
          toast({
            title: 'Create User',
            description: 'User created successfully',
            status: 'success',
          })

          formik.resetForm()
        }
      } catch (err) {
        toast({
          title: 'Create User',
          description: 'Error ocurred',
          status: 'error',
        })
      }
    },
  })

  const uploadBatchFile = async (e: any) => {
    e.preventDefault()
    if (batchFile) {
      uploadBatchUserUpload(batchFile)
        .then((data) => {
          toast({
            title: 'Batch Upload',
            description: 'Batch user created sucessfully',
            status: 'success',
          })

          push(HrAdminRoutes.Users.BatchUpload, { data: data })
        })
        .catch((error) =>
          toast({
            title: 'Batch Upload',
            description:
              'Upload fail, please try again :' + JSON.stringify(error),
            status: 'error',
          }),
        )
    } else {
      toast({
        title: 'Batch Upload',
        description: 'Please select a file to upload',
        status: 'error',
      })
    }
  }


  return (
    <Container
      boxShadow="base"
      maxW="container.xl"
      bg="white"
      py={3}
      px={10}
      mb={6}
      pb={10}
      minHeight="50vh"
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Text color="brand.3" fontSize={18} fontWeight="semibold">
          Create User
        </Text>

        <Button
          mt={8}
          variant="outline"
          colorScheme="brand"
          size="sm"
          px={6}
          fontSize={12}
          onClick={() => setBatchUpload(!batchUpload)}
        >
          {' '}
          {<IoIosCloudUpload />} Batch User Upload
        </Button>
      </Flex>

      {batchUpload && (
        <Box mt={4}>
          <form onSubmit={(e) => uploadBatchFile(e)}>
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />
              {!isDragActive && 'Click here or drop a file to upload!'}
              {isDragActive && !isDragReject && 'Drop file here!'}
              {isDragReject && 'File type not accepted!'}
              {acceptedFiles.length && <Text>{acceptedFiles[0].name}</Text>}
              <em>(Only *.xlsx and *.xls file will be accepted)</em>
            </div>
            <Button
              mt={4}
              isFullWidth={true}
              colorScheme="brand"
              size="sm"
              px={6}
              fontSize={12}
              type="submit"
            >
              {' '}
              Upload
            </Button>
          </form>
        </Box>
      )}

      <Box mt={4}>
        <form onSubmit={formik.handleSubmit}>
          <FormControl
            isInvalid={
              formik.errors.fullname && formik.touched.fullname ? true : false
            }
          >
            <FormLabel color="brand.subtitle" fontSize={13}>
              Full Name
            </FormLabel>
            <InputGroup>
              <InputLeftElement
                color="brand.subtitle"
                pointerEvents="none"
                children={<IoMdContact color="brand.subtitle" />}
              />
              <Input
                name="fullname"
                id="fullname"
                placeholder="Full Name"
                type="text"
                value={formik.values.fullname}
                onChange={formik.handleChange}
                fontSize={12}
              />
            </InputGroup>
            <FormErrorMessage fontSize={12}>
              {formik.errors.fullname}
            </FormErrorMessage>
          </FormControl>
          <Flex mt={8}>
            <FormControl
              isInvalid={
                formik.errors.email && formik.touched.email ? true : false
              }
            >
              <FormLabel color="brand.subtitle" fontSize={13}>
                Email Address
              </FormLabel>
              <InputGroup>
                <InputLeftElement
                  color="brand.subtitle"
                  pointerEvents="none"
                  children={<IoMdMail color="brand.subtitle" />}
                />
                <Input
                  name="email"
                  id="email"
                  autoComplete="new-email"
                  placeholder="Email Address"
                  type="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  fontSize={12}
                />
              </InputGroup>
              <FormErrorMessage fontSize={12}>
                {formik.errors.email}
              </FormErrorMessage>
            </FormControl>
            <Spacer mx={8} />
            <FormControl
              isInvalid={
                formik.errors.phone && formik.touched.phone ? true : false
              }
            >
              <FormLabel color="brand.subtitle" fontSize={13}>
                Phone
              </FormLabel>
              <InputGroup>
                <InputLeftElement
                  color="brand.subtitle"
                  pointerEvents="none"
                  children={<IoMdCall color="brand.subtitle" />}
                />
                <Input
                  name="phone"
                  id="phone"
                  placeholder="Phone"
                  type="text"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  fontSize={12}
                />
              </InputGroup>
              <FormErrorMessage fontSize={12}>
                {formik.errors.phone}
              </FormErrorMessage>
            </FormControl>
          </Flex>

          <Flex mt={8}>
            <FormControl
              isInvalid={
                formik.errors.password && formik.touched.password ? true : false
              }
            >
              <FormLabel color="brand.subtitle" fontSize={13}>
                Password:
              </FormLabel>
              <InputGroup>
                <InputLeftElement
                  color="brand.subtitle"
                  pointerEvents="none"
                  children={<IoMdLock color="brand.subtitle" />}
                />
                <Input
                  type="password"
                  autoComplete="new-password"
                  placeholder="Enter a password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  fontSize={13}
                />
              </InputGroup>
              <FormErrorMessage fontSize={12}>
                {formik.errors.password}
              </FormErrorMessage>
            </FormControl>
            <Spacer mx={8} />
            <FormControl
              isInvalid={
                formik.errors.cpassword && formik.touched.cpassword
                  ? true
                  : false
              }
            >
              <FormLabel color="brand.subtitle" fontSize={13}>
                Confirm Password
              </FormLabel>
              <InputGroup>
                <InputLeftElement
                  color="brand.subtitle"
                  pointerEvents="none"
                  children={<IoMdLock color="brand.subtitle" />}
                />
                <Input
                  type="password"
                  autoComplete="new-password"
                  placeholder="Confirm password"
                  name="cpassword"
                  onChange={formik.handleChange}
                  value={formik.values.cpassword}
                  fontSize={13}
                />
              </InputGroup>
              <FormErrorMessage fontSize={12}>
                {formik.errors.cpassword}
              </FormErrorMessage>
            </FormControl>
          </Flex>

          <Flex mt={8}>
            <FormControl
              isInvalid={
                formik.errors.role && formik.touched.role ? true : false
              }
            >
              <FormLabel color="brand.subtitle" fontSize={13}>
                Role
              </FormLabel>
              <Role
                roleId={formik.values.role}
                setRoleId={formik.handleChange}
                size="md"
              />
              <FormErrorMessage fontSize={12}>
                {formik.errors.role}
              </FormErrorMessage>
            </FormControl>

            <Spacer mx={8} />
            <FormControl
              isInvalid={
                formik.errors.unit && formik.touched.unit ? true : false
              }
            >

              <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>
                Department
              </FormLabel>
              <Select variant="outline" placeholder="Select a Unit"   value={formik.values.unit} name="unit" size='sm' onChange={formik.handleChange} fontSize={12} color="brand.subtitle" >
                {
                  categories.map(each => <option key={each.id} value={each.category_id}> {each.title} </option>)
                }
              </Select>
              <FormErrorMessage fontSize={12}>
                {formik.errors.unit}
              </FormErrorMessage>
            </FormControl>
          </Flex>

          <Flex justifyContent="flex-end">
            <Button
              mt={8}
              colorScheme="brand"
              type="submit"
              isLoading={formik.isSubmitting}
              size="sm"
              px={6}
              fontSize={12}
            >
              {' '}
              Create User
            </Button>
          </Flex>
        </form>
      </Box>
    </Container>
  )
}

export default CreateUser
