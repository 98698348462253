import { Select } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { IStateList } from "../../models/location.model";
import { fetchStates } from "../../services/location.service";

export const State: React.FC<any> = ({countryId, stateId, setStateId}) => {

    const [states, setstates] = useState<IStateList[]>([]);

    useEffect(() => {
        if(countryId)
            fetchStates(countryId)
            .then((data) => {
                let states: IStateList[] = data.map(({id, name, }) => ({id, name}) )

                setstates(states)
            })
            .catch((err) => err)
    }, [countryId]);


    return (
        <>
            <Select  variant="outline" placeholder="Select a State" size="sm" name="state" value={stateId} onChange={setStateId} fontSize={12} color="brand.subtitle" >
                {
                    states.map( each => <option key={each.id} value={each.id}> {each.name} </option>)
                }
            </Select>        
        </>
    );
}