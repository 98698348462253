import { ChevronDownIcon } from "@chakra-ui/icons";
import {
    Image,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton, Text, Box, useToast, FormControl, FormLabel, InputGroup, InputLeftElement, Input, FormErrorMessage, Spacer, Flex, Button, Checkbox, Center, Select, SimpleGrid, Icon, Textarea, HStack, CircularProgress, CircularProgressLabel, Table, Thead, Tr, Th, Tbody, Td, Avatar, Menu, MenuButton, MenuList, MenuItem,
  } from "@chakra-ui/react"
import { useEffect, useRef, useState } from "react";


import { useHistory, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import blucanaryLogo2 from "../../img/logo2.png";
import { IEachCourse, IEachCourseData, IUserDashboard } from "../../models/auth.model";
import { HrAdminRoutes } from "../../pages/HrAdmin/routes";
import { fetchUserOverview, genExcel } from "../../services/hr/users-http.service";


 




  export const ViewEachCourseModal: React.FC<any> = ({isOpen, isCourse = false, onClose, course }: {isOpen: any, isCourse:boolean, onClose: any, course:IEachCourseData[]}) => {

    const componentRef = useRef(null)
    const toast = useToast();
    
    
    let { category_id } = useParams<{ category_id: string }>()
    const [show, setShow] = useState(false);


    

    const   handlerPrint = useReactToPrint({
    
        content: () => componentRef.current as any,
        onAfterPrint: () => {
          setShow(false)
          toast({
            title: ' PDF',
            description: 'PDF File downloaded sucessfully',
            status: 'success',
          })
        }
    })
    
    const tryPDF = () =>{
    setShow(true)
    setTimeout(() => {
        
        handlerPrint()
    }, 500);
    
    }


    //   function handleExcel(){
    //     genExcel()
    //     .then((response:any) => {
    //     var blob = new Blob(
    //         [response ] ,
    //         {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"}, 
    //     );
    //     var a = document.createElement("a");
    //     let url = window.URL.createObjectURL(blob);
    //     a.href = url;
    //     a.download = "users_report.xlsx";
    //     a.click();
        
         
    //       if(response) {
    //           toast({
    //               title: ' Excel',
    //               description: 'Excel File downloaded sucessfully',
    //               status: 'success',
    
    //             })
    //              push(HrAdminRoutes.Departments.ManageDepartment)
    //           }
    //       }) 
    //       .catch((error) =>
    //         toast({
    //           title: 'Excel',
    //           description: 'Download Failed, please try again :',
    //           status: 'error',
    //         }))
    //   }


    const [isLoading, setIsLoading] = useState(true)
    
    return (
      <>
        <Modal   isOpen={isOpen && isCourse} onClose={onClose} scrollBehavior="outside" closeOnOverlayClick={true}  size="4xl" >
            <ModalOverlay />
              <ModalContent ref={componentRef}  borderRadius={5} px={6} >
              {show ? <Image pl={3} mt={4} src={blucanaryLogo2} width="200px" height="auto"  /> : <></>}  
                <ModalHeader >
                 <Text fontSize={16}>Courses</Text>
                </ModalHeader>
                <ModalCloseButton size="sm" />
                <ModalBody pb={6}>
                    <Table variant="simple" mt={2} size = "sm">
                        <Thead fontWeight="normal">
                            <Tr color="brand.subtitle">
                                <Th textTransform="none" textAlign={"start"}>Title</Th>
                                <Th textTransform="none">Instructor</Th>
                                <Th textTransform="none">Category</Th>
                                <Th textTransform="none">#Enrolled</Th>
                               
                            </Tr>
                        </Thead>
        
                        <Tbody>
                        {course && course.map((each, index) => (
                            <Tr key={index} fontSize={13} color="brand.subtitle">
                            <Td>
                                <HStack
                                spacing={3}
                                alignItems="center"
                                maxW="fit-content"
                                >
                                {/* <Avatar
                                    
                                    verticalAlign={"center"}
                                    size="sm"
                                    name={each.title}
                                    src={each.photo_url}
                                    ml={16}
                                    mr={6}
                                /> */}
                                <Text
                                    color="brand.3"
                                    whiteSpace="nowrap"
                                    isTruncated
                                    title={each.title}
                                >
                                    {each.title}
                                </Text>
                                </HStack>
                            </Td>
        
                            <Td>{each.instructor ? each.instructor : "Nil"}</Td>
        
                            <Td>{each.category? each.category : "Nil" }</Td>
        
                            <Td>{each.enrolled? each.enrolled : "-"}</Td>
                            
                        </Tr>
                        ))}
                        </Tbody>
                    </Table>
                    <Flex justifyContent={"flex-end"} mt={5}>
                        <Menu>
                            <MenuButton 
                            as={Button} rightIcon={<ChevronDownIcon />}
                                colorScheme="brand"
                                variant={"outline"}
                                size="sm"
                                fontSize={12}
                            >
                            Generate Report
                            </MenuButton>
                            <MenuList fontSize={12}>
                            <MenuItem onClick={tryPDF}> PDF</MenuItem>
                            {/* <MenuItem onClick={handleExcel}>Excel</MenuItem> */}
                            </MenuList>
                     </Menu>
                    </Flex>
                    
                </ModalBody>
                
              </ModalContent>
        </Modal>
      </>
     
    )
  }
