import {Button, FormLabel, Input, Text, Box, Link, InputGroup, Center, InputLeftElement, FormControl, FormErrorMessage, useToast} from '@chakra-ui/react';
import {MdMailOutline} from 'react-icons/md';
import {Link as ReactLink, useHistory } from 'react-router-dom';
import routes from './routes';
import { useEffect } from 'react';
import { forgotPassword, isAuthenticated } from '../services/auth.service';
import { useFormik } from 'formik';
import ForgotPasswordLayout from '../components/Common/ForgotPasswordLayout';


function ForgotPassword(){

    const toast = useToast();

    const history = useHistory();

    useEffect(() => {
        if(isAuthenticated()){
            history.replace('/')
        }
    }, [history]);

     const formik = useFormik({
        initialValues: {email: ''},
        validate: (values: any) => {
            const errors: any = {};
            if(!values.email){
                errors.email = 'Email is required';
            }else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
                errors.email = 'Invalid email address';
            }
            return errors;
        },
        onSubmit: async (values) => {

            try {
                 const response = await forgotPassword(values.email);
                
                toast({
                    title: "Forgot Password",
                    description: response.message,
                    status: "success",
                    duration: 3000,
                    onCloseComplete: () => {
                        history.replace('/')
                    }
                })
                
            } catch (error: any) {
                toast({
                    title: "Forgot Password",
                    description: error.data.message,
                    status: "error",
                })
            }
        }
    });

   return (
       <ForgotPasswordLayout>

           <Box>
                <Text mb={14} fontSize={18} fontWeight="semibold" color="brand.2">Forgot Password</Text>

                <Text fontSize={12} color="brand.subtitle" mb={14}>Enter the email address linked to your account and we would send you a link to reset your password</Text>

                <form onSubmit={formik.handleSubmit}>
                    
                    <FormControl isInvalid={(formik.errors.email && formik.touched.email) ? true : false} mb={14}>
                        <FormLabel color="brand.subtitle" fontSize={12} >EMAIL ADDRESS:</FormLabel>
                        <InputGroup>
                            <InputLeftElement 
                                color="brand.subtitle"
                                pointerEvents="none"
                                children={<MdMailOutline color="brand.subtitle" />}
                            />
                            <Input type="email" placeholder="alex@gmail.com" name="email" onChange={formik.handleChange}  fontSize={13} />
                        </InputGroup>
                        <FormErrorMessage fontSize={12} >{formik.errors.email}</FormErrorMessage>
                    </FormControl>
                    
                    <Center>
                        <Button w="100%" type="submit" bg="brand.2" color="white" mb={6} fontSize={13} isLoading={formik.isSubmitting}> Reset Password </Button>
                    </Center>

                    <Center>
                        <Text color="brand.bodyText" fontSize={12} alignSelf="center" >Have an account? <Link as={ReactLink} to={routes.Login} color="brand.2">Login</Link> </Text>
                    </Center>
                </form>
           </Box>

       </ForgotPasswordLayout>
    );
}

export default ForgotPassword;