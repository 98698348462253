import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Button, Flex, Textarea, useToast, FormErrorMessage, ModalHeader, ModalCloseButton, FormHelperText, Text, FormControl
} from "@chakra-ui/react"
import { useRef, useState } from "react";


export const CourseDeclineModal: React.FC<any> = ({ isOpen, onClose, courseIndex, courseId, courseApproval }: { isOpen: any, onClose: any, courseIndex: number, courseId: string, courseApproval: (truth: boolean, courseId: string, courseIndex: number, message?: string, action?: string) => Promise<void> }) => {
  const toast = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [value, setValue] = useState('');

  const declineRef = useRef<HTMLTextAreaElement>(null);
  let handleInputChange = (e: any) => {
    let inputValue = e.target.value
    setValue(inputValue)
  }
  const courseDecline = async (e: any) => {
    
  
    console.log(declineRef.current?.value, 'textarea value')


    setIsSubmitting(true);
    try {
      if (declineRef.current?.value === '') {
        toast({
          title: "Course Decline",
          description: "State reason for declining",
          status: "error",
          duration: 2000
        })
        setIsSubmitting(false);
      } else {
        let inputValue = e.target.value
        setValue(inputValue)
        await courseApproval(false, courseId, courseIndex, declineRef.current?.value,'decline');

        setIsSubmitting(false);

        onClose();
      }



    } catch (error) {

    }
  }


  return (
    <>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="xl" scrollBehavior="inside" >
        <ModalOverlay />
        <ModalContent borderRadius={5} px={6} >
          <ModalHeader fontSize={17} color="brand.2" >Kindly provide feedback on why course was declined</ModalHeader>

          <ModalCloseButton size="sm" fontSize={9} />

          <ModalBody pb={4} >


            <Textarea
             onChange={handleInputChange}
              value={value}
              ref={declineRef}
              p={4}
              mb={6}
              fontSize={12}
              size="sm"
              minH="15rem"
              isRequired
            />

            <Flex justifyContent={"end"} justifyItems={"end"}>
              <Button colorScheme="brand" size="sm" fontSize={12} borderRadius={3} px={6} onClick={(e) => courseDecline(e)} isLoading={isSubmitting} > SEND </Button>
            </Flex>




          </ModalBody>

        </ModalContent>
      </Modal>
    </>
  )
}