import { Box, Button, Container, IconButton, Text } from "@chakra-ui/react";
import { Table, Thead, Tbody, Tr, Th, Td} from "@chakra-ui/react"
import { useState } from "react";
import { BsDownload } from "react-icons/bs";
import { EmptyInvoice } from "../../components/Student/EmptyState/Invoice";


const Invoice = () => {

    const [invoice, setInvoice] = useState([]);

    return (
        <>
            <Container boxShadow="base" p={3}  maxW="container.xl"  mb={6} bg="white">
                <Text color="brand.3" fontSize={18} pl={6} fontWeight="semibold" >Invoices </Text>
            </Container>

            <Container boxShadow="base" p={3}  maxW="container.xl"  mb={6} bg="white" minHeight="50vh">

                {
                    invoice.length === 0 ?

                    <EmptyInvoice />

                    :
                    
                    <Box px={6}>
                        <Table variant="unstyled">
                            <Thead fontWeight="normal" borderBottomWidth="1.1px" borderColor="#D5D5D5">
                                <Tr color="brand.3" >
                                    <Th>ORDER ID</Th>
                                    <Th>DATE</Th>
                                    <Th>AMOUNT</Th>
                                    <Th>STATUS</Th>
                                    <Th></Th>
                                </Tr>
                            </Thead>
            
                            <Tbody>
                                {
                                    Array(7).fill("").map((each, index) => 
                                        <Tr key={index} fontSize={13} color="brand.subtitle">
                                            <Td>#1845</Td>
                                            <Td>12 April 2020, 12:30PM</Td>
                                            <Td>$45</Td>
                                            <Td><Button size="xs" bg="#19CB98" color="white" fontSize={10}  >COMPLETE</Button></Td>
                                            <Td>
                                                <IconButton
                                                    variant="ghost"
                                                    aria-label="Download"
                                                    color="brand.2"
                                                    icon={<BsDownload />}
                                                />
                                            </Td>
                                        </Tr>
                                    )
                                }
                                
                            </Tbody>
                        </Table>
                    </Box>
                }

                
            </Container>
        </>
    );
}

export default Invoice;