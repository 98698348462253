import { Box, Text, Flex, Image, Button } from "@chakra-ui/react";
import React from "react";
import { IEnrollmentWithCourse } from "../../models/course.model";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton
} from "@chakra-ui/react"
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { IUser } from "../../models/auth.model";
import { firstName } from "../../utils/firstName.util";
import { FaAward } from "react-icons/fa";


export const StudentBadgeModal: React.FC<any> = ({ isOpen, onClose, data, user }: { isOpen: any, onClose: any, data: IEnrollmentWithCourse, user: IUser }) => {

    const downloadBadge = () => {

        const input = document.getElementById('divToPrint');

        html2canvas(input as HTMLElement, { useCORS: true })
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF({ orientation: 'landscape' });
                const imgProps = pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                pdf.addImage(imgData, 'PNG', 0, pdfHeight / 2, pdfWidth, pdfHeight);
                pdf.save(firstName(user.full_name) + "_badge.pdf");
            })
    }

    return (
        <>

            <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="outside" closeOnOverlayClick={false} size="4xl">
                <ModalOverlay />
                <ModalContent borderRadius={5} px={6} >
                    <ModalHeader >
                    </ModalHeader>
                    <ModalCloseButton size="sm" />
                    <ModalBody pb={6}>
                       <Box mt={4} id={"divToPrint"}  mb='20px' p='5px'>
                       <Box  mx={3}  p='4px'   border='2px' borderColor='blue'>
                            <Flex mx={2} p='10px' mb='10px' mt='10px'  border='2px' borderColor='blue' direction={"column"}  justifyContent={"center"} alignContent={"center"} alignItems={"center"} >

                                <Image mb={3} src={data?.courses.level?.badge_url_link} loading="eager" width="13em" height="auto" alt='Badge' />

                                <Text mb={4} fontSize={25} color="brand.2" fontWeight={"semibold"} >Congratulations, <Box as={"span"}> {firstName(user.full_name)} </Box></Text>

                                <Text  color="brand.subtitle" fontSize={12} >You just completed the course </Text>

                                <Text mb={2} fontWeight={"semibold"} color={"brand.2"}> {data?.courses.title} </Text>

                                <Text mb={6} fontSize={12} fontWeight={"semibold"} color={"brand.subtitle"}>On {new Date(data?.completedAt as string).toDateString()} </Text>

                            </Flex>
                        </Box>
                       </Box>



                        <Button mx={2} variant="outline" colorScheme="brand" onClick={() => downloadBadge()} leftIcon={<FaAward fontSize={14} />} fontSize={12} width="7rem" size="sm" > Download </Button>

                    </ModalBody>
                </ModalContent>
            </Modal>

        </>
    );
}