import { Box, Button, Flex, Text, Image } from "@chakra-ui/react";
import card from '../../../img/card.svg';


export const EmptyBilling: React.FC<any> = () => {

    return (
        <Flex minHeight="inherit"  justifyContent="center">
        
            <Flex direction="column" alignItems="center" justifyContent="center" >

                <Box color="brand.subtitle" mb={6}>
                    <Image height="3em" width="auto" src={card} />
                </Box>

                <Text mb={4} fontSize={13} color="brand.subtitle"> No card added. Add new card below </Text>

                <Button colorScheme="brand" size="sm" px={6} fontSize={11}>Add Card</Button>

            </Flex>
        </Flex>
    );
}