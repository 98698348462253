import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton, Avatar, Text, HStack, Box, Progress, Flex, Button, Select
} from "@chakra-ui/react"
import { ICourseProgressList, ITrainingMgrCourseList, } from "../../models/course.model"
import { Table, Thead, Tbody, Tr, Th, Td} from "@chakra-ui/react"
import { ScreenLoader } from "../Common/ScreenLoader"
import { EmptyRecordResult } from "../Common/EmptyRecords"
import { BsDownload } from "react-icons/bs"
import { dateFilters } from "../../utils/report.util"

export const CourseProgressModal: React.FC<any> = ({isOpen, onClose, coursesProgress, isLoading, selectedCourse, flterCourse }: {isOpen: any, onClose: any, coursesProgress: ICourseProgressList[], isLoading:boolean, selectedCourse: ITrainingMgrCourseList, flterCourse:any }) => {

    return (
    <>
        
      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="outside" closeOnOverlayClick={false}  size="6xl">
        <ModalOverlay />
        <ModalContent borderRadius={5} px={6} >
            <ModalHeader mt={4}>
                <Flex justifyContent="space-between" alignItems="center">
                    <Text fontSize={16}> {selectedCourse?.title} </Text>               
                    
                        <Flex justifyContent="center" alignItems="center" >
                            <Flex mr={10} justifyContent="space-between" alignItems="center" >
                                <Text color="brand.subtitle" whiteSpace="nowrap" mr={3} fontSize={12} >Filter by:</Text>

                                <Select size="sm" fontSize={11} color="brand.subtitle" onChange={(e) => flterCourse(selectedCourse.courseId, e.target.value)} >
                                    {
                                        dateFilters().map( (each, index) => 
                                            <option key={index} value={each.value} > {each.title} </option>
                                        )
                                    }
                                </Select>
                            </Flex>
                            { coursesProgress.length > 0 && <Button colorScheme="brand" mr={10} variant="outline" leftIcon={<BsDownload />} onClick={() => console.log() } size="sm" fontSize={11}> Generate Report</Button> }
                        </Flex>
                </Flex>
            </ModalHeader>
            
            <ModalCloseButton color="brand.2" size="sm" />
            
            <ModalBody pb={6}>

                {
                    isLoading ? 
                    
                    <ScreenLoader />

                    :
                    
                    <>
                        {
                            coursesProgress.length < 1 ? 

                            <EmptyRecordResult />

                            :

                            <Table variant="simple" mt={5} >
                                
                                <Thead fontWeight="normal" >
                                    <Tr color="brand.subtitle" >
                                        <Th textTransform="none">Name</Th>
                                        {/* <Th textTransform="none">Visit Time</Th> */}
                                        <Th textTransform="none">Duration of Course</Th>
                                        {/* <Th textTransform="none">Time Spent on Course</Th> */}
                                        <Th textTransform="none">Start Date</Th>
                                        <Th textTransform="none">End Date</Th>
                                        <Th textTransform="none">% of Progress</Th>
                                        {/* <Th textTransform="none">Pass/Fail Rate</Th> */}
                                    </Tr>
                                </Thead>

                                <Tbody>
                                    {
                                        coursesProgress.map((each, index) => 
                                            <Tr key={index} fontSize={12} color="brand.subtitle">
                                                <Td>
                                                    <HStack>
                                                        <Avatar size="xs" name={each.name} src={each.img_url} />
                                                        <Text isTruncated fontSize={11}>{each.name}</Text>                                                
                                                    </HStack>
                                                </Td>
                                                <Td> {each.courseDuration} </Td>
                                                <Td>{each.startDate}</Td>
                                                <Td>{each.endDate}</Td>
                                                <Td width="20%" ><Flex justifyContent="center" alignItems="center" > <Progress width="100%" value={each.progress} borderRadius={20} size="xs" mr={3} /> <Box as="span" whiteSpace="nowrap" fontSize={10}> {each.progress}% </Box> </Flex></Td>
                                            </Tr>
                                        )
                                    }
                                    
                                </Tbody>
                            </Table>
                        }
                    </>
                }

            </ModalBody>

            <ModalFooter>
                
            </ModalFooter>
        </ModalContent>
      </Modal>

    </>
  )
}