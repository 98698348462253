import { Button, useToast } from '@chakra-ui/react'
import XLSX from 'sheetjs-style'
import * as FileSaver from 'file-saver'

const ExportExcel = ({ excelData, fileName }: { excelData: any, fileName: any }) => {
const toast = useToast()
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8';
    const fileExtension ='.xlsx';

    const exportToExcel = async () =>{
        toast({
            title: ' Excel',
            description: 'Excel File downloaded sucessfully',
            status: 'success',
          })
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = {Sheets: {'data' : ws}, SheetNames: ['data']};
        const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
        const data = new Blob([excelBuffer], {type: fileType})
        FileSaver.saveAs(data, fileName + fileExtension)
    }

    return (
        <>
            <Button mr={3} borderRadius={0} colorScheme='blue' size='sm' fontSize={12} onClick={() => {exportToExcel()}}>
                Generate Excel 
            </Button>
        </>
    )
}

export default ExportExcel;