import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton, Button, Flex, InputGroup, Input, InputRightElement, VStack, StackDivider, Avatar, Text, HStack, Box, Divider, useToast, FormControl, FormErrorMessage, FormLabel, InputRightAddon
} from "@chakra-ui/react"
import { ChangeEvent, useEffect, useState } from "react";
import { BsCheck, BsPlus } from "react-icons/bs";
import { RiSearch2Line } from "react-icons/ri"
import { IUser } from "../../models/auth.model";
import { assignCourseToStudentService, fetchStudents } from "../../services/trainingMgr/course-http.service";
import { EmptySearchResult } from "../Common/EmptySearchResult";


export const AssignCourseModal: React.FC<any> = ({ isOpen, onClose, selectedCourseId, studentIds, updateRecord }: { isOpen: any, onClose: any, selectedCourseId: any, studentIds: string[], updateRecord: any }) => {

    const [isSearchEmpty, setIsSearchEmpty] = useState(false);
    const [assignedDuration, setAssignedDuration] = useState('');
    const [startDate, setStartDate] = useState('');
    const [students, setStudents] = useState<IUser[]>([]);

    const [studentsMemo, setStudentsMemo] = useState<IUser[]>([]);

    const [filteredStudents, setFilteredStudents] = useState<IUser[]>([]);

    const [pickedStudents, setPickedStudents] = useState<IUser[]>([]);

    const [isEnrolling, setIsEnrolling] = useState(false);

    const toast = useToast();


    useEffect(() => {
        fetchStudents()
            .then(data => {
                setFilteredStudents(data)
                setStudents(data);
                setStudentsMemo(data);
            })
            .catch(e => e)

    }, []);


    useEffect(() => {

        let unAssignedStudents = students.filter(each => !(studentIds.includes(each.user_id as string)))

        setFilteredStudents(unAssignedStudents);
        setStudents(unAssignedStudents);

    }, [studentIds]);

    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {

        let searchTerm = event.target.value.toLowerCase();

        let filtered = students?.filter(each => each.full_name.toLowerCase().includes(searchTerm));

        setFilteredStudents(filtered);

        if (filtered?.length > 0)
            setIsSearchEmpty(false);
        else
            setIsSearchEmpty(true)
    }

    const pickUser = (index: number) => {
        let user = filteredStudents?.at(index);
        console.log(user, 'user')
        console.log(pickedStudents, 'picked students')

        let indexOfUser = pickedStudents?.findIndex(each => each.id == user?.id)
        console.log(indexOfUser, 'index')

        if (indexOfUser >= 0) {
            pickedStudents?.splice(indexOfUser, 1)
            setPickedStudents([...pickedStudents])
            console.log('picked students', pickedStudents)
        } else {
            setPickedStudents([user as IUser, ...pickedStudents as IUser[]])
            console.log(pickedStudents, 'index-1')
        }
    }

    const isPicked = (index: number): boolean => {

        let user = filteredStudents?.at(index);

        let userIndex = pickedStudents?.findIndex(each => each.id === user?.id)

        return userIndex >= 0 ? true : false;
    }

    const assignCourseToStudents = async () => {

        setIsEnrolling(true);
      
        const totalSeconds = parseInt(assignedDuration) * 24 * 3600
        let data = {
            type: "some",
            courseId: selectedCourseId,
            userIds: pickedStudents.map(each => each.user_id) as string[],
            assignedDuration: totalSeconds,
            startDate: startDate
        }

        try {
            const response = await assignCourseToStudentService(data);

            setPickedStudents([]);

            updateRecord(response.data);

            onClose();

            toast({
                title: "Assign Course",
                description: response.message,
                status: "success",
                duration: 3000
            })

        } catch (error) {

            toast({
                title: "Assign Course",
                description: "Something went Wrong. Try again later",
                status: "error",
            })

        } finally {
            setIsEnrolling(false);
        }
    }

    const closeModal = () => {

        setPickedStudents([]);

        setStudents(studentsMemo);

        onClose()
    }


    return (
        <>
            <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={closeModal} scrollBehavior="inside" >
                <ModalOverlay />
                <ModalContent borderRadius={5} px={6} >
                    <ModalHeader color="brand.2" fontSize={16}>Assign course to student</ModalHeader>
                    <ModalCloseButton size="sm" />
                    <ModalBody pb={6}>
                        <Flex justifyContent="space-between" alignItems="center" mb={3}>
                            <InputGroup color="brand.subtitle" bg="white" >
                                <Input placeholder="Search student" onChange={e => handleSearchChange(e)} fontSize={12} size="sm" />

                                <InputRightElement h="1.9rem" pointerEvents="none" children={<RiSearch2Line size="0.9rem" />} />
                            </InputGroup>
                        </Flex>

                        {
                            pickedStudents.length > 0 &&
                            <Box mb={2} justifyContent="center" display="flex">
                                <Text fontSize={12} color="brand.2" > {pickedStudents?.length} <Box color="brand.subtitle" as="span"> students selected </Box></Text>
                            </Box>
                        }

                        <Divider />

                        <VStack
                            mt={4}
                            divider={<StackDivider borderColor="gray.200" />}
                            spacing={4}
                            align="stretch"

                        >
                            {
                                isSearchEmpty ?

                                    <EmptySearchResult />

                                    :

                                    filteredStudents?.map((each, index) =>

                                        <Flex key={index} justifyContent="space-between">

                                            <HStack>
                                                <Avatar size="xs" name={each.full_name} src={each.photo_url} />
                                                <Box>
                                                    <Text isTruncated fontSize={11}>{each.full_name}</Text>
                                                    <Text isTruncated color='#7883A7' fontSize='9px'>{each.email}</Text>
                                                </Box>

                                            </HStack>

                                            <Box width={6} color={isPicked(index) ? "success.100" : "brand.bodyText"} height={6} borderRadius={20} border="dotted" justifyContent="center" alignItems="center" cursor="pointer" onClick={() => pickUser(index)}>
                                                <Flex direction="column" justifyContent="center" height="100%" alignItems="center" fontSize={16}>
                                                    {
                                                        isPicked(index) ?
                                                            <BsCheck />
                                                            :
                                                            <BsPlus />
                                                    }
                                                </Flex>
                                            </Box>
                                        </Flex>
                                    )
                            }

                        </VStack>

                    </ModalBody>

                    <ModalFooter>
                        {
                            pickedStudents.length > 0 &&
                            (
                                <Box>
                                    <HStack mt={4} spacing={10}>
                                        <FormControl >
                                            <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>Expected Start Date</FormLabel>
                                            <InputGroup>
                                                <Input placeholder="Expected Start Date" type="date" bg="inputText.500" name="startDate" onChange={(e) => setStartDate(e.target.value)} fontSize={12} />
                                            </InputGroup>
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>Assigned Duration</FormLabel>
                                            <InputGroup>
                                                <Input type="number" bg="inputText.500" name="assignedDuration" onChange={(e) => setAssignedDuration(e.target.value)} fontSize={12} />
                                                <InputRightAddon>
                                                    days
                                                </InputRightAddon>
                                            </InputGroup>
                                        </FormControl>
                                    </HStack>
                                    <Flex mt={'20px'} justifyContent={'flex-end'}>
                                        < Button colorScheme="brand" mr={3} size="xs" isLoading={isEnrolling} fontSize={11} borderRadius={2} px={5} onClick={assignCourseToStudents}> Done </Button>
                                    </Flex>  </Box>

                            )
                        }

                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}