import { Box, Container, Flex, HStack, Image, Input, InputGroup, Text, InputRightElement, Avatar, useDisclosure, Portal, AvatarGroup } from "@chakra-ui/react";
import { Table, Thead, Tbody, Tr, Th, Td} from "@chakra-ui/react"
import { RiSearch2Line } from "react-icons/ri";
import { ChangeEvent, useEffect, useState } from "react";
import { EmptyCourseTM } from "../../components/TrainingManager/EmptyState/Courses";
import { courseAssignWithStudentsListUtil } from "../../utils/course.util";
import { IInstructorCourseAssignList } from "../../models/course.model";
import { EmptySearchResult } from "../../components/Common/EmptySearchResult";
import { ScreenLoader } from "../../components/Common/ScreenLoader";
import { BsPlus } from "react-icons/bs";
import { AssignCourseModal } from "../../components/TrainingManager/AssignCourseModal";
import { IUser } from "../../models/auth.model";
import { fetchAssignedCourses } from "../../services/instructor/courses-http.service";


const AssignCourse = () => {

    const [assignCourses, setAssignCourses] = useState<IInstructorCourseAssignList[]>([]);
    
    const [filteredAssignCourses, setfilteredAssignCourses] = useState<IInstructorCourseAssignList[]>([]);

    const [isSearchEmpty, setIsSearchEmpty] = useState(false);

    const [isLoading, setIsLoading] = useState(true);
    
    const { isOpen, onOpen, onClose } = useDisclosure()

    const [selectedCourse, setselectedCourse] = useState('');

    const [enrolledStudentIds, setEnrolledStudentIds] = useState<string[]>([]);

    const [searchTerm, setSearchTerm] = useState('');


     useEffect(() => {

        fetchAssignedCourses()
         .then((data) => {
            setfilteredAssignCourses(courseAssignWithStudentsListUtil(data));
            setAssignCourses(courseAssignWithStudentsListUtil(data));
            setIsLoading(false);
        })
        .catch((err) => (err))

    }, []);

    const handleSearchChange  = (event: ChangeEvent<HTMLInputElement>) => {
        
        let searchTerm = event.target.value.toLowerCase();

        setSearchTerm(searchTerm);
        
        let filtered = assignCourses.filter(each => each.title.toLowerCase().includes(searchTerm) );
        
        setfilteredAssignCourses(filtered);
        
        if(filtered.length > 0)
            setIsSearchEmpty(false);
        else
            setIsSearchEmpty(true)
    }


    const setCourseId = (each: IInstructorCourseAssignList) => {
        setselectedCourse(each.courseId as string);
        setEnrolledStudentIds([...each.students?.map(each => each.user_id) as string[]]);
        onOpen();
    }

    const updateRecord = (students: IUser[]) => {

        let courseId = selectedCourse;

        let foundCourse = assignCourses.filter(each => each.courseId === courseId).at(0);
        
        let foundCourseIndex = assignCourses.findIndex(each => each.courseId === courseId);

        foundCourse?.students?.push(...students)

        assignCourses.splice(foundCourseIndex, 1, foundCourse as IInstructorCourseAssignList);

        setAssignCourses(assignCourses);

        let filtered = assignCourses.filter(each => each.title.toLowerCase().includes(searchTerm) );
    
        setfilteredAssignCourses(filtered);
    }

    return (

        <Container boxShadow="base" maxW="container.xl" bg="white" pt={3} pb={10} px={8} minHeight="70vh">

            {
                isLoading? 
                <ScreenLoader />
                
                :
                assignCourses.length === 0 ? 
                <EmptyCourseTM />
                :
                <Box>

                    <Flex justifyContent="space-between">
                        <Text pl={6} color="brand.3" fontSize={18} fontWeight="semibold"> Assign Course </Text>
                    </Flex>

                    <Flex justifyContent="space-between" alignItems="center" px={6} mt={6}>
                        <InputGroup color="brand.subtitle" bg="white" width="40%" >
                            <Input placeholder="Search all courses" onChange={ e => handleSearchChange(e)} fontSize={12} size="sm" />

                            <InputRightElement h="1.9rem" pointerEvents="none" children={<RiSearch2Line size="0.9rem"  />} />
                        </InputGroup>
                    </Flex>
                    
                    {
                        isSearchEmpty ?

                        <Box pt={20}>
                            <EmptySearchResult />
                        </Box>

                        :

                        <Table variant="simple" mt={5} >
                            <Thead fontWeight="normal" >
                                <Tr color="brand.subtitle" >
                                    <Th textTransform="none">Name</Th>
                                    <Th textTransform="none">Date Created</Th>
                                    <Th textTransform="none">Assign To</Th>
                                </Tr>
                            </Thead>

                            <Tbody>
                                {
                                    filteredAssignCourses.map((each, index) => 
                                        <Tr key={index} fontSize={13} color="brand.subtitle">
                                            <Td>
                                                <HStack>
                                                    <Image src={each.cover_image} fallbackSrc="https://via.placeholder.com/150" height="auto" maxWidth="50px" />
                                                    <Text fontSize={11}>{each.title}</Text>                                                
                                                </HStack>                                            
                                            </Td>
                                            <Td>{each.date_added}</Td>
                                            <Td> 
                                                <HStack alignItems="center" spacing={4}>
                                                     {/* <Switch />
                                                     <Text>OR</Text> */}
                                                    <HStack spacing={2}>
                                                        <AvatarGroup size="xs" fontSize={10} color="brand.2" max={3}>
                                                            {
                                                                each.students?.map( (eachStudent, studentIndex) => 
                                                                    <Avatar key={studentIndex} name={eachStudent.full_name} src={eachStudent.photo_url} />
                                                                )
                                                            }
                                                        </AvatarGroup>

                                                        <Box width={6} height={6} borderRadius={20} border="dotted" justifyContent="center" alignItems="center" cursor="pointer" onClick={ () => setCourseId(each)  }>
                                                            <Flex direction="column" alignItems="center" fontSize={16} justifyContent="center" height="100%">
                                                                <BsPlus   />
                                                            </Flex>
                                                        </Box>
                                                    
                                                    </HStack>
                                                     
                                                </HStack>
                                            </Td>
                                        </Tr>
                                    )
                                }
                                
                            </Tbody>
                        </Table>
                    }
                    
                </Box>
            }

            <Portal> <AssignCourseModal selectedCourseId={selectedCourse} studentIds={enrolledStudentIds} updateRecord={updateRecord} isOpen={isOpen} onClose={onClose} /> </Portal>

        </Container>   
   );
}

export default AssignCourse;