import { Select } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { ICountryList } from "../../models/location.model";
import { fetchCountries } from "../../services/location.service";

export const Country: React.FC<any> = ({countryId, setCountryId, ...rest }) => {

    const [countries, setcountries] = useState<ICountryList[]>([]);

    useEffect(() => {
        fetchCountries()
        .then((data) => {
            let countries: ICountryList[] = data.map(({id, name, }) => ({id, name}) )

            setcountries(countries)
        })
        .catch((err) => err)
        
    }, []);
    
    return (
        <>
            <Select {...rest}  variant="outline" placeholder="Select a Country" name="country" value={countryId} size="sm" onChange={setCountryId} fontSize={12} color="brand.subtitle" >
                {
                    countries.map( each => <option key={each.id} value={each.id}> {each.name} </option>)
                }
            </Select>
        </>
    );
}