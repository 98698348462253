import { HrAdminRoutes } from "../pages/HrAdmin/routes"
import { InstructorRoutes } from "../pages/Instructor/routes"
import { ManagementRoutes } from "../pages/Management/routes"
import { StudentRoutes } from "../pages/Student/routes"
import { TrainingMgrRoutes } from "../pages/TrainingManager/routes"

export enum Roles {
    STUDENT = "STUDENT",
    INSTRUCTOR = "INSTRUCTOR",
    TRAINING_MANAGER = "TRAINING MANAGER",
    HR_ADMIN = "HR ADMIN",
    MANAGEMENT = "MANAGEMENT"
}

export enum RoutingRoles {
    STUDENT = "STUDENT",
    INSTRUCTOR = "INSTRUCTOR",
    TRAINING_MANAGER = "TRAINING_MANAGER",
    HR_ADMIN = "HR_ADMIN",
    MANAGEMENT = "MANAGEMENT"
}

export const ROLESMAP = {
    STUDENT: ['STUDENT'],
    INSTRUCTOR: ['INSTRUCTOR', 'STUDENT'],
    TRAINING_MANAGER: ['TRAINING MANAGER', 'INSTRUCTOR', 'STUDENT'],
    HR_ADMIN: ['HR ADMIN', 'TRAINING MANAGER', 'INSTRUCTOR', 'STUDENT'],
    MANAGEMENT: ['MANAGEMENT', 'HR ADMIN', 'TRAINING MANAGER', 'INSTRUCTOR', 'STUDENT']
}

export const ROLEROUTES = {
    STUDENT: StudentRoutes.Dashboard,
    INSTRUCTOR: InstructorRoutes.Main,
    TRAINING_MANAGER: TrainingMgrRoutes.Main,
    HR_ADMIN: HrAdminRoutes.Main,
    MANAGEMENT: ManagementRoutes.Main
}