import { Avatar, Box, Button, Container, Flex, HStack, IconButton, Image, useDisclosure, InputGroup, Menu, Collapse, MenuButton, MenuList, MenuItem, Link, Text } from "@chakra-ui/react";
import {Link as ReactLink, Route, Switch, useRouteMatch, useHistory} from 'react-router-dom';
import {RiDashboardFill, RiUserSettingsLine} from 'react-icons/ri';
import { BsBell} from 'react-icons/bs';
import { HiOutlineAcademicCap} from 'react-icons/hi';
import {BiBookAdd, BiBookBookmark} from 'react-icons/bi';
import {MdKeyboardArrowDown, MdSecurity} from 'react-icons/md';
import { FiUsers } from "react-icons/fi";
import blucanaryLogo2 from '../../img/logo2.png'
import Dashboard from "../../pages/TrainingManager/Dashboard";
// import Student from "../../pages/Instructor/Student";
import Profile from "../../pages/Instructor/Profile";
import Security from "../../pages/Instructor/Security";
import CreateCourse from "../../pages/Instructor/CreateCourse";
import { destroyUserSession, getLocalUserProfile } from "../../services/auth.service";
import { StoreContext } from "../../store/StoreContext";
import { useContext, useEffect } from "react";
import { TrainingMgrRoutes } from "../../pages/TrainingManager/routes";
import AllCourses from "../../pages/TrainingManager/AllCourses";
import CourseStatus from "../../pages/TrainingManager/CourseStatus";
import AssignCourse from "../../pages/TrainingManager/AssignCourse";
import AllGroups from "../../pages/TrainingManager/AllGroups";
import AllGroupDiscussion from "../../pages/TrainingManager/GroupDiscussion";
import CreateGroupTM from "../../pages/TrainingManager/CreateGroup";
import StudentOverview from "../../pages/TrainingManager/StudentOverview";
import { NavLink } from "../Common/NavLink";
import PreviewCourse from "./PreviewCourse";
import EditCourse from "../../pages/Instructor/EditCourse";
import { Error404 } from "../Common/Error404";


export const TrainingMgrMain: React.FC<any> = () => {

    const { isOpen: isOpenCourse, onToggle: onToggleCourse } = useDisclosure()
    
    const { isOpen: isOpenGroup, onToggle: onToggleGroup } = useDisclosure()

    const {globalState, actions} = useContext(StoreContext);

    let {isExact} = useRouteMatch();

    let {location, push, replace} = useHistory();

    useEffect(() => {
        let user = getLocalUserProfile();

        if(user != null){
            actions?.updateProfile(user);
        }
    }, []);

    const handleLogout = () => {
        destroyUserSession();
        replace('/');
    }

    const activeLink = (link: string) => {

        let style = {
            borderLeftColor: "white",
            bg: "white"
        }

        if(link === location.pathname ){
            style.bg = "brand.1";
            style.borderLeftColor = "brand.2"
        }

        return style;
    }

    return (
        <Box minHeight="100vh">

            <Flex>

                <Container maxWidth="18%" >
                </Container>

                <Container maxWidth="18%" minHeight="100%" pt={4} boxShadow="base" bg="white" position="fixed" px={0} overflow="auto">
                    
                    <Image pl={10}  src={blucanaryLogo2} width="70%" height="auto"  />

                    <Flex direction="column" fontSize={13} color="brand.bodyText"  pt={12} >
                        
                        <NavLink pl={9} label="Dashboard" to={TrainingMgrRoutes.Main} activeOnlyWhenExact={true} icon={<RiDashboardFill size={18} />} />

                        <Box py={2} borderLeftWidth="4px">

                            <Box cursor="pointer" pb={5} onClick={onToggleCourse} > <Box pl={9} display="flex" alignItems="end" > <BiBookBookmark size={18} /> <Text mr={2} pl={3}>Courses</Text> <MdKeyboardArrowDown fontSize={18} /></Box></Box>
                            
                            <Collapse in={isOpenCourse}  >
                                <Link  as={ReactLink} to={TrainingMgrRoutes.Course.Fetch} > <Box mb={5} py={2} borderLeftWidth="4px" bg={activeLink(TrainingMgrRoutes.Course.Fetch).bg} borderLeftColor={activeLink(TrainingMgrRoutes.Course.Fetch).borderLeftColor} pl="5em" > All Courses</Box> </Link>
                                <Link  as={ReactLink} to={TrainingMgrRoutes.Course.Status} > <Box mb={5} py={2} borderLeftWidth="4px" bg={activeLink(TrainingMgrRoutes.Course.Status).bg} borderLeftColor={activeLink(TrainingMgrRoutes.Course.Status).borderLeftColor}  pl="5em" > Course Status</Box > </Link>
                                <Link  as={ReactLink} to={TrainingMgrRoutes.Course.Assign} > <Box mb={5} py={2} borderLeftWidth="4px" bg={activeLink(TrainingMgrRoutes.Course.Assign).bg} borderLeftColor={activeLink(TrainingMgrRoutes.Course.Assign).borderLeftColor}  pl="5em" > Assign Course</Box > </Link>
                                <Link  as={ReactLink} to={TrainingMgrRoutes.Course.Create} > <Box mb={5} py={2} borderLeftWidth="4px" bg={activeLink(TrainingMgrRoutes.Course.Create).bg} borderLeftColor={activeLink(TrainingMgrRoutes.Course.Create).borderLeftColor}  pl="5em" > Add Course</Box > </Link>
                            </Collapse>
                        </Box>

                        <NavLink pl={9} label="Students Overview" to={TrainingMgrRoutes.Student} icon={<HiOutlineAcademicCap size={18} />} />
                        
                        <Box py={2} borderLeftWidth="4px">

                            <Box cursor="pointer" pb={5} onClick={onToggleGroup} > <Box pl={9} display="flex" alignItems="end" > <FiUsers size={18} /> <Text mr={2} pl={3}>Groups</Text> <MdKeyboardArrowDown fontSize={18} /></Box></Box>
                            
                            <Collapse in={isOpenGroup}  >
                                <Link  as={ReactLink} to={TrainingMgrRoutes.Group.Fetch} > <Box mb={5} py={2} borderLeftWidth="4px" bg={activeLink(TrainingMgrRoutes.Group.Fetch).bg} borderLeftColor={activeLink(TrainingMgrRoutes.Group.Fetch).borderLeftColor}  pl="5em"> All Groups</Box > </Link>
                                <Link  as={ReactLink} to={TrainingMgrRoutes.Group.Discussion} > <Box mb={5} py={2} borderLeftWidth="4px" bg={activeLink(TrainingMgrRoutes.Group.Discussion).bg} borderLeftColor={activeLink(TrainingMgrRoutes.Group.Discussion).borderLeftColor}  pl="5em"> Discussions</Box > </Link>
                                <Link  as={ReactLink} to={TrainingMgrRoutes.Group.Create} > <Box mb={5} py={2} borderLeftWidth="4px" bg={activeLink(TrainingMgrRoutes.Group.Create).bg} borderLeftColor={activeLink(TrainingMgrRoutes.Group.Create).borderLeftColor}  pl="5em"> Create Group</Box > </Link>
                            </Collapse>
                        </Box>

                        <NavLink pl={9} label="Edit Profile" to={TrainingMgrRoutes.Profile} icon={<RiUserSettingsLine size={18} />} />
                        <NavLink pl={9} label="Security" to={TrainingMgrRoutes.Security} icon={<MdSecurity size={18} />} />

                    </Flex>

                </Container>

                <Container maxW="container.xl" ml={16} mr={16} pt={4} >

                    <Flex justifyContent="space-between" alignItems="center">
                        {
                            !isExact ? <Box></Box> : <InputGroup color="brand.subtitle" bg="white" w="30%" >
                                {/* <Input placeholder="Search courses" fontSize={12} size="sm" /> */}

                                {/* <InputRightElement h="1.9rem" pointerEvents="none" children={<RiSearch2Line size="0.9rem"  />} /> */}
                            </InputGroup>
                        }

                        <HStack spacing={6}>
                            <Button colorScheme="brand" leftIcon={<BiBookAdd />} onClick={() => push(TrainingMgrRoutes.Course.Create)} size="sm" fontSize={12}>New Course</Button>
                            
                            <IconButton
                                variant="ghost"
                                aria-label="bell"
                                color="brand.2"
                                borderRadius={20}
                                icon={<BsBell />}
                            />
                            
                            <Avatar size="sm" name={globalState.profile?.full_name} src={globalState.profile?.photo_url} />

                            <Menu>
                                <MenuButton >
                                    <HStack color="brand.subtitle" fontSize={13}>
                                        <Flex direction="column" alignItems="flex-end">
                                            <Text whiteSpace="nowrap" > {globalState?.profile?.full_name}  </Text>
                                            <Text fontSize={11}>Training Manager</Text>
                                        </Flex>
                                        <MdKeyboardArrowDown />
                                    </HStack> 
                                </MenuButton>
                                <MenuList fontSize={13} borderRadius={0} color="brand.bodyText" >
                                    <MenuItem onClick={() => push(TrainingMgrRoutes.Main)} >Go to dashboard</MenuItem>
                                    <MenuItem onClick={() => push(TrainingMgrRoutes.Course.Fetch)} > Courses</MenuItem>
                                    <MenuItem onClick={handleLogout} >Logout</MenuItem>         
                                </MenuList>
                            </Menu>
                        </HStack>
                    </Flex>

                    <Container maxW="container.xl" pt={isExact? 6: 10} px={0}>
                        <Switch>
                            <Route exact path={TrainingMgrRoutes.Main}>
                                <Dashboard/>
                            </Route>
                            <Route exact path={TrainingMgrRoutes.Course.Fetch}>
                                <AllCourses />
                            </Route>
                            <Route exact path={TrainingMgrRoutes.Course.Create}>
                                <CreateCourse />
                            </Route>
                            <Route exact path={TrainingMgrRoutes.Course.EditPath}>
                                <EditCourse />
                            </Route>
                            <Route exact path={TrainingMgrRoutes.Course.Status}>
                                <CourseStatus />
                            </Route>
                            <Route exact path={TrainingMgrRoutes.Course.Assign}>
                                <AssignCourse />
                            </Route>
                            <Route exact path={TrainingMgrRoutes.Course.PreviewPath}>
                                <PreviewCourse />
                            </Route>
                            <Route exact path={TrainingMgrRoutes.Student}>
                                <StudentOverview />
                            </Route>
                            <Route exact path={TrainingMgrRoutes.Group.Fetch}>
                                <AllGroups />
                            </Route>
                            <Route exact path={TrainingMgrRoutes.Group.Discussion}>
                                <AllGroupDiscussion />
                            </Route>
                            <Route exact path={TrainingMgrRoutes.Group.Create}>
                                <CreateGroupTM />
                            </Route>
                            <Route exact path={TrainingMgrRoutes.Profile}>
                                <Profile />
                            </Route>
                            <Route exact path={TrainingMgrRoutes.Security}>
                                <Security />
                            </Route>
                            <Route  path="*" >
                                <Error404 />
                            </Route>
                        
                        </Switch>                     

                    </Container>
                    
                </Container>

            </Flex>

        </Box>
    );
}