import {
    Box,
    Container,
    HStack,
    Tag,
    Text,
    VStack,
    Spacer,
    StatGroup,
    Stat,
    StatNumber,
    StatHelpText,
    Divider,
    Center,
    Avatar,
    Stack,
    Badge,
    Wrap,
    LinkOverlay,
    AspectRatio,
    Image,
    LinkBox,
    Button,
    Grid,
    GridItem,
    CircularProgress,
    CircularProgressLabel,
    Flex,
    Link,
    useDisclosure,
    Portal,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    useToast,
  } from '@chakra-ui/react'
  import { useState, useContext, useEffect, useRef } from 'react'
  import { Link as ReactLink, useHistory, useParams } from 'react-router-dom'
  import bannerBg from '../../img/course-Bg.svg'
  import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    PieChart,
    Pie,
    Sector,
    Cell,
  } from 'recharts'
  import { IUser, IUserDashboard } from '../../models/auth.model'
  import { fetchUserOverview, genExcel, getStudentOverview } from '../../services/hr/users-http.service'
  import { appConstant } from '../../utils/app.util'
  import { ScreenLoader } from '../../components/Common/ScreenLoader'
  import { EmptyFeaturedCourse } from '../../components/Student/EmptyState/FeaturedCourse'
  import { HrAdminRoutes } from './routes'
  import { FaUserGraduate } from 'react-icons/fa'
  import { BsBook, BsClock, BsStar } from 'react-icons/bs'
  import { hrStudentQiUtil } from '../../utils/course.util'
  import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'
  
  import 'swiper/swiper.min.css'
  import { EmptyAnalytics } from '../../components/Instructor/EmptyState/Analytics'
  import { HiOutlineArrowLeft } from 'react-icons/hi'
  import { StudentOverviewModal } from '../../components/Hr/StudentOverviewModal'

  
  
  const COLORS = ['#0088FE', '#33DAFF']
  
  export const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
   }: any) => {
    const RADIAN = Math.PI / 180
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)
  
    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    )
  }
  
  
  
  const renderColorfulLegendText = (value: string, entry: any) => {
    // const { color } = entry;
  
    return <span style={{ color: "#7883A7"}}>{value}</span>;
  };
  
  
  
  
  
  
  
  
  const data = [ 
    {
      name: 'Jan',
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: 'Feb',
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: 'Mar',
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: 'Apr',
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: 'May',
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: 'Jun',
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: 'Jul',
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ]
  
  
  
  export default function EachStudentOverview() {
    let { userId } = useParams<{ userId: string }>()
    const [user, setUser] = useState<IUserDashboard>()
    const [datapie, setdatapie] = useState<any[]>([])
    const [qi, setQi] = useState<any[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const [limitedUser, setLimitUser] = useState<any[]>()
  
    const [swiperRef, setSwiperRef] = useState<any>(null)
    const { isOpen, onOpen, onClose } = useDisclosure();
    const history = useHistory();
  
    const constrainUser = async () => {
      if(user){
            const data = user?.courses_in_progress.slice(0,5);
            setLimitUser(data);
            console.log(data, "limit data");
          }
      
    }
  
  
    useEffect(() => {
      getStudentOverview(userId)
        .then((data) => {
          console.log(data,"student overview")
          setUser(data)
          setdatapie([
            { name: 'Total Enrolled', value: data.total_enrolled },
            { name: 'In Progress', value: data.courses_in_progress_count },
          ])
  
          setQi(
            !data.bridge_count_per_month
              ? []
              : hrStudentQiUtil(data.bridge_count_per_month),
          )
          setIsLoading(!isLoading)
        })
        .catch((err) => err)
    }, [])
  
    useEffect(() => {
      constrainUser();
    }, [user])
  
   
  
  
    function showAllInProgress(){
      onOpen() 
      
    }
  
  
  
  
    return (
      <Box>
        {isLoading ? (
          <ScreenLoader />
        ) : (
          <> 
            {user &&
              (
                <>
                  <Flex justifyContent="flex-start" mb={5}>
                    
                      <HStack color="brand.2">
                        <HiOutlineArrowLeft />
                        <Text role={"button"} onClick={() => history.goBack()} fontSize={13} fontWeight="semibold">
                          {' '}
                          Go back{' '}
                        </Text>
                      </HStack>
                    
                  </Flex>
                  
                    <Text color="brand.3" fontSize={18} fontWeight="semibold">
                      Overview
                    </Text>
  
                  <Grid
                    templateRows="repeat(2, 1fr)"
                    templateColumns="repeat(5, 1fr)"
                    gap={4}
                  >
                    <GridItem rowSpan={2} colSpan={1} bg="white">
                      <Flex>
                        <Box mt={6} ml={3}>
                          <Avatar size="md" name={'Name'} />
                        </Box>
                        <Box mt={7} px={2}>
                          <VStack align={'start'}>
                            <Text color="#2F2963">{user.user.full_name}</Text>
  
                            <Text fontSize={13} mb={3}>
                              Brief Info
                            </Text>
  
                            <Text>{user.user.description}</Text>
  
                            <Text fontSize={13} mb={3} color="gray.500">
                              Phone
                            </Text>
                            <Text>{user.user.phone}</Text>
  
                            <Divider orientation="horizontal" my={6} />
  
                            <Text fontSize={13} mb={3} color="gray.500">
                              Email
                            </Text>
                            <Text>{user.user.email}</Text>
  
                            <Divider orientation="horizontal" my={6} />
                          </VStack>
                        </Box>
                      </Flex>
                    </GridItem>
                    <GridItem colSpan={4} bg="white">
                      <Grid
                        templateRows="repeat(1, 1fr)"
                        templateColumns="repeat(5, 1fr)"
                        gap={1}
                      >
                        <GridItem bg="white" colSpan={3}>
                          <Box my={6} ml={8}>
                            <Text
                              color="brand.3"
                              fontSize={16}
                              fontWeight="semibold"
                              
                            >
                              Course Status
                            </Text>
                          </Box>
  
                          <Grid
                            templateRows="repeat(1, 1fr)"
                            templateColumns="repeat(3, 1fr)"
                            gap={1}
                          >
                            <GridItem bg="white" colSpan={1}>
                              <PieChart width={300} height={300}>
                                <Pie
                                  data={datapie}
                                  cx="50%"
                                  cy="50%"
                                  labelLine={false}
                                  label={renderCustomizedLabel}
                                  outerRadius={80}
                                  fill="#8884d8"
                                  dataKey="value"
                                >
                                  {data.map((entry, index) => (
                                    <Cell
                                      key={`cell-${index}`}
                                      fill={COLORS[index % COLORS.length]}
                                    />
                                  ))}
                                </Pie>
                                <Legend
                                  verticalAlign="bottom"
                                  height={36}
                                  align="center"
                                  iconType="square"
                                  formatter={renderColorfulLegendText}
                                />
                              </PieChart>
                            </GridItem>
                            <GridItem bg="white" colSpan={1}>
                              <VStack align={'start'}>
                                <Text color="#4E78F5">{user.total_enrolled}</Text>
                                <Text fontSize={13} color="gray.500">
                                  Total Enrolled
                                </Text>
                                <Text color="#4E78F5">
                                  {user.courses_in_progress_count}
                                </Text>
                                <Text fontSize={13} color="gray.500">
                                  All Courses
                                </Text>
                                <Text color="#4E78F5">
                                  {user.courses_not_started_count}
                                </Text>
                                <Text fontSize={13} color="gray.500">
                                  Not Started
                                </Text>
                                <Text color="#4E78F5">
                                  {user.courses_completed_count}
                                </Text>
                                <Text fontSize={13} color="gray.500">
                                  Completed
                                </Text>
                              </VStack>
                            </GridItem>
                          </Grid>
                        </GridItem>
                        <GridItem bg="white" colSpan={2}>
                          <VStack align={'start'}>
                            <Box my={6}>
                              <Text
                                color="brand.3"
                                fontSize={16}
                                fontWeight="semibold"
                              >
                                All Courses
                              </Text>
                            </Box>
                            {limitedUser?.map((val) => (
                              <HStack>
                                <CircularProgress
                                  value={Number(val.progress)}
                                  color="#4E78F5"
                                >
                                  <CircularProgressLabel>
                                    {Number(val.progress).toFixed(0)}%
                                  </CircularProgressLabel>
                                </CircularProgress>
  
                                <Text>{val.course}</Text>
                              </HStack>
                            ))}
                            <Button
                              justifySelf="center"
                              alignSelf="center"
                              bgColor="white"
                              color="brand.2"
                              width="100%"
                              onClick={showAllInProgress}
                            >
                            See More
                           </Button>
                          </VStack>
                        </GridItem>
                      </Grid>
                    </GridItem>
                    <GridItem colSpan={4} bg="white">
                      <Grid
                        templateRows="repeat(1, 1fr)"
                        templateColumns="repeat(2, 1fr)"
                        gap={1}
                      >
                        <GridItem>
                          <Box my={6} ml={8}>
                            <Text
                              color="brand.3"
                              fontSize={16}
                              fontWeight="semibold"
                            >
                              Quality Assessment Index
                            </Text>
                          </Box>
  
                          <Box>
                            <LineChart
                              width={450}
                              height={250}
                              data={qi}
                              margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                              }}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis dataKey="name" />
                              <YAxis name="No of Bridges" />
                              <Tooltip />
                              <Line
                                dataKey="amt"
                                stroke="#8884d8"
                                dot={{ stroke: '#4E78F5', strokeWidth: 4 }}
                              />
                            </LineChart>
                          </Box>
                        </GridItem>
  
                        <GridItem p={6} mt={12}>
                          <Grid
                            templateRows="repeat(2, 1fr)"
                            templateColumns="repeat(2, 1fr)"
                            gap={8}
                          >
                            <GridItem>
                              <VStack spacing={4} align={'start'}>
                                <Text fontSize={13} color="gray.500">
                                  Courses
                                </Text>
                                <Text color="#4E78F5" fontSize={16}>
                                  {user.total_enrolled}
                                </Text>
                              </VStack>
                            </GridItem>
                            <GridItem>
                              <VStack spacing={4} align={'start'}>
                                <Text fontSize={13} color="gray.500">
                                  No. of Bridges
                                </Text>
                                <Text color="#4E78F5">{user.bridge_count}</Text>
                              </VStack>
                              1
                            </GridItem>
                            <GridItem>
                              <VStack spacing={4} align={'start'}>
                                <Text fontSize={13} color="gray.500">
                                  Average Scores
                                </Text>
                                <Text color="#4E78F5">{user.avg_score}%</Text>
                              </VStack>
                            </GridItem>
                            <GridItem>
                              <VStack spacing={4} align={'start'}>
                                <Text fontSize={13} color="gray.500">
                                  Comment
                                </Text>
                                <Text color="#4E78F5">Very Good</Text>
                              </VStack>
                            </GridItem>
                          </Grid>
                        </GridItem>
                      </Grid>
                    </GridItem>
                  </Grid>
                  <Portal>
                    {' '}
                    <StudentOverviewModal
                      isOpen={isOpen}
                      onClose={onClose}
                    />
  
                    {' '}
                </Portal>
                  
                </>
            )}
          </>
        )}
      
      </Box>
  
  
        
    )
  }
  
  
  
  