import { Image, Text, Box, Flex, Tag, TagLabel } from "@chakra-ui/react";
import React from "react";

interface ICourseCardRating{
    data: {
        coverImage: any,
        title: string,
        author: string,
        days: number,
        price: number
    }
}

const CourseCardWTag: React.FC<ICourseCardRating> = (props) => {

    const {coverImage, title, author, days, price } = props.data;

    return (
        <Box borderWidth="1px" maxW="lg" borderRadius="md" overflow="auto" mr={5}>
            <Image src={coverImage}></Image>
            <Box p={4}>
                <Text fontSize={18} color="brand.3">
                    {title}
                </Text>
                <Text color="brand.subtitle" fontSize={12} mt={3}>
                    {author}
                </Text>
                <Flex justifyContent="space-between" mt={2} >
                    <Tag size="sm"
                        borderRadius="full"
                        variant="solid"
                        colorScheme="brand">
                        <TagLabel fontSize={10} >
                            In {days} days
                        </TagLabel>
                    </Tag>
                    <Text color="brand.3">
                        ${price}
                    </Text>
                </Flex>
            </Box>
        </Box>
    );
}

export default CourseCardWTag;