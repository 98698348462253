import { Container, Text, Divider, Flex, Button, Avatar, Box, Input, Spinner, FormControl, FormLabel, Spacer, Checkbox, FormErrorMessage, useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import { useContext, useEffect, useRef, useState } from "react";
import { VscDeviceCamera } from "react-icons/vsc";
import { Category } from "../../components/Common/Category";
import { Country } from "../../components/Common/Country";
import { State } from "../../components/Common/State";
import { IUserDto } from "../../models/auth.model";
import { httpStatusCode } from "../../models/httpStatus.model";
import { getProfile, updateProfile, updateProfileImageService } from "../../services/auth.service";
import { updateUserProfileoNStorage } from "../../services/user.service";
import { StoreContext } from "../../store/StoreContext";

const Profile = () => {

    const toast = useToast();

    const [isUploading, setIsUploading] = useState(false);
    
    const [isDeleting, setIsDeleting] = useState(false);
    
    const inputRef = useRef<HTMLInputElement | null>(null)

    const {actions, globalState} = useContext(StoreContext)

     useEffect(() => {
            getProfile()
            .then((response) => {

                let data = response.user;

                formik.setValues({
                    fullName: data.full_name??'',
                    phoneNumber: data.phone??'',
                    unit: data.unit?.category_id??'',
                    role: data.roleDesc??'',
                    newsletter: data.newsletter??false,
                    birthday: data.birthday??'',
                    country: data.country_id??'',
                    state: data.state_id??''
                })

            })
            .catch((err) => err)
    }, []);

    const formik = useFormik({
        initialValues: {
            fullName: '',
            phoneNumber: '',
            unit: '',
            role: '',
            newsletter: false,
            birthday:'',
            country:'',
            state: ''
        },
        validate: (values: any) => {
            const errors: any = {};
            if(!values.fullName){
                errors.fullName = 'Full Name is required';
            }else if(
                /^(?=.*\d)/g.test(values.fullName)
            ){
                errors.fullName = 'Only letters are accepted';
            }
            if(!values.phoneNumber){
                errors.phoneNumber = 'Phone Number is required'
            }
            if(!values.unit){
                errors.unit = 'Unit is required'
            }
            return errors;
        },
        onSubmit: async (values) => {
            try {

                let data: IUserDto = {
                    fullName: values.fullName,
                    phone: values.phoneNumber,
                    unit: values.unit,
                    role: values.role,
                    newsletter: values.newsletter,
                    birthday: values.birthday,
                    countryId: values.country,
                    stateId: values.state
                };

                let response = await updateProfile(data);

                if(response.statusCode === httpStatusCode.OK){

                    actions?.updateProfile(response.data);

                    toast({
                        title: "Profile Update",
                        description: response.message,
                        status: "success",
                        duration: 2000
                    })

                }           

            } catch (error) {
                toast({
                    title: "Profile Update",
                    description: "Something went Wrong. Try again later",
                    status: "error",
                })
            }
        }
    });

   const updateProfileImage = async (e: React.ChangeEvent<HTMLInputElement>) => {

        try {

            let value = e.target.value;
        
            if(value){

                let fileName = e.target.files?.item(0)?.name as string;
                
                let file = e.target.files?.item(0) as File;
                
                if(file.size > 3000000){

                    toast({
                        title: "Profile Image",
                        description: "Maximum Image Size is 3MB",
                        status: "error",
                        duration: 3000,
                    }) 
                    return;
                }
                
                setIsUploading(true);

                let formData = new FormData();

                formData.append('profileImage', file, fileName);

                const profile = await updateProfileImageService(formData);

                if(profile){
                    
                    actions?.updateProfile(profile.data);

                    updateUserProfileoNStorage(profile.data);

                    setIsUploading(false);
                    
                    toast({
                        title: "Profile Image",
                        description: profile.message,
                        status: "success",
                        duration: 2000,
                    })
                }
            }
            
        } catch (error) {
            setIsUploading(false);

            toast({
                title: "Profile Image",
                description: "Something went Wrong. Try again later",
                status: "error",
            })  
        }
   }

   const deleteProfileImage = async () => {
       
        try {

            setIsDeleting(true);

            const profile = await updateProfileImageService();

            if(profile){
                
                actions?.updateProfile(profile.data);

                setIsDeleting(false);

                updateUserProfileoNStorage(profile.data);
                
                toast({
                    title: "Profile Image",
                    description: "Profile Image has been removed",
                    status: "success",
                    duration: 2000,
                })
            }
            
        } catch (error) { console.log(error)
            setIsDeleting(false);

            toast({
                title: "Profile Image",
                description: "Something went Wrong. Try again later",
                status: "error",
            })  
        }
   }

    return (
        <>
            <Container boxShadow="base" p={3}  maxW="container.xl"  mb={6} bg="white">
                <Text color="brand.3" fontSize={18} pl={6} fontWeight="semibold" >Edit Profile </Text>
            </Container>

            <Container boxShadow="base" p={3}  maxW="container.xl"  mb={6} bg="white" pb={12}>

                <Flex direction="column" px={6} >
                    <Text color="brand.3" fontSize={18} fontWeight="semibold" >Profile Details</Text>
                    <Text color="brand.subtitle" fontSize={12}>You have full control to manage your account</Text>
                </Flex>

                <Divider mt={3} orientation="horizontal" color="#D5D5D5" border="1px"  />

                <Flex justifyContent="space-between" borderBottom="1px" borderBottomColor="#E5E5E5" alignItems="center" mt={3} mx={6} pb={4}>

                    <Flex alignItems="center">
                        <Box position="relative" cursor="pointer" onClick={() => inputRef?.current?.click()} >
                            <Box position="absolute" color="white" top="3.5" left="3.5" zIndex="overlay">
                                { isUploading? <Spinner size="sm" /> : <VscDeviceCamera fontSize={20} />}
                            </Box>
                            <Box>
                                <Avatar size="md" name={globalState.profile?.full_name} src={globalState.profile?.photo_url}  />
                            </Box>
                        </Box>                        
                        <input accept='image/*' type='file' name="profileImage" ref={inputRef} onChange={(e) => updateProfileImage(e) } style={{ display: 'none' }}></input>

                        <Flex direction="column" ml={4}>
                            <Text color="brand.3" fontSize={14} fontWeight="semibold">
                                Your Picture
                            </Text>
                            <Text color="brand.subtitle" fontSize={12}>
                                You can upload either a JPG or PNG. A maximum size of 3MB  can be uploaded.
                            </Text>
                        </Flex>
                    </Flex>
                    
                    { globalState.profile?.photo_url && <Button  variant="outline" color="#FC4B25" borderColor="#FC4B25" size="sm" px={8} fontSize={10} onClick={deleteProfileImage} isLoading={isDeleting} >Delete</Button> }
                </Flex>

                <Box mx={6}>

                    <Flex direction="column" mt={6}>
                        <Text fontWeight="semibold" color="brand.3">
                            Personal Details
                        </Text>
                        <Text color="brand.subtitle" fontSize={13}> 
                            Edit your personal information and address
                        </Text>
                    </Flex>

                    <Box mt={8}>

                        <form onSubmit={formik.handleSubmit}>
                            
                            <FormControl id="fullName" isInvalid={(formik.errors.fullName && formik.touched.fullName) ? true : false}>
                                <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>Full Name</FormLabel>
                                <Input placeholder="Full Name" name="fullName" type="text" fontSize={12} value={formik.values.fullName} onChange={formik.handleChange} />
                                <FormErrorMessage fontSize={12} >{formik.errors.fullName}</FormErrorMessage>
                            </FormControl>

                            <Flex mt={6}>
                                <FormControl id="phoneno" isInvalid={(formik.errors.phoneNumber && formik.touched.phoneNumber) ? true : false}>
                                    <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>Phone Number</FormLabel>
                                    <Input placeholder="Phone Number" name="phoneNumber" type="tel" fontSize={12} value={formik.values.phoneNumber} onChange={formik.handleChange} />
                                    <FormErrorMessage fontSize={12} >{formik.errors.phoneNumber}</FormErrorMessage>
                                </FormControl>

                                <Spacer mx={10} />

                                <FormControl id="birthday" isInvalid={(formik.errors.birthday && formik.touched.birthday) ? true : false}>
                                    <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>Birthday</FormLabel>
                                    <Input placeholder="Birthday" name="birthday" type="date" onChange={formik.handleChange} value={formik.values.birthday} fontSize={12} />
                                    <FormErrorMessage fontSize={12} >{formik.errors.birthday}</FormErrorMessage>
                                </FormControl>
                            </Flex>

                            <Flex mt={6}>
                                <FormControl id="unit" isInvalid={(formik.errors.unit && formik.touched.unit) ? true : false}>
                                    <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>Unit</FormLabel>
                                    <Category unitId={formik.values.unit} setUnitId={formik.handleChange} />
                                    <FormErrorMessage fontSize={12} >{formik.errors.unit}</FormErrorMessage>
                                </FormControl>

                                <Spacer mx={10} />

                                <FormControl id="role">
                                    <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>Role</FormLabel>
                                    <Input placeholder="Enter your role" name="role" value={formik.values.role} onChange={formik.handleChange} fontSize={12} />
                                </FormControl>

                            </Flex>

                            <Flex mt={6}>
                                <FormControl id="country">
                                    <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>Country</FormLabel>
                                    <Country countryId={formik.values.country} setCountryId={formik.handleChange}  />
                                </FormControl>

                                <Spacer mx={10} />

                                <FormControl id="state">
                                    <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>State</FormLabel>
                                    <State countryId={formik.values.country} stateId={formik.values.state} setStateId={formik.handleChange} />
                                </FormControl>

                            </Flex>

                            <Flex mt={6} >
                                <Checkbox colorScheme="brand" name="newsletter" isChecked={formik.values.newsletter} onChange={formik.handleChange} />
                                <Text ml={3} color="brand.3" fontSize={13}>
                                    Subscribe to Newsletter
                                </Text>
                            </Flex>

                            <Button mt={8} colorScheme="brand" size="sm" px={8} fontSize={12} type="submit" isLoading={formik.isSubmitting} >Save Changes</Button> 
                        </form>

                    </Box>

                </Box>

            </Container>
        </>
    )
}

export default Profile;