import { Box, Flex, Spinner } from "@chakra-ui/react";
import React from "react";

export const ScreenLoader: React.FC<any> = () => {

    return (
        <Box >
           <Flex justifyContent="center" alignItems="center"  width="100%" minHeight="50vh">
                <Spinner color="brand.2" size="xl" />
            </Flex>
        </Box>
    );
}