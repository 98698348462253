import {Box, Text, HStack, Flex, Button, VStack, Textarea, Image, FormControl, Input, FormLabel, useToast, FormErrorMessage, AspectRatio} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import {HiOutlineLightBulb} from 'react-icons/hi'
import { useHistory } from 'react-router';
import ImageSvg from '../../../img/image.svg';
import { ICourseSettingsState } from '../../../models/create_course.model';
import { removeCourseFromStateService } from '../../../services/instructor/course.service';
import { submitCourseSettings } from '../../../services/instructor/courses-http.service';
import { Accessibility } from '../../Common/Accessibility';
// import { InstructorRoutes } from '../../../pages/Instructor/routes';


const Setting = ({prePage, ...rest}: {prePage: any}) => {

    const history = useHistory();

    const inputRef = useRef<HTMLInputElement | null>(null)

    const [fileName, setFileName] = useState<string>('');
    
    const [currentFile, setCurrentFile] = useState<File>();
    const [newCourseTitle, setCourseTitle] =useState<string | undefined>('')
    
    const [currentFileUrl, setCurrentFileUrl] = useState<string>();

    const toast = useToast();

    useEffect(() => {
        let courseTitle = localStorage.getItem('course-title')?.toString();
       
        setTimeout(() => {
            setCourseTitle(courseTitle)
        }, 3000);
        console.log(courseTitle, 'course title')
        console.log(newCourseTitle, 'course title')
      
    }, []);

    
    

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        
        if(value){
            
            let fileName = e.target.files?.item(0)?.name as string;

            let file = e.target.files?.item(0) as File;
            
            if(file.size > 6000000){

                formik.setErrors({coverImage: 'Maximum Image Size is 5MB'});
                toast({
                    title: "Cover Image",
                    description: "Maximum Image Size is 5MB",
                    status: "error",
                    duration: 3000,
                }) 
                return;
            }

            setFileName(fileName);

            setCurrentFile(file);

            setCurrentFileUrl(URL.createObjectURL(file))
        }
        else{
            setFileName('');
            setCurrentFileUrl('');
            setCurrentFile(undefined);
        }
        
        formik.handleChange(e);
    }


    const formik = useFormik({
        validate: (values: any) => {
            const errors: any = {};
            if(!values.accessibility){
                errors.accessibility = 'Course Accessibility is required';
            }
            if(!values.tags){
                errors.tags = 'Course Tag is required'
            }
            if(!values.welcomeMsg){
                errors.welcomeMsg = 'Welcome Message is required'
            }
            if(!values.coverImage){
                errors.coverImage = 'Cover Image is required'
            }
            return errors;
        },
        initialValues: {
            accessibility: '',
            tags: '',
            welcomeMsg: '',
            congratMsg: `You just completed the course: ${newCourseTitle} Completing an online course is no simple endeavor. It requires time, dedication, and commitment, so when we say "Congratulations" - we mean it!`,
            coverImage: ''
        },
        onSubmit: async (values) => {

            try {

                if(currentFile !== undefined){

                    let courseId = localStorage.getItem('course-id') || '';

                    let formData = new FormData();

                    formData.append('courseId', courseId);
                    formData.append('congratulatoryMessage', values.congratMsg);
                    formData.append('welcomeMessage', values.welcomeMsg);
                    formData.append('courseAccessibilityId', values.accessibility);
                    formData.append('coverImage', currentFile, fileName);
                    console.log(formData.entries(), 'this is form data')

                    const course = await submitCourseSettings(formData);

                    if(course){
                        
                        removeCourseFromStateService();

                        toast({
                            title: "Course Setup",
                            description: course.message,
                            status: "success",
                            duration: 2000,
                            onCloseComplete: () => history.replace('/')
                        })
                    }
                }
            }catch(error) {
                 toast({
                    title: "Course Settings",
                    description: "Something went Wrong. Try again later",
                    status: "error",
                })                
            }
        }
    })


    useEffect(() => {
       let storedData = localStorage.getItem('course-settings');
       
       if(storedData){ 
           let parsedData: ICourseSettingsState = JSON.parse(storedData);
           formik.setValues({
            ...parsedData
           });
           setCurrentFileUrl(parsedData.coverImage);
       }
    }, []);

  

    return (

        <Box px={6} >

            <form onSubmit={formik.handleSubmit}> 
                <HStack spacing={10}>
                    <Box width="50%">
                        <Text fontSize={13} px={3} color="brand.3" fontWeight="semibold">Upload Cover Image</Text>
                        
                        <Box mt={3} width="25rem" height="15rem" border="1px"  borderStyle="dashed" borderRadius="3px" boxSizing="border-box" borderColor="brand.bodyText" bg="brand.coverImage">
                            {
                                currentFileUrl ? 
                                <AspectRatio ratio={4/3} maxW="24.9rem" maxHeight="14.9rem">
                                    <Image src={currentFileUrl} />
                                </AspectRatio>

                                :

                                <VStack justifyContent="center" alignContent="center" alignItems="center" justifyItems="center" height="100%">
                                    <Image width="3.5em" src={ImageSvg} /><br/>
                                    <Text fontSize={13} color="brand.subtitle" >Drag Image here or <Box as="span" color="brand.2" cursor="pointer" onClick={() => inputRef?.current?.click()} >browse</Box> </Text>
                                    <Text fontSize={11} color="brand.subtitle" >Maximum Size: 5MB.</Text>
                                </VStack>
                            }         
                        </Box>
                    </Box>

                    <Box width="50%">
                        <HStack spacing={6} color="brand.2" bg="info.100" height="4rem" justifyContent="center">
                            <Box ml={6}><HiOutlineLightBulb fontSize={24}  /></Box>
                            <Text  fontSize={13}>
                                Courses with great cover image stands out and get more enrollment
                            </Text>
                        </HStack>
                        <Box mt={6}>
                            <FormControl mr={10}  isInvalid={(formik.errors.coverImage && formik.touched.coverImage) ? true : false} >
                                <Flex>                            
                                    <input accept="image/jpg, image/png, image/jpeg" type='file' name="coverImage" ref={inputRef} onChange={(e) => handleFileChange(e) } style={{ display: 'none' }}></input>
                                    <Input size="sm" color="brand.subtitle" defaultValue={fileName} placeholder="No file selected" onClick={() => inputRef?.current?.click()} fontSize={12} />                                   
                                    <Button colorScheme="brand" onClick={() => inputRef?.current?.click()} size="sm" borderRadius={0} fontSize={11} px={6} >UPLOAD IMAGE</Button>
                                </Flex>
                                <FormErrorMessage fontSize={12}> {formik.errors.coverImage} </FormErrorMessage>
                            </FormControl>
                        </Box>
                    </Box>
                </HStack>

                {/* <HStack spacing={6} mt={6}>
                    <FormControl width="15%">
                        <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>Course Price </FormLabel>
                        <Select  variant="outline" bg="inputText.500" placeholder="USD" size="sm" fontSize={12} color="brand.subtitle" />
                    </FormControl>

                    <FormControl width="25%">
                        <FormLabel fontWeight="normal" color="brand.3" fontSize={13} visibility="hidden"> " " </FormLabel>
                        <Input bg="inputText.500" placeholder="Enter Price" fontSize={12} />
                    </FormControl>
                    
                    <FormControl width="60%">
                        <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>Create Coupon Code</FormLabel>
                        <Input bg="inputText.500" placeholder="Create a new coupon" fontSize={12} />
                    </FormControl>
                </HStack> */}
                
                <HStack spacing={10} mt={6}>
                    <FormControl width="20%" isInvalid={(formik.errors.accessibility && formik.touched.accessibility) ? true : false}>
                        <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>Learner's Accessibility </FormLabel>
                        <Accessibility accessibilityId={formik.values.accessibility} setaccessibilityId={formik.handleChange} />
                        <FormErrorMessage fontSize={12} >{formik.errors.accessibility}</FormErrorMessage>
                    </FormControl>

                    <FormControl width="80%" isInvalid={(formik.errors.tags && formik.touched.tags) ? true : false}>
                        <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>Tags</FormLabel>
                        <Input bg="inputText.500" placeholder="Seperate each tags with a comma" value={formik.values.tags} onChange={formik.handleChange} name="tags" fontSize={12} />
                        <FormErrorMessage fontSize={12} >{formik.errors.tags}</FormErrorMessage>
                    </FormControl>
                </HStack>

                <FormControl id="groupDescription" mt={6} isInvalid={(formik.errors.welcomeMsg && formik.touched.welcomeMsg) ? true : false}>
                    <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>Welcome Message </FormLabel>
                        
                    <Textarea
                        p = {4}
                        bg="inputText.500"
                        fontSize={12} 
                        size="sm"
                        minH="10rem"
                        placeholder="Write a message to welcome student who will be taking your course after enrollment"
                        name="welcomeMsg"
                        onChange={formik.handleChange}
                        value={formik.values.welcomeMsg}
                    />
                    <FormErrorMessage fontSize={12} >{formik.errors.welcomeMsg}</FormErrorMessage>
                </FormControl>
                
                <FormControl id="groupDescription" mt={6} isInvalid={(formik.errors.congratMsg && formik.touched.congratMsg) ? true : false}>
                    <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>Congratulatory Message </FormLabel>
                        
                    <Textarea
                        p = {4}
                        bg="inputText.500"
                        fontSize={12} 
                        isReadOnly
                        size="sm"
                        minH="10rem"
                       
                        name="congratMsg"
                        onChange={formik.handleChange}
                        value={formik.values.congratMsg}
                    />
                    <FormErrorMessage fontSize={12} >{formik.errors.congratMsg}</FormErrorMessage>
                </FormControl>

                <Flex justifyContent="space-between" mt={6}>
                    <Button  onClick={prePage} variant="outline" colorScheme="brand" size="sm" fontSize={11} borderRadius={10} px={8}>PREVIOUS</Button>
                    <Button type="submit" colorScheme="brand" size="sm" fontSize={11}  borderRadius={10} px={6}>SUBMIT FOR REVIEW</Button>
                </Flex>
            
            </form>            

        </Box>
    );
}

export default Setting;