import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton, Text, Box, useToast, FormControl, FormLabel, InputGroup, InputLeftElement, Input, FormErrorMessage, Spacer, Flex, Button, Checkbox, Center, Select, SimpleGrid, Icon, Textarea
  } from "@chakra-ui/react"
import { useState } from "react";


import { useHistory } from "react-router-dom";
import { fetchBlacklistedWords, uploadBlacklistedWords } from "../../services/hr/users-http.service";

 




  export const AddWordModal: React.FC<any> = ({isOpen, onClose, reloadModal }: {isOpen: any, onClose: any, reloadModal: any }) => {

    let {replace, push} = useHistory();

    const [wordadded, setwordadded] = useState<string>();

    

    function handleAddWord(){
            
        fetchBlacklistedWords()
        .then((data) => {
         const newArray = data
         newArray.push(wordadded)
         uploadBlacklistedWords(newArray).then(response => {
            if(response === 200){
                onClose()
                reloadModal()
            }
        })


        })
        .catch((err) => err)
 
        
            
    }
  

    
    return (
      <>
         <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="outside" closeOnOverlayClick={true}  size="4xl">
            <ModalOverlay />
              <ModalContent borderRadius={5} px={6} >
                <ModalHeader >
                 <Text fontSize={16}>Add Blaklisted Word</Text>               
                </ModalHeader>
                <ModalCloseButton size="sm" />
                <ModalBody pb={6}>
                  <Textarea onChange={e=>setwordadded(e.target.value)}>
                      
                  </Textarea>
                </ModalBody>

               
                
                
                
                <ModalFooter>
                <Button
                    onClick={handleAddWord}
                    alignSelf={'end'}
                    colorScheme="brand"
                    size="sm"
                    fontSize={12}
                    >
                        Done
                    </Button>
                </ModalFooter>
              </ModalContent>
          </Modal>
      </>
     
    )
  }

 