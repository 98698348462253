import { Box, Container, Flex, Grid, GridItem, VStack, Text, Stack, Progress, HStack, Avatar, Button, Table, Thead, Tr, Th, Tbody, Td } from "@chakra-ui/react";
import StarRating from 'react-svg-star-rating';
import { Pagination, PaginationContainer, PaginationNext, PaginationPage, PaginationPageGroup, PaginationPrevious, usePagination } from "@ajna/pagination";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ICourse, IReview, IPaginatedCourseReviewDto } from "../../models/course.model";
import { Ipage } from "../../models/auth.model";
import { fetchAllCourseReview, fetchCourseReview } from "../../services/hr/users-http.service";
import { ScreenLoader } from "../../components/Common/ScreenLoader";
import { EmptyRecordResult } from "../../components/Common/EmptyRecords";
import { HrAdminRoutes } from "./routes";


export default function ViewReview ()  { 

    let {replace, push} = useHistory();
    let { reviewId } = useParams<{reviewId: string}>();
    const [isLoading, setIsLoading] = useState(true);
    const [reviews, setReviews] = useState<ICourse>();
    const [page, setPage] = useState<Ipage>({page_total: 0, total: 0});

    const {
        currentPage,
        setCurrentPage,
        pagesCount,
        pages
      } = usePagination({
        initialState: { currentPage: 1, pageSize: 10 },
        total: page.total
      });

      useEffect(() => {
        let url = `?page=${currentPage}&limit=${10}`;
        fetchCourseReview(reviewId)
        .then(data => {
            if(data.results){
                setReviews(data.results)
                setIsLoading(!isLoading)
            }         
        })
        .catch(e => {
            console.log(e);
            setIsLoading(!isLoading)
           // history.goBack();
        })
    }, []);

    return (
        <>
            <Container boxShadow="base" py={3} px={12} maxW="container.xl"  mb={6} bg="white" pb={12}>

                
                {

                isLoading?
                <ScreenLoader />
                
                :
                
                !reviews || reviews === undefined ? 
                <EmptyRecordResult />
                :
                <>
                <Flex direction="column" mb={8}>
                    <Text color="brand.3" fontSize={18} fontWeight="semibold" > {reviews?.title}</Text>
                </Flex>

                <Table variant="simple" mt={5} >
                            <Thead fontWeight="normal" >
                                <Tr color="brand.subtitle" >
                                    <Th textTransform="none">Name</Th>
                                    <Th textTransform="none">Comment</Th>
                                    <Th textTransform="none">Date Submitted</Th>
                                </Tr>
                            </Thead>

                            <Tbody>
                            {
                                (reviews.courseReviews && reviews.courseReviews.length) &&
                                <>
                                {
             reviews.courseReviews.map((each, index) => (
<Tr key={index} fontSize={13} color="brand.subtitle">
                                            <Td role={"button"} >
                                                <HStack spacing={6} alignItems="start" maxW="fit-content">
                                                    <Avatar size="md" name={each.user?.full_name} mr={4} src={each.user?.full_name}/>
                                                    <VStack>
                                                        <Text color="brand.3" whiteSpace="nowrap" isTruncated title={each.user?.full_name}>
                                                            {each.user?.full_name}
                                                        </Text>
                                                    </VStack>

                                                </HStack>
                                                
                                            </Td>
                                            <Td alignContent={"flex-start"} align="left">
                                                
                                                <StarRating isReadOnly={true} unit="float" size={11} initialRating={each.rating} containerClassName="flexStar"/>
                        <Text color="brand.3" fontSize={10} whiteSpace="nowrap">{each.review}</Text>
                                                   
                                            </Td>
                                            <Td>
                                                {new Date(each.createdAt).toDateString()}
                                            </Td>
                                        </Tr>
             ))}
                                </>
                            }
             
                            
                            </Tbody>
                        </Table>



             

                    </>

            }

            

            </Container>
        </>
    );
}

