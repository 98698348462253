import { Redirect, Route, useLocation } from "react-router-dom";
import RoleSetting from "../pages/RoleSetting";
import { getLocalUserProfile, isAuthenticated } from "../services/auth.service";

export const AuthGuard = ({ path, component: Component, ...rest }: {component: any, path: string}) => {
  
  const location = useLocation();

  const user = getLocalUserProfile()

  return (
    <Route {...rest}>
      {isAuthenticated() ?

        !user?.rankId ?

        <RoleSetting />

        :

        <Component {...rest} />
      :
        <Redirect to={{ pathname: "/", state: { from: location } }} />
      }
    </Route>
  );
};