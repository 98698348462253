import { Dispatch } from "react";
import { IUser } from "../models/auth.model";
import { actionTypes } from "./types";


export interface IActionModel{
    type: string;
    payload: any;
}

export const useActions = (dispatch: Dispatch<IActionModel>) => ({
    updateProfile: (payload: IUser) => dispatch({type: actionTypes.UPDATE_PROFILE, payload: payload})
});