import {Flex, Box, Container, Text, Link, Image, Button, Divider, Progress, HStack} from '@chakra-ui/react';
import {RiErrorWarningLine} from 'react-icons/ri';
import { BsClipboard, BsCardList} from 'react-icons/bs';
import {FaAward} from 'react-icons/fa';
import {GiGraduateCap} from 'react-icons/gi';
import {CgPlayButtonR} from 'react-icons/cg';
import ai from '../../img/article.svg';
import { useHistory } from 'react-router';
import { StudentRoutes } from './routes';
import { StoreContext } from '../../store/StoreContext';
import { useContext, useEffect, useState } from 'react';
import { firstName } from '../../utils/firstName.util';
import { CourseEnrolled } from '../../components/Student/EmptyState/CourseEnrolled';
import { EmptyHourSpent } from '../../components/Student/EmptyState/HourSpent';
import { IStudentDashboardStat } from '../../models/dashboard.model';
import { fetchCourseEnrollmentStatus, fetchDashboardStat, fetchMandatoryCourses } from '../../services/student/course-http.service';
import { ICourseEnrollmentStatusList, IEnrollmentWithCourseLessons } from '../../models/course.model';
import { courseEnroledStatus } from '../../utils/course.util';
import { DashboardLoader } from '../../components/Common/DashboardLoader';
import bannerBg from '../../img/course-Bg.svg';



const Dashboard = () => {

    const history = useHistory();
    
    const [mandatoryCourses, setMandatoryCourses] = useState([]);

    const [exploredCourses, setExploredCourses] = useState([]);

    const [upcomingCourses, setUpcomingCourses] = useState([]);

    const [hoursSpent, setHoursSpent] = useState([]);

    const {globalState} = useContext(StoreContext);

    const [dashboardStat, setDashboardStat] = useState<IStudentDashboardStat>();

    const [coursesEnrolled, setCoursesEnrolled] = useState<ICourseEnrollmentStatusList[]>([]);

    const [isLoadingEnrolledCourse, setIsLoadingEnrolledCourse] = useState(true);
    const [ mandatoryNumber, setMandatoryCoursesNumber] = useState<number>(0)

    useEffect(() => {
         fetchDashboardStat()
        .then((data) => {
            setDashboardStat(data);

            const limit = 10
            fetchCourseEnrollmentStatus(limit)
            .then(data => {
                console.log("Here")
                setCoursesEnrolled(courseEnroledStatus(data))
                setIsLoadingEnrolledCourse(false)

                
            })
            fetchMandatoryCourses()
            .then( data => {
                let mandatoryCourse :any[] =[];
                let completedcourse : any[] = []
                data.forEach((course) =>{
                    course.completedAt == null ? mandatoryCourse.push(course): completedcourse.push(course)
                })
                console.log(data, 'this man')
                setMandatoryCoursesNumber(mandatoryCourse.length)
                // setIsLoadingMandatory(false)
            })
           
        })

        .catch((err) => err)
       

    }, []);

    const resumeCourse = (courseId: string) => {
        history.push(StudentRoutes.Course.InfoLink(courseId));
        console.log(courseId, 'courseID')
    }


    return (
        <>
            <Box bg="brand.2" height="4rem" backgroundImage={bannerBg} borderTopLeftRadius={4} borderTopRightRadius={4} >
                <Flex direction="row" justifyContent="space-between" height="inherit" pl={10} pr={10} alignItems="center" >
                    <Flex direction="row" alignItems="center">
                        <Text pr={5}><RiErrorWarningLine size={25} color="white" /></Text>
                        <Flex direction="column" color="white" >
                            <Text fontSize={18} fontWeight="semibold" >Hi, {firstName(globalState.profile?.full_name)}</Text>
                            {
                                mandatoryNumber > 0 &&
                                <Text fontSize={11}>You have {mandatoryNumber} mandatory course(s) to take</Text>
                            }
                        </Flex>
                    </Flex>
                    <Button pb={0} color="brand.2" onClick={() => history.push(StudentRoutes.Course.Fetch)} fontSize={11} size="sm" >Go to Course</Button>
                </Flex>
            </Box>

            <HStack direction="row" spacing={8} pt={6} pb={8} >
                
                <Container height="6rem" width="20rem" boxShadow="base" bg="white" m={0} >
                    <Flex direction="row" height="inherit" alignItems="center" pl={4}>
                        <Text pr={8} ><GiGraduateCap size={40} color="#58CEF3" /></Text>
                        <Flex direction="column">
                            <Text color="brand.3" fontSize={25} fontWeight="semibold" >{dashboardStat?.courses_enrolled}</Text>
                            <Text color="brand.3" fontSize={12} fontWeight="semibold" >COURSES ENROLLED</Text>
                        </Flex>
                    </Flex>
                </Container>
                
                <Container height="6rem" width="20rem" boxShadow="base" bg="white" m={0}>
                    <Flex direction="row" height="inherit" alignItems="center" pl={4}>
                        <Text pr={8} color="brand.2" ><BsClipboard size={40}  /></Text>
                        <Flex direction="column">
                            <Text color="brand.3" fontSize={25} fontWeight="semibold" >{dashboardStat?.pending_assignments}</Text>
                            <Text color="brand.3" fontSize={12} fontWeight="semibold" >PENDING ASSIGNMENTS</Text>
                        </Flex>
                    </Flex>
                </Container>
                
                <Container height="6rem" width="20rem" boxShadow="base" bg="white" m={0}>
                    <Flex direction="row" height="inherit" alignItems="center" pl={4}>
                        <Text pr={8}><FaAward size={40} color="#FECF9B" /></Text>
                        <Flex direction="column">
                            <Text color="brand.3" fontSize={25} fontWeight="semibold" >{dashboardStat?.courses_completed}</Text>
                            <Text color="brand.3" fontSize={12} fontWeight="semibold" >COURSES COMPLETED</Text>
                        </Flex>
                    </Flex>
                </Container>

            </HStack>

            <Box boxShadow="sm" p={0} maxW="container.xl" pb={0.1} mb={6} bg="white">

                <Flex direction="row" justifyContent="space-between" px={8} py={3} >
                    <Text color="brand.3" fontSize={14} fontWeight="semibold" >Courses Enrolled {coursesEnrolled?.length > 0 && `(${coursesEnrolled?.length})` } </Text>
                    { dashboardStat?.courses_enrolled as number > 3 && <Link color="brand.2" onClick={() => history.push(StudentRoutes.Course.Fetch) } fontSize={11} fontWeight="semibold" pr={6}>View All</Link> }
                </Flex>

                <Divider orientation="horizontal" color="#D5D5D5" border="1px" mb={3} />

                {
                    isLoadingEnrolledCourse?
                    <DashboardLoader />
                    :
                    coursesEnrolled?.length === 0 ? 

                    <Box my={10}>
                        <CourseEnrolled />
                    </Box>

                    :

                    coursesEnrolled?.map((each, index) => 
                        
                        <Box boxShadow="sm" key={index}  mx={8} height="4rem" pr={6} mb={5} pt={4}>
                            <Flex direction="row" justifyContent="space-between" alignItems="center" >
                            
                                <Flex direction="row">

                                    <Image src={each.coverImage} fallbackSrc="https://via.placeholder.com/150" width="3.2rem" mr={4} />
                            
                                    <Flex direction="column" justifyContent="space-around" width="15em">
                                        <Text isTruncated fontSize={13} color="brand.3">
                                            {each.title}
                                        </Text>
                                        <Flex direction="row" fontSize={10} color="brand.bodyText" pb={2}>
                                            <Flex alignItems="center" mr={4}>
                                                <CgPlayButtonR />
                                                <Text pl={1} >{each.videosCount} Videos</Text>                                                    
                                            </Flex>
                                            
                                            <Flex alignItems="center" >
                                                <BsCardList />
                                                <Text pl={1}> {each.lessonCount} Lessons</Text>                                                    
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </Flex>
                                
                                <Flex alignItems="center">
                                    <Progress value={each.statusPercentage} size="sm" color="brand.2" width="20rem" borderRadius="0.5rem"  height="0.4rem" />
                                    <Text pl={5} fontSize={12} fontWeight="semibold" color="brand.bodyText" >{each.statusPercentage} %</Text>

                                </Flex>

                                <Button pb={0} bg="brand.2" color="white" onClick={() => resumeCourse(each.courseId)} fontSize={11} width="6rem" size="sm" >Resume</Button>

                            </Flex>
                        </Box>
                    )
                }

            </Box>

            <Box boxShadow="sm" p={0} maxW="container.xl" pb={0.1} mb={6} bg="white">

                <Flex direction="row" justifyContent="space-between" px={8} py={3} >
                    <Text color="brand.3" fontSize={14} fontWeight="semibold" > Hours Spent </Text>
                </Flex>

                <Divider orientation="horizontal" color="#D5D5D5" border="1px" mb={3} />

                {
                    hoursSpent.length === 0 ? 

                    <Box my={10}>
                        <EmptyHourSpent />
                    </Box>

                    :

                    <Box>
                        Hours Spent
                    </Box>
                }

            </Box>

            {
                exploredCourses.length > 0 &&

                <Container p={0} boxShadow="base" bg="white" mb={6} maxW="container.xl" pl={8} pb={6}>
                    
                    <Flex direction="row" justifyContent="space-between" py={3} >
                        <Text color="brand.3" fontSize={14} fontWeight="semibold" >Explore Courses</Text>
                    </Flex>

                    <Divider orientation="horizontal" color="#D5D5D5" border="1px" mb={3} />

                    <Flex>
                        <Flex direction="row" boxShadow="base" pr={3}>

                            <Image src={ai} width="3.2rem" mr={4} />
                    
                            <Flex direction="column" justifyContent="center">
                                <Text fontSize={13}>
                                    Machine Learning
                                </Text>
                                <Text  fontSize={11}>456 courses</Text>
                            </Flex>
                        </Flex>
                    </Flex>                         

                </Container>
            }

            {
                upcomingCourses.length > 0 &&

                <Container p={0} boxShadow="base" bg="white" mb={6} maxW="container.xl" pl={8} pb={6}>
                    
                    <Flex direction="row" justifyContent="space-between" py={3} >
                        <Text color="brand.3" fontSize={14} fontWeight="semibold" >Upcoming Courses</Text>
                    </Flex>

                    <Divider orientation="horizontal" color="#D5D5D5" border="1px" mb={3} />

                    <Flex>
                        <Flex direction="row" boxShadow="base" pr={3}>

                            <Image src={ai} width="3.2rem" mr={4} />
                    
                            <Flex direction="column" justifyContent="center">
                                <Text fontSize={13}>
                                    Machine Learning
                                </Text>
                                <Text  fontSize={11}>456 courses</Text>
                            </Flex>
                        </Flex>
                    </Flex>                         

                </Container>
            }

        </>
    );
}

export default Dashboard;