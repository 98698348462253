import { Avatar, Box, Button, Container, Flex, HStack, Text, IconButton, VStack, Divider, Select, Textarea, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, toast, useToast } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { BsStarFill } from "react-icons/bs";
import { FaRegFlag } from "react-icons/fa";
import { ScreenLoader } from "../../components/Common/ScreenLoader";
import { EmptyReviews } from "../../components/Instructor/EmptyState/Review";
// import myreviews from '../../data/reviews.json';
import { IReviewList } from "../../models/course.model";
import { fetchReviews, submitReviewResponse } from "../../services/instructor/courses-http.service";
import { reviewListUtil } from "../../utils/course.util";
interface responseValue {
    status: boolean,
    value: string
}
interface ReviewListResponse {

    [key: string]:  responseValue
}

const Reviews = () => {

    const [reviews, setreviews] = useState<IReviewList[]>([]);

    const cancelRef = useRef().current;
    const toast = useToast();

    const [isLoading, setIsLoading] = useState(true);
    const [response, setResponse] = useState<ReviewListResponse>({});
    const [change, setChange] = useState(false);
    const [isOpen, setIsOpen] = useState(false)
    const [currentItem, setCurrentItem] = useState<{index: string, value:responseValue}>()

    useEffect(() => {
        fetchReviews()
        .then((data) => {
            let res: ReviewListResponse = {}
            for (const value of data) {
                res[String(value.review_id)] = {value: '', status: false}
            }
            setResponse(res);
            setreviews(reviewListUtil(data))
            setIsLoading(!isLoading)
        })
        .catch((err) => err)
            
    }, []);

    useEffect(() => {
        setreviews(reviews)
            
    }, [change]);

    const setResponseData = () => {
        setIsOpen(!isOpen)
        if (!currentItem) {
           return false; 
        }

        submitReviewResponse({review_id: Number(currentItem.index), new_comment: currentItem.value.value, isAnonymous: false})
        .then((data) => {
            let newresponse = response;
            newresponse[currentItem.index] = currentItem.value;
            setResponse(newresponse);
            setChange(!change)
            toast({
                title: "Review Comment",
                description: "Comment submitted successfully",
                status: "success",
                duration: 3000,
            }) 
        })
        .catch((err) => {
            console.log(err);
            toast({
                title: "Review Comment",
                description: "Unable to submit, please try again",
                status: "error",
                duration: 3000,
            }) 
            
        })
        
    }

    const setReviewValue = (index:string, value: string) => {
        let newresponse = response;
        newresponse[index] = {...newresponse[index], value};
        setResponse(newresponse);
        setChange(!change)
    }

    const setItemData = (index: string, value:responseValue) => {
        if (response[index].value === '' && response[index].status) {
            toast({
                title: "Review Comment",
                description: "Review comment cannot be empty",
                status: "error",
                duration: 3000,
            }) 
            return;
        }

        if (!response[index].status) {
            let newresponse = response;
            newresponse[index] = value;
            setResponse(newresponse);
            setChange(!change)
        }else{
            setCurrentItem({index,value});
            setIsOpen(!isOpen);
        }
    }

    return (

        <Container boxShadow="base" maxW="container.xl" bg="white" py={3} px={10} mb={6} minHeight="70vh">

            {
                isLoading?
                <ScreenLoader />
                :
                reviews.length === 0 ?

                <EmptyReviews />

                :
                
                <Box>
                
                    <Text color="brand.3" fontSize={18} fontWeight="semibold">
                        {reviews.length > 0 ? 'Reviews' : 'Review'} ({reviews.length})
                    </Text>

                    <Flex mt={6} justifyContent="space-between">
                        <Box width="50%">
                            <Select  variant="outline" placeholder="ALL" size="sm" fontSize={12} color="brand.subtitle" />
                        </Box>

                        <Box width="40%" display="inline-flex">
                            <Select  variant="outline" placeholder="Rating" size="sm" fontSize={12} color="brand.subtitle" />
                            <Select ml={6} variant="outline" placeholder="Sort" size="sm" fontSize={12} color="brand.subtitle" />
                        </Box>
                    </Flex>

                    <Box my={8} >
                        <VStack spacing={6}>
                            {
                                reviews.map((each, index) => 
                                {
                                    return (
                                        <>
                                        <Box key={index} width="100%">
                                        <Flex justifyContent="space-between" alignContent="start" alignItems="start" >


                                            <Box>
                                                <Flex justifyContent="space-between" alignItems="start"  >
                                                
                                                    <Avatar size="md" name={each.name} src={each.img_url} />
                                                    
                                                    <Box ml={4}>
                                                        <Text color="brand.3" fontWeight="semibold" > {each.name} </Text>                                                     

                                                        <Box fontSize={12} color="brand.subtitle" >1 hour ago</Box>

                                                        <Box display="flex" color="brand.star" alignItems="center" mt={1} >
                                                            <HStack spacing={0.5} fontSize={7}>
                                                                {
                                                                    Array(each.star)
                                                                        .fill("")
                                                                        .map((_, i) => (
                                                                            <BsStarFill
                                                                                key={i}
                                                                            />
                                                                        )
                                                                    )
                                                                }
                                                            </HStack>
                                                            
                                                            <Box fontSize={12} color="brand.subtitle" ml={2}> for <Box as="span" color="brand.2"> {each.title} </Box></Box>
                                                        </Box>

                                                        <Text mt={1.5} fontSize={13.5} color="brand.title">
                                                            {each.comments}
                                                        </Text>

                                                       {
                                                           response &&
                                                        <>
                                                        {
                                                            !response[String(each.id)].status && response[String(each.id)].value !== '' ?
                                                            <>
                                                            <HStack>
                                                                <Avatar size="sm" name={each.name} src={each.img_url} />
                                                                <Text color="brand.3" fontSize={12.5} fontWeight="semibold" > {each.name} </Text> 
                                                            </HStack>
                                                            <Text ml={10} fontSize={12} color="brand.title">{response[String(each.id)].value}</Text>
                                                            </>
                                                            :
                                                            <>
                                                            {response[String(each.id)].status &&
                                                            <Textarea
                                                            placeholder="Review comment goes here"
                                                            p = {4}
                                                            fontSize={12}
                                                            size="sm"
                                                            minH="5rem"
                                                            onChange={(e) => setReviewValue(String(each.id), e.target.value)}
                                                            value={response[String(each.id)].value}
                                                            key={String(each.id)}
                                                        />
                                                        }

                                                        <Button mt={4} variant="outline" colorScheme="brand" size="sm"
                                                         fontSize={12} fontWeight="normal" px={6} 
                                                         onClick={
                                                             () => 
                                                                 setItemData(
                                                                     String(each.id),
                                                                     {...response[String(each.id)], status: !response[String(each.id)].status}) }
                                                        >Respond</Button>
                                                        </>
                                                        }
                                                        </>
                                                       
                                                       }
                                                    </Box>

                                                </Flex>

                                            </Box>

                                            <IconButton
                                                _focus={{boxShadow: "none"}}
                                                variant="link"
                                                aria-label="flag"
                                                color="brand.2"
                                                borderRadius={20}
                                                icon={<FaRegFlag />}
                                            />

                                        </Flex>

                                        { index !== reviews.length-1 ? <Divider mt={6}></Divider> : <Box> </Box > }
                                    
                                    </Box>
                                    </>  
                                    )
                                }
                                                              
                                )
                            }
                                
                        </VStack>

                    </Box>
                </Box>

            }

<AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={()=> setIsOpen(!isOpen)}
      >
        <AlertDialogContent>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Confirm Action
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={()=> setIsOpen(!isOpen)}>
                Cancel
              </Button>
              <Button colorScheme='brand' onClick={() => setResponseData() } ml={3}>
                Proceed
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogContent>
      </AlertDialog>

        </Container>
            
    );
}

export default Reviews;