import { CancelTokenSource } from "axios";
import { IDepartment, IDepartmentData, IEachDepartment, IHrActivityReport, IHrDashboard, IHrTopCourses, IHrTopInstructors, IHrTopStudents, IPaginatedUserDto, IUser, IUserDashboard } from "../../../models/auth.model";
import { ICategory, IPaginatedCourseDto, IPaginatedCourseReviewDto } from "../../../models/course.model";
import { IAllEnrolmentData } from "../../../models/enrolment/enrolment.model";
import { IUserGroupSelect } from "../../../models/group.model";
import { HttpResponse } from "../../../models/httpResponse.model";
import { IUserCreateDto, IUserEditDto, IPaginatedAnyUserDto, IAnyUserList, toggleUsersStatus } from "../../../models/user.model";
import {httpClient, axios} from "../../httpClient"

export let cancleRequest: CancelTokenSource;

export const fetchDepartment = async (url: string = "" ) => {

    let response = await httpClient.get<HttpResponse<IDepartment>>(`/hr/departments-overview${url}`);
    return response.data.data;
    
}
export const fetchAllEnrolments = async (url: string  ) => {

    let response = await httpClient.get<HttpResponse<IAllEnrolmentData>>(`/hr/courses/all-enrollments${url}`);
    return response.data.data;
    
}

export const genExcelDepartment = async () => {

    let response = await httpClient.get<HttpResponse<any>>(`/hr/departments-overview/download`, { responseType: 'arraybuffer' });
   

    return response.data;
}

export const genExcelEachDepartment = async (category_id: string) => {

    let response = await httpClient.get<HttpResponse<any>>(`/hr/departments/${category_id}/members/download`, { responseType: 'arraybuffer' });
   

    return response.data;
}

export const fetchEachDepartmentMember = async (category_id: string, url: string = "") => {
    let response = await httpClient.get<HttpResponse<IEachDepartment>>(`/hr/departments/${category_id}/members${url}`);
    console.log(response, 'each user id')
    return response.data.data;
} 

export const createNewDepartment = async (data: any) => {

    let response = await httpClient.post<HttpResponse<any>>("/hr/departments", data);
console.log(response, 'department creation')
    return response.data;
}
export const deleteDepartment = async ( category_id:string) => {

    let response = await httpClient.delete<HttpResponse<any>>(`/hr/departments/${category_id}`);
console.log(response, 'department deletion')

    return response.data;
}
export const editDepartment = async (data: any) => {

    let response = await httpClient.put<HttpResponse<any>>("/hr/departments", data);
console.log(response, 'department edited')
    return response.data;
}

export const fetchAllUsers = async ( url: string = "") => {

    let response = await httpClient.get<HttpResponse<IPaginatedUserDto>>(`/hr/users${url}`);
    console.log(response.data.data,'users in dept')
    return response.data;
}
export const fetchDepartmentByCategoryId = async (category_id: string, ) => {
    let response = await httpClient.get<HttpResponse<IDepartmentData>>(`/hr/departments/${category_id}`);
    return response.data.data;
} 

export const addMembersToDepartment = async (category_id: number, data: IUserGroupSelect[]) => {

    let response = await httpClient.put<HttpResponse<any>>(`/hr/departments/${category_id}/members`, data);
console.log(response, 'member added to department')
    return response.data;
}
///hr/departments/fhdf/members


