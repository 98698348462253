import {Box, Text, Avatar} from '@chakra-ui/react'
import React from 'react';

const CourseLine: React.FC<{position: string, title: string, bgColor: string, textColor: string}> = ({position, title, bgColor, textColor}) => {

    return (
        <Box display="inline-flex" alignItems="center">
            <Avatar bg={bgColor} color={textColor} size="sm" name={position} />
            <Text fontSize={13} whiteSpace="nowrap" ml={3} color="brand.subtitle">{title}</Text>                        
        </Box>
    )
}

export default CourseLine;