import { Avatar, Box, Container, HStack, Tag, Text, VStack } from "@chakra-ui/react";
import { Table, Thead, Tbody, Tr, Th, Td} from "@chakra-ui/react"
import transactions from '../../../data/transactions.json'

const Revenue = () => {

    return (
        <>
            <Text color="brand.subtitle" fontSize={17}>
                See how much your course is making
            </Text>

            <HStack mt={6} spacing={20}  justifyContent="center">
                <Container  width="fit-content" px={14} py={2} boxShadow="base" bg="white" m={0} >
                    <VStack pb={3} pt={1}>
                        <Text color="brand.subtitle" fontSize={13}  >Total Revenue</Text>

                        <Text color="brand.subtitle" fontWeight="semibold" fontSize={30} >$487.96</Text>
                    </VStack>
                </Container>

                <Container  width="fit-content" px={14} py={2} boxShadow="base" bg="white" m={0} >
                    <VStack pb={3} pt={1}>
                        <Text color="brand.subtitle" fontSize={13}  >This Month</Text>

                        <Text color="brand.subtitle" fontWeight="semibold" fontSize={30} >$127.43</Text>
                    </VStack>
                </Container>
                
                <Container  width="fit-content" px={14} py={2} boxShadow="base" bg="white" m={0} >
                    <VStack pb={3} pt={1}>
                        <Text color="brand.subtitle" fontSize={13}  >Balance</Text>

                        <Text color="brand.subtitle" fontWeight="semibold" fontSize={30} >$287.96</Text>
                    </VStack>
                </Container>
            </HStack>

            <Box boxShadow="base" mt={6}>
                <Box pt={6} ml={6}>
                    <Text fontWeight="semibold" color="brand.3" fontSize={18}>Latest Transactions</Text>

                </Box>

                <Table variant="simple" mt={5} >
                    <Thead fontWeight="normal" >
                        <Tr color="brand.subtitle" >
                            <Th textTransform="none">Student</Th>
                            <Th textTransform="none">ID</Th>
                            <Th textTransform="none">Course</Th>
                            <Th textTransform="none">Price</Th>
                            <Th textTransform="none">Status</Th>
                        </Tr>
                    </Thead>

                    <Tbody>
                        {
                            transactions.map((each, index) => 
                                <Tr key={index} fontSize={13} color="brand.subtitle">
                                    <Td>
                                        <HStack spacing={3} alignItems="center" maxW="fit-content">
                                            <Avatar name={each.student} size="sm" />

                                            <Text > 
                                                {each.student} 
                                            </Text>
                                        </HStack>
                                        
                                    </Td>
                                    <Td>{each.id}</Td>
                                    <Td>{each.course}</Td>
                                    <Td>{each.price}</Td>
                                    <Td> <Tag variant="outline" width="70%" justifyContent="center" borderRadius={1} fontSize={12} colorScheme={each.status? "success": "danger"}>{each.status? 'Successful' : 'Failed'}</Tag> </Td>
                                </Tr>
                            )
                        }
                        
                    </Tbody>
                </Table>
            </Box>
        </>
    );
}

export default Revenue;