
export const ManagementRoutes = {
    Main: "/management",
    Report: "/management/report",
    Student: "/management/student",
    Profile: "/management/profile",
    Security: "/management/security",
    Group: {
        Fetch: "/management/groups",
        Create: "/management/group/create",
        Discussion: "/management/group/discussion",
    },
    Course: {
        Fetch: "/management/courses",
        Status: "/management/courses/status",
        Assign: "/management/courses/assign",
        Create: "/management/course/create"
    }
}