import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton, Text, Box, useToast, FormControl, FormLabel, InputGroup, InputLeftElement, Input, FormErrorMessage, Spacer, Flex, Button, Checkbox, Center, Select, SimpleGrid, Icon
  } from "@chakra-ui/react"
import { FaChalkboardTeacher, FaUserGraduate } from "react-icons/fa";
import { useHistory } from "react-router-dom";

  import { IUser, IUserLevel, IUserRank } from "../../models/auth.model";
import { HrAdminRoutes } from "../../pages/HrAdmin/routes";


  export const PreviewProfileModal: React.FC<any> = ({isOpen,isPreview, onClose, userData, reloadData }: {isPreview:boolean,isOpen: any, onClose: any, userData: IUser, reloadData: any }) => {

    let {replace, push} = useHistory();

    
  const viewUser = async (userId: number) => {
      push(HrAdminRoutes.Users.ViewLink(userId));
      
  }

  
    
    return (
      <>
         <Modal isOpen={isOpen && isPreview} onClose={onClose} scrollBehavior="outside" closeOnOverlayClick={true}  size="4xl">
            <ModalOverlay />
              <ModalContent borderRadius={5} px={6} >
                <ModalHeader >
                 <Text fontSize={16}>{userData.full_name}</Text>               
                </ModalHeader>
                <ModalCloseButton size="sm" />
                <ModalBody pb={6}>
                  
                </ModalBody>
               

                <Flex pt= {100} pr={100} pl ={100} > 
                      {userData.role?.role_id && userData.role.role_id >= 1 &&  <Button
                        onClick={()=>viewUser(userData.id)}
                        border= "4px"
                        borderRadius="0" 
                        borderColor= 'brand.2'
                        bg = 'white'
                        pl = {20}
                        pt = {50}
                        ml = {50}
                        mr= {20} 
                        h = {200}
                        w = {300}
                        >
                        <Icon 
                        color='brand.2'
                        boxSize='200'
                        
                        >
                        {<FaUserGraduate />}

                        </Icon>
                      </Button>}
                      { userData.role?.role_id && userData.role.role_id >= 2 &&  <Button 
                        onClick={()=>viewUser(userData.id)
                        
                        }
                        border= "4px"
                        borderRadius="0" 
                        borderColor= 'success.500'
                        bg = 'white'
                        h = {200}
                        w = {300}
                        pl = {20}
                        pt = {50}
                      >
                        
                        <Icon 
                        color='success.500'
                        boxSize='200'
                        
                        >
                        {<FaChalkboardTeacher/>}

                        </Icon>
                        
                      </Button> }
                    </Flex>
                <Flex>
                {userData.role?.role_id && userData.role.role_id >= 1 && <Text ml = {250} mr = {250} fontSize={20} mt = {2}  color="brand.2" >Student</Text>}
                {userData.role?.role_id && userData.role.role_id >= 2 &&<Text fontSize={20} mt = {2}  color="success.500" >Instructor</Text>}
                </Flex>
                

{/* 
                <Box as='button' border= "1px" borderRadius='0' borderColor='gray.200' color='blue' px={4} >
                  Button
                </Box> */}
                
                   

                  
                
                
                
                
                <ModalFooter>
                </ModalFooter>
              </ModalContent>
          </Modal>
      </>
     
    )
  }

  // <Flex>
  // <Box borderColor='brand' p = {6} variant={'outline'}>
  //   xs
  //   {/* {<FaUserGraduate />} */}
    
  //  </Box>
//    <Box  p = {6}>
//     {/* {<FaChalkboardTeacher/>} */}
//     sm
//    </Box>
// </Flex>