export const appConstant = {
    role: {
        hr_admin : 4,
        training_manager : 3,
        instructor: 2,
        student: 1
    },
    months : ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
}

export const getFullDate = (datestring: string) => {
    const date = new Date( 
     datestring)
     const dateFormat =date.toLocaleDateString("en-US", { day: 'numeric' }) + " "+ date.toLocaleDateString("en-US", { month: 'short' }) + "," + date.toLocaleDateString("en-US", { year: 'numeric' })
   return dateFormat
  }