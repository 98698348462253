import { Box, Button, Container, Flex, HStack, Image, Input, InputGroup, Text, InputRightElement, Avatar, IconButton, useToast, Menu, MenuButton, MenuList, MenuItem, Portal, useDisclosure } from "@chakra-ui/react";
import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react"
import { BiBookAdd } from "react-icons/bi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { RiSearch2Line } from "react-icons/ri";
import { IoMdCheckmarkCircle } from "react-icons/io";
import { FaTimesCircle } from "react-icons/fa";
import { Status } from "../../components/Common/Status";
import { TrainingMgrRoutes } from "./routes";
import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import { courseApprovalService, courseDeletionService, fetchCourseProgress, fetchCourses } from "../../services/trainingMgr/course-http.service";
import { ICourseProgressList, ITrainingMgrCourseList } from "../../models/course.model";
import { courseProgressutil, courseStatusUtil, courseWithAuthorListUtil } from "../../utils/course.util";
import { EmptyCourseTM } from "../../components/TrainingManager/EmptyState/Courses";
import { ScreenLoader } from "../../components/Common/ScreenLoader";
import { EmptySearchResult } from "../../components/Common/EmptySearchResult";
import { CourseProgressModal } from "../../components/TrainingManager/CourseProgressModal";
import { StoreContext } from "../../store/StoreContext";
import { CourseDeclineModal } from "../../components/TrainingManager/CourseDeclineModal";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
} from '@chakra-ui/react'


const AllCourses = () => {

    const toast = useToast();

    let { replace, push } = useHistory();

    const [courses, setCourses] = useState<ITrainingMgrCourseList[]>([]);

    const [filteredCourses, setfilteredCourses] = useState<ITrainingMgrCourseList[]>([]);

    const [selectedCourse, setSelectedCourse] = useState<ITrainingMgrCourseList>();

    const [isLoading, setIsLoading] = useState(true);

    const [isSearchEmpty, setIsSearchEmpty] = useState(false);

    const { isOpen, onOpen, onClose } = useDisclosure()

    const { isOpen: isDeclineOpen, onOpen: onDeclineOpen, onClose: onDeclineClose } = useDisclosure()

    const [isLoadingCourseProgress, setIsLoadingCourseProgress] = useState(false);

    const [coursesProgress, setCoursesProgress] = useState<ICourseProgressList[]>([]);

    const { globalState } = useContext(StoreContext)

    const [pickedCourseIndex, setPickedCourseIndex] = useState<number>();

    const [pickedCourseId, setPickedCourseId] = useState<string>();

    const [isOpenAlert, setIsOpen] = useState(false)

    const onCloseAlert = () => setIsOpen(false)

    const cancelRef = useRef(null)

    // const [lastProgressDate, setLastProgressDate] = useState<String>();


    useEffect(() => {

        fetchCourses()
            .then((data) => {
                console.log(data)
                setfilteredCourses(courseWithAuthorListUtil(data));
                setCourses(courseWithAuthorListUtil(data));
                setIsLoading(false);
            })
            .catch((err) => err)

    }, []);


    const courseApproval = async (truth: boolean, courseId: string, courseIndex: number, message?: string, action?: string) => {

        try {
            let courseResponse = await courseApprovalService({ courseId, approve: truth, message: message, action: action });

            let course = courseResponse.data;


            if (course) {

                let existingCourse = courses.filter(each => each.courseId === course.course_id).at(0) as ITrainingMgrCourseList;

                let mainCourseIndex = courses.findIndex(each => each.courseId === course.course_id);

                if (course.approvedAt !== null) {
                    toast({
                        title: "Course Approval",
                        description: "Course activated successfully",
                        status: "success",
                    })
                } else if (course.declinedAt !== null) {
                    courses.splice(mainCourseIndex, 1)
                    toast({
                        title: "Course Decline",
                        description: "Course succesfully declined",
                        status: "success",
                    })
                }


                if (course.suspendedAt === null) {

                    courses.splice(mainCourseIndex, 1, { ...existingCourse, approvedAt: course.approvedAt, suspendedAt: course.suspendedAt, declinedAt: course.declinedAt, status: courseStatusUtil(course.approvedAt as string, course.suspendedAt as string, course.declinedAt as string) })

                } else if (course.suspendedAt !== null) {
                    courses.splice(mainCourseIndex, 1, { ...existingCourse, approvedAt: course.approvedAt, suspendedAt: course.suspendedAt, status: courseStatusUtil(course.approvedAt as string, course.suspendedAt as string, course.declinedAt as string) })

                    //  courses.splice(mainCourseIndex, 1);
                    toast({
                        title: "Course Suspension",
                        description: "Course succesfully suspended",
                        status: "success",
                    })
                }

                setCourses([...courses]);

                let existingFilteredCourse = filteredCourses.at(courseIndex) as ITrainingMgrCourseList;

                if (course.suspendedAt === null) {

                    filteredCourses.splice(courseIndex, 1, { ...existingFilteredCourse, approvedAt: course.approvedAt, suspendedAt: course.suspendedAt, status: courseStatusUtil(course.approvedAt as string, course.suspendedAt as string, course.declinedAt as string) })

                } else {
                    filteredCourses.splice(courseIndex, 1, { ...existingFilteredCourse, approvedAt: course.approvedAt, suspendedAt: course.suspendedAt, status: courseStatusUtil(course.approvedAt as string, course.suspendedAt as string, course.declinedAt as string) })

                    //filteredCourses.splice(courseIndex, 1);

                }

                setfilteredCourses([...filteredCourses]);
            }

        } catch (error) {
            toast({
                title: "Course Approval",
                description: "Something went Wrong. Try again later",
                status: "error",
            })
        }
    }

    const courseDecline = (courseId: string, courseIndex: number) => {

        setPickedCourseIndex(courseIndex);

        setPickedCourseId(courseId);

        onDeclineOpen();
    }

    const changeCourseApproval = async (course: ITrainingMgrCourseList, courseIndex: number) => {

        let truth = course.approvedAt ? false : true;

        if (truth) {

            await courseApproval(truth, course?.courseId as string, courseIndex);


        } else {

            await courseApproval(false, course?.courseId as string, courseIndex, 'suspend');
        }

    }

    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {

        let searchTerm = event.target.value.toLowerCase();

        let filtered = courses.filter(each => each.title.toLowerCase().includes(searchTerm));

        setfilteredCourses(filtered);

        if (filtered.length > 0)
            setIsSearchEmpty(false);
        else
            setIsSearchEmpty(true)
    }

    const viewCourse = async (course: ITrainingMgrCourseList) => {

        setCoursesProgress([]);

        setIsLoadingCourseProgress(true);

        setSelectedCourse(course);

        onOpen();

        try {
            let courseProgress = await fetchCourseProgress(course.courseId as string);

            setCoursesProgress(courseProgressutil(courseProgress));

            setIsLoadingCourseProgress(false)

        } catch (error) {
            console.log(error);
        }
    }

    const flterCourse = async (courseId: string, lastProgressDate: string) => {

        setCoursesProgress([]);

        setIsLoadingCourseProgress(true);

        try {
            let courseProgress = await fetchCourseProgress(courseId as string, lastProgressDate);

            setCoursesProgress(courseProgressutil(courseProgress));

            setIsLoadingCourseProgress(false)

        } catch (error) {
            console.log(error);
        }
    }

    const courseDeleteAlert = (courseId: string, courseIndex: number) => {

        setPickedCourseIndex(courseIndex);

        setPickedCourseId(courseId);

        setIsOpen(true);
    }

    const deleteCourse = async () => {

        try {
            let courseResponse = await courseDeletionService(pickedCourseId as string);

            let course = courseResponse.data;

            onCloseAlert();
            toast({
                title: "Course Deletion",
                description: "Course Deleted Successfully",
                status: "success",
            })

            if (course) {

                let mainCourseIndex = courses.findIndex(each => each.courseId === course.course_id)

                courses.splice(mainCourseIndex, 1)

                setCourses([...courses]);

                filteredCourses.splice(pickedCourseIndex as number, 1)

                setfilteredCourses([...filteredCourses]);
            }

        } catch (error) {
            onCloseAlert();

            toast({
                title: "Course Deletion",
                description: "Something went Wrong. Try again later",
                status: "error",
            })
        }

    }

    return (

        <Container boxShadow="base" maxW="container.xl" bg="white" pt={3} pb={10} px={8} minHeight="70vh">

            {
                isLoading ?
                    <ScreenLoader />

                    :

                    courses.length === 0 ?
                        <EmptyCourseTM />
                        :
                        <Box>

                            <Flex >
                                <Text pl={6} color="brand.3" fontSize={18} fontWeight="semibold"> All Courses </Text>
                                {/* <Button colorScheme="brand" leftIcon={<BiBookAdd fontSize={14} />} onClick={() => replace(TrainingMgrRoutes.Course.Create)} size="sm" fontSize={10}>NEW COURSE</Button> */}
                            </Flex>

                            <Flex justifyContent="space-between" alignItems="center" px={6} mt={6}>
                                <InputGroup color="brand.subtitle" bg="white" width="40%" >
                                    <Input placeholder="Search all courses" onChange={e => handleSearchChange(e)} fontSize={12} size="sm" />

                                    <InputRightElement h="1.9rem" pointerEvents="none" children={<RiSearch2Line size="0.9rem" />} />
                                </InputGroup>
                            </Flex>

                            {
                                isSearchEmpty ?

                                    <Box pt={20}>
                                        <EmptySearchResult />
                                    </Box>

                                    :

                                    <Table variant="simple" mt={5} >
                                        <Thead fontWeight="normal" >
                                            <Tr color="brand.subtitle" >
                                                <Th textTransform="none">Name</Th>
                                                <Th textTransform="none">Instructor</Th>
                                                <Th textTransform="none">Date Added</Th>
                                                <Th textTransform="none">Status</Th>
                                                <Th textTransform="none">Action</Th>
                                                <Th textTransform="none"></Th>
                                            </Tr>
                                        </Thead>

                                        <Tbody>
                                            {
                                                filteredCourses.map((each, index) =>
                                                    <Tr key={index} fontSize={13} color="brand.subtitle">
                                                        <Td cursor="pointer" >
                                                            <HStack>
                                                                <Image src={each.cover_image} fallbackSrc="https://via.placeholder.com/150" height="auto" maxWidth="50px" />
                                                                <Text fontSize={11}>{each.title}</Text>
                                                            </HStack>
                                                        </Td>
                                                        <Td>
                                                            <HStack>
                                                                <Avatar size="xs" name={each.author} src={each.img_url} />
                                                                <Text isTruncated fontSize={11}>{each.author}</Text>
                                                            </HStack>
                                                        </Td>
                                                        <Td fontSize={12} whiteSpace="nowrap" >{each.date_added}</Td>
                                                        <Td><Status statusType={each.status as string} /></Td>
                                                        <Td>
                                                            {
                                                                (each.approvedAt) ?

                                                                    <Button colorScheme="red" onClick={() => changeCourseApproval(each, index)} variant="outline" rounded="none" size="xs" fontSize={10}> Deactivate </Button>
                                                                    : (each.suspendedAt) ?
                                                                        <Button colorScheme="brand" onClick={() => changeCourseApproval(each, index)} variant="outline" rounded="none" size="xs" fontSize={10}> Activate </Button>
                                                                        :
                                                                        <HStack>
                                                                            <Button colorScheme="brand" onClick={() => push(TrainingMgrRoutes.Course.PreviewLink(each?.courseId as string))} variant="outline" rounded="none" size="xs" fontSize={10}> Preview </Button>
                                                                            <Button colorScheme="red"onClick={() => courseDeleteAlert(each?.courseId as string, index)}  variant="outline" rounded="none" size="xs" fontSize={10}> Delete </Button>
                                                                        </HStack>
                                                            }
                                                        </Td>
                                                        <Td>
                                                            {/* {
                                                                !each.suspendedAt && !each.approvedAt &&(
                                                                    <Menu >
                                                                <MenuButton >
                                                                    <IconButton
                                                                        variant="ghost"
                                                                        aria-label="dots"
                                                                        borderRadius={20}
                                                                        size="xs"
                                                                        icon={<BsThreeDotsVertical />}
                                                                        fontSize={16}
                                                                    />
                                                                </MenuButton>
                                                                <MenuList fontSize={12} minWidth="8rem" maxWidth="8rem" borderRadius={0} color="brand.bodyText" >
                                                                    {each.authorId === globalState.profile?.user_id && <MenuItem onClick={() => push(TrainingMgrRoutes.Course.EditLink(each?.courseId as string))} > Edit </MenuItem>}
                                                                    <MenuItem onClick={() => push(TrainingMgrRoutes.Course.PreviewLink(each?.courseId as string))} > Preview </MenuItem>
                                                                    <MenuItem onClick={() => courseDeleteAlert(each?.courseId as string, index)} > Delete Course </MenuItem>
                                                                </MenuList>
                                                            </Menu>

                                                                )
                                                            } */}
                                                        </Td>
                                                    </Tr>
                                                )
                                            }

                                        </Tbody>
                                    </Table>
                            }

                        </Box>
            }

            <Portal>
                <CourseProgressModal isOpen={isOpen} onClose={onClose} isLoading={isLoadingCourseProgress} coursesProgress={coursesProgress} selectedCourse={selectedCourse} flterCourse={flterCourse} />
            </Portal>

            <Portal>
                <CourseDeclineModal isOpen={isDeclineOpen} onClose={onDeclineClose} courseIndex={pickedCourseIndex} courseId={pickedCourseId} courseApproval={courseApproval} />
            </Portal>

            <Portal>
                <AlertDialog
                    isOpen={isOpenAlert}
                    leastDestructiveRef={cancelRef}
                    onClose={onCloseAlert}
                >
                    <AlertDialogOverlay>
                        <AlertDialogContent>
                            <AlertDialogHeader fontSize='md' fontWeight='semibold'>
                                Delete Course
                            </AlertDialogHeader>

                            <AlertDialogBody fontSize={15}>
                                Are you sure? You can't undo this action afterwards.
                            </AlertDialogBody>

                            <AlertDialogFooter>
                                <Button size={"sm"} ref={cancelRef} onClick={onCloseAlert}>
                                    Cancel
                                </Button>
                                <Button size={"sm"} colorScheme='red' onClick={deleteCourse} ml={3}>
                                    Delete
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialogOverlay>
                </AlertDialog>
            </Portal>

        </Container>
    );
}

export default AllCourses;