import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton, Avatar, Text, HStack, Box, Container, Progress, VStack, StackDivider, CircularProgress, CircularProgressLabel
} from "@chakra-ui/react"
import { IStudent, IStudentList } from "../../models/course.model"
import { useEffect, useState } from "react";


export const StudentProfileModal: React.FC<any> = ({isOpen, onClose, student, studentData }: {isOpen: any, onClose: any, student: IStudentList, studentData: IStudent }) => {

    const [coursesProgress, setCoursesProgress] = useState<IEachCourseProgress[]>([]);

    const [progressValue, setProgressValue] = useState(0);

    const [inProgress, setInProgress] = useState(0);
    
    const [notStarted, setNotStarted] = useState(0);
    
    const [completed, setCompleted] = useState(0);

    
    interface IEachCourseProgress{
        title: string,
        progress: number
    }

    useEffect(() => {

        if(studentData !== undefined){

            let inProgress = studentData.courseEnrollments.filter(each => (each.courses.courseLessons.length) !== (each.progresses.length) ).length

            let notStarted = studentData.courseEnrollments.filter(each => (each.progresses.length) === 0 ).length

            let completed = studentData.courseEnrollments.filter(each => (each.courses.courseLessons.length) === (each.progresses.length) ).length

            setInProgress(inProgress);

            setNotStarted(notStarted);

            setCompleted(completed)

            let courseData = studentData?.courseEnrollments?.map( each => {

                let completed = each.progresses.length;

                let lessons = each.courses.courseLessons.length;

                let progress = (completed / lessons) * 100;

                return {
                    title: each.courses.title,
                    progress: Math.round(progress)
                }
            })
        
            setCoursesProgress(courseData);
        }

        var timeout: any;

        for (let index = 0; index <= student.progress; index++) {
            timeout = setTimeout(() => {
                setProgressValue(index);
            }, index * 30);
            
        }

        return () => clearTimeout(timeout)

    }, [student]);


    return (
    <>
        
      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="outside" closeOnOverlayClick={true}  size="4xl">
        <ModalOverlay />
        <ModalContent borderRadius={5} px={6} >
          <ModalHeader >
              <Text fontSize={16}>Overview</Text>               
            </ModalHeader>
          <ModalCloseButton size="sm" />
          <ModalBody pb={6}>

            <Text fontSize={13} pb={3} pl={3} color="brand.2" >Profile</Text>

            <Progress width="30%" value={25} size="xs" height="0.1rem" mb={4} ></Progress>

            <HStack spacing={4} justifyContent="start" alignItems="start">
                
                <Container  boxShadow="sm" maxW="container.xl" bg="white" py={3} px={4} width="35%" >
                    <HStack justifyContent="start" alignItems="start">
                        <Avatar size="sm" name={student.name} src={student.img_url} />
                        <Box>
                            <Text isTruncated fontSize={13}  color="brand.3" fontWeight="semibold">{student.name}</Text>           

                            <Text isTruncated fontSize={10} fontWeight="normal" color="brand.subtitle"> Student </Text>     
                            
                            <Text mt={4} color="brand.3" fontSize={13}>Brief Info</Text>  
                            
                            <Text fontSize={11} textAlign="start" mt={2} color="brand.subtitle">
                                {studentData?.description}
                            </Text>
                            
                            <VStack justifyContent="start" alignItems="start" mt={4} divider={<StackDivider borderColor="gray.200" />} align="stretch" spacing={3}>
                                <Box color="brand.subtitle">
                                    <Text fontSize={11} mb={1} >Phone Number</Text>
                                    <Text fontSize={13}> {studentData?.phone} </Text>
                                </Box>
                                
                                <Box color="brand.subtitle">
                                    <Text fontSize={11} mb={1} >Email</Text>
                                    <Text fontSize={13}> {studentData?.email} </Text>
                                </Box>
                                
                                <Box color="brand.subtitle">
                                    <Text fontSize={11} mb={1} >Learner's Status</Text>
                                    <Text fontSize={13}>Internal</Text>
                                </Box>
                                
                                <Box color="brand.subtitle">
                                    <Text fontSize={11} mb={1} >Country</Text>
                                    <Text fontSize={13}>Nigeria</Text>
                                </Box>
                            </VStack>
                        </Box>
                    </HStack>
                </Container>
               
               <Box width="65%">
                    <Container  boxShadow="sm" maxW="container.xl" bg="white" py={3} px={1} mb={4} >
                        <HStack alignItems="start" justifyContent="space-between" spacing={8}>
                            <Box width="50%">
                                
                                <Text fontSize={12} color="brand.3" fontWeight="semibold" >Course Status</Text>

                                <HStack mt={4} alignItems="center" spacing={5}>
                                
                                    <CircularProgress color="brand.2" value={progressValue} mt={2} size="130px" capIsRound={true} thickness="8px" >
                                          <CircularProgressLabel>
                                              <Box color="brand.subtitle">
                                                <Text fontSize={16} fontWeight="semibold">{progressValue}%</Text>
                                                <Text fontSize={11}>Progress</Text>
                                              </Box>                                              
                                          </CircularProgressLabel>
                                    </CircularProgress>
                                
                                    <VStack alignItems="start" spacing={1}>
                                        <Box>
                                            <Text fontSize={16} color="brand.2" > {student.courses} </Text>
                                            <Text fontSize={10} color="brand.subtitle">Total Enrolled</Text>
                                        </Box>
                                        <Box>
                                            <Text fontSize={16} color="brand.2" > {inProgress} </Text>
                                            <Text fontSize={10} color="brand.subtitle">In Progress</Text>
                                        </Box>
                                        <Box>
                                            <Text fontSize={16} color="brand.2" > {notStarted} </Text>
                                            <Text fontSize={10} color="brand.subtitle">Not Started</Text>
                                        </Box>
                                        <Box>
                                            <Text fontSize={16} color="brand.2" > {completed} </Text>
                                            <Text fontSize={10} color="brand.subtitle">Completed</Text>
                                        </Box>
                                    </VStack>

                                </HStack>
                            </Box>
                            
                            <Box width="50%">

                                <Text fontSize={12} color="brand.3" fontWeight="semibold" >In Progress</Text>

                                <VStack mt={4} alignItems="start" justifyContent="space-between">

                                    {
                                        coursesProgress.map( (each, index) => 

                                            <HStack key={index} justifyContent="start" width="100%" spacing={4}>
                                                
                                                <CircularProgress color="brand.2" value={each.progress} mt={2} size="35px" capIsRound={true} thickness="6px" >
                                                    <CircularProgressLabel>
                                                            <Text fontSize={9} color="brand.2" fontWeight="semibold"> {each.progress} %</Text>
                                                    </CircularProgressLabel>
                                                </CircularProgress>

                                                <Text fontSize={13} color="brand.subtitle" isTruncated title="" > {each.title} </Text>
                                            
                                            </HStack>
                                        )
                                    }
                                    
                                </VStack>
                            </Box>
                        </HStack>
                    </Container>
                    
                    <Container  boxShadow="sm" maxW="container.xl" bg="white" py={3} px={4} >
                        .
                    </Container>
               </Box>

            </HStack>

          </ModalBody>

          <ModalFooter>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </>
  )
}