
const Progress = {
    baseStyle:{
        borderRadius: "2.82px",
        color: "#4E78F5",
        backgroundColor: "#4E78F5"
    },
    variants: {
        solid: {
            bg: "red",
        },
  },
    defaultProps: {
        colorScheme: "brand"
    },
}

export default Progress;