import { IBridgeCount, IBridgeCountData, IUser } from "../../models/auth.model";
import { IAssignCourseDto, IAssignCourseToUnitDto, ICourse, ICourseApproval, ICourseProgress, ICourseWithAuthor, ICourseWithAuthorAndStudents, IStudent } from "../../models/course.model";
import { ITrainingMgrCourseCategories, ITrainingMgrDashboardStat } from "../../models/dashboard.model";
import { HttpResponse } from "../../models/httpResponse.model";
import {httpClient} from "../httpClient"

export const fetchDashboardStat = async () => {

    let response = await httpClient.get<HttpResponse<ITrainingMgrDashboardStat>>("/training-manager/dashboard/stats");

    return response.data.data;
}

export const fetchDashboardCourseCategories = async () => {

    let response = await httpClient.get<HttpResponse<ITrainingMgrCourseCategories[]>>("/training-manager/courses/categories");

    return response.data.data;
}

export const fetchCourses = async () => {

    let response = await httpClient.get<HttpResponse<ICourseWithAuthor[]>>("/training-manager/all-courses");

    return response.data.data;
}

export const fetchCoursesListTM = async () => {

    let response = await httpClient.get<HttpResponse<ICourse[]>>("/training-manager/courses/list");

    return response.data.data;
}

export const fetchAssignedCourses = async () => {

    let response = await httpClient.get<HttpResponse<ICourseWithAuthorAndStudents[]>>("/training-manager/courses/assign");

    return response.data.data;
}

export const courseApprovalService = async (data: ICourseApproval) => {

    let response = await httpClient.put<HttpResponse<ICourse>>("/training-manager/course/approve", data);
    return response.data;
}

export const fetchPendingCourses = async (limit?: number) => {

    let response = await httpClient.get<HttpResponse<ICourseWithAuthor[]>>(`/training-manager/courses/request/${limit}`);

    return response.data.data;
}

export const fetchStudents = async (limit?: number) => {

    let response = await httpClient.get<HttpResponse<IUser[]>>(`/training-manager/students/${limit}`);

    return response.data.data;
}

export const assignCourseToStudentService = async (data: IAssignCourseDto) => {

    let response = await httpClient.post<HttpResponse<IUser[]>>("/training-manager/course/assign", data);

    return response.data;
}

export const fetchStudentsOverview = async () => {

    let response = await httpClient.get<HttpResponse<IStudent[]>>("/training-manager/students/overview");

    return response.data.data;
}

export const fetchCourseStatus = async () => {

    let response = await httpClient.get<HttpResponse<ICourse[]>>("/training-manager/courses/status");

    return response.data.data;
}

export const fetchCoursePreview = async (courseId: string) => {

    let response = await httpClient.get<HttpResponse<ICourse>>(`/training-manager/course/${courseId}/preview`);

    return response.data.data;
}


export const fetchCourseProgress = async (courseId: string, lastProgressDate?: string): Promise<ICourseProgress | undefined> => {

    const url = lastProgressDate? `/training-manager/course/${courseId}/progress?lastProgress=${lastProgressDate}` : `/training-manager/course/${courseId}/progress`

    let response = await httpClient.get<HttpResponse<ICourseProgress>>(url);

    return response.data.data;
}

export const fetchCourseStudents = async (courseId: string) => {

    let response = await httpClient.get<HttpResponse<IUser[]>>(`/training-manager/courses/${courseId}/students`);

    return response.data.data;
}

export const fetchMandatoryAssignedCourses = async () => {

    let response = await httpClient.get<HttpResponse<ICourseWithAuthorAndStudents[]>>("/training-manager/courses/mandatory/assign");

    return response.data.data;
}

export const courseDeletionService = async (courseId: string) => {

    let response = await httpClient.delete<HttpResponse<ICourse>>(`/training-manager/course/${courseId}`);

    return response.data;
}

export const assignCourseToUnitService = async (data: IAssignCourseToUnitDto) => {

    let response = await httpClient.post<HttpResponse<IUser[]>>(`/training-manager/course/unit/assign`, data);

    return response.data;
}

export const fetchBridgeCount = async (courseId: string, url: string = "") => {

    let response = await httpClient.get<HttpResponse<any[]>>(`/training-manager/courses/${courseId}/students/${url}`);

    return response.data.data;
}