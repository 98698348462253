import { Flex, Text } from "@chakra-ui/react";


export const EmptySearchResult: React.FC<any> = () => {

     return (
        <Flex minHeight="10em"  justifyContent="center">
        
            <Flex direction="column" alignItems="center" justifyContent="center" >


                <Text mb={4} fontSize={13} color="brand.subtitle"> No Search results Found. </Text>
                
            </Flex>
        </Flex>
    );
}