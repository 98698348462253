import { Box, Button, Container, Flex, HStack, Image, Input, InputGroup, Text, InputRightElement, Avatar, IconButton, Portal, useDisclosure, useToast } from "@chakra-ui/react";
import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react"
import { BsThreeDotsVertical } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { TrainingMgrRoutes } from './routes';
import { RiSearch2Line } from "react-icons/ri";
import { IoMdCheckmarkCircle } from "react-icons/io";
import { FaTimesCircle } from "react-icons/fa";
import { Status } from "../../components/Common/Status";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { ChangeEvent, useEffect, useState } from "react";
import { fetchTMGroups, groupApprovalService } from "../../services/group.service";
import { IGroupList, IGroupWithStatus } from "../../models/group.model";
import { EmptyGroup } from "../../components/Instructor/EmptyState/Groups";
import { ScreenLoader } from "../../components/Common/ScreenLoader";
import { EmptySearchResult } from "../../components/Common/EmptySearchResult";
import { GroupDeclineModal } from "../../components/TrainingManager/GroupDeclineModal";
import { ITrainingMgrCourseList } from "../../models/course.model";
import { groupStatusUtil } from "../../utils/group.util";



const AllGroups = () => {

    const [groups, setGroups] = useState<IGroupWithStatus[]>([]);

    const [filteredGroups, setFilteredGroups] = useState<IGroupWithStatus[]>([]);
    const [pickedGroupIndex, setPickedGroupIndex] = useState<number>();
    const toast = useToast();

    const [pickedGroupId, setPickedGroupId] = useState<string>();

    const [isLoading, setIsLoading] = useState(true);
    const { isOpen: isDeclineOpen, onOpen: onDeclineOpen, onClose: onDeclineClose } = useDisclosure()


    const [isSearchEmpty, setIsSearchEmpty] = useState(false);

    let history = useHistory();

    useEffect(() => {

        fetchTMGroups()
            .then((data) => {
                setFilteredGroups((data));
                setGroups((data));
                setIsLoading(false)
            })
            .catch((err) => err)

    }, [])

    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {

        let searchTerm = event.target.value.toLowerCase();

        let filtered = groups.filter(each => each.title.toLowerCase().includes(searchTerm));

        setFilteredGroups(filtered);

        if (filtered.length > 0)
            setIsSearchEmpty(false);
        else
            setIsSearchEmpty(true)
    }

    const groupApproval = async (groupIndex: number, groupId: number, group_status_id: number) => {

        try {
            let groupResponse = await groupApprovalService({ group_id: groupId, group_status_id: 3 });
            console.log(groupResponse, 'group response data')
            let group = groupResponse.data;

                   
            if (group) {

                // let existingGroup = groups.filter(each => each.group_id === group.course_id).at(0) as IGroupWithStatus;

                // let mainGroupIndex = groups.findIndex(each => each.group_id === group.course_id);


                toast({
                    title: "Course Approval",
                    description: "Group approval successful",
                    status: "success",
                })



             

                setGroups([...groups]);

                let existingFilteredCourse = filteredGroups.at(groupIndex) as IGroupWithStatus;
                existingFilteredCourse.status = group.status

             
                filteredGroups.splice(groupIndex, 1, { ...existingFilteredCourse})

               

                setFilteredGroups([...filteredGroups]);
            }

        } catch (error) {
            toast({
                title: "Group Approval",
                description: "Something went Wrong. Try again later",
                status: "error",
            })
        }
    }


    const groupDecline = async (groupIndex: number, groupId: number, group_status_id: number) => {

        try {
            let groupResponse = await groupApprovalService({ group_id: groupId, group_status_id: 2 });
            console.log(groupResponse, 'group response data')
            let group = groupResponse.data;

                   
            if (group) {

                toast({
                    title: "Group Approval",
                    description: "Group decline successful",
                    status: "success",
                })
                setGroups([...groups]);

                let existingFilteredCourse = filteredGroups.at(groupIndex) as IGroupWithStatus;
                existingFilteredCourse.status = group.status

             
                filteredGroups.splice(groupIndex, 1, { ...existingFilteredCourse})

               

                setFilteredGroups([...filteredGroups]);
            }

        } catch (error) {
            toast({
                title: "Group Approval",
                description: "Something went Wrong. Try again later",
                status: "error",
            })
        }
    }
   


    return (

        <Container boxShadow="base" maxW="container.xl" bg="white" pt={3} pb={10} px={8} minHeight="70vh">

            {
                isLoading ?

                    <ScreenLoader />

                    :

                    groups.length === 0 ?

                        <EmptyGroup createGroupView={() => history.push(TrainingMgrRoutes.Group.Create)} />

                        :

                        <Box>

                            <Flex justifyContent="space-between">
                                <Text pl={6} color="brand.3" fontSize={18} fontWeight="semibold"> All Groups </Text>
                                <Button colorScheme="brand" leftIcon={<AiOutlineUsergroupAdd style={{ 'fontSize': '18' }} />} onClick={() => history.replace(TrainingMgrRoutes.Group.Create)} size="sm" fontSize={10}>NEW GROUP</Button>
                            </Flex>

                            <Flex justifyContent="space-between" alignItems="center" px={6} mt={6}>
                                <InputGroup color="brand.subtitle" bg="white" width="40%" >
                                    <Input placeholder="Search all groups on this plaftorm" onChange={e => handleSearchChange(e)} fontSize={12} size="sm" />

                                    <InputRightElement h="1.9rem" pointerEvents="none" children={<RiSearch2Line size="0.9rem" />} />
                                </InputGroup>
                            </Flex>

                            {
                                isSearchEmpty ?

                                    <EmptySearchResult />

                                    :

                                    <Table variant="simple" mt={5} >
                                        <Thead fontWeight="normal" >
                                            <Tr color="brand.subtitle" >
                                                <Th textTransform="none">Name</Th>
                                                <Th textTransform="none">Instructor</Th>
                                                <Th textTransform="none">Date Added</Th>
                                                <Th textTransform="none">Status</Th>
                                                <Th textTransform="none">Action</Th>
                                            
                                            </Tr>
                                        </Thead>

                                        <Tbody>
                                            {
                                                filteredGroups.map((each, index) =>
                                                    <Tr key={index} fontSize={13} color="brand.subtitle">
                                                        <Td>
                                                            <Text fontSize={11}>{each.title}</Text>
                                                        </Td>
                                                        <Td>
                                                            <HStack>
                                                                <Avatar size="xs" name={each.user.full_name} src={each.user.photo_url} />
                                                                <Text isTruncated fontSize={11}>{each.user.full_name}</Text>
                                                            </HStack>

                                                        </Td>
                                                        <Td>{(new Date(each.createdAt).toDateString())}</Td>
                                                        <Td><Status statusType={each.status.title} /></Td>
                                                        <Td>
                                                            <HStack>
                                                                <IconButton
                                                                    variant="ghost"
                                                                    aria-label="accept"
                                                                    color="success.500"
                                                                    borderRadius={20}
                                                                    size="xs"
                                                                    onClick={() => groupApproval(index, each.id, each.status.id)}
                                                                    icon={<IoMdCheckmarkCircle />}
                                                                    fontSize={16}
                                                                />
                                                                <IconButton
                                                                    variant="ghost"
                                                                    aria-label="cancle"
                                                                    color="danger.300"
                                                                    borderRadius={20}
                                                                    onClick={() => groupDecline(index, each.id, each.status.id)}
                                                                    size="xs"
                                                                    icon={<FaTimesCircle />}
                                                                    fontSize={14}
                                                                />
                                                            </HStack>
                                                        </Td>
                                                     
                                                    </Tr>
                                                )
                                            }

                                        </Tbody>
                                    </Table>

                            }


                        </Box>
            }
            <Portal>
                <GroupDeclineModal isOpen={isDeclineOpen} onClose={onDeclineClose} groupIndex={pickedGroupIndex} groupId={pickedGroupId} groupApproval={groupApproval} />
            </Portal>


        </Container>

    );
}

export default AllGroups;