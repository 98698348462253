import { CancelTokenSource } from "axios";
import { IUserInstructorDashboard } from "../../models/auth.model";
import { ICourse, ICourseDto, ICourseInfoFormData, ICourseTopic, ICourseTopicDto, ICourseWithStudents, ILesson, ILessonArticleDto, ILessonDto, ILessonNoteDto, ILessonUpdateDto, InstructorCourse, IReview, IReviewResponse, IReviewResponseDto, IReviews, IStudent } from "../../models/course.model";
import { IQuizOptionState } from "../../models/create_course.model";
import { IGroup } from "../../models/group.model";
import { HttpResponse } from "../../models/httpResponse.model";
import { ICorrectAnswerDto, IQuiz, IQuizAnswer, IQuizAnswerDto, IQuizDto, IQuizPassmarkDto, IQuizQuestion, IQuizQuestionDto } from "../../models/quiz.model";
import {httpClient, axios} from "../httpClient"

export let cancleRequest: CancelTokenSource;

export const fetchCourses = async () => {

    let response = await httpClient.get<HttpResponse<InstructorCourse[]>>("/instructor/course");

    return response.data.data;
}

export const fetchStudents = async () => {

    let response = await httpClient.get<HttpResponse<IStudent[]>>("/instructor/students");

    return response.data.data;
}

export const fetchReviews = async () => {

    let response = await httpClient.get<HttpResponse<IReviews[]>>("/instructor/course/review");

    return response.data.data;
}

export const submitReviewResponse = async (data: IReviewResponseDto) => {

    let response = await httpClient.post<HttpResponse<IReviewResponse[]>>("/instructor/course/review/reply", data);

    return response.data.data;
}

export const fetchGroups = async () => {

    let response = await httpClient.get<HttpResponse<IGroup[]>>("/instructor/groups");

    return response.data.data;
}

export const getDashboard = async () => {

    let response = await httpClient.get<HttpResponse<IUserInstructorDashboard>>("/instructor/dashboard");

    return response.data.data;
}

export const fetchCourseFormData = async () => {

    let response = await httpClient.get<HttpResponse<ICourseInfoFormData>>("/instructor/course/information/formdata");

    return response.data.data;
}

export const submitCourseInfo = async (courseInfo: ICourseDto) => {
    let response = await httpClient.post<HttpResponse<ICourse>>("/instructor/course/information", courseInfo);

    return response.data.data;
}

export const updateCourseInfo = async (courseInfo: ICourseDto) => {
    let response = await httpClient.put<HttpResponse<ICourse>>("/instructor/course/information", courseInfo);

    return response.data.data;
}

export const submitCourseTopic = async (courseTopic: ICourseTopicDto) => {
    let response = await httpClient.post<HttpResponse<ICourseTopic>>("/instructor/course/topic", courseTopic);

    return response.data.data;
}

export const updateCourseTopic = async (courseTopic: ICourseTopicDto) => {
    let response = await httpClient.put<HttpResponse<ICourseTopic>>("/instructor/course/topic", courseTopic);

    return response.data.data;
}

export const submitCourseLesson = async (courseLesson: ILessonDto) => {
    let response = await httpClient.post<HttpResponse<ILesson>>("/instructor/course/lesson", courseLesson);

    return response.data.data;
}

export const submitCourseLessonVideo = async (formData: FormData, onUploadProgress: ((ProgressEvent: any) => void | undefined) ) => {
    
    cancleRequest =  axios.CancelToken.source(); 

    let response = await httpClient.put<HttpResponse<ILesson>>("instructor/course/lesson/video", formData, {headers:{ "Content-Type": "multipart/form-data"}, cancelToken: cancleRequest.token, onUploadProgress})

    return response.data;
}

export const submitCourseLessonArticle = async (lessonArticle: ILessonArticleDto) => {

    let response = await httpClient.put<HttpResponse<ILesson>>("/instructor/course/lesson/article", lessonArticle);

    return response.data.data;
}

export const submitCourseSettings = async (formData: FormData ) => {
    
    let response = await httpClient.put<HttpResponse<ICourse>>("instructor/course/setting", formData, {headers:{ "Content-Type": "multipart/form-data"} })

    return response.data;
}

export const fetchAssignedCourses = async () => {

    let response = await httpClient.get<HttpResponse<ICourseWithStudents[]>>("/instructor/courses/assign");

    return response.data.data;
}

export const fetchCoursesList = async () => {

    let response = await httpClient.get<HttpResponse<ICourse[]>>("/instructor/courses/list");

    return response.data.data;
}

export const getFullCourseData = async (courseId: string) => {

    let response = await httpClient.get<HttpResponse<ICourse>>(`/instructor/courses/${courseId}`);

    return response.data.data;
}

export const createQuizService = async (data: IQuizDto) => {

    let response = await httpClient.put<HttpResponse<IQuiz>>(`/instructor/course/lesson/quiz`, data);

    return response.data.data;
}

export const createQuizQuestionService = async (data: IQuizQuestionDto) => {

    let response = await httpClient.put<HttpResponse<IQuizQuestion>>(`/instructor/course/lesson/quiz/question`, data);

    return response.data.data
}

export const createQuizAnswerService = async (data: IQuizAnswerDto) => {

    let response = await httpClient.put<HttpResponse<IQuizAnswer>>(`/instructor/course/lesson/quiz/answer`, data);

    return response.data.data
}

export const correctAnswerService = async (data: ICorrectAnswerDto) => {

    let response = await httpClient.patch<HttpResponse<IQuizAnswer>>(`/instructor/course/lesson/quiz/correct-answer`, data);

    return response.data.data
}

export const quizPassMarkUpdateService = async (data: IQuizPassmarkDto) => {

    let response = await httpClient.patch<HttpResponse<IQuiz>>(`/instructor/course/lesson/quiz/passmark`, data);

    return response.data.data
}

export const courseLessonDeletionService = async (lessonId: string) => {

    let response = await httpClient.delete<HttpResponse<ILesson>>(`/instructor/course/lesson/${lessonId}`);

    return response.data;
}

export const updateCourseLesson = async (courseLesson: ILessonUpdateDto) => {
    let response = await httpClient.put<HttpResponse<ICourseTopic>>("/instructor/course/lesson", courseLesson);

    return response.data.data;
}

export const courseQuestionDeletionService = async (questionId: string) => {

    let response = await httpClient.delete<HttpResponse<IQuizQuestion>>(`/instructor/course/lesson/quiz/question/${questionId}`);
console.log(response, 'deleted question')
    return response.data;
}

export const updateQuestionLesson = async (courseLesson: IQuizQuestionDto) => {
    let response = await httpClient.put<HttpResponse<IQuizQuestion>>("/instructor/course/lesson/quiz/question", courseLesson);
    console.log(response, 'updated question')
    return response.data.data;
}
export const optionDeletionService = async (answerId: string) => {

    let response = await httpClient.delete<HttpResponse<ILesson>>(`/instructor/course/lesson/quiz/answer/${answerId}`);

    return response.data;
}

export const updateOptionLesson = async (courseLesson: IQuizAnswerDto) => {
    let response = await httpClient.put<HttpResponse<IQuizAnswer>>("/instructor/course/lesson/quiz/answer", courseLesson);

    return response.data.data;
}


export const submitCourseLessonNote = async (lessonNote: ILessonNoteDto) => {

    let response = await httpClient.put<HttpResponse<ILesson>>("/instructor/course/lesson/note", lessonNote);

    return response.data.data;
}

export const deleteCourseLessonNote = async (lessonId: string) => {

    let response = await httpClient.delete<HttpResponse<ILesson>>(`/instructor/course/lesson/${lessonId}/note`);

    return response.data.data;
}

export const submitCourseLessonSlide = async (formData: FormData, onUploadProgress: ((ProgressEvent: any) => void | undefined) ) => {
    
    cancleRequest =  axios.CancelToken.source(); 

    let response = await httpClient.put<HttpResponse<ILesson>>("instructor/course/lesson/slide", formData, {headers:{ "Content-Type": "multipart/form-data"}, cancelToken: cancleRequest.token, onUploadProgress})

    return response.data;
}

export const createExamService = async (data: IQuizDto) => {

    let response = await httpClient.put<HttpResponse<IQuiz>>(`/instructor/course/exam`, data);

    return response.data.data;
}

export const deleteExamService = async (quizId: string) => {

    let response = await httpClient.delete<HttpResponse<IQuiz>>(`/instructor/course/exam/${quizId}`);

    return response.data.data;
}