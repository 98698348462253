import { Button, FormLabel, Input, Text, Box, Link, InputGroup, Center, InputLeftElement, FormControl, FormErrorMessage, useToast} from '@chakra-ui/react';
import {MdLock} from 'react-icons/md';
import {Link as ReactLink, useHistory, useParams } from 'react-router-dom';
import routes from './routes';
import { useEffect } from 'react';
import { isAuthenticated, resetPassword } from '../services/auth.service';
import { useFormik } from 'formik';
import ForgotPasswordLayout from '../components/Common/ForgotPasswordLayout';


function ResetPassword(){

    const toast = useToast();

    const history = useHistory();

    const params = useParams<{resetId: string}>();

    useEffect(() => {
        if(isAuthenticated()){
            history.replace('/')
        }
    }, [history]);


     const formik = useFormik({
        initialValues: { password: '', confirmPassword: ''},
        validate: (values: any) => {
            const errors: any = {};
            if(!values.password){
                errors.password = 'Password is required';
            }
            if(!values.confirmPassword){
                errors.confirmPassword = 'Confirm Password is required';
            }else if( (values.password !== values.confirmPassword) ){
                errors.confirmPassword = 'Password do not match';
            }
            return errors;
        },
        onSubmit: async (values) => {

            try {
                 const response = await resetPassword({password: values.password, confirm_password: values.confirmPassword, code: params.resetId});
                
                toast({
                    title: "Reset Password",
                    description: response.message,
                    status: "success",
                    duration: 3000,
                    onCloseComplete: () => {
                        history.replace('/')
                    }
                })
            } catch (error: any) {
                toast({
                    title: "Reset Password",
                    description: error.data.message,
                    status: "error",
                })
            }
        }
    });

   return (
       <ForgotPasswordLayout>

           <Box>
                <Text mb={14} fontSize={18} fontWeight="semibold" color="brand.2">Create New Password</Text>

                <Text fontSize={12} color="brand.subtitle" mb={10}>Enter a new password that is different from your previously used password</Text>

                <form onSubmit={formik.handleSubmit}>
                    
                    
                    <FormControl isInvalid={(formik.errors.password && formik.touched.password) ? true : false} mb={8}>
                        <FormLabel color="brand.subtitle" fontSize={12} >ENTER NEW PASSWORD:</FormLabel>
                        <InputGroup>
                            <InputLeftElement 
                                color="brand.subtitle"
                                pointerEvents="none"
                                children={<MdLock color="brand.subtitle" />}
                            />
                            <Input type="password" placeholder="Enter your password" name="password" onChange={formik.handleChange} fontSize={13} />
                        </InputGroup>
                        <FormErrorMessage fontSize={12} >{formik.errors.password}</FormErrorMessage>
                    </FormControl>
                   
                    <FormControl isInvalid={(formik.errors.confirmPassword && formik.touched.confirmPassword) ? true : false} mb={14}>
                        <FormLabel color="brand.subtitle" fontSize={12} >CONFIRM NEW PASSWORD:</FormLabel>
                        <InputGroup>
                            <InputLeftElement 
                                color="brand.subtitle"
                                pointerEvents="none"
                                children={<MdLock color="brand.subtitle" />}
                            />
                            <Input type="password" placeholder="Enter your password" name="confirmPassword" onChange={formik.handleChange} fontSize={13} />
                        </InputGroup>
                        <FormErrorMessage fontSize={12} >{formik.errors.confirmPassword}</FormErrorMessage>
                    </FormControl>
                    
                    <Center>
                        <Button w="100%" type="submit" bg="brand.2" color="white" mb={6} fontSize={13} isLoading={formik.isSubmitting} >Create</Button>
                    </Center>

                    <Center>
                        <Text color="brand.bodyText" fontSize={12} alignSelf="center" >Have an account? <Link as={ReactLink} to={routes.Login} color="brand.2">Login</Link> </Text>
                    </Center>
                </form>
           </Box>

       </ForgotPasswordLayout>
    );
}

export default ResetPassword;