
const Input = {
    baseStyle: {
       borderRadius: "0px"
    },
    // Two sizes: sm and md
    sizes: {
        
    },
    // Two variants: outline and solid
    variants: {
      
    },
    // The default size and variant values
}

export default Input;