import { Box, Flex, Text, Image } from "@chakra-ui/react";
import offStar from '../../../img/fluent_star-off-20-regular.svg';


export const EmptyRating: React.FC<any> = () => {

    return (
        <Flex minHeight="inherit"  justifyContent="center">
        
            <Flex direction="column" alignItems="center" justifyContent="center" >

                <Box color="brand.subtitle" mb={3} width={10}>
                    <Image src={offStar} />
                </Box>

                <Text mb={4} fontSize={13} color="brand.subtitle"> No ratings on your course yet </Text>
                
            </Flex>
        </Flex>
    );
}