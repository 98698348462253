import {Flex, Image, Menu, MenuButton, MenuList, MenuItem, HStack, Text, Box, IconButton, Input, InputGroup, InputRightElement, Divider, Avatar} from '@chakra-ui/react';
import blucanaryWhite from '../../img/blucanary_white.png';
import { RiSearch2Line} from 'react-icons/ri';
import { BsFillAlarmFill} from 'react-icons/bs';
import {FaCaretDown, FaRegCalendarCheck} from 'react-icons/fa';
import {MdKeyboardArrowDown} from 'react-icons/md';
import { destroyUserSession } from '../../services/auth.service';
import { useHistory } from 'react-router';
import {StudentRoutes} from '../../pages/Student/routes';
import { useContext, useEffect } from 'react';
import { StoreContext } from '../../store/StoreContext';


const Header = () => {

    const history = useHistory();    
    
    const {globalState} = useContext(StoreContext);

    const handleLogout = () => {
        destroyUserSession();
        history.replace('/');
    }

    return(
        <Flex height="50px" bg="brand.2" direction="row" px={14} alignItems="center" justifyContent="space-between" >
            
            <Flex alignItems="center" >
                <Image mr={20} src={blucanaryWhite} width={120}   />
                <Box as="span" onClick={() => history.push(StudentRoutes.Course.Explore)}>
                    {/* <HStack cursor="pointer" color="white" fontSize={13} spacing={0.4} >
                        <Text whiteSpace="nowrap" > Explore </Text>
                        <Text pt={0.5}> <MdKeyboardArrowDown /> </Text>
                    </HStack>  */}
                </Box>
                
            </Flex>

            <Flex alignItems="center" height="inherit">

                {/* <InputGroup bg="#0E5B99" color="white" borderColor="#0E5B99">
                    <Input placeholder="Search" fontSize={12} size="sm" />

                    <InputRightElement h="1.9rem" pointerEvents="none" children={<RiSearch2Line size="0.9rem"  color="white" />} />
                </InputGroup> */}

                {/* <IconButton ml={10} _hover={{bg: "brand.200"}} variant="ghost" borderRadius={20} aria-label="Alarm" color="white" icon={<BsFillAlarmFill />}   /> */}

                {/* <IconButton _hover={{bg: "brand.200"}} variant="ghost" borderRadius={20} aria-label="Calendar" color="white" icon={<FaRegCalendarCheck />}   /> */}

                <Divider ml={3} orientation="vertical" borderWidth={1} color="white" />

                <Avatar ml={6} mr={3} size="sm" name={globalState.profile?.full_name} src={globalState.profile?.photo_url} />

                <Menu>
                    <MenuButton >
                        <HStack color="white" fontSize={13}>
                            <Text whiteSpace="nowrap" isTruncated > {globalState?.profile?.full_name}  </Text>
                            <FaCaretDown />
                        </HStack> 
                    </MenuButton>
                    <MenuList fontSize={13} borderRadius={0} color="brand.bodyText" >
                        <MenuItem onClick={() => history.push(StudentRoutes.Dashboard)} >Go to dashboard</MenuItem>
                        <MenuItem onClick={() => history.push(StudentRoutes.Course.Fetch)} >My Courses</MenuItem>
                        <MenuItem onClick={() => history.push(StudentRoutes.Course.Timeline)} >Course Timeline</MenuItem>
                        <MenuItem onClick={() => history.push(StudentRoutes.Profile)} >Edit Profile</MenuItem>
                        <MenuItem onClick={handleLogout} >Logout</MenuItem>         
                    </MenuList>
                </Menu>
            </Flex> 
            
        </Flex>
    )
}

export default Header;