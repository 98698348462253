import { Box, Flex, Text, Image } from "@chakra-ui/react";
import badge from '../../../img/badge.svg';

export const CompletedCourses: React.FC<any> = () => {

    return (
        <Flex minHeight="inherit"  justifyContent="center">
        
            <Flex direction="column" alignItems="center" justifyContent="center" >

                <Box color="brand.subtitle" mb={4}>
                    <Image height="2em" width="auto" src={badge} />
                </Box>

                <Text mb={8} fontSize={12} color="brand.subtitle"> Completed course will be displayed here </Text>

            </Flex>
        </Flex>
    );
}