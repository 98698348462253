import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Routes from "./pages/routes";

import { StudentRoutes } from "./pages/Student/routes";

import { InstructorRoutes } from "./pages/Instructor/routes";

import { TrainingMgrRoutes } from "./pages/TrainingManager/routes";

// Pages
import Login from "./pages/Login";

import SignUp from "./pages/Signup";
import { AuthGuard } from "./guards/AuthGuard";
import { InstructorGuard } from "./guards/InstructorGuard";
import { StudentGuard } from "./guards/StudentGuard";
import { StoreProvider } from "./store/StoreContext";
import { TrainingMgrGuard } from "./guards/TrainingMgrGuard";
import { HrAdminRoutes } from "./pages/HrAdmin/routes";
import { ManagementRoutes } from "./pages/Management/routes";
import { HrAdminGuard } from "./guards/HrAdminGuard";
import { ManagementGuard } from "./guards/ManagementGuard";
import { Error404 } from "./components/Common/Error404";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";

export const App = () => {
  return (
    <StoreProvider>
      <Router>
        <Switch>
          <Route exact path={Routes.ForgotPassword}>
            <ForgotPassword />
          </Route>

          <Route exact path={Routes.ResetPassword}>
            <ResetPassword />
          </Route>

          <Route exact path={Routes.Signup}>
            <SignUp />
          </Route>

          <Route exact path={Routes.Login}>
            <Login />
          </Route>

          <AuthGuard path={InstructorRoutes.Main} component={InstructorGuard} />

          <AuthGuard path={StudentRoutes.Dashboard} component={StudentGuard} />

          <AuthGuard
            path={TrainingMgrRoutes.Main}
            component={TrainingMgrGuard}
          />

          <AuthGuard path={HrAdminRoutes.Main} component={HrAdminGuard} />

          <AuthGuard path={ManagementRoutes.Main} component={ManagementGuard} />

          <Route path="*">
            <Error404 />
          </Route>
        </Switch>
      </Router>
    </StoreProvider>
  );
};
