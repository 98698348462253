import React from 'react'
import {
  Box,
  Button,
  Container,
  Flex,
  HStack,
  Image,
  Input,
  InputGroup,
  Tag,
  Text,
  InputRightElement,
  Link,
  IconButton,
  Portal,
  useDisclosure,
  Avatar,
  VStack,
  Badge,
  toast,
  useToast,
  Icon,
} from '@chakra-ui/react'
import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react'
import { ChangeEvent, useEffect, useState } from 'react'
import { ScreenLoader } from '../../../components/Common/ScreenLoader'
import { EmptyUsers } from '../../../components/Hr/EmptyState/Users'
import { HrAdminRoutes } from '../routes'
import {
    createBatches,
  fetchUsers,
  updateStatus,
} from '../../../services/hr/users-http.service'
import { fetchByRole } from '../../../services/hr/users-http.service'
import { useHistory } from 'react-router-dom'
import { RiSearch2Line } from 'react-icons/ri'
import { BiUserPlus } from 'react-icons/bi'
import { EmptyCourseSearch } from '../../../components/Student/EmptyState/EmptyCourseSearch'
import { IoMdCreate } from 'react-icons/io'
import { FaCheck, FaCheckCircle, FaTimesCircle } from 'react-icons/fa'
import { EditUserProfileModal } from '../../../components/Hr/EditUserProfileModal'
import { Ipage, IUser } from '../../../models/auth.model'
import { appConstant } from '../../../utils/app.util'
import {
  Pagination,
  PaginationContainer,
  PaginationNext,
  PaginationPage,
  PaginationPageGroup,
  PaginationPrevious,
  usePagination,
} from '@ajna/pagination'
import { IAnyUserList } from '../../../models/user.model'
import { useLocation } from 'react-router-dom'
import { Checkbox, CheckboxGroup } from '@chakra-ui/react'


export default function BatchUpload() {
  type BatchUploadModel = {
    email: string
    fullName: string
    password: string
    phone: string
    role: number
    role_name: string
    unit: string
  }

  type StateData = {
    data: BatchUploadModel[]
  }

  const toast = useToast()
  const location = useLocation<StateData>()
  const data = location.state.data
  console.log(data)
  const [checkedItems, setCheckedItems] = useState<boolean[]>([])
  const allChecked = checkedItems.every(Boolean)
  const isIndeterminate = checkedItems.some(Boolean) && !allChecked
  

  //createBatches();

  let {replace, push} = useHistory();
  // const [isLoading, setIsLoading] = useState(true);
  // const [users, setUsers] = useState<IAnyUserList[]>([]);
  // const [filteredUsers, setFilteredUsers] = useState<IAnyUserList[]>([]);
  // const [selectedUser, setSelectedUser] = useState<IUser>();
  // const [page, setPage] = useState<Ipage>({page_total: 0, total: 0});
  // const [isSearchEmpty, setIsSearchEmpty] = useState(false);
  // const [search, setSearch] = useState("");
  // const [query, setQuery] = useState("");
  // const { isOpen, onOpen, onClose } = useDisclosure();
  // const toast = useToast();
  // const [reload, setStatus] = useState(false);


 
  const checkAllItems = (e: any) => {
    let checkedArray = checkedItems.map(() => e.target.checked)
    setCheckedItems(checkedArray)
  }

  function updateCheckItems(checked: boolean, index: number): void {
    let checkedArray = [...checkedItems]
    console.log(checked)
    checkedArray[index] = checked;
    console.log(checkedArray)
    setCheckedItems(checkedArray);
  }


  useEffect(() => {
    let checkedArray = data.map(() => false)
    setCheckedItems(checkedArray)
    console.log(checkedItems)
  }, [data])

  function submitBatches(){
      createBatches(data)
      .then((response) => {
        if(response === 200) {
            toast({
                title: 'Batch Upload',
                description: 'Batch user created sucessfully',
                status: 'success',
              })
               push(HrAdminRoutes.Users.Manage)
            }
        }) 
        .catch((error) =>
          toast({
            title: 'Batch Upload',
            description:
              'Upload fail, please try again :' + JSON.stringify(error),
            status: 'error',
          }))
  }





  return (
    <Container
      boxShadow="base"
      maxW="container.xl"
      bg="white"
      pt={3}
      pb={10}
      px={8}
      minHeight="70vh"
    >
      <Box pl={6}>
        <Text  color="brand.3" fontSize={22} fontWeight="semibold" mb={50} mt={20}>
          Upload users in batches
        </Text>

        <Flex>
          <Icon 
          as = {FaCheckCircle}
          color = '#19CB98'
          mr={5}
          mt = {1}
          />
          
          
          <Text  color="brand.2" >
          {data.length} users uploaded successfully
          </Text>
        </Flex>  
        

        <Table variant="simple" mt={5}>
          <Thead fontWeight="normal">
            <Tr color="brand.subtitle">
            <Checkbox mt={4}
                      isChecked={allChecked}
                      isIndeterminate={isIndeterminate}
                      onChange={(e) => checkAllItems(e)}
                    ></Checkbox>
              <Th textTransform="none" textAlign={'center'}>
                Names
              </Th>
              <Th textTransform="none">Email</Th>
              <Th textTransform="none">Password</Th>
              <Th textTransform="none">Unit</Th>
              <Th textTransform="none">Roles</Th>
            </Tr>
          </Thead>

          <Tbody>
              {data.map((each, index) => (
                <Tr key={index} fontSize={13} color="brand.subtitle">
                <Checkbox mt={4}
                          isChecked={checkedItems[index]}
                          onChange={(e) =>
                            updateCheckItems(
                              e.target.checked, index
                            )
                          }
                        ></Checkbox>
                  <Td  
                   color="brand.3"
                   whiteSpace="nowrap"
                   isTruncated
                   title={each?.fullName}
                   pl = {140}
                   > 
                        
                    {each?.fullName}
                       
                      
                    
                  </Td>

                  <Td title={each?.email}>{each?.email} </Td>

                    <Td title={each?.password}>{each?.password} </Td>

                    <Td title={each?.unit}>{each?.unit} </Td>

                    <Td>{each?.role_name} </Td>
                </Tr>
              ))}
            
          </Tbody>
        </Table>


        <Flex alignContent={"flex-end"} justifyContent={'flex-end'} mt={20}>
            <Button  colorScheme="brand" leftIcon={<BiUserPlus />} onClick={() => submitBatches()} size="sm" fontSize={12}>Create New User</Button>
        </Flex>
        


      </Box>
    </Container>
  )
}


 


