import { IUserLevel, IUserRank } from "../models/auth.model";
import { IAccessibility, ICategory, ILearningRules, IUserRoles } from "../models/course.model";
import { HttpResponse } from "../models/httpResponse.model";
import {httpClient} from "./httpClient";

export const fetchCategories = async () => {

    let response = await httpClient.get<HttpResponse<ICategory[]>>("/shared/category");

    return response.data.data;
}

export const fetchUserRoles = async () => {

    let response = await httpClient.get<HttpResponse<IUserRoles[]>>("/auth/role");

    return response.data.data;
}

export const fetchLearningRules = async () => {

    let response = await httpClient.get<HttpResponse<ILearningRules>>(`/shared/learning-rules`);

    return response.data.data;
}

export const fetchAccessibility = async () => {

    let response = await httpClient.get<HttpResponse<IAccessibility[]>>("/shared/accessibility");

    return response.data.data;
}

export const fetchUserLevel = async () => {

    let response = await httpClient.get<HttpResponse<IUserLevel[]>>("/shared/levels");

    return response.data.data;
}

export const fetchUserRank = async (levelId: number) => {

    let response = await httpClient.get<HttpResponse<IUserRank[]>>(`/shared/levels/${levelId}/ranks`);

    return response.data.data;
}

export const fetchUserRanks = async () => {

    let response = await httpClient.get<HttpResponse<IUserRank[]>>(`/shared/ranks`);

    return response.data.data;
}