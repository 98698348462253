import React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { TrainingMgrMain } from "../components/TrainingManager/Main";
import { Roles } from "../models/roles.model";
import { getRole } from "../services/auth.service";
import { allowRoleAccess } from "./RoleGuard";

export const TrainingMgrGuard: React.FC<any> = ({ path, component: Component, ...rest }: {component: any, path: string}) => {
  
  const location = useLocation();

  return (
    <Route {...rest}>
      {allowRoleAccess(getRole() as Roles, Roles.TRAINING_MANAGER) ?
        <TrainingMgrMain {...rest} />
      :
        <Redirect to={{ pathname: "/", state: { from: location } }} />
      }
    </Route>
  );
};