import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton, Text, Box, useToast, FormControl, FormLabel, InputGroup, InputLeftElement, Input, FormErrorMessage, Spacer, Flex, Button, Checkbox, Center, Select, HStack, Portal, useDisclosure
} from "@chakra-ui/react"
import { Ipage, IUser, IUserLevel, IUserRank } from "../../models/auth.model";
import {
    Pagination,
    PaginationContainer,
    PaginationNext,
    PaginationPage,
    PaginationPageGroup,
    PaginationPrevious,
    usePagination,
  } from "@ajna/pagination";

import { useFormik } from "formik";
import { editUserProfile, getUploadableUnit } from "../../services/hr/users-http.service";
import { IoMdCall, IoMdContact, IoMdMail } from "react-icons/io";
import { MultiSelect } from "../Common/MultiSelect";
import { IMultiSelect } from "../../models/course.model";
import { useEffect, useRef, useState } from "react";
import { fetchCategories, fetchUserLevel, fetchUserRank } from "../../services/shared.service";
import { HiUserAdd } from "react-icons/hi";
import { FaTimesCircle } from "react-icons/fa";
import { ICreateGroup, IUserGroupSelect } from "../../models/group.model";
import { createGroupTmService } from "../../services/group.service";
import { useHistory } from "react-router-dom";
import { UsersListModal } from "../../components/Common/UsersListModal";
import { AllCourseSelectBox } from "../../components/Common/AllCoursesSelectionBox";
import { addMembersToDepartment, createNewDepartment, fetchAllUsers } from "../../services/hr/department/department.service";


export const CreateDepartmentModal: React.FC<any> = ({ isOpenn, onClosed, isDepartment = false, reloadData }: { isDepartment: boolean, isOpenn: any, onClosed: any, reloadData: any }) => {
    const toast = useToast();
    const cancelRef = useRef().current;
    const [members, setMembers] = useState<IUser[]>([]);
    const [users, setUsers] = useState<IUser[]>([]);
    const { onOpen, isOpen, onClose } = useDisclosure();
    const history = useHistory();
    const [search, setSearch] = useState("");
    const [query, setQuery] = useState("");
    const [courseId, setCourseId] = useState<string>();
    const [uploadableUnit, setUploadableUnit] = useState<IMultiSelect[]>([]);
    const [levels, setLevels] = useState<IUserLevel[]>([]);
    const [level, setLevel] = useState<any>("");
    const [page, setPage] = useState<Ipage>({ page_total: 0, total: 0 });
    const [selectedUnit, setSelectedUnit] = useState<IMultiSelect[]>([]);
    const [ranks, setRanks] = useState<IUserRank[]>([]);

    const {
        currentPage,
        setCurrentPage,
        pagesCount,
        pages
      } = usePagination({
        //pagesCount: isNaN(Math.ceil(page.total / page.page_total)) ? 1 : Math.ceil(page.total / page.page_total),
        initialState: { currentPage: 1, pageSize: 10 },
        total: page.total
      });

    useEffect(() => {
        let url = `?page=${currentPage}&limit=${100}&search=${query}`
        fetchAllUsers(url)
            .then((data) => {
                setPage({ page_total: data.data.page_total, total: data.data.total })
                try {
                    setUsers(data.data.results);
                    setMembers([])
                    
                } catch (error) {
                    toast({
                        title: "Department Setup",
                        description: "Something went wrong, Please try again Later.",
                        status: "error",
                        duration: 3000
                    })
                    return error
                }

            })
            .catch((err) => err)

    }, [currentPage, query]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
          if (search.length > 0) {
            setQuery(search);
          } else {
            setQuery("");
          }
        }, 700);
    
        return () => clearTimeout(delayDebounceFn);
      }, [search]);
    
    const removeMember = (index: number) => {
        let user = members.at(index);
        setUsers([user as IUser, ...users]);
        members?.splice(index, 1)
        setMembers([...members])
    }



    const formik = useFormik({
        validate: (values: any) => {
            const errors: any = {};
            if (!values.departmentName) {
                errors.departmentName = 'Department Name is required'
            }
            return errors;
        },
        initialValues: {
            departmentName: '',
        },
        onSubmit: async (values) => {

            try {



                if(members.length === 0){
                    toast({
                        title: "Group Setup",
                        description: "Add at least one Member",
                        status: "warning",
                        duration: 3000
                    })
                    return;
                }


                let memberList: any[] = members.map(each => ({ userId: each.id as number, isAdmin: false }));

                let users = [...memberList];
             

                let departmentData: any = {
                    title: values.departmentName,
                    description: 'creating a new department'
                }
                

                const department = await createNewDepartment(departmentData);
                //trying something
                
                if (department) {

                    const addMember = await addMembersToDepartment(department.data.category_id,users)
                   if(addMember){
                    toast({
                        title: "Department Setup",
                        description: department.message,
                        status: "success",
                        duration: 2000,
                        onCloseComplete: () => history.goBack()
                    })

                   }
                }

            } catch (error) {
                toast({
                    title: "Department Setup",
                    description: "Something went Wrong. Try again later",
                    status: "error",
                })
            }
        }
    })

    const viewMemberModal = () => {

        onOpen();
       
    
    }






    return (
        <>

            <Modal isOpen={isOpenn && isDepartment} onClose={onClosed} scrollBehavior="outside" closeOnOverlayClick={true} size="lg">
                <ModalOverlay />
                <ModalContent borderRadius={5} px={6} >
                    <ModalHeader >
                        <Text fontSize={13} pb={3} pl={3} color="brand.2" >New Department</Text>
                    </ModalHeader>
                    <ModalCloseButton size="sm" />
                    <ModalBody pb={6}>

                        <Box mt={4}>

                            <form onSubmit={formik.handleSubmit} >

                                <FormControl>
                                    <FormLabel color="brand.subtitle" fontSize={13}>Department Name</FormLabel>
                                    <InputGroup>
                                        <InputLeftElement
                                            color="brand.subtitle"
                                            pointerEvents="none"
                                            children={<IoMdContact color="brand.subtitle" />}
                                        />
                                        <Input name="departmentName" id="departmentName" placeholder="Department Name" type="text" fontSize={12} onChange={formik.handleChange} />
                                    </InputGroup>
                                    <FormErrorMessage fontSize={12} >{formik.errors.departmentName}</FormErrorMessage>
                                </FormControl>


                                <FormControl id="addMembers" mt={6} >
                                    <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>Add Members</FormLabel>
                                    <Box border="1px solid" borderRadius="0.125rem" borderColor="inherit" minHeight={10} display="flex" >
                                        <HStack>
                                            {
                                                members.slice(0,2).map((each, index) =>
                                                    <Button ml={2} key={index} color="brand.subtitle" size="sm" fontWeight="semibold" px={4} variant="solid" onClick={() => removeMember(index)} fontSize={12} rightIcon={<FaTimesCircle />} > {each.full_name} </Button>
                                                )
                                            }
                                            <Button ml={2} color="brand.subtitle" size="sm" fontWeight="semibold" px={4} variant="solid" fontSize={12} onClick={viewMemberModal} leftIcon={<HiUserAdd fontSize={16} />} > Add Members </Button>
                                        </HStack>
                                    </Box>
                                </FormControl>

                                <Flex mt={12} justifyContent="end">
                                    <HStack>
                                        <Button size="sm" px={6} onClick={() =>{onClosed()}} fontSize={12} ref={cancelRef}>
                                            Cancel
                                        </Button>
                                        <Button colorScheme="brand" type="submit"

                                            size="sm" px={6} fontSize={12} > CREATE</Button>
                                    </HStack>
                                </Flex>
                            </form>

                        </Box>



                    </ModalBody>

                    <ModalFooter>
                    </ModalFooter>
                </ModalContent>
                <Portal> <UsersListModal  isOpen={isOpen} onClose={onClose} users={users} setUsers={setUsers} setMembers={setMembers} members={members} title="Add Member" /> </Portal>
            </Modal>


        </>
    )




}
