import {Flex, Box, Container} from '@chakra-ui/react';
import {RiDashboardFill, RiUserSettingsLine} from 'react-icons/ri';
import {SiMoleculer} from 'react-icons/si';
import { BsBook } from 'react-icons/bs';
import {MdSecurity} from 'react-icons/md';
import {StudentRoutes} from '../../pages/Student/routes';
import {Route, Switch, useRouteMatch} from 'react-router-dom';
import Dashboard from '../../pages/Student/Dashboard';
import Explore from '../../pages/Student/Explore';
import Courses from '../../pages/Student/Courses';
import Invoice from '../../pages/Student/Invoice';
import Billing from '../../pages/Student/Billing';
import Profile from '../../pages/Student/Profile';
import Security from '../../pages/Student/Security';
import EnrollCourse from '../../pages/Student/EnrollCourse';
import Header from '../Common/Header';
import React, { useContext, useEffect } from 'react';
import { StoreContext } from '../../store/StoreContext';
import { getLocalUserProfile } from '../../services/auth.service';
import CoursePage from './CoursePage';
import { Error404 } from '../Common/Error404';
import LessonsPage from './LessonsPage';
import { NavLink } from '../Common/NavLink';
import CourseTimeline from '../../pages/Student/CourseTimeline';
// import CourseLesson from '../../pages/Student/CourseLesson';
// import Assessment from '../../pages/Student/Assessement';
// import Discussion from '../../pages/Student/Discussion';
// import Progress from '../../pages/Student/Progress';
// import WatchLesson from '../../pages/Student/WatchLesson';


export const StudentMain: React.FC<any> = (props) => {

    let { path } = useRouteMatch();

    const {actions} = useContext(StoreContext);

    useEffect(() => {
        let user = getLocalUserProfile();

        if(user != null){
            actions?.updateProfile(user);
        }
    }, []);
   

    return (
        <Box minHeight="100vh" >

            <Header {...props} />

            <Flex  >

                <Container  maxWidth="18%" >
                </Container>

                <Switch>
                   
                   <Route exact path={[StudentRoutes.Course.LessonPath, StudentRoutes.Course.SpecificLessonPath]}>
                        <LessonsPage />
                    </Route>
                   
                   <Route exact path={StudentRoutes.Course.InfoPath}>
                        <CoursePage />
                    </Route>
                    
                    <Route path={path}>
                        
                        <Container maxWidth="18%" pl={0} minHeight="100%" boxShadow="base" lineHeight={"100%"} bg="white" position="fixed" px = {0}>
                            <Flex direction="column" fontSize={13} color="brand.bodyText"  pt={12} >
                                <NavLink label="Dashboard" to={StudentRoutes.Dashboard} activeOnlyWhenExact={true} icon={<RiDashboardFill size={18} />} />
                                <NavLink label="Explore" to={StudentRoutes.Course.Explore} activeOnlyWhenExact={true} icon={<SiMoleculer size={18} />} />
                                <NavLink label="Courses" to={StudentRoutes.Course.Fetch} activeOnlyWhenExact={true} icon={<BsBook size={18} />} />
                                <NavLink label="Edit Profile" to={StudentRoutes.Profile} icon={<RiUserSettingsLine size={18} />} />
                                <NavLink label="Security" to={StudentRoutes.Security} icon={<MdSecurity size={18} />} />
                            </Flex>
                        </Container>

                        <Container maxW="container.xl" ml={16} mr={16} pt={12}>
                            <Switch>
                                <Route exact path={path}>
                                    <Dashboard/>
                                </Route>
                                <Route exact path={StudentRoutes.Course.Explore}>
                                    <Explore/>
                                </Route>
                                <Route exact path={StudentRoutes.Course.Fetch}>
                                    <Courses/>
                                </Route>
                                <Route exact path={StudentRoutes.Course.Timeline}>
                                    <CourseTimeline/>
                                </Route>
                                <Route path={StudentRoutes.Invoice}>
                                    <Invoice/>
                                </Route>
                                <Route path={StudentRoutes.Billing}>
                                    <Billing/>
                                </Route>
                                <Route path={StudentRoutes.Profile}>
                                    <Profile/>
                                </Route>
                                <Route path={StudentRoutes.Security}>
                                    <Security/>
                                </Route>
                                <Route path={StudentRoutes.Course.EnrollPath}>
                                    <EnrollCourse/>
                                </Route>
                                <Route  path="*" >
                                    <Error404 />
                                </Route>
                            </Switch>                     

                        </Container>
                    </Route>

                    <Route  path="*" >
                        <Error404 />
                    </Route>
                    
                </Switch>             
            </Flex>

        </Box>
    );
}