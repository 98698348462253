import { Avatar, Box, Button, Container, Flex, HStack, IconButton, Input, InputGroup, InputRightElement, Text, VStack, Wrap, Portal, useDisclosure } from "@chakra-ui/react";
import { ChangeEvent, useEffect, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { RiSearch2Line } from "react-icons/ri";
import { EmptySearchResult } from "../../components/Common/EmptySearchResult";
import { ScreenLoader } from "../../components/Common/ScreenLoader";
import { EmptyStudents } from "../../components/Instructor/EmptyState/Students";
import { StudentProfileModal } from "../../components/Instructor/StudentProfileModal";
import { IStudent, IStudentList } from "../../models/course.model";
import { fetchStudents } from "../../services/instructor/courses-http.service";
import { studentListUtil } from "../../utils/course.util";
// import mystudents from '../../data/students.json';

const Student = () => {

    const [students, setstudents] = useState<IStudentList[]>([]);
    
    const [studentsData, setStudentsData] = useState<IStudent[]>([]);
    
    const [selectedStudent, setSelectedStudent] = useState<IStudentList>({courses:0, img_url: '', name: '', progress: 0});
    
    const [selectedStudentData, setSelectedStudentData] = useState<IStudent>();
    
    const [filteredStudents, setfilteredStudents] = useState<IStudentList[]>([]);

    const [isLoading, setIsLoading] = useState(true);

    const [isSearchEmpty, setIsSearchEmpty] = useState(false);

    const { isOpen, onOpen, onClose } = useDisclosure()


    useEffect(() => {
        fetchStudents()
        .then((data) => {
            setfilteredStudents(studentListUtil(data));
            setstudents(studentListUtil(data));
            setStudentsData(data);
            setIsLoading(false)
        })
        .catch((err) => err)
            
    }, []);

    const handleSearchChange  = (event: ChangeEvent<HTMLInputElement>) => {
        
        let searchTerm = event.target.value.toLowerCase();
        
        let filtered = students.filter(each => each.name.toLowerCase().includes(searchTerm) );
        
        setfilteredStudents(filtered);
        
        if(filtered.length > 0)
            setIsSearchEmpty(false);
        else
            setIsSearchEmpty(true)
    }

    const viewProfile = (userId: string) => {

        let student = students.filter(each => each.userId === userId).at(0);

        let studentData = studentsData.filter(each => each.user_id === userId).at(0);

        setSelectedStudent(student as IStudentList);

        setSelectedStudentData(studentData);

        onOpen();
    }

    return (

        <Container boxShadow="base" maxW="container.xl" bg="white" py={3} px={10} mb={6} minHeight="70vh">
            
            {
                isLoading?
                <ScreenLoader />
                :
                students.length === 0 ? 
                
                <EmptyStudents />
                
                :

                <Box>
                    <Text color="brand.3" fontSize={18} fontWeight="semibold">
                       {students.length > 1 ? 'Students' : 'Student'} ({students.length})
                    </Text>

                    <InputGroup color="brand.subtitle" bg="white" mt={5} width="40%">
                        <Input placeholder="Search students taking your course" onChange={ e => handleSearchChange(e)} fontSize={12} size="sm" />

                        <InputRightElement h="1.9rem" pointerEvents="none" children={<RiSearch2Line size="0.9rem"  />} />
                    </InputGroup>
                    
                    <Box my={6}>

                        {
                            isSearchEmpty ? 

                            <EmptySearchResult />

                            :

                            <Wrap spacing={10}>

                                {
                                    filteredStudents.map((each, index) => 

                                    <Box key={index} boxShadow="base" boxSizing="border-box" width="11em" height="14em" mr={10}>

                                        <VStack height="inherit" spacing={2}>

                                            <Flex width="100%" justifyContent="center" >

                                                <Avatar mt={5} size="lg" name={each.name} src={each.img_url} ml={16} mr={6} />

                                                <IconButton
                                                    variant="ghost"
                                                    aria-label="dot"
                                                    color="brand.subtitle"
                                                    borderRadius={20}
                                                    icon={<BsThreeDotsVertical />}
                                                />

                                            </Flex>

                                            <Text  pb={2} pt={1} color="brand.3" fontSize={13} fontWeight="semibold">{each.name}</Text>

                                            <Button  variant="outline" onClick={() => viewProfile(each?.userId as string)} colorScheme="brand" size="xs" fontSize={11} fontWeight="normal" px={8} >View Profile</Button>

                                            <HStack fontSize={14} color="brand.subtitle" pt={2} spacing={10}>
                                                <VStack spacing={0}>
                                                    <Text fontSize={13} fontWeight="semibold">{each.courses} </Text>
                                                    <Text fontSize={11}> {each.courses > 1 ? 'Courses' : 'Course'} </Text>
                                                </VStack>
                                                
                                                <VStack spacing={0}>
                                                    <Text fontWeight="semibold" fontSize={13}>{each.progress}%</Text>
                                                    <Text fontSize={11}>Progress</Text>
                                                </VStack>

                                            </HStack>
                                        </VStack>                         
                                    </Box>
                                    )
                                }

                            </Wrap>
                        }                        

                    </Box>
                </Box>
            }
            
            <Portal> <StudentProfileModal isOpen={isOpen} onClose={onClose} student={selectedStudent} studentData={selectedStudentData} /> </Portal>

        </Container>            
    );
}

export default Student;