import { FormControl, FormErrorMessage } from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { Flex, Text, HStack, Table, Thead, Tr, Td, Tbody, Progress, useToast, IconButton, Textarea, Image,  } from "@chakra-ui/react";
import { Box } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { FaTimes } from "react-icons/fa";
import { partial } from "filesize";
import { deleteLessonNoteToStateService, getLessonFromStateService, removeLessonAssetFromStateService, updateLessonAssetToStateService, updateLessonNoteToStateService } from "../../../../services/instructor/course.service";
import { cancleRequest, deleteCourseLessonNote, submitCourseLessonNote, submitCourseLessonSlide } from "../../../../services/instructor/courses-http.service";
import { AssetType, ILessonState } from "../../../../models/create_course.model";
import { ILessonNoteDto } from "../../../../models/course.model";
import { BsPencilSquare } from "react-icons/bs";
import { VscTrash } from "react-icons/vsc";
import pdfImg from '../../../../img/pdf.svg';


export const SlideUpload: React.FC<any> = ({goBack, lessonId, lessonIndex, lectureIndex}) => {

    const [isUploading, setIsUploading] = useState<boolean>(false);

    const [currentFile, setCurrentFile] = useState<File>();
    
    const [fileName, setFileName] = useState<string>('');
    
    const [uploadFileSize, setUploadFileSize] = useState('');

    const inputRef = useRef<HTMLInputElement | null>(null)

    const toast = useToast();

    const [progress, setProgress] = useState(0);

    const [selectedLesson, setSelectedLesson] = useState<ILessonState | null>();

    const [isUploaded, setIsUploaded] = useState(false);

    const [uploadLink, setUploadLink] = useState<string>();

    const [addNote, setAddNote] = useState(false);

    const [note, setNote] = useState<string>();


    useEffect(() => {
      
        let selectedLesson = getLessonFromStateService(lectureIndex, lessonIndex);
        setSelectedLesson(selectedLesson)
        console.log(selectedLesson)

            let lessonUploaded = selectedLesson?.isUploaded;

            if(lessonUploaded){
                
                setIsUploaded(lessonUploaded);

                setUploadLink(selectedLesson?.asset_link)

                setFileName(selectedLesson?.asset_file_name || '')

                if(selectedLesson?.description)
                    setNote(selectedLesson.description)
            }
    }, []);
    
    const formikSlide = useFormik({
        initialValues: {
            slide_lesson: undefined,
        },
        validate: (values: any) => {
           const errors: any = {};
            if(!values.slide_lesson){
                errors.slide_lesson = 'A PDF file is required';
            }
            return errors;
        },
        onSubmit: async (values) => {
            try {

                if(currentFile !== undefined){

                    setIsUploading(true);

                    let formData = new FormData();

                    formData.append('lessonId', lessonId);
                    formData.append('slide', currentFile, fileName);

                    const lessonSlide = await submitCourseLessonSlide(formData, (event) => {
                        setProgress(Math.round((100 * event.loaded) / event.total));
                    })

                    if(lessonSlide){
                        setIsUploading(false);

                        updateLessonAssetToState(true, AssetType.SLIDE, lessonSlide.data.doc_url || '');
                        toast({
                            title: "Slide Upload",
                            description: "Slide uploaded successfully",
                            status: "success",
                        }) 
                    }                    
                }
                
            } catch (error) {
                setIsUploading(false);
                if(error)
                    toast({
                        title: "Course Lesson",
                        description: "Something went Wrong. Try again later",
                        status: "error",
                    }) 
            }
        }
    })

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        
        let value = e.target.value;

        if(value){
            
            let fileName = e.target.files?.item(0)?.name as string;

            let fileType = e.target.files?.item(0)?.type;

            let file = e.target.files?.item(0) as File;
            
            if(fileType?.split('/').at(1) !== 'pdf'){
                formikSlide.setErrors({slide_lesson: 'Only PDF file format is supported'});
                return;
            }
            
            setFileName(fileName);
            
            setCurrentFile(file);

            const fileSize = partial({base: 2, standard: "jedec"});

            setUploadFileSize(fileSize(file.size));
        }
        else{
            setFileName('');
            setCurrentFile(undefined);
        }
        
        formikSlide.handleChange(e);
    }

    const updateLessonAssetToState = (isUploaded: boolean, assetType: AssetType, assetLink: string) => {
        setIsUploaded(true);
        setUploadLink(assetLink);
        updateLessonAssetToStateService(lectureIndex, lessonIndex, isUploaded, assetType, assetLink, fileName)   
    }

    const removeLessonAssetFromState = () => {
        if(isUploading)
            cancleRequest.cancel("Upload Cancled");            
        removeLessonAssetFromStateService(lectureIndex, lessonIndex);
        goBack()
    }

    const formikNote = useFormik({
        initialValues: {
            note: '',
        },
        validate: (values: any) => {
           const errors: any = {};
            // if(!values.note){
            //     errors.note = 'A Note is required';
            // }
            // return errors;
        },
        onSubmit: async (values) => {
            try {

                let lessonData: ILessonNoteDto = {lessonId, note: values.note}
                
                const lessonNote = await submitCourseLessonNote(lessonData);

                if(lessonNote){
                    updateLessonNoteToStateService(lectureIndex, lessonIndex, values.note);
                    toast({
                        title: "Course Lesson",
                        description: "Note updated successfully",
                        status: "success",
                    }) 
                }
                setNote(values.note)
                
            } catch (error) {
                toast({
                    title: "Course Lesson",
                    description: "Something went Wrong. Try again later",
                    status: "error",
                }) 
            }
        }
    })

    const editNote = (note: string) => {

        formikNote.setValues({note: note})

        setNote('');

        setAddNote(true);
    }

    const deleteNote = async () => {

         try {

            const lessonNote = await deleteCourseLessonNote(lessonId);
            console.log(lessonNote)

            if(lessonNote){
             
                deleteLessonNoteToStateService(lectureIndex, lessonIndex, '');
                toast({
                    title: "Course Lesson",
                    description: "Note deleted succesfully",
                    status: "success",
                })
                setNote('');
                formikNote.setValues({note: ''})
            }
                        
             
            setAddNote(false)
            
        } catch (error) {
           
        }
    }



    return (
        <>
            <Box mt={2}>

                <Flex justifyContent="space-between" mb={4}>
                    <Text fontSize={13} color="brand.2" borderBottom="1px" borderBottomWidth={2.5} >Upload Slide</Text>
                    <Button color="danger.100" onClick={() => removeLessonAssetFromState() } borderColor="danger.100" variant="outline" type="submit" size="sm" fontSize={11} rightIcon={<FaTimes />} >Remove Slide</Button>
                </Flex>

                {
                    isUploaded ? 

                    <Box>
                       <HStack alignItems="start" spacing={4} mb={4}>
                            <Image src={pdfImg} />

                           <Text pt={4} fontSize={12} title={fileName} color={"brand.subtitle"} fontWeight="semibold" isTruncated maxWidth="30em">{fileName}</Text>

                       </HStack>

                       {
                            note ?

                            <Box fontSize={13} px={6} border={"1px"} borderRadius={3} py={4} borderColor={"brand.borderBox"} >
                                
                                <HStack spacing={2} justifyContent={"end"} mb={2} >
                                    <IconButton
                                        variant="ghost"
                                        aria-label="pencil"
                                        color="brand.subtitle"
                                        borderRadius={20}
                                        size="sm"
                                        icon={<BsPencilSquare />}
                                        onClick={() => editNote(note) }
                                    />
                                    <IconButton
                                        variant="ghost"
                                        aria-label="trash"
                                        color="brand.subtitle"
                                        borderRadius={20}
                                        size="sm"
                                        icon={<VscTrash />}
                                        onClick={() => deleteNote() }
                                    />
                                </HStack>
                                
                                {note}

                            </Box>

                            :

                            addNote ?

                            <form onSubmit={formikNote.handleSubmit}>
                                <FormControl id="note"  isInvalid={(formikNote.errors.note && formikNote.touched.note) ? true : false}>
                                    <Textarea
                                        p = {4}
                                        fontSize={12}
                                        size="sm"
                                        minH="10rem"
                                        name="note"
                                        onChange={formikNote.handleChange}
                                        placeholder="Notes..."
                                        value={formikNote.values.note}
                                    />
                                    <FormErrorMessage fontSize={12}> {formikNote.errors.note} </FormErrorMessage>
                                </FormControl>

                                <Flex justifyContent="flex-end">
                                    <Button mt={4} colorScheme="brand" type="submit" isLoading={formikNote.isSubmitting} size="sm" px={4} fontSize={11} > SUBMIT</Button> 
                                </Flex>
                            </form>

                            :

                            <Button  colorScheme="brand" onClick={() => setAddNote(true) } size="sm" fontSize={11} borderRadius={2} px={6}>ADD NOTES</Button>
                       }

                    </Box>

                    :
                    
                    isUploading ?

                    <Box>
                       <Table variant="unstyled">
                            <Thead fontWeight="semibold" >
                                <Tr color="brand.subtitle" fontSize={14} >
                                    <Td>Filename</Td>
                                    <Td>Type</Td>
                                    <Td>Status</Td>
                                    <Td>Size</Td>
                                </Tr>
                            </Thead>
            
                            <Tbody>
                                <Tr fontSize={13} color="brand.subtitle">
                                    <Td isTruncated maxW="20em" >{fileName}</Td>
                                    <Td>Slide</Td>
                                    <Td><Progress isIndeterminate={isUploading? true: false} value={progress} /></Td>
                                    <Td>{uploadFileSize}</Td>
                                </Tr>                                
                            </Tbody>
                        </Table>
                    </Box>

                    :

                    <Box>
                        <form onSubmit={formikSlide.handleSubmit}>
                            <FormControl mr={10}  isInvalid={(formikSlide.errors.slide_lesson && formikSlide.touched.slide_lesson) ? true : false} >
                                <Flex>                            
                                    <input accept="application/pdf" type='file' name="slide_lesson" ref={inputRef} onChange={(e) => handleFileChange(e) } style={{ display: 'none' }}></input>
                                    <Input placeholder="No file selected" defaultValue={fileName} fontSize={14} onClick={ () => inputRef?.current?.click() } borderRadius={1}  />
                                    <Button colorScheme="brand" type="submit" isLoading={formikSlide.isSubmitting} size="md" fontSize={11} >UPLOAD SLIDE</Button>
                                </Flex>
                                <FormErrorMessage fontSize={12}> {formikSlide.errors.slide_lesson} </FormErrorMessage>
                            </FormControl>
                        </form>

                        <Text fontSize={13} color="brand.subtitle" mt={4}>
                            <Box as="span" fontWeight="semibold" color="brand.2">Note: </Box> Slides should be in PDF formart
                        </Text>
                   </Box>

                }

            </Box>
        </>
    );
}