import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { AiOutlineUsergroupAdd, AiOutlineUsergroupDelete } from "react-icons/ai";

export const EmptyGroup: React.FC<any> = ({createGroupView}) => {

    return (
        <Flex minHeight="inherit"  justifyContent="center">
        
            <Flex direction="column" alignItems="center" justifyContent="center" >

                <Box color="brand.subtitle" mb={6}>
                    <AiOutlineUsergroupDelete size={40} />
                </Box>

                <Text mb={4} fontSize={13} color="brand.subtitle"> No groups. You can create one below </Text>

                <Button colorScheme="brand" onClick={createGroupView} leftIcon={<AiOutlineUsergroupAdd style={{'fontSize': '18'}} />} size="sm" fontSize={11}>New Group</Button>

            </Flex>
        </Flex>
    );
}