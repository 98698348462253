import React from "react";
import { Button, IconButton,Text } from "@chakra-ui/react";
import { BsArrowRight ,BsArrowLeft } from "react-icons/bs";

const PaginationButton = ({ label, onClick, disabled }) => (
 
  <IconButton size={'sm'} onClick={onClick} disabled={disabled}>
    {label  ==  'Previous' ? <BsArrowLeft /> : label  ==  'Next'? <BsArrowRight /> : <Text fontWeight={'400'} fontSize={'12px'}>{label}</Text>}
  </IconButton>
);


export default PaginationButton;