import { Select } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { ICategory } from "../../models/course.model";
import { fetchCategories } from "../../services/shared.service";

export const Category: React.FC<any> = ({unitId, setUnitId, size = "sm", ...rest }) => {

    const [categories, setCategories] = useState<ICategory[]>([]);

    useEffect(() => {
        fetchCategories()
        .then((data) => {
            setCategories(data)
        })
        .catch((err) => err)
        
    }, []);
    
    return (
        <>
            <Select {...rest}  variant="outline" placeholder="Select a Unit" name="unit"  size={size} onChange={setUnitId} fontSize={12} color="brand.subtitle" >
                {
                    categories.map( each => <option key={each.id} value={each.category_id}> {each.title} </option>)
                }
            </Select>
        </>
    );
}