import { Box, Flex, Image, Text } from "@chakra-ui/react";
import lineChart from '../../../img/lineChart.svg';


export const EmptyReport: React.FC<any> = () => {

    return (
        <Flex minHeight="inherit"  justifyContent="center">
        
            <Flex direction="column" alignItems="center" justifyContent="center" >

                <Box color="brand.subtitle" mb={6}>
                    <Image src={lineChart} />
                </Box>

                <Text mb={4} fontSize={13} color="brand.subtitle"> No traffic records yet </Text>
                
            </Flex>
        </Flex>
    );
}