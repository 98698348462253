import axios, { AxiosError, AxiosResponse } from "axios";
import { HttpResponse, HttpErrorResponse } from "../models/httpResponse.model";
import { httpStatusCode } from "../models/httpStatus.model";
import { destroyUserSession, getAuthToken } from "./auth.service";

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  // baseURL: "http://4.180.77.128:3025/api/v1",
  // baseURL: "http://20.254.242.217:3001/api/v1",
  // baseURL: "https://blucanary.bluechiptech.biz/api/v1",
});

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  // baseURL: "http://20.254.242.217:3001/api/v1",
  // baseURL: "https://blucanary.bluechiptech.biz/api/v1",
});

httpClient.interceptors.request.use(
  (config) => {
    config.headers = {
      Authorization: `Bearer ${getAuthToken()}`,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "*",
    };

    return config;
  },
  (error) => Promise.reject(error)
);

httpClient.interceptors.response.use(
  (response: AxiosResponse<HttpResponse>) => response,

  (error: AxiosError<HttpErrorResponse>) => {
    // const originalRequest = error.config;
    const status = error.response ? error.response.status : null;
    if (status === httpStatusCode.UNAUTHORIZED) {
      destroyUserSession();
      window.location.replace("/");
    }
    return Promise.reject(error.response);
  }
);

export { httpClient, axios, axiosClient };
