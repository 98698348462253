import React from "react";
import {Select} from "chakra-react-select";


export const MultiSelect: React.FC<any> = ({data = [], name, selected, setSelected, placeholder = "Select options", size = "sm", ...rest }) => {

    return (
            <Select {...rest}  
                isMulti
                variant="outline"
                selectedOptionStyle="check"
                placeholder={placeholder}
                name={name}
                value={selected}
                size={size}
                onChange={setSelected}
                fontSize={12} color="brand.subtitle" 
                options={data}
            />
            
        
    );
}