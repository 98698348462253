import { usePagination } from "@ajna/pagination";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton, Text, Box, useToast, FormControl, FormLabel, InputGroup, InputLeftElement, Input, FormErrorMessage, Spacer, Flex, Button, Checkbox, Center, Select, SimpleGrid, Icon, Textarea,Progress, HStack, CircularProgress, CircularProgressLabel, InputRightElement, Table, Tbody, Tr, Td, Thead, Th, Badge
  } from "@chakra-ui/react"
import { url } from "inspector";
import { useEffect, useState } from "react";
import { RiSearch2Line } from "react-icons/ri";


import { useHistory, useParams } from "react-router-dom";
import { BridgeCounStatusDto, IBridgeCount, IBridgeCountData, Ipage, IUser, IUserDashboard } from "../../models/auth.model";
import { ICourseEnrollmentStatusList } from "../../models/course.model";
import { fetchUserOverview, getStudentOverview } from "../../services/hr/users-http.service";
import { fetchBridgeCountStatus } from "../../services/student/course-http.service";
import { fetchBridgeCount } from "../../services/trainingMgr/course-http.service";
import { EmptySearchResult } from "../Common/EmptySearchResult";



 




  export const ViewBridgeCountModal: React.FC<any> = ({isOpenn, onClosed, isBridgeCount = false, BridgeArray,handleUserSearchChange,isSearchUserStatusEmpty}: {isOpenn: any, onClosed: any,isBridgeCount: boolean,handleUserSearchChange:any,isSearchUserStatusEmpty:any, BridgeArray:BridgeCounStatusDto[]}) => {
    let { courseId } = useParams<{ courseId: string }>()

    
    const [isLoading, setIsLoading] = useState(true)
    const [page, setPage] = useState<Ipage>({ page_total: 0, total: 0 })
    const [search, setSearch] = useState('')
    const [query, setQuery] = useState('')
    const [reload, setStatus] = useState(false)
    const { currentPage, setCurrentPage, pagesCount, pages } = usePagination({
      //pagesCount: isNaN(Math.ceil(page.total / page.page_total)) ? 1 : Math.ceil(page.total / page.page_total),
      initialState: { currentPage: 1, pageSize: 10 },
      total: page.total,
    })

    
    
    return (
      <>
        <Modal isOpen={isOpenn && isBridgeCount} onClose={onClosed} scrollBehavior="inside" closeOnOverlayClick={true}  size="4xl" >
            <ModalOverlay />
              <ModalContent borderRadius={5} px={6} >
                <ModalHeader >
                 <Text color="brand.2" fontSize={16}>Bridge Count</Text>               
                </ModalHeader>
                <ModalCloseButton size="sm" />
                <ModalBody pb={6}>

                {isSearchUserStatusEmpty ? 

                    <Box pt={20}>
                        <EmptySearchResult />
                    </Box>
                    :
                    <>
                    <Flex justifyContent="space-between" alignItems="center"  mt={6} mb={3}>
                      <InputGroup color="brand.subtitle" bg="white" width="40%">
                        <Input placeholder="Search all students" onChange={e => handleUserSearchChange(e)} fontSize={12} size="sm" />

                        <InputRightElement h="1.9rem" pointerEvents="none" children={<RiSearch2Line size="0.9rem" />} />
                      </InputGroup>
                   </Flex><Table variant="simple" mt={2} size="sm">

                    <Tbody>
                      {BridgeArray && BridgeArray.map((each, index) => (
                        <Tr key={index} fontSize={13} color="brand.subtitle">

                          <Td>
                            <HStack
                              spacing={3}
                              alignItems="center"
                              maxW="fit-content"
                            >
                              {/* <Avatar
                
                                verticalAlign={"center"}
                                size="sm"
                                name={each.title}
                                src={each.photo_url}
                                ml={16}
                                mr={6}
                            /> */}
                              <Text
                                color="brand.3"
                                whiteSpace="nowrap"
                                isTruncated
                                title={each.user.full_name}
                              >
                                {each.user.full_name}
                              </Text>
                            </HStack>
                          </Td>
                          <Td>
                            <Flex alignItems="center">
                              <Progress value={ (each.progresses.length / each?.courses?.courseLessons.length) * 100} size="sm" color="brand.2" width="20rem" borderRadius="0.5rem" height="0.4rem" />
                              <Text pl={5} fontSize={12} fontWeight="semibold" color="brand.bodyText">{(each.progresses.length / each?.courses?.courseLessons.length) * 100} %</Text>
                              { 
                                (each.progresses.length / each?.courses?.courseLessons.length) * 100 < 100 ? 
                                
                                <Text pl={5} fontSize={12} fontWeight="semibold" color={"red"}>Failed to complete</Text>

                                : 
                                
                                <Text pl={5} fontSize={12} fontWeight="semibold" color={"blue"}>Complete</Text>
                                
                              }



                            </Flex>
                          </Td>

                        </Tr>
                      ))}
                    </Tbody>
                  </Table></>

                 }  
                
                     
                </ModalBody>
                
              </ModalContent>
        </Modal>
      </>
     
    )
  }

                    