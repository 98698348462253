import { Box, Text, VStack, Image, Button } from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router";
import svg404 from '../../img/404.svg'


export const Error404: React.FC<any> = () => {

    const history = useHistory()

    return (
        <Box minHeight="100vh" width="100%">

            <Box display="flex" justifyContent="center" alignItems="center">
                <VStack mt={12} spacing={0}>

                    <Box color="brand.subtitle" >
                    <Image src={svg404} />
                    </Box>

                     <Text fontSize={14} color="brand.subtitle"> Sorry, the content you’re looking for doesn’t exist. </Text>

                     <Text pb={6} fontSize={14} color="brand.subtitle">Either it was removed or you mistyped the link.</Text>

                      <Button colorScheme="brand" onClick={() => history.goBack()} size="sm" px={8} fontSize={13}>Go Home</Button>
                </VStack>
                 
            </Box>
           
        </Box>
    );
}