import {
  Container,
  HStack,
  Text,
  Box,
  Button,
  Link,
  AspectRatio,
  Flex,
  Portal,
  useDisclosure,
  useToast,
  ListItem,
  OrderedList,
  RadioGroup,
  Radio,
  Image,
} from '@chakra-ui/react'
import React, { RefObject, useEffect, useRef, useState } from 'react'
import { BiPlayCircle } from 'react-icons/bi'
import {
  ICourseTopic,
  ICourseWithEnrollment,
  ILesson,
  ILessonProgressDto,
} from '../../models/course.model'
import { StudentRoutes } from '../../pages/Student/routes'
import {
  checkCourseCompletedService,
  courseCompletedService,
  fetchCourseExam,
  getStudentCourseEnrollmentLessons,
  submitCourseReviewService,
  submitLessonProgressService,
  submitQuizQuestionAnswer,
  submitQuizTakenCompleted,
  submitQuizTakenService,
} from '../../services/student/course-http.service'
import { secondsToHrMin } from '../../utils/secondsToHrMin'
import { ScreenLoader } from '../Common/ScreenLoader'
import { Link as ReactLink, useRouteMatch, useHistory } from 'react-router-dom'
import { HiOutlineArrowLeft } from 'react-icons/hi'
import { CourseCompletedModal } from './CourseCompletedModal'
import {
  IExamTakenAnswerDto,
  IQuizQuestion,
  IQuizTaken,
  IQuizTakenAnswerDto,
  IQuizTakenCompletedDto,
  IQuizTakenDto,
  IExam
} from '../../models/quiz.model'
import reading from '../../img/reading.png'
import quiz from '../../img/quiz.png'
import { Document, Page, pdfjs } from 'react-pdf'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`


const LessonsPage: React.FC<any> = () => {
  let { params } = useRouteMatch<{ courseId: string; lessonId: string }>()

  let history = useHistory()

  const [courseData, setCourseData] = useState<ICourseWithEnrollment>()

  const [courseLessons, setCourseLessons] = useState<(ILesson | undefined)[]>(
    [],
  )
  const [course_ID, setCourseId] = useState<string | undefined>('')

  const [selectedLesson, setSelectedLesson] = useState<ILesson>()

  const [isLoading, setIsLoading] = useState(true)

  const [isSubmittingReview, setSubmittingReview] = useState(false)

  const [selectedLessonIndex, setSelectedLessonIndex] = useState<number>(0)

  const videoRef = useRef() as RefObject<HTMLVideoElement>

  const [isVideo, setIsVideo] = useState(false)

  const [isArticle, setIsArticle] = useState(false)

  const [isSlide, setIsSlide] = useState(false)

  const [isQuiz, setIsQuiz] = useState(false)

  const [supposedCurrentTime, setSupposedCurrentTime] = useState(0)

  const { isOpen, onOpen, onClose } = useDisclosure()

  const toast = useToast()

  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState<number>(0)

  const [hasExam, setHasExam] = useState(false)
  const [isExam, setIsExam] = useState(false)
  const [selectedExamQuestionIndex, setSelectedExamQuestionIndex] = useState<number>(0)
  const [examQuestions, setExamQuestions] = useState<IQuizQuestion[]>([])
  const [examAnswerId, setExamAnswerId] = useState<string>('')
  const [examTaken, setExamTaken] = useState<boolean>(false)
  const [exam, setExam] = useState<IExam>()
  const [examQuestionId, setExamQuestionId] = useState<string>()
  const [examScore, setExamScore] = useState<{ score: number; total: number }>()
  const [showExamResult, setShowExamResult] = useState<boolean>(false)
  const [paused, setPaused] = useState(0)

  const [quizQuestions, setQuizQuestions] = useState<IQuizQuestion[]>([])

  let enrollmentId = useRef<string>()
  const [videoInProgess, setVideoInProg] = useState(true);

  let coursePayloads = useRef<ICourseWithEnrollment>()

  const [quizAnswerId, setQuizAnswerId] = useState<string>()

  const [isSubmittingAnswer, setIsSubmittingAnswer] = useState<boolean>(false)

  const [showQuizResult, setShowQuizResult] = useState<boolean>(false)

  const [quizScore, setQuizScore] = useState<{ score: number; total: number }>()

  const [numPages, setNumPages] = useState<number>(1)
  const [resumeTime, setResumeTime] = useState<string>('')

  // const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    let { courseId, lessonId } = params
    
    
    getStudentCourseEnrollmentLessons(courseId)
      .then(async (data: any) => {
        setCourseData(data)
        setCourseId(data.course?.title)
        console.log(data, "data")
        let courseLessonsData = data.course?.topics?.flatMap(
          (each: any) => each.lessons,
        )
        console.log(courseLessonsData, "course lesson data")
        if (!courseLessonsData) history.goBack()
        coursePayloads.current = data
        courseLessons.push(...(courseLessonsData ?? []))
        enrollmentId.current = data.courseEnrollment?.enrollment_id as string
        await filterLesson(
          lessonId ?? (courseLessonsData?.at(0)?.lesson_id as string),
        )

        if (data.course?.exam !== null) {
          if (data.course?.exam.isExam) {
            let course = await fetchCourseExam(data?.course?.course_id as string)
            let examQuestions = course?.exam?.questions
            console.log(examQuestions, "exam questions")
            console.log(course.exam, " course exam")
            if (examQuestions?.length) {
              setHasExam(true)
              setExam(course.exam)
              setExamQuestions(examQuestions)

            }
          }
        } else {
          setHasExam(false)
          setExam(undefined)
          setExamQuestions([])
        }

        setIsLoading(false)
      })
      .catch((e) => {
        history.goBack()
        console.log(e);
      })
  }, [])

  useEffect(() => {

    if (params.lessonId && !isLoading) {
      if (showQuizResult) setShowQuizResult(false);
      if (showExamResult) setShowExamResult(false);

      filterLesson(params.lessonId)
    }
  }, [params.lessonId])

 

  const getTopic_LessonId = (
    lessonId: string,
    courseData: ICourseWithEnrollment,
  ) => {
    const courseTopics = courseData.course?.topics
    const selectedTopic = courseTopics?.find(
      (each) =>
        each.lessons?.find((e) => e.lesson_id === lessonId)?.lesson_id ===
        lessonId,
    ) as ICourseTopic

    const lessonIndex = selectedTopic?.lessons?.findIndex(
      (each) => each.lesson_id === lessonId,
    ) as number

    const topicIndex = courseTopics?.findIndex(
      (each) => each.topic_id === selectedTopic.topic_id,
    ) as number

    return { topicIndex, lessonIndex }
  }

  const filterLesson = async (lessonId: string) => {

    let lesson = courseLessons.find((lesson) => lesson?.lesson_id === lessonId)
    let startTime = localStorage.getItem('pauseTime')
    if (startTime !== null && lesson){
      let resumeTime = JSON.parse(startTime)
      console.log(resumeTime,'has resume')
      console.log(lesson.id, 'select id')
      console.log(resumeTime.lessonId , 'saved id')
      if(   resumeTime.lessonId == lesson.id){
        setResumeTime(resumeTime.pausetime)
      } 
    }

    if (lesson) {
      setSelectedLessonIndex(courseLessons.indexOf(lesson))
      setSelectedLesson(lesson)

      if (lesson.video_url) {
        let nextLessonIndex = selectedLessonIndex + 1
        let nextLessonId = courseLessons.at(nextLessonIndex)?.id
        getStudentCourseEnrollmentLessons(params.courseId)
          .then(async (data: any) => {
            data?.courseProgress?.forEach((progress: any) => {

              console.log(progress, 'course data')
              nextLessonId == progress.lesson?.id ? setVideoInProg(false) : setVideoInProg(true);
            })
          })


        console.log(selectedLessonIndex, nextLessonIndex, nextLessonId)

        videoRef.current?.load()
        await videoRef.current?.play()



        setIsArticle(false)
        setIsQuiz(false)
        setIsSlide(false)
        setIsVideo(true)
        setIsExam(false)
      } else if (lesson.article) {
        setIsVideo(false)
        setIsQuiz(false)
        setIsSlide(false)
        setIsArticle(true)
        setIsExam(false)
      } else if (lesson.doc_url) {
        setIsVideo(false)
        setIsQuiz(false)
        setIsArticle(false)
        setIsSlide(true)
        setIsExam(false)
      } else if (lesson.quiz) {
        setIsArticle(false)
        setIsVideo(false)
        setIsSlide(false)
        setIsQuiz(true)
        setIsExam(false)
        setQuizQuestions(lesson?.quiz?.questions as IQuizQuestion[])

        let { lessonIndex, topicIndex } = getTopic_LessonId(
          lessonId,
          coursePayloads.current as ICourseWithEnrollment,
        )

        if (lesson?.quiz?.quizTaken?.length) {
          if (lesson.quiz.quizTaken.at(0)?.finishedAt) {
            const { score, total } = calculateQuizScore(
              topicIndex,
              lessonIndex,
              courseData ?? (coursePayloads.current as ICourseWithEnrollment),
            )

            setShowQuizResult(true)

            setQuizScore({ score, total })
          }
        } else {
          await submitQuizTaken(
            topicIndex,
            lessonIndex,
            lesson.quiz.quiz_id,
            enrollmentId.current as string,
          )
        }
      }
    } else {
      history.goBack()
    }
  }

  const resume = async () => {

    if (resumeTime) {
      console.log(parseFloat(resumeTime), 'time from on load')
      console.log(videoRef.current)
      if (!videoRef.current?.paused && !videoRef.current?.ended && videoRef.current) {
        let numresTime = parseInt(resumeTime)
        videoRef.current.currentTime = numresTime
        console.log(numresTime, 'num res')
      }

    }
  }

  const videoCompleted = async () => {

    setVideoInProg(false);
    console.log(videoInProgess);
    let selectedLessonId = courseLessons.at(selectedLessonIndex)
      ?.lesson_id as string
    await submitLessonProgress(selectedLessonId)

  }

  const articleReadTime = () => {
    const wpm = 225
  }

  const nextLesson = async (gotoNextLesson?: boolean) => {
    const currentLessonID = courseLessons.at(selectedLessonIndex)
      ?.lesson_id as string


    const isVisited = isCurrentLessonVisited(currentLessonID)

    if (!isVisited || gotoNextLesson) {

      if (hasExam && !examTaken && selectedLessonIndex === courseLessons.length - 1) {
        setIsArticle(false)
        setIsQuiz(false)
        setIsSlide(false)
        setIsVideo(false)
        setIsExam(true)
        submitExamTaken()
        return
      }


      if (selectedLessonIndex < courseLessons.length - 1 && (isSlide || isArticle)) {
        let nextLessonIndex = selectedLessonIndex + 1
        setSelectedLessonIndex(selectedLessonIndex + 1)
        let selectedLessonId = courseLessons.at(selectedLessonIndex)
          ?.lesson_id as string
        await submitLessonProgress(selectedLessonId)
        history.push(
          StudentRoutes.Course.SpecificLessonLink(
            params.courseId,
            courseLessons.at(nextLessonIndex)?.lesson_id,
          ),
        )
      } else if (selectedLessonIndex < courseLessons.length - 1) {
        let nextLessonIndex = selectedLessonIndex + 1
        setSelectedLessonIndex(selectedLessonIndex + 1)
        history.push(
          StudentRoutes.Course.SpecificLessonLink(
            params.courseId,
            courseLessons.at(nextLessonIndex)?.lesson_id,
          ),
        )
      } else {
        console.log('BOLAJI MUILI')
        let enrollment = await checkCourseCompletedService(
          courseData?.courseEnrollment?.enrollment_id as string,
        )
        console.log(enrollment, "enrollment")
        if (!enrollment.completedAt) {
          

          await submitCourseCompleted()
          onOpen()
        } else {
          history.replace(StudentRoutes.Course.Explore)
        }
      }
    }
  }

  const pickLesson = (lessonId: string) => {
    let curLessonIndex = courseLessons.findIndex(
      (each) => each?.lesson_id === lessonId,
    )

    let isVisited =
      curLessonIndex === 0 ? true : isCurrentLessonVisited(lessonId)

    if (curLessonIndex > 0 && !isVisited) {
      isVisited = isCurrentLessonVisited(
        courseLessons.at(curLessonIndex - 1)?.lesson_id as string,
      )
    }

    if (isVisited) {
      history.push(
        StudentRoutes.Course.SpecificLessonLink(params.courseId, lessonId),
      )
    }
  }

  const submitLessonProgress = async (lessonId: string) => {
    let data: ILessonProgressDto = {
      enrollmentId: courseData?.courseEnrollment?.enrollment_id as string,
      lessonId: lessonId,
    }

    const newCourseProgress = await submitLessonProgressService(data)

    let updatedCourseProgress = [
      ...(courseData?.courseProgress as []),
      newCourseProgress,
    ]

    setCourseData({ ...courseData, courseProgress: [...updatedCourseProgress] })
  }

  const isCurrentLessonVisited = (lessonId: string) => {
    let progressList = courseData?.courseProgress?.filter(
      (each) => each.lesson?.lesson_id === lessonId,
    )
    let isVisited = (progressList?.length as number) > 0 ? true : false
    return isVisited
  }

  const submitCourseCompleted = async () => {
   
    try {
      onOpen()
      console.log('bolaji')
      await courseCompletedService({
        enrollmentId: courseData?.courseEnrollment?.enrollment_id as string,
      })
    } catch (error) {
      toast({
        title: 'Course',
        description: 'Something went Wrong. Try again later',
        status: 'error',
      })
    }
  }

  const videoTimeUpdate = () => {
    if (!videoRef.current?.seeking) {
      if (videoRef.current?.paused) {
        let pausetime = videoRef.current?.currentTime as number
        let resumeObject  = {
          pausetime : pausetime.toString(),
          lessonId: selectedLesson?.id
        }
        localStorage.setItem('pauseTime', JSON.stringify(resumeObject));
        console.log(pausetime, 'time of video')
      }
      setSupposedCurrentTime(videoRef.current?.currentTime as number)
    }

  }

  const videoSeek = () => {

    if (resumeTime && supposedCurrentTime < parseInt(resumeTime))  {
      resume()
      
    } else {
      var delta = (videoRef.current?.currentTime as number) - supposedCurrentTime

      if (delta > 0.01) {
        if (videoRef.current) videoRef.current.currentTime = supposedCurrentTime
      }
    }
  }

  const submitCourseReview = async (
    rating: number,
    review: string,
    isAnonymous: boolean,
  ) => {
    if (rating < 1) {
      toast({
        title: 'Course Review',
        description: 'Kindly rate the course',
        status: 'warning',
        duration: 3000,
      })
      return
    }

    setSubmittingReview(true)

    try {
      let reviewResp = await submitCourseReviewService({
        rating,
        review,
        courseId: courseData?.course?.course_id as string,
        isAnonymous,
      })

      if (!reviewResp.data) throw new Error('Something went wrong')

      toast({
        title: 'Course Review',
        description: reviewResp.message,
        status: 'success',
        duration: 3000,
        onCloseComplete: () => {
          onClose()

          setSubmittingReview(false)

          history.replace(StudentRoutes.Course.Explore)
        },
      })
    } catch (error) {
      setSubmittingReview(false)
      toast({
        title: 'Course Review',
        description: 'Something went Wrong. Try again later',
        status: 'error',
        duration: 3000,
      })
    }
  }

  const submitQuizTaken = async (
    topicIndex: number,
    lessonIndex: number,
    quizId: string,
    enrollmentId: string,
  ): Promise<void> => {
    try {
      let data: IQuizTakenDto = {
        quizId,
        enrollmentId,
      }

      const quizTaken = await submitQuizTakenService(data)

      let courseDatas = courseData ?? coursePayloads.current

      courseDatas?.course?.topics
        ?.at(topicIndex)
        ?.lessons?.at(lessonIndex)
        ?.quiz?.quizTaken?.push(quizTaken)

      setCourseData({ ...courseDatas })
    } catch (error) {
      toast({
        title: 'Quiz',
        description: 'Something went Wrong. Try again later',
        status: 'error',
      })
    }
  }

  const submitQuizAnswer = async (
    quizTakenId: string,
    quizQuestionId: string,
    quizAnswerId: string,
    lessonId: string,
  ) => {
    try {
      if (!quizAnswerId) {
        toast({
          title: 'Quiz',
          description: 'Choose an option',
          status: 'warning',
          duration: 3000,
        })
        return
      }

      let data: IQuizTakenAnswerDto = {
        quizAnswerId: quizAnswerId,
        quizQuestionId: quizQuestionId,
        quizTakenId: quizTakenId,
      }

      setIsSubmittingAnswer(true)

      const quizTakenAnswer = await submitQuizQuestionAnswer(data)

      let { lessonIndex, topicIndex } = getTopic_LessonId(
        lessonId,
        courseData as ICourseWithEnrollment,
      )

      courseData?.course?.topics
        ?.at(topicIndex)
        ?.lessons?.at(lessonIndex)
        ?.quiz?.quizTaken?.at(0)
        ?.quizTakenAnswer?.push(quizTakenAnswer)

      setCourseData({ ...courseData })

      if (selectedQuestionIndex !== quizQuestions.length - 1) {
        let nextQuestion = selectedQuestionIndex + 1

        setSelectedQuestionIndex(nextQuestion)
      } else {
        await submitLessonProgress(lessonId)

        let quizTaken = await quizTakenCompleted(quizTakenId)

        let selectedQuizTaken = courseData?.course?.topics
          ?.at(topicIndex)
          ?.lessons?.at(lessonIndex)
          ?.quiz?.quizTaken?.at(0) as IQuizTaken

        courseData?.course?.topics
          ?.at(topicIndex)
          ?.lessons?.at(lessonIndex)
          ?.quiz?.quizTaken?.splice(0, 1, {
            ...selectedQuizTaken,
            finishedAt: quizTaken.finishedAt,
          })

        setCourseData({ ...courseData })

        const { score, total } = calculateQuizScore(
          topicIndex,
          lessonIndex,
          courseData as ICourseWithEnrollment,
        )

        setQuizScore({ score, total })

        setShowQuizResult(true)
      }

      setQuizAnswerId('')

      setIsSubmittingAnswer(false)
    } catch (error) {
      setIsSubmittingAnswer(false)

      toast({
        title: 'Quiz',
        description: 'Something went Wrong. Try again later',
        status: 'error',
      })
    }
  }

  const submitExamTaken = async () => {
    let data: IQuizTakenDto = {
      quizId: exam?.quiz_id as string,
      enrollmentId: enrollmentId.current as string,
    }

    const quizTaken = await submitQuizTakenService(data)
    let courseDatas = courseData ?? coursePayloads.current

    courseDatas?.course?.exam.quizTaken?.push(quizTaken)
    setCourseData({ ...courseDatas })
    console.log(courseDatas)

  }
  const submitExamAnswer = async (
    examTakenId: string,
    examQuestionId: string,
    examAnswerId: string,
    lessonId: string,
  ) => {
    try {
      if (!examAnswerId) {
        toast({
          title: 'Exam',
          description: 'Choose an option',
          status: 'warning',
          duration: 3000,
        })
        return
      }

      let data: IQuizTakenAnswerDto = {
        quizAnswerId: examAnswerId,
        quizQuestionId: examQuestionId,
        quizTakenId: examTakenId,
      }
      setIsSubmittingAnswer(true)

      const examTakenAnswer = await submitQuizQuestionAnswer(data)

      courseData?.course?.exam.quizTaken?.at(0)
        ?.quizTakenAnswer?.push(examTakenAnswer)

      setCourseData({ ...courseData })


      if (selectedExamQuestionIndex < examQuestions.length - 1) {
        let nextExamQuestion = selectedExamQuestionIndex + 1
        setSelectedExamQuestionIndex(nextExamQuestion)
      } else {
        await submitLessonProgress(lessonId)

        let examTaken = await quizTakenCompleted(examTakenId)

        let selectedQuizTaken = courseData?.course?.exam.quizTaken?.at(0) as IQuizTaken

        courseData?.course?.exam.quizTaken?.splice(0, 1, {
          ...selectedQuizTaken,
          finishedAt: examTaken.finishedAt,
        })

        setCourseData({ ...courseData })

        const { score, total } = calculateExamScore(
          courseData as ICourseWithEnrollment,
        )
        setExamScore({ score, total })

        setShowExamResult(true)
      }

      setExamAnswerId('')

      setIsSubmittingAnswer(false)
    } catch (error) {
      setIsSubmittingAnswer(false)

      toast({
        title: 'Exam',
        description: 'Something went Wrong. Try again later',
        status: 'error',
      })
    }
  }

  const quizTakenCompleted = async (quizTakenId: string) => {
    let data: IQuizTakenCompletedDto = { quizTakenId }
    const quizTaken = await submitQuizTakenCompleted(data)
    return quizTaken
  }

  const calculateQuizScore = (
    topicIndex: number,
    lessonIndex: number,
    courseData: ICourseWithEnrollment,
  ): { score: number; total: number } => {

    const selectedLessonQuiz = courseData?.course?.topics
      ?.at(topicIndex)
      ?.lessons?.at(lessonIndex)?.quiz

    let correctAnswers = selectedLessonQuiz?.questions?.flatMap((each) =>
      each.answers?.flatMap((each) => ({ id: each.id, correct: each.correct })),
    )

    let correctAnswersIds = correctAnswers
      ?.filter((each) => each?.correct === true)
      .map((each) => each?.id)

    let takenAnswerIds = selectedLessonQuiz?.quizTaken
      ?.at(0)
      ?.quizTakenAnswer?.flatMap((each) => each.quizAnswerId)

    const distinctTakenAnswerIds = new Set(takenAnswerIds)

    const correctTakenIds = correctAnswersIds?.filter((each) =>
      distinctTakenAnswerIds.has(each as number),
    )

    let total = correctAnswersIds?.length as number

    let score = correctTakenIds?.length as number

    return { score, total }
  }

  const calculateExamScore = (
    courseData: ICourseWithEnrollment,
  ): { score: number; total: number } => {
    const selectedLessonExam = courseData?.course?.exam

    let correctAnswers = selectedLessonExam?.questions?.flatMap((each) =>
      each.answers?.flatMap((each) => ({ id: each.id, correct: each.correct })),
    )

    let correctAnswersIds = correctAnswers
      ?.filter((each) => each?.correct === true)
      .map((each) => each?.id)

    let takenAnswerIds = selectedLessonExam?.quizTaken
      ?.at(0)
      ?.quizTakenAnswer?.flatMap((each) => each.quizAnswerId)

    const distinctTakenAnswerIds = new Set(takenAnswerIds)

    const correctTakenIds = correctAnswersIds?.filter((each) =>
      distinctTakenAnswerIds.has(each as number),
    )


    let total = correctAnswersIds?.length as number

    let score = correctTakenIds?.length as number

    console.log(score, "score")
    console.log(total, "total")


    return { score, total }

  }

  const pdfLoad = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages)
  }


  return (
    <>
      <Container
        maxWidth="18%"
        pl={0}
        height="95vh"
        boxShadow="base"
        bg="white"
        position="fixed"
        overflowY="auto"
      >
        <Box width="100%" pl={8} mt={10} mb={10}>
          {courseData?.course?.topics?.map((topic, index) => (
            <Box key={index} mb={6}>
              <Text
                color="brand.3"
                fontSize={14}
                isTruncated
                title={topic.title}
              >
                {' '}
                {topic.title}{' '}
              </Text>

              {topic.lessons?.map((lesson, lindex) => (
                <Link
                  key={lindex}
                  onClick={() => pickLesson(lesson.lesson_id as string)}
                >
                  <HStack
                    width="100%"
                    pl={2}
                    alignItems="start"
                    spacing={3}
                    mt={4}
                  >{
                      lesson.video_url && (
                        <Box color="brand.3" fontSize={22}>
                          {' '}
                          <BiPlayCircle />{' '}
                        </Box>

                      )

                    }
                    {lesson.article && (
                      <Image src={reading} />

                    )}
                    {lesson.doc_url && (
                      <Image src={reading} />

                    )}
                    {lesson?.quiz?.title && (
                      <Image src={quiz} />
                    )}
                    {lesson.exam?.id && (
                      <Image src={quiz} />
                    )}


                    <Box>
                      <Text fontSize={13} color="brand.subtitle">
                        {lesson.title}
                      </Text>
                      <Text fontSize={11} color="brand.subtitle">
                        {' '}
                        {secondsToHrMin(
                          lesson.duration ? lesson?.duration : 0,
                        )}{' '}
                      </Text>
                    </Box>
                  </HStack>
                </Link>
              ))}
            </Box>
          ))}
        </Box>
      </Container>

      <Container maxW="container.xl" ml={16} mr={16} pt={12} mb={6}>
        {isLoading ? (
          <ScreenLoader />
        ) : (
          <Box mb={8}>
            <Flex justifyContent="flex-end" mb={2}>
              <Link
                as={ReactLink}
                to={StudentRoutes.Course.Explore}
              >
                <HStack color="brand.2">
                  <HiOutlineArrowLeft />
                  <Text fontSize={13} fontWeight="semibold">
                    {' '}
                    Go back{' '}
                  </Text>
                </HStack>
              </Link>
            </Flex>

            <Container
              boxShadow="base"
              p={3}
              maxW="container.xl"
              mb={6}
              bg="white"
            >
              <Text color="brand.3" fontSize={16} pl={6}>
                {' '}
                {isExam ? exam?.title : selectedLesson?.title}{' '}
              </Text>
            </Container>

            {isVideo && (
              <AspectRatio ratio={16 / 9}>
                <video
                  controls
                  autoPlay
                  ref={videoRef}
                  onSeeking={videoSeek}
                  onTimeUpdate={videoTimeUpdate}
                  onEnded={videoCompleted}
                  controlsList="nodownload"
                >
                  <source src={selectedLesson?.video_url} />
                </video>
              </AspectRatio>
            )}

            {isSlide && (
              <Flex justifyContent={'center'}>
                <Document
                  file={selectedLesson?.doc_url}
                  onLoadSuccess={pdfLoad}
                  onLoadError={(error) => console.log('Inside Error', error)}
                >
                  {Array.from(new Array(numPages), (el, index) => (
                    <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                  ))}
                </Document>
              </Flex>
            )}

            {isArticle && (
              <Box fontSize={14} px={20} textAlign="justify">
                {selectedLesson?.article}
              </Box>
            )}

            {isQuiz && (
              <Box
                boxShadow="base"
                bg="white"
                px={10}
                minHeight={'55vh'}
                pt={10}
              >
                {showQuizResult ? (
                  <Flex
                    direction={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    height="100%"
                  >
                    <Text mb={4} mt={10} color="brand.subtitle" fontSize={14}>
                      You have completed the quiz
                    </Text>

                    <Text
                      mb={2}
                      fontWeight={'semibold'}
                      fontSize={18}
                      color="brand.subtitle"
                    >
                      Your score is
                    </Text>

                    <Text
                      mb={8}
                      color="success.500"
                      fontWeight={'bold'}
                      fontSize={30}
                    >
                      {' '}
                      {quizScore?.score}/{quizScore?.total}{' '}
                    </Text>

                    <Button
                      mb={4}
                      colorScheme="brand"
                      size="sm"
                      fontSize={11}
                      onClick={() => nextLesson(true)}
                      borderRadius={0}
                      px={6}
                    >
                      {' '}
                      Next Lesson{' '}
                    </Button>
                  </Flex>
                ) : (
                  <Box>
                    <Text color="brand.3" fontWeight={'semibold'} mb={5}>
                      Question {selectedQuestionIndex + 1} of{' '}
                      {quizQuestions.length}{' '}
                    </Text>

                    <Box
                      fontSize={15}
                      color="brand.subtitle"
                      textAlign="justify"
                      mb={4}
                    >
                      {
                        selectedLesson?.quiz?.questions?.at(
                          selectedQuestionIndex,
                        )?.question
                      }
                    </Box>

                    <Box px={4} mb={8}>
                      <RadioGroup
                        onChange={setQuizAnswerId}
                        value={quizAnswerId}
                      >
                        <OrderedList
                          type="A"
                          listStyleType="upper-alpha"
                          spacing={4}
                          fontSize={14}
                        >
                          {selectedLesson?.quiz?.questions
                            ?.at(selectedQuestionIndex)
                            ?.answers?.map((eachOption, index) => (
                              <ListItem
                                key={index}
                                pl={2}
                                color="brand.subtitle"
                              >
                                <Radio
                                  value={eachOption.quiz_answer_id}
                                  colorScheme="brand"
                                  size={'sm'}
                                  spacing={3}
                                >
                                  {eachOption.answer}
                                </Radio>
                              </ListItem>
                            ))}
                        </OrderedList>
                      </RadioGroup>
                    </Box>

                    <Flex justifyContent="flex-end" px={10}>
                      <Button
                        colorScheme="brand"
                        size="sm"
                        isLoading={isSubmittingAnswer}
                        fontSize={11}
                        onClick={() =>
                          submitQuizAnswer(
                            selectedLesson?.quiz?.quizTaken?.at(0)
                              ?.quiz_taken_id as string,
                            selectedLesson?.quiz?.questions?.at(
                              selectedQuestionIndex,
                            )?.quiz_question_id as string,
                            quizAnswerId as string,
                            selectedLesson?.lesson_id as string,
                          )
                        }
                        borderRadius={0}
                        px={10}
                      >
                        {' '}
                        NEXT{' '}
                      </Button>
                    </Flex>
                  </Box>
                )}
              </Box>
            )}

            {isExam && (
              <Box
                boxShadow="base"
                bg="white"
                px={10}
                minHeight={'55vh'}
                pt={10}
              >
                {showExamResult ? (
                  <Flex
                    direction={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    height="100%"
                  >
                    <Text mb={4} mt={10} color="brand.subtitle" fontSize={14}>
                      You have completed the exam
                    </Text>

                    <Text
                      mb={2}
                      fontWeight={'semibold'}
                      fontSize={18}
                      color="brand.subtitle"
                    >
                      Your score is
                    </Text>

                    <Text
                      mb={8}
                      color="success.500"
                      fontWeight={'bold'}
                      fontSize={30}
                    >
                      {' '}
                      {examScore?.score}/{examScore?.total}{' '}
                    </Text>

                    <Button
                      mb={4}
                      colorScheme="brand"
                      size="sm"
                      fontSize={11}
                      onClick={() => submitCourseCompleted()}
                      borderRadius={0}
                      px={6}
                    >
                      {' '}
                      Complete Course{' '}
                    </Button>
                  </Flex>
                ) : (
                  <Box>
                    <Text color="brand.3" fontWeight={'semibold'} mb={5}>
                      Question {selectedExamQuestionIndex + 1} of{' '}
                      {examQuestions.length}{' '}
                    </Text>

                    <Box
                      fontSize={15}
                      color="brand.subtitle"
                      textAlign="justify"
                      mb={4}
                    >
                      {examQuestions.at(selectedExamQuestionIndex)?.question}
                    </Box>

                    <Box px={4} mb={8}>
                      <RadioGroup
                        onChange={setExamAnswerId}
                        value={examAnswerId}
                      >
                        <OrderedList
                          type="A"
                          listStyleType="upper-alpha"
                          spacing={4}
                          fontSize={14}
                        >
                          {examQuestions
                            ?.at(selectedExamQuestionIndex)
                            ?.answers?.map((eachOption, index) => (
                              <ListItem
                                key={index}
                                pl={2}
                                color="brand.subtitle"
                              >
                                <Radio
                                  value={eachOption.quiz_answer_id}
                                  colorScheme="brand"
                                  size={'sm'}
                                  spacing={3}
                                >
                                  {eachOption.answer}
                                </Radio>
                              </ListItem>
                            ))}
                        </OrderedList>
                      </RadioGroup>
                    </Box>

                    <Flex justifyContent="flex-end" px={10}>
                      <Button
                        colorScheme="brand"
                        size="sm"
                        isLoading={isSubmittingAnswer}
                        fontSize={11}
                        onClick={() => submitExamAnswer(
                          exam?.quizTaken?.at(0)
                            ?.quiz_taken_id as string,
                          exam?.questions?.at(
                            selectedExamQuestionIndex,
                          )?.quiz_question_id as string,
                          examAnswerId as string,
                          selectedLesson?.lesson_id as string,
                        )}
                        borderRadius={0}
                        px={10}
                      >
                        {' '}
                        NEXT{' '}
                      </Button>
                    </Flex>
                  </Box>
                )}
              </Box>
            )}

            {selectedLesson?.description && (
              <Box
                fontSize={13}
                px={6}
                border={'1px'}
                borderRadius={3}
                py={4}
                borderColor={'brand.borderBox'}
                mt={4}
              >
                {selectedLesson?.description}
              </Box>
            )}
          </Box>
        )}
        <Flex justifyContent='space-between'>
          {courseLessons.length > 0 && isVideo && resumeTime &&
            (
              <Button
                colorScheme="brand"
                onClick={() => resume()}
                size="sm"
                fontSize={11}
                borderRadius={0}
                px={6}
              >resume</Button>)
          }
          {courseLessons.length > 0 && (

            <Flex justifyContent="flex-end">
              {!isQuiz && !isExam && isVideo && (
                <Button
                  colorScheme="brand"
                  onClick={() => (isSlide ? nextLesson(true) : nextLesson(true))}
                  size="sm"
                  disabled={videoInProgess}
                  fontSize={11}
                  borderRadius={0}
                  px={6}
                >
                  {' '}
                  {selectedLessonIndex >= courseLessons.length - 1 && !hasExam
                    ? 'COMPLETE LESSON'
                    : selectedLessonIndex >= courseLessons.length - 1 && hasExam
                      ? 'TAKE EXAM'
                      : 'NEXT LESSON'}{' '}
                </Button>
              )}
            </Flex>
          )}

          {courseLessons.length > 0 && (

            <Flex justifyContent="flex-end">
              {!isQuiz && !isExam && !isVideo && (
                <Button
                  colorScheme="brand"
                  onClick={() => (isSlide ? nextLesson(true) : nextLesson(true))}
                  size="sm"
                  fontSize={11}
                  borderRadius={0}
                  px={6}
                >
                  {' '}
                  {selectedLessonIndex >= courseLessons.length - 1 && !hasExam
                    ? 'COMPLETE LESSON'
                    : selectedLessonIndex >= courseLessons.length - 1 && hasExam
                      ? 'TAKE EXAM'
                      : 'NEXT LESSON'}{' '}
                </Button>
              )}
            </Flex>
          )}

        </Flex>



        <Portal>
          {' '}
          <CourseCompletedModal
            isOpen={isOpen}
            onClose={onClose}
            isCourseId={course_ID}
            submitCourseReview={submitCourseReview}
            isSubmittingReview={isSubmittingReview}
          />{' '}
        </Portal>
      </Container>
    </>
  )
}

export default LessonsPage
