import { Box, Button, Container, Divider, Flex, FormControl, FormErrorMessage, FormLabel, HStack, Input, Text, useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import { useContext } from "react";
import { IPasswordDto } from "../../models/auth.model";
import { httpStatusCode } from "../../models/httpStatus.model";
import { updateEmail, updatePassword } from "../../services/auth.service";
import { StoreContext } from "../../store/StoreContext";

const Security = () => {

    const toast = useToast();

    const {actions, globalState} = useContext(StoreContext)

    const passwordUpdateFormik = useFormik({
        initialValues:{
            currentPassword: '',
            newPassword: '',
            cNewPassword: ''
        },
        validate: (values: any) => {
            const errors: any = {};
            if(!values.currentPassword){
                errors.currentPassword = 'Current Password is required';
            }
            if(!values.newPassword){
                errors.newPassword = 'New Password is required'
            }
            if(values.newPassword.length < 8){
                errors.newPassword = 'Minimum of 8 character is required'
            }
            if(values.newPassword !== values.cNewPassword){
                errors.cNewPassword = 'New Password and Confirm New Password do not Match'
            }
            return errors;
        },
        onSubmit: async (values) => {

            try {

                let data: IPasswordDto = {
                    password: values.newPassword,
                    oldPassword: values.currentPassword
                }

                const response = await updatePassword(data); 

                if(response.statusCode === httpStatusCode.OK){

                    toast({
                        title: "Password Update",
                        description: response.message,
                        status: "success",
                    })
                }                
            } catch (error: any) {
                toast({
                    title: "Profile Update",
                    description: error.data.error,
                    status: "error",
                })
            }

        }
    })

    const emailUpdateFormik = useFormik({
        initialValues: {email: ''},
        validate: (values: any) => {
            const errors: any = {};
            if(!values.email){
                errors.email = 'Email is required';
            }else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
                errors.email = 'Invalid email address';
            }
            return errors;
        },
        onSubmit: async (values) => {

            try {
                 const response = await updateEmail({email: values.email});
                
                 if(response.statusCode === httpStatusCode.OK){

                    actions?.updateProfile(response.data);

                    toast({
                        title: "Email Update",
                        description: response.message,
                        status: "success",
                        duration: 2000,
                    })
                }
            } catch (error: any) {
                toast({
                    title: "Email Update",
                    description: error.data.error,
                    status: "error",
                })
            }
        }
    })

    return (
        <>
            <Container boxShadow="base" p={3}  maxW="container.xl"  mb={6} bg="white" pb={12}>

                <Flex direction="column" px={6} mb={8}>
                    <Text color="brand.3" fontSize={18} fontWeight="semibold" > Security</Text>
                </Flex>

                <Box mx={6} mt={3}>

                    <HStack mb={6}>
                        <Box mr={6}>
                            <Text color="brand.2" fontSize={14} fontWeight="semibold">Two-Factor Authentication</Text>
                            <Text mt={1} color="brand.subtitle" fontSize={12}>Protect your account by enabling a two-factor authentication </Text>
                        </Box>
    
                        <Button mt={4} colorScheme="brand" size="sm" px={10} fontSize={12} > Enable </Button> 

                    </HStack>
                    
                    <Text color="brand.2" fontSize={14} fontWeight="semibold">Email Address</Text>
                    <Text mt={1} color="brand.subtitle" fontSize={12}>Your current email address is <Box as="span" color="brand.2"> {globalState.profile?.email} </Box> </Text>

                    <form onSubmit={emailUpdateFormik.handleSubmit}>
                        <FormControl id="email" mt={4} isInvalid={(emailUpdateFormik.errors.email && emailUpdateFormik.touched.email) ? true : false}>
                            <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>New email address</FormLabel>
                            <HStack>
                                <Input placeholder="" name="email" onChange={emailUpdateFormik.handleChange} fontSize={12} width="29%" mr={6} />
                                <Button colorScheme="brand" type="submit" size="sm" px={10} fontSize={12} > Update </Button> 
                            </HStack>
                            <FormErrorMessage fontSize={12} >{emailUpdateFormik.errors.email}</FormErrorMessage>
                        </FormControl>
                    </form>

                    <Divider my={5} orientation="horizontal" color="#D5D5D5" border="1px"  />

                    <form onSubmit={passwordUpdateFormik.handleSubmit}>

                        <Text color="brand.2" fontSize={14} fontWeight="semibold">Change Password</Text>
                        <Text mt={1} color="brand.subtitle" fontSize={12}>Change your password below </Text>

                        <FormControl id="password" mt={4} isInvalid={(passwordUpdateFormik.errors.currentPassword && passwordUpdateFormik.touched.currentPassword) ? true : false}>
                            <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>Current Password</FormLabel>
                            <Input placeholder="" type="password" name="currentPassword" onChange={passwordUpdateFormik.handleChange} fontSize={12} width="29%" />
                            <FormErrorMessage fontSize={12} >{passwordUpdateFormik.errors.currentPassword}</FormErrorMessage>
                        </FormControl>
                        
                        <FormControl id="newPassword" mt={4} isInvalid={(passwordUpdateFormik.errors.newPassword && passwordUpdateFormik.touched.newPassword) ? true : false}>
                            <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>New Password</FormLabel>
                            <Input placeholder="" type="password" name="newPassword" onChange={passwordUpdateFormik.handleChange} fontSize={12} width="29%" />
                            <FormErrorMessage fontSize={12} >{passwordUpdateFormik.errors.newPassword}</FormErrorMessage>
                        </FormControl>
                        
                        <FormControl id="cNewPassword" mt={4} isInvalid={(passwordUpdateFormik.errors.cNewPassword && passwordUpdateFormik.touched.cNewPassword) ? true : false}>
                            <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>Confirm New Password</FormLabel>
                            <Input placeholder="" type="password" name="cNewPassword" onChange={passwordUpdateFormik.handleChange} fontSize={12} width="29%" />
                            <FormErrorMessage fontSize={12} >{passwordUpdateFormik.errors.cNewPassword}</FormErrorMessage>
                        </FormControl>

                        <Button mt={6} colorScheme="brand" type="submit" size="sm" px={10} isLoading={passwordUpdateFormik.isSubmitting} fontSize={12} > Save Password </Button> 

                    </form>

                </Box>
            
            </Container>
        </>
    );
}

export default Security;