import {Flex, Image, HStack, Box} from '@chakra-ui/react';
import blucanary from '../../img/logo2.png';
import roleSettingImg from '../../img/role_settings.svg';

const RoleSettingLayout = (props: any) => {

    return (
        <Flex minHeight="100vh" >

            <HStack alignItems="center" width="100%" spacing={40}>

                <Flex direction="column" bg="white" height="100vh" justifyContent="center" width="100%" >

                    <Box pl={{base: "5em", lg: "10em", xl: "10em"}} mb={6}>
                        <Image src={blucanary} width="8rem"  />
                    </Box>

                    <Box pl={{base: "5em", lg: "10em", xl: "10em"}} pr={{base: "0em", lg: "8em", xl: "8em"}} mt={10}>
                        {props.children}
                    </Box>
                
                </Flex>

                <Box width={{ base: "0%",  md: "20%", lg: "50%", xl: "100%" }}>
                    <Image src={roleSettingImg} width="30em" />
                </Box>

            </HStack>
           
        </Flex>
    );
}

export default RoleSettingLayout;