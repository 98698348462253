import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { BiBookAdd, BiBookBookmark } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import { TrainingMgrRoutes } from "../../../pages/TrainingManager/routes";

export const EmptyCourseTM: React.FC<any> = () => {
    let {push} = useHistory();

    return (
        <Flex minHeight="inherit"  justifyContent="center">
        
            <Flex direction="column" alignItems="center" justifyContent="center" alignContent="center" >

                <Box color="brand.subtitle" mb={4}>
                    <BiBookBookmark size={40} />
                </Box>

                <Text mb={4} fontSize={13} color="brand.subtitle"> There are currently no course added </Text>
                
                <Button colorScheme="brand" leftIcon={<BiBookAdd />} onClick={() => push(TrainingMgrRoutes.Course.Create)} size="sm" fontSize={12}>Add New Course</Button>

            </Flex>
        </Flex>
    );
}