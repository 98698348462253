import { Box, Button, Container, Flex, HStack, Image, Input, InputGroup, Text, InputRightElement, IconButton, Tag } from "@chakra-ui/react";
import { Table, Thead, Tbody, Tr, Th, Td} from "@chakra-ui/react"
import { BiBookAdd } from "react-icons/bi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { RiSearch2Line } from "react-icons/ri";
import { AiFillStar } from "react-icons/ai";
import { CourseActiveStatus } from "../../components/Common/CourseActiveStatus";
import { TrainingMgrRoutes } from "./routes";
import { EmptyCourseTM } from "../../components/TrainingManager/EmptyState/Courses";
import { ChangeEvent, useEffect, useState } from "react";
import { fetchCourseStatus } from "../../services/trainingMgr/course-http.service";
import { courseStatusListUtil } from "../../utils/course.util";
import { ICourseStatusList } from "../../models/course.model";
import { ScreenLoader } from "../../components/Common/ScreenLoader";
import { EmptySearchResult } from "../../components/Common/EmptySearchResult";


const CourseStatus = () => {

    let {replace} = useHistory();

    const [isSearchEmpty, setIsSearchEmpty] = useState(false);

    const [isLoading, setIsLoading] = useState(true);

    const [coursesStatues, setCoursesStatues] = useState<ICourseStatusList[]>([]);
    
    const [filteredCoursesStatues, setFilteredCoursesStatues] = useState<ICourseStatusList[]>([]);

    useEffect(() => {

        fetchCourseStatus()
         .then((data) => {

            setCoursesStatues(courseStatusListUtil(data))
            
            setFilteredCoursesStatues(courseStatusListUtil(data))

            setIsLoading(false);
        })
        .catch((err) => err)

    }, []);

    const handleSearchChange  = (event: ChangeEvent<HTMLInputElement>) => {
        
        let searchTerm = event.target.value.toLowerCase();
        
        let filtered = coursesStatues.filter(each => each.title.toLowerCase().includes(searchTerm) );
        
        setFilteredCoursesStatues(filtered);
        
        if(filtered.length > 0)
            setIsSearchEmpty(false);
        else
            setIsSearchEmpty(true)
    }

    return (

        <Container boxShadow="base" maxW="container.xl" bg="white" pt={3} pb={10} px={8} minHeight="70vh" mb={4}>

            {
                isLoading? 
                <ScreenLoader />
                
                :

                coursesStatues.length === 0 ? 
                <EmptyCourseTM />
                :
                <Box>

                    <Flex justifyContent="space-between">
                        <Text pl={6} color="brand.3" fontSize={18} fontWeight="semibold"> Course Status </Text>
                        <Button colorScheme="brand" leftIcon={<BiBookAdd fontSize={14} />} onClick={() => replace(TrainingMgrRoutes.Course.Create)} size="sm" fontSize={10}>NEW COURSE</Button>
                    </Flex>

                    <Flex justifyContent="space-between" alignItems="center" px={6} mt={6}>
                        <InputGroup color="brand.subtitle" bg="white" width="40%" >
                            <Input placeholder="Search all courses" fontSize={12} onChange={ e => handleSearchChange(e)} size="sm" />

                            <InputRightElement h="1.9rem" pointerEvents="none" children={<RiSearch2Line size="0.9rem"  />} />
                        </InputGroup>
                    </Flex>

                    {
                        filteredCoursesStatues.length === 0 ? 
                        
                        <Box pt={20}>
                            <EmptySearchResult />
                        </Box>

                        :

                        <Table variant="simple" mt={5} >
                            <Thead fontWeight="normal" >
                                <Tr color="brand.subtitle" >
                                    <Th textTransform="none">Courses</Th>
                                    <Th textTransform="none">Start Date</Th>
                                    <Th textTransform="none">End Date </Th>
                                    <Th textTransform="none">Status</Th>
                                    <Th textTransform="none"></Th>
                                </Tr>
                            </Thead>

                            <Tbody>
                                {
                                    filteredCoursesStatues.map((each, index) => 
                                        <Tr key={index} fontSize={13} color="brand.subtitle">
                                            <Td>
                                            <HStack>
                                                    <Image src={each.coverImage} fallbackSrc="https://via.placeholder.com/150" height="auto" maxWidth="70px" />
                                                    <Flex direction="column" alignItems="start" >
                                                        
                                                        <Text fontWeight="semibold" fontSize={13}>{each.title}</Text>
                                                        
                                                        <Text fontSize={11}> by <Box color="brand.2" as="span">{each.author}</Box></Text>
                                                        
                                                        <HStack spacing={0.5} fontSize={10}  justifyContent="center" alignItems="center">
                                                            {
                                                                Array(5).fill("").map((val, index) => <AiFillStar key={index} color={Math.floor(each.star) > index ? "#E9A426" : "#CACDD8"}/> )
                                                            }
                                                            <Box as="span" pl={1} color="brand.subtitle" pr={2}>
                                                                {each.rating}
                                                            </Box>
                                                            <Tag  mt={2} colorScheme={each.isLive? "success": "danger"} variant="outline" borderRadius={2} px={3} minHeight={0} lineHeight={1.8} fontSize={8}> {each.isLive? "Live" : "Declined"} </Tag>
                                                        </HStack >
                                                        
                                                    </Flex>
                                                </HStack>                                            
                                            </Td>
                                            <Td fontSize={12} whiteSpace="nowrap">{each.startDate? each.startAt : '--'}</Td>
                                            <Td fontSize={12} whiteSpace="nowrap">{each.endDate? each.endAt : '--'}</Td>
                                            <Td width="12em"><CourseActiveStatus startDate={each.startDate as string} endDate={each.endDate as string} /></Td>
                                            <Td> 
                                                <IconButton
                                                    variant="ghost"
                                                    aria-label="dots"
                                                    borderRadius={20}
                                                    size="xs"
                                                    icon={<BsThreeDotsVertical />}       
                                                    fontSize={16}                                             
                                                /> 
                                            </Td>
                                        </Tr>
                                    )
                                }
                                
                            </Tbody>
                        </Table>
                    }
                    
                </Box> 
            }

        </Container>
   
   );
}

export default CourseStatus;