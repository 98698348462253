import Button from './components/Button';
import Input from './components/Input';
import Progress from './components/Progress';
import Link from './components/Link';
import "@fontsource/inter"

const styles =  {
  fonts: {
    heading: `"inter", sans-serif`,
    body: `"inter", sans-serif`,
  },
  colors: {
    brand: {
      1: "#EBF0FF",
      2: "#4E78F5",
      3: "#2F2963",
      bodyText: "#7883A7",
      50: "#ecf0fe",
      100: "#d4defc",
      500: "#4E78F5",
      600: "#4E78F5",
      800: "white",
      title: "#576AA7",
      subtitle: "#7883A7",
      progress: "#33DAFF",
      border: "#D5D5D5",
      star: "#E9A426",
      borderBox: "#EBEEF8",
      coverImage: "#f3f2fc",
      grey: "#FDFDFD"
    },
    checkBox:{
      500: "#7883A7"
    },
    tag:{
      100: "#4E78F5",
      800: "white"
    },
    inputText: {
      500: "#F3F2FC"
    },
    success: {
      100: "#19CB98",
      500: "#19CB98",
      800: "white"
    },
    danger:{
      100: "#E53F3C",
      300: "#FE7E62",
      500: "#E53F3C",
      600: "#E53F3C",
      800: "white"
    },
    warn:{
      100: "#F0CA45",
      300: '#FFC107',
      500: "#F0CA45",
      800: "#2F2963"
    },
    info:{
      100: "#F4F7FF"
    }

  },
  styles: {
    global: {
      // styles for the `body`
      body: {        
        bg: "#FCFCFC",
      },
      a: {
        _hover: {
          textDecoration: "none",
        },
      },
    },
  },
  components:{
      Button,
      Progress,
      Input,
      Link
  }
}

export default styles;