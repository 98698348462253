import { Box, HStack, Textarea, Text, FormControl, Input, FormLabel, Select, InputGroup, Button, Flex, FormErrorMessage, useToast } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { ICategory, ICourse, ICourseDto, IFormat, ILevel } from '../../../models/course.model';
import { ICourseInfoState } from '../../../models/create_course.model';
import { getLocalUserProfile } from '../../../services/auth.service';
import { fetchCourseFormData, submitCourseInfo, updateCourseInfo } from '../../../services/instructor/courses-http.service';
import { courseCategoriesFilter } from '../../../utils/course.util';
import Setting from './Settings';
import { Editor, EditorState } from 'draft-js';
import RichTextEditor from '../../Common/RichTextEditor';

const CourseInfo = ({ nextPage, ...rest }: { nextPage: any }) => {

    const toast = useToast();

    const [categories, setcategories] = useState<ICategory[]>([]);

    const [level, setlevel] = useState<ILevel[]>([]);

    const [format, setformat] = useState<IFormat[]>([]);

    const [formatTitle, setformatTitle] = useState<string>();
    const [courseTitle, setCourseTitle] = useState<string | undefined>('')
    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );

    const mountedRef = useRef(true)

    const courseFormatRef = useRef<HTMLSelectElement>(null)

    const user = getLocalUserProfile()


    useEffect(() => {

        fetchCourseFormData()
            .then((data) => {

                if (!mountedRef.current) return;

                setcategories(courseCategoriesFilter(data.categories, user?.unitId as number));
                setlevel(data.level);
                setformat(data.format);

                let storedData = localStorage.getItem('course-info');

                if (storedData) {
                    let parsedData: ICourseInfoState = JSON.parse(storedData);

                    formik.setValues({
                        ...parsedData
                    })

                    let formatFiltered = data.format.filter(each => each.format_id === parsedData.courseFormat);
                    setformatTitle(formatFiltered.at(0)?.title)
                }
            })
            .catch((err) => err)

        return () => { mountedRef.current = false }

    }, []);
    console.log(editorState)

    const formik = useFormik({
        validate: (values: any) => {
            const errors: any = {};
            if (!values.courseTitle) {
                errors.courseTitle = 'Course Title is required';
            }
            if (!values.courseCategory) {
                errors.courseCategory = 'Course Category is required'
            }
            if (!values.courseDescription) {
                errors.courseDescription = 'Course Description is required'
            }
            if (!values.courseLevel) {
                errors.courseLevel = 'Course Level is required'
            }
            if (!values.courseFormat) {
                errors.courseFormat = 'Course Format is required'
            }
            if (!values.startDate) {
                errors.startDate = 'Start date is required'
            }
            if (!values.endDate) {
                errors.endDate = 'End date is required'
            }
            if (values.startDate || values.endDate) {
                if (values.endDate < values.startDate)
                    errors.endDate = 'Date Range is not Valid'
                else if (new Date(values.endDate).getTime() < new Date().getTime())
                    errors.endDate = 'Course End Date must be greater than Current Date'
            }
            return errors;
        },
        initialValues: {
            courseTitle: '',
            courseCategory: '',
            courseLevel: '',
            courseFormat: '',
            startDate: '',
            endDate: '',
            courseDescription: ''
        },
        onSubmit: async (values) => {

            let course: ICourse;

            let courseInfo: ICourseDto = {
                title: values.courseTitle,
                categoryId: values.courseCategory,
                courseFormatId: values.courseFormat,
                courseLevelId: values.courseLevel,
                description: values.courseDescription,
                startDate: values.startDate,
                endDate: values.endDate
            }
            localStorage.setItem('course-title', courseInfo.title);
            console.log(categories, "categories body")

            try {
                let courseId = localStorage.getItem('course-id')
                let categoryId = localStorage.getItem('category-id')

                console.log(categoryId, "category id from local storage")

                if (courseId) {
                    courseInfo.courseId = courseId;
                    // courseInfo.categoryId = categoryId;

                    course = await updateCourseInfo(courseInfo);
                }
                else {
                    course = await submitCourseInfo(courseInfo);

                    localStorage.setItem('course-id', course.course_id);
                }

                let localStateData: ICourseInfoState = { ...values, courseFormatTitle: formatTitle?.trim() as string }

                localStorage.setItem('course-info', JSON.stringify(localStateData));

                nextPage();

            } catch (error) {

                localStorage.removeItem('course-info');

                toast({
                    title: "Course Information",
                    description: "Something went Wrong. Try again later",
                    status: "error",
                })
            }
        }
    })

    const pickSourseFormat = (e: React.ChangeEvent<HTMLSelectElement>) => {
        let selectedIndex = e.currentTarget.selectedIndex;
        console.log(selectedIndex,'selected index')
        let title = e.target[selectedIndex].textContent as string;
        setformatTitle(title);
    }


    return (
        <Box px={6} >

            <form onSubmit={formik.handleSubmit}>

                <FormControl isInvalid={(formik.errors.courseTitle && formik.touched.courseTitle) ? true : false} >
                    <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>Course Title</FormLabel>
                    <Input bg="inputText.500" placeholder="Enter a title for your course" value={formik.values.courseTitle} name="courseTitle" onChange={formik.handleChange} fontSize={12} />
                    <FormErrorMessage fontSize={12} >{formik.errors.courseTitle}</FormErrorMessage>
                </FormControl>

                <FormControl mt={6} isInvalid={(formik.errors.courseCategory && formik.touched.courseCategory) ? true : false}>
                    <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>Course Category</FormLabel>
                    <Select textTransform='lowercase' variant="outline" bg="inputText.500" placeholder="Select a category" value={formik.values.courseCategory} name="courseCategory" onChange={formik.handleChange} size="sm" fontSize={12} color="brand.subtitle" >
                        {
                            categories.map(each => <option key={each.id} value={each.category_id}> {each.title} </option>)
                        }
                    </Select>
                    <FormErrorMessage fontSize={12} >{formik.errors.courseCategory}</FormErrorMessage>
                </FormControl>

                <HStack mt={6} spacing={10}>
                    <FormControl isInvalid={(formik.errors.courseLevel && formik.touched.courseLevel) ? true : false}>
                        <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>Course Level</FormLabel>
                        <Select variant="outline" textTransform='lowercase' bg="inputText.500" size="sm" placeholder="Select a Level" value={formik.values.courseLevel} name="courseLevel" onChange={formik.handleChange} fontSize={12} color="brand.subtitle" >
                            {
                                level.map(each => <option key={each.id} value={each.level_id}> {each.title} </option>)
                            }
                        </Select>
                        <FormErrorMessage fontSize={12} >{formik.errors.courseLevel}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={(formik.errors.courseFormat && formik.touched.courseFormat) ? true : false}>
                        <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>Format</FormLabel>
                        <Select ref={courseFormatRef} variant="outline" bg="inputText.500" size="sm" fontSize={12} placeholder="Select a Format" value={formik.values.courseFormat} name="courseFormat" onChange={(e) => { formik.handleChange(e); pickSourseFormat(e) }} color="brand.subtitle" >
                            {
                                format.map(each => <option key={each.id} value={each.format_id}> {each.title} </option>)
                            }
                        </Select>
                        <FormErrorMessage fontSize={12} >{formik.errors.courseFormat}</FormErrorMessage>
                    </FormControl>

                    <FormControl>
                        <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>Language</FormLabel>
                        <Select variant="outline" bg="inputText.500" placeholder="English (US)" size="sm" fontSize={12} color="brand.subtitle" />
                    </FormControl>

                </HStack>

                <HStack mt={6} spacing={10}>
                    <FormControl >
                        <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>Course Start Date</FormLabel>
                        <InputGroup>
                            <Input placeholder="Select Start Date" type="date" bg="inputText.500" name="startDate" value={formik.values.startDate} onChange={formik.handleChange} fontSize={12} />
                            {/* <InputRightElement pointerEvents="none" children={<VscCalendar fontSize={20} color="#7883A7" />} /> */}
                        </InputGroup>
                    </FormControl>

                    <FormControl isInvalid={(formik.errors.endDate && formik.touched.endDate) ? true : false}>
                        <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>Course End Date</FormLabel>
                        <InputGroup>
                            <Input placeholder="Select End Date" type="date" bg="inputText.500" name="endDate" value={formik.values.endDate} onChange={formik.handleChange} fontSize={12} />
                            {/* <InputRightElement pointerEvents="none" children={<VscCalendar fontSize={20} color="#7883A7" />} /> */}
                        </InputGroup>
                        <FormErrorMessage fontSize={12} >{formik.errors.endDate}</FormErrorMessage>
                    </FormControl>

                </HStack>

                <FormControl id="groupDescription" mt={6} isInvalid={(formik.errors.courseDescription && formik.touched.courseDescription) ? true : false}>
                    <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>Course Description </FormLabel>
                    <Textarea
                        p={4}
                        bg="inputText.500"
                        fontSize={12}
                        size="sm"
                        minH="10rem"
                        placeholder="Write a description"
                        name="courseDescription"
                        onChange={formik.handleChange}
                        value={formik.values.courseDescription}
                    />
                    <FormErrorMessage fontSize={12} >{formik.errors.courseDescription}</FormErrorMessage>

                </FormControl>

                <Flex justifyContent="flex-end" mt={6}>
                    <Button type="submit" colorScheme="brand" size="sm" fontSize={11} isLoading={formik.isSubmitting} borderRadius={10} px={10}>NEXT</Button>
                </Flex>

            </form>
        </Box>
    );
}


export default CourseInfo;