import {
    Pagination,
    PaginationContainer,
    PaginationNext,
    PaginationPage,
    PaginationPageGroup,
    PaginationPrevious,
    usePagination,
} from "@ajna/pagination";
import {
    Box,
    Button,
    Container,
    Flex,
    HStack,
    Image,
    Input,
    InputGroup,
    Tag,
    Text,
    InputRightElement,
    Link,
    IconButton,
    Portal,
    useDisclosure,
    Avatar,
    VStack,
    Badge,
    Spacer,
    useToast,
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Select,
    Progress,
    Checkbox,
    FormLabel,
} from "@chakra-ui/react";

import { ChevronDownIcon } from '@chakra-ui/icons'

import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
} from '@chakra-ui/react'

import React, { ChangeEvent, useCallback, useMemo, useRef } from "react";
import { useReactToPrint } from 'react-to-print'
import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import blucanaryLogo2 from "../../../img/logo2.png";
import { useEffect, useState } from "react";
import { ScreenLoader } from "../../../components/Common/ScreenLoader";
import { useHistory } from "react-router-dom";
import { RiSearch2Line } from "react-icons/ri";
import { EmptyCourseSearch } from "../../../components/Student/EmptyState/EmptyCourseSearch";
import { IoMdCreate } from "react-icons/io";
import { BsCheckLg, BsFillStarFill, BsThreeDotsVertical } from "react-icons/bs";
import { IDepartmentData, IEachCourseData, Ipage } from "../../../models/auth.model";
import { fetchDepartment, editDepartment, fetchAllEnrolments } from "../../../services/hr/department/department.service";
import { HrAdminRoutes } from "../routes";
import { fetchStudentEnrolment } from "../../../services/student/course-http.service";
import PaginationData from "../../../components/Common/pagination/PaginationData";
import { IAllEnrolmentResult } from "../../../models/enrolment/enrolment.model";
import { IInstructorReportData } from "../../../models/enrolment/instructorReport.model";
import { getFullDate } from "../../../utils/app.util";
import ExportExcel from "../../../utils/ExportExcel";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { FaTimes } from "react-icons/fa";
const InstructorReport = () => {
    let { replace, push } = useHistory();
    const toast = useToast()
    const [isLoading, setIsLoading] = useState(true);
    const [instrcutorList, setinstrcutorList] = useState<IInstructorReportData[]>([]);
    const componentRef = useRef(null)
    const [show, setShow] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(50);
    const [searchQuery, setSearchQuery] = useState("");
    const [checkedItems, setCheckedItems] = useState<boolean[]>([])
    const [checkedIndex, setCheckedIndex] = useState<number[]>([])
    const [checkedUsers, setCheckedUsers] = useState<any[]>([])
    const allChecked = checkedItems.every(Boolean)
    const isIndeterminate = checkedItems.some(Boolean) && !allChecked
    const [deptFilter, setDeptFilter] = useState<number>();
    const [end_date, setend_date] = useState('');
    const [start_date, setstart_date] = useState("");
    const [reload, setReload] = useState(false);
    const [errorMsg, setError] = useState('');
    const [filteredDepartments, setFilteredDepartments] = useState<IDepartmentData[]>([]);
    useEffect(() => {
        let url = ''
        if (deptFilter) {
            url = `?filterBy=department&filter=${deptFilter}`
        } else if (end_date) {
            url = `?filterBy=date&filter=${start_date},${end_date}`
        }

        fetchStudentEnrolment(url)
            .then((data) => {
                setinstrcutorList(data)
                setIsLoading(false)
            })
            .catch((err) => err)
    }, [reload, deptFilter]);

    useEffect(() => {
        let url = `?page=${1}&limit=${50}`
        fetchDepartment(url)
            .then((data) => {

                setFilteredDepartments(data.results)
                setIsLoading(false)

            })
            .catch((err) => err)

    }, []);
    const dateChange = (event: ChangeEvent<HTMLInputElement>) => {
        let date = event.target.value.toLowerCase();

        const fromDateObj = new Date(start_date);
        const toDateObj = new Date(date);

        if (toDateObj < fromDateObj) {
            setError("The 'To' date cannot be earlier than the 'From' date.");
            setend_date('');
        } else {
            setend_date(date);
            setDeptFilter(undefined)
            setReload(!reload);
            setError('')
        }

    };
   const startdateChange = (event: ChangeEvent<HTMLInputElement>) => {
    let date = event.target.value.toLowerCase();
   
    const fromDateObj = new Date(date);
    const toDateObj = new Date(end_date);

    if (toDateObj < fromDateObj) {
      setError("The 'To' date cannot be earlier than the 'From' date.");
    } else {
      setstart_date(date);
      setError('')
    }
  };
    const handleDepartmentChange = (dept: number) => {
        if (dept == 0) {
            setDeptFilter(undefined)
            setend_date('')
        }
        setDeptFilter(dept);
        setend_date('')

    };
    const filteredinstrcutorList = useMemo(() => {
        return instrcutorList.filter((batch) =>
            batch.instructor.toString().toLowerCase().includes(searchQuery.toString().toLowerCase()) ||
            batch.course_title.toString().toLowerCase().includes(searchQuery.toString().toLowerCase())
        );
    }, [instrcutorList, searchQuery]);
    const indexOfLastItem = useMemo(() => currentPage * itemsPerPage, [currentPage, itemsPerPage]);
    const indexOfFirstItem = useMemo(() => indexOfLastItem - itemsPerPage, [indexOfLastItem, itemsPerPage]);

    const currentItems: IInstructorReportData[] = useMemo(() => {
        return filteredinstrcutorList.slice(indexOfFirstItem, indexOfLastItem);
    }, [filteredinstrcutorList, indexOfFirstItem, indexOfLastItem]);

    const resetCheckedItems = useCallback(() => {
        const newCheckedItems = currentItems.map(() => false);
        setCheckedItems(newCheckedItems);
    }, [currentItems]);

    useEffect(() => {
        resetCheckedItems();
    }, [currentItems, resetCheckedItems]);

    const totalPages = Math.ceil(filteredinstrcutorList.length / itemsPerPage);

    const handleItemsPerPageChange = (newItemsPerPage: number) => {
        setItemsPerPage(newItemsPerPage);
        setCurrentPage(1);
    };

    const handlePageChange = (pageNumber: number) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };
    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset to the first page when the search query changes
    };
    const checkAllItems = (e: any) => {

        let checkedArray = checkedItems.map(() => e.target.checked)
        setCheckedItems(checkedArray)
        if (e.target.checked) {
            const newCheckedItems = currentItems.map((each, index) => index);
            const checkedList = filteredinstrcutorList.map((item) => {
                return {
                    Instructor: item.instructor,
                    Title: item.course_title,
                    Category: item.category,
                    Approved: item.course_approved_at ? 'Approved' : 'Unapproved',
                    Declined: item.course_declined_at ? 'Declined' : 'Active',
                    No_of_lesson: item.no_of_lessons,
                    No_of_enrollment: item.no_of_enrollments,
                    Rating: item.rating,
                    Start_date: item.course_start_at && getFullDate(item.course_start_at),
                    Validity_Period: item.course_end_at && getFullDate(item.course_end_at),
                    Date_uploaded: item.course_uploaded_at && getFullDate(item.course_uploaded_at)
                }
            })
            setCheckedUsers(checkedList)
            setCheckedIndex(newCheckedItems)
        } else {
            setCheckedUsers([])
            setCheckedIndex([])
        }

    }

    function updateCheckItems(checked: boolean, index: number, selectedList: IInstructorReportData): void {
        let checkedArray = [...checkedItems]
        checkedArray[index] = checked;
        setCheckedItems(checkedArray);
        const selectedItems = {
            Instructor: selectedList.instructor,
            Title: selectedList.course_title,
            Category: selectedList.category,
            Approved: selectedList.course_approved_at ? 'Approved' : 'Unapproved',
            Declined: selectedList.course_declined_at ? 'Declined' : 'Active',
            No_of_lesson: selectedList.no_of_lessons,
            No_of_enrollment: selectedList.no_of_enrollments,
            Rating: selectedList.rating,
            Start_date: selectedList.course_start_at && getFullDate(selectedList.course_start_at),
            Validity_Period: selectedList.course_end_at && getFullDate(selectedList.course_end_at),
            Date_uploaded: selectedList.course_uploaded_at && getFullDate(selectedList.course_uploaded_at)
        }

        if (checked === true) {
            //setting selected id
            let checkedArrayIndex: any[] = [...checkedIndex];
            checkedArrayIndex.push(index)
            setCheckedIndex(checkedArrayIndex)
            //setting filteredUsers
            let checkedArrayUser: any[] = [...checkedUsers];
            checkedArrayUser.push(selectedItems)
            setCheckedUsers(checkedArrayUser)

        } else if (checked === false) {
            //removing selected id
            let checkedArrayIndex: any[] = [...checkedIndex];
            let ind = checkedArrayIndex.indexOf(index)
            checkedArrayIndex.splice(ind, 1)
            setCheckedIndex(checkedArrayIndex)
            console.log(ind, 'index of index')
            //removing selected Users
            let checkedArrayUser: any[] = [...checkedUsers];
            let indUser = checkedArrayUser.indexOf(selectedItems)
            checkedArrayUser.splice(ind, 1)
            setCheckedUsers([...checkedArrayUser])
        }
    }

    const exportPDF = () => {


        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = `Instructor Report`;
        const headers = [["Instructor", "Course Title", "Category", 'Approved', 'Declined', "#Enrollments",'#Lesson', "Rating", "Course Start Date", "Validity Period", "Date Uploaded"]];

        const dataf = checkedUsers?.map(item => [
            item.Instructor,
            item.Title,
            item.Category,
            item.Approved,
            item.Declined,
            Number(item.No_of_enrollment),
            Number(item.No_of_lesson),
            Number(item.Rating),
            item.Start_date,
            item.Validity_Period,
            item.Date_uploaded
        ]);

        toast({
            title: ' PDF',
            description: 'PDF File downloaded sucessfully',
            status: 'success',
        })

        doc.text(title, marginLeft, 40);
        autoTable(doc, {
            startY: 50,
            head: headers,
            body: dataf as string[][]
        });
        doc.save("Instructor Report.pdf")


    }
    return (
        <Container
            boxShadow="base"
            maxW="container.xl"
            bg="white"
            pt={3}
            pb={10}
            px={1}
            minHeight="70vh"
        >
            {isLoading ? (
                <ScreenLoader />
            ) :
                <Box>
                    <Flex justifyContent={'space-between'}>
                        <Text pl={6} color="brand.3" fontSize={18} fontWeight="semibold">
                            Instructor Report
                        </Text>
                        <Select
                            variant="outline"
                            size="sm"
                            width={'150px'}
                            value={deptFilter}
                            placeholder='By Department'
                            onChange={(e) => handleDepartmentChange(Number(e.target.value))}
                            fontSize={12}
                        >
                            {
                                filteredDepartments.map((dept, index) => (
                                    <option key={index} value={dept.id}>{dept.title.toLowerCase()}</option>

                                ))

                            }
                            <option value={0}>All</option>

                        </Select>
                    </Flex>
                    <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        px={2}
                        mt={4}
                    >
                        <HStack>
                            <InputGroup color="brand.subtitle" bg="white" width="40%">
                                <Input
                                    placeholder="Search by name,course"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    fontSize={12}
                                    size="sm"
                                />
                                <InputRightElement
                                    h="1.9rem"
                                    pointerEvents="none"
                                    children={<RiSearch2Line size="0.9rem" />}
                                />
                            </InputGroup>
                            <HStack>
                                <FormLabel fontSize={'12px'} mt={'5px'}> From:</FormLabel>

                                <Input
                                    width={"200px"}
                                    bg={"white"}
                                    type={"date"}
                                    fontSize={'12px'}
                                    onChange={(e) => startdateChange(e)}
                                />
                            </HStack>
                            <HStack ml={'10px'}>
                                <FormLabel fontSize={'12px'} mt={'5px'}> To:</FormLabel>
                                <Box >
                                    <Input
                                        width={"200px"}
                                        bg={"white"}
                                        type={"date"}
                                        value={end_date}
                                        fontSize={'12px'}
                                        onChange={(e) => dateChange(e)}
                                    />
                                    {errorMsg && <Text color={'red'} fontSize={'10px'}>{errorMsg}</Text>}
                                </Box>

                            </HStack>
                        </HStack>
                        <HStack>
                            <ExportExcel
                                excelData={checkedUsers}
                                fileName={"Instructor Report"}
                            />
                            <Button size={'sm'} fontSize={'12px'} colorScheme={'blue'} variant={'outline'} onClick={exportPDF}>Generate PDF</Button>
                        </HStack>

                    </Flex>

                    {currentItems.length === 0 ? (
                        <EmptyCourseSearch />
                    ) : (
                        <Box className="scrollable-container" ref={componentRef} >
                            {show ? <Image pl={1} mt={4} src={blucanaryLogo2} width="200px" height="auto" /> : <></>}
                            <Table size={'sm'} variant="simple" mt={8} >
                                <Thead fontWeight="normal">
                                    <Tr color="brand.subtitle">

                                        <Th>
                                            <Checkbox mt={4}
                                                isChecked={allChecked}
                                                isIndeterminate={isIndeterminate}
                                                onChange={(e) => checkAllItems(e)}
                                            ></Checkbox>
                                        </Th>

                                        <Th textTransform="none" >
                                            Instructor
                                        </Th>
                                        <Th textTransform="none" >Course Title</Th>
                                        <Th textTransform="none" >Category</Th>
                                        <Th textTransform="none" >Is Approved</Th>
                                        <Th textTransform="none" >Is Declined</Th>
                                        <Th textTransform="none">#Course Lesson</Th>
                                        <Th textTransform="none">#Enrollments</Th>
                                        <Th textTransform="none">Rating</Th>
                                        <Th textTransform="none">Course Start Date</Th>
                                        <Th textTransform="none">Validity Period</Th>
                                        <Th textTransform="none">Date Uploaded</Th>

                                    </Tr>
                                </Thead>

                                <Tbody className="scrollable-content">
                                    {currentItems.map((each, index) => (
                                        <Tr key={index} mt={10} fontSize={'13px'} color="brand.subtitle">
                                            <Td>
                                                <Checkbox mt={10}
                                                    isChecked={checkedItems[index]}
                                                    onChange={(e) =>
                                                        updateCheckItems(
                                                            e.target.checked, index, each
                                                        )
                                                    }
                                                ></Checkbox>
                                            </Td>
                                            <Td  >

                                                <Text color="#4E78F5" fontSize={'13px'} textTransform={'capitalize'} whiteSpace="nowrap">
                                                    {each.instructor ? each.instructor.slice(0, 15).toLowerCase() : "Nil"}{
                                                        each.instructor.length > 15 && '-'
                                                    }
                                                </Text>
                                                <Text color="#4E78F5" fontSize={'13px'} whiteSpace="nowrap">
                                                    {each.instructor ? each.instructor.slice(15, 30).toLowerCase() : "Nil"}
                                                </Text>
                                            </Td>

                                            <Td>
                                                <Box >
                                                    <Text color="#4E78F5" fontSize={'13px'} textTransform={'capitalize'} whiteSpace="nowrap">
                                                        {each.course_title ? each.course_title.slice(0, 15).toLowerCase() : "Nil"}
                                                    </Text>
                                                    <Text color="#4E78F5" fontSize={'13px'} whiteSpace="nowrap">
                                                        {each.course_title ? each.course_title.slice(15, 30).toLowerCase() : "Nil"}
                                                    </Text>
                                                    <Text color="#4E78F5" fontSize={'13px'} whiteSpace="nowrap">
                                                        {each.course_title ? each.course_title.slice(30, 45).toLowerCase() : "Nil"}
                                                    </Text>

                                                </Box>

                                            </Td>

                                            <Td><Text color="#4E78F5" fontSize={'13px'} textTransform={'capitalize'} whiteSpace="nowrap">
                                                {each.category ? each.category : "Nil"}
                                            </Text>
                                            </Td>
                                            <Td>
                                                <Button color={'white'} size={'sm'} fontSize='12px' px={each.course_approved_at ? '10px' : '10px'}
                                                    colorScheme={each.course_approved_at ? 'green' : 'red'}>
                                                    {each.course_approved_at ? 'APPROVED' : 'UNAPPROVED'}
                                                </Button>
                                            </Td>
                                            <Td>
                                                <Button color={'white'} size={'sm'} fontSize='12px' px={each.course_declined_at ? '10px' : '10px'}
                                                    colorScheme={each.course_declined_at ? 'red' : 'green'}>
                                                    {each.course_declined_at ? 'DECLINED' : 'ACTIVE'}
                                                </Button>
                                            </Td>

                                            <Td color="#4E78F5" ><Text color="#4E78F5" fontSize={'13px'} textTransform={'capitalize'} whiteSpace="nowrap">
                                                {each.no_of_lessons ? each.no_of_lessons : 'Nil'}</Text>  </Td>
                                            <Td color="#4E78F5" ><Text color="#4E78F5" fontSize={'13px'} textTransform={'capitalize'} whiteSpace="nowrap">
                                                {each.no_of_enrollments ? each.no_of_enrollments : 'Nil'}</Text>  </Td>
                                            <Td color="#4E78F5" ><Text color="#4E78F5" fontSize={'13px'} textTransform={'capitalize'} whiteSpace="nowrap">
                                                {each.rating ? Math.round((parseFloat(each.rating) + Number.EPSILON) * 100) / 100 : 'Nil'} </Text>  </Td>

                                            <Td><Text color="#4E78F5" fontSize={'13px'} textTransform={'capitalize'} whiteSpace="nowrap">
                                                {each.course_start_at ? getFullDate(each.course_start_at) : 'Nil'}
                                            </Text>
                                            </Td>
                                            <Td><Text color="#4E78F5" fontSize={'13px'} textTransform={'capitalize'} whiteSpace="nowrap">
                                                {each.course_end_at ? getFullDate(each.course_end_at) : 'Nil'}
                                            </Text>
                                            </Td>

                                            <Td color="#4E78F5" > <Text color="#4E78F5" fontSize={'13px'} textTransform={'capitalize'} whiteSpace="nowrap">
                                                {each.course_uploaded_at ? getFullDate(each.course_uploaded_at) : 'Nil'}</Text>  </Td>


                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                            <Flex justifyContent={'flex-end'} mt={'10px'}>
                                <HStack mr={'5px'}>
                                    <Text fontWeight={400} fontSize={'10px'}>Rows per page :</Text>
                                    <Select
                                        variant="outline"
                                        size="sm"
                                        width={'70px'}
                                        value={itemsPerPage}
                                        onChange={(e) => handleItemsPerPageChange(Number(e.target.value))}
                                        fontSize={12}
                                    >
                                        <option value={25}>25</option>
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>

                                    </Select>

                                </HStack>
                                <PaginationData
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    handlePageChange={handlePageChange}
                                />

                            </Flex>
                        </Box>
                    )}
                </Box>
            }



        </Container>
    );
}

export default InstructorReport