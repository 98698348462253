import { Box, Button, Container, Flex, HStack, Image, Input, InputGroup, Tag, Text, InputRightElement, Link, IconButton, Portal, useDisclosure, Avatar, VStack, Badge, toast, useToast, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react"
import { ChangeEvent, useEffect, useState } from "react";
import { ScreenLoader } from "../../../components/Common/ScreenLoader";
import { EmptyUsers } from "../../../components/Hr/EmptyState/Users";
import { HrAdminRoutes } from "../routes";
import { fetchAllUsers, fetchUsers, updateStatus } from "../../../services/hr/users-http.service";
import { fetchByRole } from "../../../services/hr/users-http.service";
import { useHistory } from "react-router-dom";
import { RiSearch2Line } from "react-icons/ri";
import { BiUserPlus } from "react-icons/bi";
import { EmptyCourseSearch } from "../../../components/Student/EmptyState/EmptyCourseSearch";
import { FaEllipsisV } from "react-icons/fa";
import { FaCheck, FaTimesCircle, FaTrash } from "react-icons/fa";
import { EditUserProfileModal } from "../../../components/Hr/EditUserProfileModal";
import { Ipage, IUser, LastSeen } from "../../../models/auth.model";
import { appConstant } from "../../../utils/app.util"
import { Pagination, PaginationContainer, PaginationNext, PaginationPage, PaginationPageGroup, PaginationPrevious, usePagination } from "@ajna/pagination";
import { IAnyUserList } from "../../../models/user.model";

import { PreviewProfileModal } from '../../../components/Hr/PreviewProfileModal'
import { AssignRoleModal } from '../../../components/Hr/AssignRoleModal'
import { fetchAllRoles } from "../../../services/hr/users-http.service";
import { Roles } from "../../../models/roles.model";

export default function AllUsers() {

    let { replace, push } = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [filteredUsers, setFilteredUsers] = useState<IAnyUserList[]>([]);
    const [selectedUser, setSelectedUser] = useState<IUser>();
    const [page, setPage] = useState<Ipage>({ page_total: 0, total: 0 });
    const [isSearchEmpty, setIsSearchEmpty] = useState(false);
    const [search, setSearch] = useState("");
    const [query, setQuery] = useState("");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();
    const [reload, setStatus] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isAssign, setIsAssign] = useState(false);
    const [isPreview, setIsPreview] = useState(false);
    const [fetchedAssigned, setassignRole] = useState<any[]>([]);



    useEffect(() => {
        fetchAllRoles()
            .then((data) => {
                setassignRole(data)
            }).catch((err) => err);


    }, []);



    const {
        currentPage,
        setCurrentPage,
        pagesCount,
        pages
    } = usePagination({
        //pagesCount: isNaN(Math.ceil(page.total / page.page_total)) ? 1 : Math.ceil(page.total / page.page_total),
        initialState: { currentPage: 1, pageSize: 10 },
        total: page.total
    });

    useEffect(() => {
        let url = `?page=${currentPage}&limit=${10}&search=${query}`
        fetchAllUsers(url)
            .then((data) => {
                setPage({ page_total: data.page_total, total: data.total })
                setFilteredUsers(data.results);
                setIsLoading(false)
            })
            .catch((err) => err)

    }, [currentPage, query, reload]);



    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (search.length > 0) {
                setQuery(search);
            } else {
                setQuery("");
            }
        }, 700);

        return () => clearTimeout(delayDebounceFn)
    }, [search])

    const editUser = async (userId: number) => {
        let user = filteredUsers.filter(each => each.id === userId).at(0);
        if (user?.courseEnrollments) {
            delete user.courseEnrollments
        }

        setSelectedUser(user);
        onOpen();
        setIsEdit(true)
        setIsAssign(false)
        setIsPreview(false)

    }

    const previewUser = async (userId: number) => {
        let user = filteredUsers.filter(each => each.id === userId).at(0);

        setSelectedUser(user);
        onOpen();
        setIsEdit(false)
        setIsAssign(false)
        setIsPreview(true)

    }


    const assignRole = async (userId: number) => {
        let user = filteredUsers.filter(each => each.id === userId).at(0);

        setSelectedUser(user);
        onOpen();
        setIsEdit(false)
        setIsAssign(true)
        setIsPreview(false)
    }





    const updatestatus = async (status: number, user_id: number) => {

        const user = [{
            user_id,
            status
        }]

        updateStatus(user)
            .then((data) => {
                setStatus(!reload)
                setIsLoading(false)
                if (status === 1) {
                    toast({
                        title: "Update status",
                        description: "Status enabled successfully",
                        status: "success"
                    })
                } if (status === -1) {
                    toast({
                        title: "Update status",
                        description: "Status disabled successfully",
                        status: "success"
                    })
                }

            })
            .catch((error) =>
                toast({
                    title: error?.statusText ? error?.statusText : "Update status",
                    description: error?.data?.error ? error?.data?.error : "Error ocurred",
                    status: "error",
                })
            )


    };


    const reloadData = () => {
        setStatus(!reload);
    }





    const viewUser = async (userId: number) => {
        push(HrAdminRoutes.Users.ViewLink(userId));
    }
















    return (
        <Container boxShadow="base" maxW="container.xl" bg="white" pt={3} pb={10} px={8} minHeight="70vh">

            {
                isLoading?
                <ScreenLoader />
                
                :
                <Box>
                    <Text pl={6} color="brand.3" fontSize={18} fontWeight="semibold">
                        All Users ({page.total})
                    </Text>

                    <Flex justifyContent="space-between" alignItems="center" px={6} mt={4}>
                        <InputGroup color="brand.subtitle" bg="white" width="40%">
                            <Input placeholder="Search roles on this platform" onChange={ e => setSearch(e.target.value)} fontSize={12} size="sm" />

                            <InputRightElement h="1.9rem" pointerEvents="none" children={<RiSearch2Line size="0.9rem"  />} />
                        </InputGroup>

                        <Button colorScheme="brand" leftIcon={<BiUserPlus />} onClick={() => replace(HrAdminRoutes.Users.Create)} size="sm" fontSize={12}>Create New User</Button>
                    </Flex>

                    {
                        filteredUsers.length === 0 ? 

                        <EmptyCourseSearch />

                        :

                        <Table variant="simple" mt={5} >
                            <Thead fontWeight="normal" >
                                <Tr color="brand.subtitle" >
                                    <Th textTransform="none" textAlign={"start"}>Name</Th>
                                    <Th textTransform="none">Roles</Th>
                                    <Th textTransform="none">Unit</Th>
                                    <Th textTransform="none">Last Seen</Th>
                                    <Th textTransform="none">Actions</Th>
                                </Tr>
                            </Thead>

                            <Tbody>
                                {
                                    filteredUsers.map((each, index) => 
                                        <Tr key={index} fontSize={13} color="brand.subtitle">
                                            <Td role={"button"} onClick={() => viewUser(each.id)}>
                                                <HStack spacing={6} alignItems="center" maxW="fit-content">
                                                    <Avatar  verticalAlign={"center"} size="sm" name={each.full_name} src={each.photo_url} />
                                                    <VStack>
                                                        <Text color="brand.3" whiteSpace="nowrap" isTruncated title={each.full_name}>
                                                            {each.full_name} 
                                                        </Text>
                                                    </VStack>

                                                </HStack>
                                            </Td>

                                            <Td>
                                               {each.userRoles ? each.userRoles.map((role:{title:string}) => ( 
                                               <Badge mr={2} colorScheme={role.title === "STUDENT"? "brand": role.title === "INSTRUCTOR"? "success": role.title === "TRAINING MANAGER"? "danger": role.title === "HR ADMIN"? "yellow" : "primary"} 
                                                    variant={"outline"}
                                                    textTransform={"capitalize"}
                                                    borderRadius = {10} 
                                                    pl = {2}
                                                    pr = {2}
                                                    >
                                                    {role.title}
                                                    
                                                </Badge>)) : "None"}
                                            </Td>
                                            
                                            <Td textTransform={"capitalize"}>{each.unit ? each.unit.title.toLowerCase() : "None"}</Td>
                                            <Td>{each.updatedAt ? new Date(each.updatedAt as string).toDateString() : "Never"}</Td>
                                            <Td>
                                            {each.status === 1 && 
                                            <HStack>
                                                        {/* <IconButton
                                                            title="Disable"
                                                            variant="ghost"
                                                            aria-label="delete"
                                                            color="danger.300"
                                                            borderRadius={20}
                                                            size="xs"
                                                            icon={<FaTrash />}      
                                                            fontSize={14}           
                                                            onClick={() =>  updatestatus(-1,each.id)}                                           
                                                        />   */}

                                                        <Menu>
                                                                <MenuButton
                                                                    as={IconButton}
                                                                    variant="ghost"
                                                                    aria-label="delete"
                                                                    color="danger.300"
                                                                    borderRadius={20}
                                                                    size="xs"
                                                                    icon={<FaTrash />}
                                                                    fontSize={14}
                                                                    onClick={() => updatestatus(-1, each.id)}
                                                                />

                                                                <Menu>
                                                                    <MenuButton
                                                                        as={IconButton}
                                                                        variant="ghost"
                                                                        aria-label="edit"
                                                                        color="success.500"
                                                                        borderRadius={20}
                                                                        size="xs"
                                                                        icon={<FaEllipsisV />}
                                                                        fontSize={16}
                                                                    >
                                                                    </MenuButton>
                                                                    <MenuList fontSize={12}>
                                                                        <MenuItem
                                                                            onClick={() => editUser(each.id)}
                                                                        >
                                                                            Edit
                                                                        </MenuItem>

                                                                        <MenuItem
                                                                            onClick={() => previewUser(each.id)}
                                                                        >
                                                                            Preview
                                                                        </MenuItem>

                                                                        <MenuItem
                                                                            onClick={() => assignRole(each.id)}
                                                                        >
                                                                            Assign Role
                                                                        </MenuItem>
                                                                    </MenuList>
                                                                </Menu>
                                                            </Menu>


                                                            </HStack>
                                                        }
                                                        {each.status === 0 &&
                                                            <HStack>
                                                                <IconButton
                                                                    title="Enable"
                                                                    variant="ghost"
                                                                    aria-label="enable"
                                                                    color="success.500"
                                                                    borderRadius={20}
                                                                    size="xs"
                                                                    icon={<FaCheck />}
                                                                    fontSize={14}
                                                                    onClick={() => updatestatus(1, each.id)}
                                                                />
                                                                <Menu>
                                                                    <MenuButton
                                                                        as={IconButton}
                                                                        variant="ghost"
                                                                        aria-label="edit"
                                                                        color="success.500"
                                                                        borderRadius={20}
                                                                        size="xs"
                                                                        icon={<FaEllipsisV />}
                                                                        fontSize={16}
                                                                    >
                                                                    </MenuButton>
                                                                    <MenuList fontSize={12}>
                                                                        <MenuItem
                                                                            onClick={() => editUser(each.id)}
                                                                        >
                                                                            Edit
                                                                        </MenuItem>

                                                                        <MenuItem
                                                                            onClick={() => previewUser(each.id)}
                                                                        >
                                                                            Preview
                                                                        </MenuItem>

                                                                        <MenuItem
                                                                            onClick={() => assignRole(each.id)}
                                                                        >
                                                                            Assign Role
                                                                        </MenuItem>
                                                                    </MenuList>
                                                                </Menu>


                                                            </HStack>
                                                        }
                                                        {each.status === -1 &&
                                                            <HStack>
                                                                <IconButton
                                                                    title="Enable"
                                                                    variant="ghost"
                                                                    aria-label="enable"
                                                                    color="success.500"
                                                                    borderRadius={20}
                                                                    size="xs"
                                                                    icon={<FaCheck />}
                                                                    fontSize={14}
                                                                    onClick={() => updatestatus(1, each.id)}
                                                                />
                                                                <Menu>
                                                                    <MenuButton
                                                                        as={IconButton}
                                                                        variant="ghost"
                                                                        aria-label="edit"
                                                                        color="success.500"
                                                                        borderRadius={20}
                                                                        size="xs"
                                                                        icon={<FaEllipsisV />}
                                                                        fontSize={16}
                                                                    >
                                                                    </MenuButton>
                                                                    <MenuList fontSize={12}>
                                                                        <MenuItem
                                                                            onClick={() => editUser(each.id)}
                                                                        >
                                                                            Edit
                                                                        </MenuItem>

                                                                        <MenuItem
                                                                            onClick={() => previewUser(each.id)}
                                                                        >
                                                                            Preview
                                                                        </MenuItem>

                                                                        <MenuItem
                                                                            onClick={() => assignRole(each.id)}
                                                                        >
                                                                            Assign Role
                                                                        </MenuItem>
                                                                    </MenuList>
                                                                </Menu>


                                                            </HStack>
                                                        }
                                                    </Td>
                                                </Tr>
                                            )
                                        }

                                    </Tbody>
                                </Table>
                        }
                        <Flex alignContent={"flex-end"} justifyContent={'flex-end'} mt={4}>
                            <Pagination
                                pagesCount={pagesCount}
                                currentPage={currentPage}
                                onPageChange={setCurrentPage}
                            >
                                <PaginationContainer>
                                    <PaginationPrevious>Previous</PaginationPrevious>
                                    <PaginationPageGroup>
                                        {pages.map((page: number) => (
                                            <PaginationPage
                                                key={`pagination_page_${page}`}
                                                page={page}
                                            />
                                        ))}
                                    </PaginationPageGroup>
                                    <PaginationNext>Next</PaginationNext>
                                </PaginationContainer>
                            </Pagination>

                        </Flex>
                    </Box>
            }




            {selectedUser && (
                <><Portal>
                    {' '}
                    <EditUserProfileModal
                        isOpen={isOpen}
                        onClose={onClose}
                        isEdit={isEdit}
                        userData={selectedUser}
                        reloadData={reloadData}
                        isInstructor={selectedUser.role?.role_id === appConstant.role.instructor} />

                    {' '}
                </Portal><Portal>
                        {' '}
                        <PreviewProfileModal
                            isOpen={isOpen}
                            onClose={onClose}
                            isPreview={isPreview}
                            userData={selectedUser}
                            reloadData={reloadData}
                            isInstructor={selectedUser.role?.role_id === appConstant.role.instructor} />

                        {' '}
                    </Portal><Portal>
                        {' '}
                        <AssignRoleModal
                            isOpen={isOpen}
                            onClose={onClose}
                            isAssign={isAssign}
                            roleArray={fetchedAssigned}
                            userData={selectedUser}
                            reloadData={reloadData}
                            isInstructor={selectedUser.role?.role_id === appConstant.role.instructor} />

                        {' '}
                    </Portal>
                </>
            )}









        </Container>
    )
}