import { Box, Button, Container, Divider, Flex, HStack, Link, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { ICourseWithEnrollment, ILearningRules } from "../../../models/course.model";
import { StudentRoutes } from "../../../pages/Student/routes";
import { fetchLearningRules } from "../../../services/shared.service";
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { HiOutlineArrowLeft } from "react-icons/hi";
import { Link as ReactLink } from 'react-router-dom'

const CourseAnnouncement: React.FC<any> = ({courseData}: {courseData: ICourseWithEnrollment}) => {

    let history = useHistory();
    
    const [learningRules, setLearningRules] = useState<ILearningRules>();

    const courseLesson = () => {
        history.push(StudentRoutes.Course.LessonLink(courseData.course?.course_id))
    }

    useEffect(() => {
        fetchLearningRules()
        .then(data => {
            setLearningRules(data);
           

        })
        .catch(e => e)
    }, [])

    return (
        <>  
            <Flex justifyContent={"space-between"}>
                <Text color="brand.3" fontSize={18} fontWeight="semibold">
                    {courseData?.course?.title}
                </Text>
                <Link
                as={ReactLink}
                to={StudentRoutes.Course.Explore}
              >
                <HStack color="brand.2">
                  <HiOutlineArrowLeft />
                  <Text fontSize={13} fontWeight="semibold">
                    {' '}
                    Go back{' '}
                  </Text>
                </HStack>
                </Link>
            </Flex>
           

            <Container boxShadow="sm" p={3}  maxW="container.xl" mt={4}  mb={6} bg="white">
                <Text color="brand.3" fontSize={13}  px={16} textTransform="uppercase" >Welcome to the course </Text>

                <Box fontSize={13} color="brand.subtitle" px={16} pb={6}>
                    <Divider pt={6} />
                    
                    <Text pt={4} >Dear learners,</Text>
                    
                    <Text pt={4}>Welcome to the course: <Box as="span" color="brand.2">{courseData?.course?.title}</Box> </Text>
                    
                    <Text pt={4} textAlign="justify">
                        {courseData?.course?.welcome_message}                       
                    </Text>                    

                </Box>
            </Container>
            
            <Container boxShadow="sm" p={3}  maxW="container.xl" mt={4}  mb={4} bg="white">
                <Text color="brand.3" fontSize={13}  px={16} textTransform="uppercase" >LEARNING RULES </Text>

                <Box fontSize={13} color="brand.subtitle" px={16} pb={6}>
                    <Divider pt={6} />
                    
                    <Text pt={4} >Here are some rules to guide your learning: </Text>

                    
                    
                    <Text pt={4} textAlign="justify" style={{ whiteSpace: "pre-line" }}>
                        {learningRules?.message.split("<br/>").join("\n")}                       
                    </Text>

                </Box>
            </Container>

            <Box display="flex" justifyContent="flex-end" pb={6}>
                <Button mt={8} colorScheme="brand" size="md" onClick={courseLesson} fontSize={12}   >CONTINUE LESSON</Button> 
            </Box>

        </>
    );
}

export default CourseAnnouncement;