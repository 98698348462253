import {
    Box,
    Button,
    Container,
    Flex,
    HStack,
    Image,
    Input,
    InputGroup,
    Tag,
    Text,
    InputRightElement,
    Link,
    IconButton,
    Portal,
    useDisclosure,
    Avatar,
    VStack,
    Badge,
    Spacer,
    useToast,
} from "@chakra-ui/react";

import { ChevronDownIcon } from '@chakra-ui/icons'

import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
} from '@chakra-ui/react'

import React, { ChangeEvent, useRef } from "react";
import { useReactToPrint } from 'react-to-print'

import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { ScreenLoader } from "../../components/Common/ScreenLoader";

import { updateStatus } from "../../services/hr/users-http.service";
import { Link as ReactLink, useHistory, useParams } from "react-router-dom";
import { RiSearch2Line } from "react-icons/ri";
import { EmptyCourseSearch } from "../../components/Student/EmptyState/EmptyCourseSearch";
import { FaLayerGroup } from "react-icons/fa";
import { IEachDepartmentData, Ipage } from "../../models/auth.model";
import {
    Pagination,
    PaginationContainer,
    PaginationNext,
    PaginationPage,
    PaginationPageGroup,
    PaginationPrevious,
    usePagination,
} from "@ajna/pagination";
import { IAnyUserList } from "../../models/user.model";
import { HrAdminRoutes } from "./routes";
import { CreateDepartmentModal } from "../../components/Hr/CreateDepartmentModal";
import { fetchDepartmentByCategoryId, fetchEachDepartmentMember, genExcelDepartment, genExcelEachDepartment } from "../../services/hr/department/department.service";
import { HiOutlineArrowLeft } from "react-icons/hi";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";


export default function ViewEachDepartment() {
    let { category_id } = useParams<{ category_id: string }>()
    let { push } = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [eachDepartment, seteachDepartments] = useState<IEachDepartmentData[]>([]);
    const [filteredDept, setFilteredDepartments] = useState<IEachDepartmentData[]>([]);

    const [departmentTitle, setDepartmentTitle] = useState<string>()
    const [eachdepartmentNo, seteachDepartmentNo] = useState(0);
    const [page, setPage] = useState<Ipage>({ page_total: 0, total: 0 });
    const [search, setSearch] = useState("");
    const [query, setQuery] = useState("");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const componentRef = useRef(null)
    const toast = useToast();
    const [reload] = useState(false);
    const [isDepartment, setDepartment] = useState(false);
    const [isSearchEmpty, setIsSearchEmpty] = useState(false);





    // const newDepartment = async () => {
    //   setDepartment(true)
    //   onOpen();
    // }

    const { currentPage, setCurrentPage, pagesCount, pages } = usePagination({
        initialState: { currentPage: 1, pageSize: 10 },
        total: page.total
    });

    useEffect(() => {
        let url = `?page=${currentPage}&limit=${10}&search=${query}`
        fetchEachDepartmentMember(category_id, url)
            .then((data) => {
                console.log(data, 'data from useeffect')
                seteachDepartmentNo(data.results.length)
                seteachDepartments(data.results)
                setFilteredDepartments(data.results)
                setPage({ page_total: data.page_total, total: data.total })
                setIsLoading(false)

            })
            .catch((err) => err)
        fetchDepartmentByCategoryId(category_id)
            .then((data) => {
                setDepartmentTitle(data.title as string)
            })
    }, [currentPage, query, reload])

    // useEffect(() => {

    //     fetchAllUsers()
    //         .then((data) => {  
    //             setMembers(data.results);
    //           console.log(data.results, 'new members')
    //         })
    //         .catch((err) => err)

    // }, []);

    // const removeMember = (index: number) => {
    //     let user = members.at(index);
    //     setUsers([user as IUser, ...users]);
    //     members?.splice(index, 1)
    //     setMembers([...members])
    // }
    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {

        let searchTerm = event.target.value.toLowerCase();
        console.log(searchTerm)
        if (!searchTerm) {
            setFilteredDepartments(eachDepartment);
        } else {
            let filtered = filteredDept.filter(each => each.full_name.toLowerCase().includes(searchTerm));
            setFilteredDepartments(filtered);
            if (filtered.length > 0)
                setIsSearchEmpty(false);
            else
                setIsSearchEmpty(true)
        }


    }




    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (search.length > 0) {
                console.log(search)
                setQuery(search);
            } else {
                setQuery("");
            }
        }, 700);

        return () => clearTimeout(delayDebounceFn);
    }, [search]);


    const viewUser = async (userId: number) => {
        push(HrAdminRoutes.Users.ViewLinkS(userId))
    }


    function handleExcel() {
        genExcelEachDepartment(category_id)
            .then((response: any) => {
                console.log(response, 'excel response')
                var blob = new Blob(
                    [response],
                    { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" },
                );
                var a = document.createElement("a");
                let url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = "users_report.xlsx";
                a.click();


                if (response) {
                    toast({
                        title: ' Excel',
                        description: 'Excel File downloaded sucessfully',
                        status: 'success',

                    })
                    push(HrAdminRoutes.Departments.ViewPath)
                }
            })
            .catch((error) =>
                toast({
                    title: 'Excel',
                    description: 'Download Failed, please try again :',
                    status: 'error',
                }))
    }



    const exportPDF = () => {
        let url = `?page=${currentPage}&limit=${100}&search=${query}`
        let fetchedEachDeptData: IEachDepartmentData[];

        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = `${departmentTitle}(${page.total})`;
        const headers = [["Name", "Total Enrolled", "Completed", "Bridege Count", "Pass Rate",]];
        fetchEachDepartmentMember(category_id, url)
            .then((data) => {
                fetchedEachDeptData = data.results;
                const dataEachD = fetchedEachDeptData.map(eachdept => [eachdept.full_name, eachdept.total_enrolled, eachdept.completed, eachdept.bridge_count, eachdept.passed]);

                toast({
                    title: ' PDF',
                    description: 'PDF File downloaded sucessfully',
                    status: 'success',
                })
                doc.text(title, marginLeft, 40);
                autoTable(doc, {
                    startY: 50,
                    head: headers,
                    body: dataEachD as string[][]
                });
                doc.save("eachdepartment.pdf")
            })
            .catch((err) => err)
        // console.log(category_id, "the id")

    }



    return (

        <><Flex justifyContent="flex-start" mb={2}>
            <Link
                as={ReactLink}
                to={HrAdminRoutes.Departments.ManageDepartment}
            >
                <HStack color="brand.2">
                    <HiOutlineArrowLeft />
                    <Text fontSize={13} fontWeight="semibold">
                        {' '}
                        Go back{' '}
                    </Text>
                </HStack>
            </Link>
        </Flex>
            <Container
                boxShadow="base"
                maxW="container.xl"
                bg="white"
                pt={3}
                pb={10}
                px={8}
                minHeight="70vh"
            >
                {isLoading ? (
                    <ScreenLoader />
                ) :


                    <Box>

                        {<Text pl={6} color="brand.3" fontSize={18} fontWeight="semibold">
                            {departmentTitle} ({eachdepartmentNo})
                        </Text>}

                        <Flex
                            justifyContent="space-between"
                            alignItems="center"
                            px={6}
                            mt={4}
                        >
                            <InputGroup color="brand.subtitle" bg="white" width="40%">
                                <Input
                                    placeholder="Search students on this platform"
                                    onChange={(e) => handleSearchChange(e)}
                                    fontSize={12}
                                    size="sm" />

                                <InputRightElement
                                    h="1.9rem"
                                    pointerEvents="none"
                                    children={<RiSearch2Line size="0.9rem" />} />
                            </InputGroup>

                            <Flex justifyContent="space-between">
                                <Menu>
                                    <MenuButton as={Button} rightIcon={<ChevronDownIcon />}
                                        colorScheme="brand"
                                        variant={"outline"}
                                        size="sm"
                                        fontSize={12}
                                    >
                                        Generate Report
                                    </MenuButton>
                                    <MenuList fontSize={12}>
                                        <MenuItem onClick={exportPDF}> PDF</MenuItem>
                                        <MenuItem onClick={handleExcel}>Excel</MenuItem>
                                    </MenuList>
                                </Menu>
                                <Spacer mx={2} />
                            </Flex>

                        </Flex>

                        {eachDepartment.length === 0 ? (
                            <EmptyCourseSearch />
                        ) : (
                            <Box ref={componentRef} >
                                <Table variant="simple" mt={8}>
                                    <Thead fontWeight="normal">
                                        <Tr color="brand.subtitle">
                                            <Th textTransform="none">
                                                Name
                                            </Th>
                                            <Th textTransform="none">Total Enrolled</Th>
                                            <Th textTransform="none">Completed</Th>
                                            <Th textTransform="none">Bridge Count</Th>
                                            <Th textTransform="none">Pass Rate</Th>

                                        </Tr>
                                    </Thead>

                                    <Tbody>
                                        {filteredDept.map((each, index) => (
                                            <Tr key={index} fontSize={15} color="brand.subtitle">
                                                <Td role={"button"} onClick={() => viewUser(each.id)}>
                                                    <Text

                                                        whiteSpace="nowrap"
                                                        isTruncated
                                                        title={each.full_name}
                                                        color="#4E78F5"
                                                    >
                                                        {each.full_name}
                                                    </Text>

                                                </Td>

                                                <Td>
                                                    <Text whiteSpace="nowrap">
                                                        {each.total_enrolled ? each.total_enrolled : "Nil"}
                                                    </Text>

                                                </Td>

                                                <Td>{each.completed ? each.completed : "Nil"}</Td>
                                                <Td>{each.bridge_count ? each.bridge_count : "Nil"}</Td>
                                                <Td>{each.passed ? each.passed + "%" : "Nil"}</Td>


                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </Box>
                        )}



                        <Flex alignContent={"flex-end"} justifyContent={"flex-end"} mt={4}>
                            <Pagination
                                pagesCount={pagesCount}
                                currentPage={currentPage}
                                onPageChange={setCurrentPage}
                            >
                                <PaginationContainer>
                                    <PaginationPrevious>Previous</PaginationPrevious>
                                    <PaginationPageGroup>
                                        {pages.map((page: number) => (
                                            <PaginationPage
                                                key={`pagination_page_${page}`}
                                                page={page} />
                                        ))}
                                    </PaginationPageGroup>
                                    <PaginationNext>Next</PaginationNext>
                                </PaginationContainer>
                            </Pagination>
                        </Flex>
                    </Box>}


                <Portal>
                    {" "}
                    <CreateDepartmentModal
                        isOpen={isOpen}
                        onClose={onClose}
                        isDepartment={isDepartment}
                    />{" "}
                </Portal>

            </Container></>
    );
}
