import { AuthCredentials, IPasswordDto, IResetPasswordDto, IRoleSettingDto, IUser, IUserDto, IUserEmailDto, IUserProfile, IUserSignUpDto, Payload, SessionData } from '../models/auth.model';
import { HttpResponse } from '../models/httpResponse.model';
import { httpStatusCode } from '../models/httpStatus.model';
import { Roles } from '../models/roles.model';
import {axiosClient, httpClient} from './httpClient';
import {UserAgentApplication} from 'msal';


const redirectUri = window.location.origin;

const config = {
    auth: {
        clientId: process.env.REACT_APP_AZURE_CLIENT_ID as string,
        authority: `https://login.microsoftonline.com/${ process.env.REACT_APP_AZURE_TENANT_ID }`,
        redirectUri,
        postLogoutRedirectUri: redirectUri
    }
};

export const authSSO = new UserAgentApplication(config);

export const loginService = async ({email, password}: AuthCredentials) => {

    let response = await httpClient.post<HttpResponse<SessionData>>("/auth/login", {email, password});

    if(response.data.statusCode === httpStatusCode.OK){

        let data = response.data.data
        
        setUserSession(data.user, data.payload);
    }    

    return response;
}

export const LoginAzureSSOService = async (rawIdToken: string) => {

    let response = await axiosClient.post<HttpResponse<SessionData>>('/auth/login/azure-sso', {}, {headers:{Authorization: `Bearer ${rawIdToken}`}});

    if(response.data.statusCode === httpStatusCode.OK){

        let data = response.data.data
        
        setUserSession(data.user, data.payload);
    }    

    return response;
}

export const getProfile = async () => {

    let response = await httpClient.get<HttpResponse<IUserProfile>>(`/user/profile`);

    return response.data.data;
}

export const setUserSession = (user: IUser, payload: Payload) => {

    localStorage.setItem("user", JSON.stringify(user));

    localStorage.setItem("payload", JSON.stringify(payload));

}

export const destroyUserSession = () => {

    localStorage.removeItem("payload");
    localStorage.removeItem("user");
    localStorage.removeItem("localRole");
}

export const getAuthToken = (): string | null => {

    let authToken: Payload;

    let payload = localStorage.getItem("payload");

    if(payload){
        authToken = JSON.parse(payload);
        return authToken.token
    }

    return '';
}

export const isAuthenticated = (): boolean => {

    let user = localStorage.getItem("user");

    let payload = localStorage.getItem("payload");

    if(user && payload)
        return true;

    return false;
}


export const getRole = (): string | null => {

    let user = localStorage.getItem("user");

    if(user){
        let userModel: IUser = JSON.parse(user);

        return userModel?.role?.title || '';
    }

    return null;
}

export const setLocalRole = (role: Roles) => {
    localStorage.setItem('localRole', role);
}

export const getLocalRole = (): Roles | null => {
    return localStorage.getItem('localRole') as Roles;
}

export const updateProfile = async (data: IUserDto) => {

    let response = await httpClient.put<HttpResponse<IUser>>(`/user/profile`, data);

    if(response.data.statusCode === httpStatusCode.OK){
        updateLocalUserProfile(response.data.data);
    }

    return response.data;
}

export const updatePassword = async (data: IPasswordDto) => {

    let response = await httpClient.put<HttpResponse<IUser>>(`/user/profile/password`, data);

    return response.data;
}

export const signUpService = async (data: IUserSignUpDto) => {

    let response = await httpClient.post<HttpResponse<IUserDto>>("auth/register", data);

    return response.data;
}

export const getLocalUserProfile = (): IUser | null => {

    let user = localStorage.getItem("user");

    if(user){
        let userModel: IUser = JSON.parse(user);

        return userModel;
    }

    return null;
}

export const updateLocalUserProfile = (profile: IUser) => {
    
    let user = localStorage.getItem("user");

    if(user){
        let userModel: IUser = JSON.parse(user);

        let updatedUserModel = {...userModel, ...profile};

        localStorage.setItem("user", JSON.stringify(updatedUserModel));
    }
}

export const updateEmail = async (data: IUserEmailDto) => {

    let response = await httpClient.put<HttpResponse<IUser>>(`/user/profile/email`, data);

    if(response.data.statusCode === httpStatusCode.OK){
        updateLocalUserProfile(response.data.data);
    }

    return response.data;
}

export const updateProfileImageService = async (formData?: FormData ) => {
    
    let response = await httpClient.put<HttpResponse<IUser>>("user/profile/image", formData, {headers:{ "Content-Type": "multipart/form-data"} })

    return response.data;
}
   

export const forgotPassword = async (email: string) => {

    let response = await httpClient.post<HttpResponse<IUser>>("/auth/generate-reset-password-link", {email});

    return response.data;
}

export const resetPassword = async (data: IResetPasswordDto) => {

    let response = await httpClient.put<HttpResponse<IUser>>("/auth/reset-password", data);

    return response.data;
}

export const updateRoleSetting = async (data: IRoleSettingDto) => {

    let response = await httpClient.patch<HttpResponse<IUser>>("/user/profile/rank", data);

    return response.data;
}