import { Select } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { IUserRank } from "../../models/auth.model";
import { fetchUserRank } from "../../services/shared.service";

export const Rank: React.FC<any> = ({levelId, rankId, size = "sm", setRankId, ...rest }) => {

    const [ranks, setRanks] = useState<IUserRank[]>([]);

    useEffect(() => {
        
        if(levelId){
            fetchUserRank(levelId)
            .then((data) => {

                setRanks(data)
            })
            .catch((err) => err)
        }            
        
    }, [levelId]);
    
    return (
        <>
            <Select {...rest}  variant="outline" placeholder="Select a Rank" name="rank" value={rankId} size={size} onChange={setRankId} fontSize={12} color="brand.subtitle" >
                {
                    ranks.map( each => <option key={each.id} value={each.id}> {each.name} </option>)
                }
            </Select>
        </>
    );
}