import React, { createContext, useReducer } from "react";
import { IStore, IStoreContext } from "../models/store.model";
import { IActionModel, useActions } from "./actions";
import { initialState, reducer } from "./reducers";


const StoreContext = createContext<IStoreContext>({globalState: initialState, dispatch: () => undefined });

const StoreProvider = ({children}:{children: JSX.Element}) => {

    const [globalState, dispatch]: [IStore, React.Dispatch<IActionModel>] = useReducer(reducer, initialState);
    
    const actions = useActions(dispatch);

    return (
        <StoreContext.Provider value={{globalState, dispatch, actions}} >
            {children}
        </StoreContext.Provider>
    );
}

export {StoreContext, StoreProvider};