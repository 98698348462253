import { Avatar, Badge, Box, Button, Center, Container, Divider, Flex, HStack, Progress, Spacer, Text, VStack, useToast, Image, Link } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { AiFillFacebook, AiFillLinkedin, AiFillStar, AiFillTwitterSquare, AiOutlineCheck } from 'react-icons/ai';
import { BsClock } from "react-icons/bs";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { IoMdChatboxes } from "react-icons/io";
import { useHistory, useParams,Link as ReactLink } from "react-router-dom";
import { ScreenLoader } from "../../components/Common/ScreenLoader";
import CourseCardWRating from "../../components/Student/CourseCard_w_rating";
import coursesData from "../../data/courses.json";
import { ICourseWithEnrollment, IEnrollment } from "../../models/course.model";
import { enrollCourseHttpService, getStudentCourse } from "../../services/student/course-http.service";
import { secondsToHrMin } from "../../utils/secondsToHrMin";
import { StudentRoutes } from "./routes";
// import courseContent from "../../data/courseContent.json";

const EnrollCourse = () => {

    let history = useHistory();

    const toast = useToast();

    let { courseId } = useParams<{ courseId: string }>();

    const [prerequisite, setPrerequisite] = useState([]);

    const [courseData, setCourseData] = useState<ICourseWithEnrollment>();

    const [isLoadingEnroll, setIsLoadingEnroll] = useState(false);

    const [isLoadingPage, setIsLoadingPage] = useState(true);

    useEffect(() => {
        getStudentCourse(courseId)
            .then(data => {
                if (data.courseEnrollment) {
                    if (data.courseEnrollment.enrollment_id)
                        history.replace(StudentRoutes.Course.InfoLink(courseId))
                } else {

                    setCourseData(data)

                    setIsLoadingPage(false);
                }
            })
            .catch(e => {
                history.goBack();
            })
    }, []);


    const enrollCourse = async () => {

        try {
            let enrollment = await enrollCourseHttpService(courseId) as IEnrollment;

            setIsLoadingEnroll(false);

            setCourseData({ ...courseData, courseEnrollment: enrollment })

            history.replace(StudentRoutes.Course.InfoLink(courseId), courseData)

        } catch (error) {
            setIsLoadingEnroll(false);

            toast({
                title: "Course Enrollment",
                description: "Something went Wrong. Try again later",
                status: "error",
                duration: 3000
            })
        }
    }

    return (
        <>
            {
                isLoadingPage ?

                    <ScreenLoader />

                    :

                    <Box pb={6}>
                        <Box height="10em" >
                            <Flex justifyContent="flex-end" mb={2}>
                                <Link
                                    as={ReactLink}
                                    to={StudentRoutes.Course.Explore}
                                >
                                    <HStack color="brand.2">
                                        <HiOutlineArrowLeft />
                                        <Text fontSize={13} fontWeight="semibold">
                                            {' '}
                                         Back{' '}
                                        </Text>
                                    </HStack>
                                </Link>
                            </Flex>
                            <Flex height="inherit">

                                <Box width="30%" boxSizing="content-box">
                                    <Image src={courseData?.course?.cover_image_url} maxW="100%" maxH="100%" />
                                </Box>

                                <Box bg="brand.2" width="70%" px={6} py={5}>

                                    <Box color="white" boxSizing="border-box">

                                        <Text fontSize={20} title={courseData?.course?.title} noOfLines={1} fontWeight="bold">{courseData?.course?.title}</Text>

                                        <Text fontSize={14} noOfLines={1} > {courseData?.course?.description} </Text>

                                        <Flex justifyContent="space-between" alignItems="center">
                                            <Box fontSize={11} mt={3}>

                                                <HStack spacing={1} >
                                                    {
                                                        Array(5).fill("").map((val, index) => <AiFillStar key={index} color={(courseData?.course?.courseReviews?.length as number) > index ? "#E9A426" : "#FFFFFF"} />)
                                                    }

                                                    <Box> {Math.ceil(courseData?.course?.courseReviews?.length as number).toFixed(2)} </Box>

                                                    <Text pl={3} >Instructor: {courseData?.course?.user?.full_name} </Text>
                                                </HStack>

                                                <Text mt={2} >Estimated Course Duration: {secondsToHrMin(Number(courseData?.totalLessonDuration?.totalDuration || 0))} </Text>

                                                <HStack mt={2} spacing={3}>

                                                    <HStack spacing={1}>
                                                        <IoMdChatboxes />
                                                        <Text> English </Text>
                                                    </HStack>
                                                    <HStack spacing={1}>
                                                        <BsClock />
                                                        <Text> {courseData?.course?.format?.title} </Text>
                                                    </HStack>

                                                </HStack>
                                            </Box>

                                            <Button mr={8} mt={8} bg="white" size="sm" px={6} color="brand.2" onClick={enrollCourse} isLoading={isLoadingEnroll} > ENROLL </Button>

                                        </Flex>

                                    </Box>

                                </Box>
                            </Flex>
                        </Box>

                        <Container p={3} maxW="container.xl" mb={6} bg="white">

                            <Box mt={8} mx={10} mb={6}>

                                <Flex fontSize={12} color="brand.subtitle">

                                    <Box width="50%">

                                        <Badge> About this Course</Badge>

                                        <Text mt={4}>  {courseData?.course?.description} </Text>

                                    </Box>

                                    <Spacer mx={8} />

                                    <Box width="50%">

                                        <Badge mb={4}> WHAT YOU WILL LEARN </Badge>

                                        {
                                            courseData?.course?.topics?.map((each, index) =>
                                                <HStack key={index} mb={0.5}>
                                                    <AiOutlineCheck />
                                                    <Text>{each.title}</Text>
                                                </HStack>
                                            )
                                        }

                                    </Box>

                                </Flex>

                            </Box>

                            <Divider />

                            <Box mt={8} mx={10}>

                                <Flex fontSize={12} color="brand.subtitle">

                                    <Box width="50%">

                                        <Text fontWeight="semibold" >ABOUT THE INSTRUCTOR</Text>

                                        <Text mt={4}>
                                            {courseData?.course?.user?.description}
                                        </Text>

                                    </Box>

                                    <Spacer mx={8} />

                                    <Box width="50%">

                                        <HStack mt={4} spacing={4}>
                                            <Avatar size="md" name={courseData?.course?.user?.full_name} src={courseData?.course?.user?.photo_url} />

                                            <Box>
                                                <Text color="brand.3" > {courseData?.course?.user?.full_name} </Text>
                                                <Text>Instructor</Text>
                                                <HStack mt={1}>
                                                    <AiFillFacebook />
                                                    <AiFillTwitterSquare />
                                                    <AiFillLinkedin />
                                                </HStack>
                                            </Box>

                                        </HStack>

                                    </Box>

                                </Flex>

                            </Box>

                        </Container>

                        {
                            (courseData?.course?.courseReviews?.length || 0) > 0 &&

                            <Box>
                                <Center> <Badge bg="brand.2" color="white" size="md" fontSize={12} > FEEDBACKS </Badge> </Center>

                                <Center mt={4}>
                                    <Text color="brand.subtitle" fontSize={12}>
                                        What other students turned professionals have to say about us after learning with us and reaching their goals.
                                    </Text>
                                </Center>

                                <HStack spacing={6} mx={10} mt={6} color="brand.subtitle" fontSize={12}>
                                    <Container boxShadow="base" p={6} maxW="container.xl" bg="white">
                                        <Text>
                                            A wonderful course on how to start. Mary beautifully conveys all essentials of a becoming a good Financial Anaylst. Very glad to have taken this course. Thank you Mary Ann.
                                        </Text>
                                    </Container>

                                    <Container boxShadow="base" p={6} maxW="container.xl" bg="white">
                                        <Text>
                                            A wonderful course on how to start. Mary beautifully conveys all essentials of a becoming a good Financial Anaylst. Very glad to have taken this course. Thank you Mary Ann.
                                        </Text>
                                    </Container>
                                </HStack>
                            </Box>
                        }

                        {
                            (courseData?.course?.courseReviews?.length || 0) > 0 &&

                            <Container boxShadow="base" p={3} maxW="container.xl" mt={8} bg="white">

                                <Box mx={6} mt={4} px={10}>
                                    <Badge px={12} py={1.5} borderRadius={8} bg="brand.2" color="white">REVIEWS</Badge>
                                </Box>

                                <Flex justifyContent="space-between" mt={8} mx={16} alignItems="flex-start">
                                    <Box width="15%">
                                        <Text fontSize={30} fontWeight="bold">4.7</Text>
                                        <HStack>
                                            {
                                                Array(5).fill("").map((val, index) => <AiFillStar key={index} color={4 > index ? "#E9A426" : "#CACDD8"} />)
                                            }
                                            <Text fontSize={12} color="brand.subtitle">  (6578) </Text>
                                        </HStack>

                                    </Box>

                                    <Box width="70%">
                                        <VStack mx={10}>
                                            {
                                                [80, 70, 40, 20, 10].map((each, index) =>

                                                    <Progress key={index} value={each} size="sm" color="brand.2" width="100%" borderRadius="0.5rem" height="0.4rem" />

                                                )
                                            }

                                        </VStack>
                                    </Box>

                                    <Box width="15%">
                                        <VStack spacing={0}>
                                            {
                                                Array(5).fill("").map((val, pIndex) =>
                                                    <HStack spacing={0.5}>
                                                        {
                                                            Array(5).fill("").map((val, index) => <AiFillStar fontSize={11} key={index} color={(5 - pIndex) > index ? "#E9A426" : "#CACDD8"} />)
                                                        }
                                                    </HStack>
                                                )
                                            }
                                        </VStack>

                                    </Box>
                                </Flex>

                                <Box mt={6} mx={16}>
                                    <Flex>
                                        <Box>
                                            <Avatar size="md" name="Kent Dodds" src="https://bit.ly/kent-c-dodds" />
                                        </Box>

                                        <Box ml={6}>
                                            <HStack fontSize={12} spacing={0.5}>
                                                {
                                                    Array(5).fill("").map((val, index) => <AiFillStar key={index} color={4 > index ? "#E9A426" : "#CACDD8"} />)
                                                }
                                            </HStack>
                                            <Text fontSize={12} color="brand.subtitle">
                                                A wonderful course on how to start. Mary beautifully conveys all essentials of a becoming a good Financial
                                                Anaylst. Very glad to have taken this course. Thank you Mary Ann.
                                            </Text>

                                        </Box>
                                    </Flex>
                                </Box>

                                {
                                    prerequisite.length > 0 &&

                                    <Box mt={6} mx={16}>

                                        <Divider />
                                        <Text py={2} color="brand.3" >Other prequsite courses in this series</Text>
                                        <Divider />

                                        <Flex pt={6}>
                                            {
                                                coursesData.featured.map((each, index) => (

                                                    <CourseCardWRating key={index} {...each} />
                                                ))
                                            }

                                        </Flex>
                                    </Box>
                                }
                            </Container>
                        }

                    </Box>
            }
        </>



    )
}

export default EnrollCourse;