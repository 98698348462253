import { Select } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { IUserLevel } from "../../models/auth.model";
import { fetchUserLevel } from "../../services/shared.service";

export const Level: React.FC<any> = ({levelId, setLevelId, size = "sm", ...rest }) => {

    const [levels, setLevels] = useState<IUserLevel[]>([]);

    useEffect(() => {
        fetchUserLevel()
        .then((data) => {

            setLevels(data)
        })
        .catch((err) => err)
        
    }, []);
    
    return (
        <>
            <Select {...rest}  variant="outline" placeholder="Select a Level" name="level" value={levelId} size={size} onChange={setLevelId} fontSize={12} color="brand.subtitle" >
                {
                    levels.map( each => <option key={each.id} value={each.id}> {each.name} </option>)
                }
            </Select>
        </>
    );
}