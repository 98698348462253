import { Container, FormControl, FormLabel, Input, Text, Box, HStack, Textarea, Flex, Button, RadioGroup, Radio, useToast, FormErrorMessage, Portal, useDisclosure} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { HiUserAdd } from "react-icons/hi";
import { IUser } from "../../models/auth.model";
import { IAccessibility } from "../../models/course.model";
import { fetchAccessibility } from "../../services/shared.service";
import { fetchUsersByCourseId } from "../../services/user.service";
import { firstLetterUpper } from "../../utils/firstLetterUpper.util";
import { CourseSelectBox } from "../Common/CourseSelectBox";
import { UsersListModal } from "../Common/UsersListModal";
import { FaTimesCircle } from "react-icons/fa";
import { ICreateGroup, IUserGroupSelect } from "../../models/group.model";
import { createGroupService } from "../../services/group.service";
import { useHistory } from "react-router-dom";


const CreateGroup = () => {

    const [accessibility, setAccessibility] = useState<IAccessibility[]>([]);
    
    const [accessibilityValue, setAccessibilityValue] = useState<any>('');

    const [users, setUsers] = useState<IUser[]>([]);
    
    const [members, setMembers] = useState<IUser[]>([]);
    
    const [admins, setAdmins] = useState<IUser[]>([]);
    
    const [isLoadingUsers, setIsLoadingUsers] = useState(false);

    const toast = useToast();

    const { isOpen, onOpen, onClose } = useDisclosure()
    
    const { isOpen: isOpenAdmin, onOpen: onOpenAdmin, onClose: onCloseAdmin } = useDisclosure()

    const [courseId, setCourseId] = useState<string>();

    const history = useHistory();


    useEffect(() => {
        getAccesibilities();
    }, [])

    const getAccesibilities = () => {

        fetchAccessibility()
        .then((data) => {
            let accessibilities: IAccessibility[] = data.map(({title, accessibility_id }) => ({title, accessibility_id}) )

            setAccessibility(accessibilities)
        })
        .catch((err) => err)
    }

    const fetchUsers = async (courseId: string) => {
        
        if(!courseId) {
            setUsers([]);
            return;
        }

        setIsLoadingUsers(true)

        setCourseId(courseId);

        setMembers([])
        
        setAdmins([])

        try {
            let enrollments = await fetchUsersByCourseId(courseId);

            let usersList = enrollments.map(each => each.user);
            
            setUsers(usersList);
            
            setIsLoadingUsers(false)

        } catch (error) {
            setIsLoadingUsers(false)
            toast({
                title: "Group Setup",
                description: "Something went wrong, Please try again Later.",
                status: "error",
                duration: 3000
            })
            return error;
        }
    }

    const formik = useFormik({
        validate: (values: any) => {
            const errors: any = {};
            if(!values.groupName){
                errors.groupName = 'Group Name is required'
            }
            if(!values.description){
                errors.description = 'Description is required'
            }
            if(!values.course){
                errors.course = 'Course is required'
            }
            return errors;
        },
        initialValues: {
            accessibility: '',
            groupName: '',
            description: '',
            course: '',
        },
        onSubmit: async (values) => {

            try {

                if(!accessibilityValue){
                    toast({
                        title: "Group Setup",
                        description: "Choose either Private or Public",
                        status: "warning",
                        duration: 3000
                    })
                    return;
                }

                if(members.length === 0 && admins.length === 0){
                    toast({
                        title: "Group Setup",
                        description: "Add at least one Admin/Member",
                        status: "warning",
                        duration: 3000
                    })
                    return;
                }

                let adminList: IUserGroupSelect[] = admins.map(each => ({userId: each.user_id as string, isAdmin: true}) );
                
                let memberList: IUserGroupSelect[] = members.map(each => ({userId: each.user_id as string, isAdmin: false}) );

                let users = [...adminList, ...memberList];

                let groupData: ICreateGroup = {
                    title: values.groupName,
                    courseId: values.course,
                    accessibilityId: accessibilityValue,
                    description: values.description,
                    members: users
                }
                console.log(groupData)

                const group = await createGroupService(groupData);
                console.log( group)
//trying something
                if(group){

                    toast({
                        title: "Group Setup",
                        description: group.message,
                        status: "success",
                        duration: 2000,
                        onCloseComplete: () => history.goBack()
                    })
                }
                
            }catch(error) {
                 toast({
                    title: "Group Setup",
                    description: "Something went Wrong. Try again later",
                    status: "error",
                })                
            }
        }
    })

    const viewMemberModal = () => {

        if(!courseId) {
            toast({
                title: "Group Setup",
                description: "Select a course",
                status: "warning",
                duration: 3000
            })
            return;
        }        
        onOpen();
    }
    
    const viewAdminModal = () => {

        if(!courseId) {
            toast({
                title: "Group Setup",
                description: "Select a course",
                status: "warning",
                duration: 3000
            })
            return;
        }        
        onOpenAdmin();
    }

    const removeMember = (index: number) => {
        let user = members.at(index);
        setUsers([user as IUser, ...users]);
        members?.splice(index, 1)
        setMembers([...members])
    }
    
    const removeAdmin = (index: number) => {
        let user = admins.at(index);
        setUsers([user as IUser, ...users]);
        admins?.splice(index, 1)
        setAdmins([...admins])
    }


    return (
        
        <Container boxShadow="base" maxW="container.xl" bg="white" py={3} px={10} mb={6} pb={10} minHeight="50vh">
            
            <Text color="brand.3" fontSize={18} fontWeight="semibold">
                Create Group
            </Text>

            <Box mt={4}>

                <form onSubmit={formik.handleSubmit} >

                    <FormControl id="groupName" isInvalid={(formik.errors.groupName && formik.touched.groupName) ? true : false}>
                        <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>Group Name</FormLabel>
                        <Input bg="inputText.500" onChange={formik.handleChange} name="groupName" placeholder="Enter a group name" fontSize={12} />
                        <FormErrorMessage fontSize={12} >{formik.errors.groupName}</FormErrorMessage>
                    </FormControl>

                    <FormControl id="accessibility" >
                        <RadioGroup onChange={setAccessibilityValue} color="brand.bodyText" name="accessibility" >
                                <HStack spacing={10} mt={4}>
                                    {
                                        accessibility.map((each, index) => 
                                            <Radio key={index} size="sm" value={each.accessibility_id}> { firstLetterUpper(each.title)} Group </Radio>
                                        )
                                    }
                                </HStack>
                        </RadioGroup>
                    </FormControl>
                    

                    <FormControl id="groupDescription" mt={8} isInvalid={(formik.errors.description && formik.touched.description) ? true : false} >
                        <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>Group Description </FormLabel>
                            
                        <Textarea
                            p = {4}
                            bg="inputText.500"
                            fontSize={12} 
                            placeholder="Enter a description for the group"
                            size="sm"
                            minH="10rem"
                            onChange={formik.handleChange}
                            name="description"
                        />
                        <FormErrorMessage fontSize={12} >{formik.errors.description}</FormErrorMessage>
                    </FormControl>

                    <Flex mt={6} justifyContent="space-between">

                        <FormControl id="groupAdmin" width="55%">
                            <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>Group Administrator</FormLabel>
                            <Box border="1px solid"  borderRadius="0.125rem" borderColor="inherit" minHeight={10} display="flex" >
                                <HStack>
                                    {
                                        admins.map( (each, index) => 
                                            <Button ml={2} key={index} color="brand.subtitle" size="sm" fontWeight="semibold" px={4} variant="solid" onClick={() => removeAdmin(index)} fontSize={12} rightIcon={<FaTimesCircle  />} > {each.full_name} </Button>
                                        )
                                    }
                                    <Button ml={2} color="brand.subtitle" size="sm" fontWeight="semibold" px={4} variant="solid" fontSize={12} onClick={viewAdminModal} leftIcon={<HiUserAdd fontSize={16} />} > Add Admin </Button>
                                </HStack>
                            </Box>
                        </FormControl>


                        <FormControl id="selectCourse" width="35%" isInvalid={(formik.errors.course && formik.touched.course) ? true : false} >
                            <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>Select Course</FormLabel>
                            <CourseSelectBox courseId={formik.values.course} setCourseId={(e: any) => { formik.handleChange(e); fetchUsers(e.target.value) } } />
                            <FormErrorMessage fontSize={12} >{formik.errors.course}</FormErrorMessage>
                        </FormControl>

                    </Flex>

                    <FormControl id="addMembers" mt={6} >
                        <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>Add Members</FormLabel>
                        <Box border="1px solid"  borderRadius="0.125rem" borderColor="inherit" minHeight={10} display="flex" >
                            <HStack>
                                {
                                    members.map( (each, index) => 
                                        <Button ml={2} key={index} color="brand.subtitle" size="sm" fontWeight="semibold" px={4} variant="solid" onClick={() => removeMember(index)} fontSize={12} rightIcon={<FaTimesCircle  />} > {each.full_name} </Button>
                                    )
                                }
                                <Button ml={2} color="brand.subtitle" size="sm" fontWeight="semibold" px={4} variant="solid" fontSize={12} onClick={viewMemberModal} leftIcon={<HiUserAdd fontSize={16} />} > Add Members </Button>
                            </HStack>
                        </Box>
                    </FormControl>
                    

                    <Flex justifyContent="flex-end" mt={10} >
                        <Button colorScheme="brand" type="submit" size="sm" isLoading={formik.isSubmitting} borderRadius={1} px={4} fontSize={11}>SUBMIT FOR REVIEW</Button>
                    </Flex>
                </form>

            </Box>

            <Portal> <UsersListModal  isOpen={isOpen} onClose={onClose} users={users} setUsers={setUsers} setMembers={setMembers} members={members} title="Add Member" /> </Portal>
            
            <Portal> <UsersListModal  isOpen={isOpenAdmin} onClose={onCloseAdmin} users={users} setUsers={setUsers}  setMembers={setAdmins} members={admins} title="Add Admin" /> </Portal>

        </Container>
    );
}

export default CreateGroup;