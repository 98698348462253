
const Link = {
    baseStyle:{
      _hover: {
          textDecoration: "none"
      },
      _focus: {
          boxShadow: "none"
      }
    },
    variants: {
  },
  defaultProps: {
      textDecoration: "none",
      _hover: {
          textDecoration: "none"
      }
  },
}

export default Link;