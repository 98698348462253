import {add} from 'date-fns'

export const dateFilters = () => {

    let todaysDate = new Date();
    todaysDate.setUTCHours(0,0,0,0);

    let today = todaysDate.toISOString();

    let week = add(new Date(), {weeks: -1}).toISOString();
    let fortnight = add(new Date(), {weeks: -2}).toISOString();
    let month = add(new Date(), {months: -1}).toISOString();
    let quarter = add(new Date(), {months: -4}).toISOString();
    let annual = add(new Date(), {years: -1}).toISOString();

   return [
       {title: "Today", value: today},
       {title: "Weekly", value: week},
       {title: "Fortnightly", value: fortnight},
       {title: "Monthly", value: month},
       {title: "Quarterly", value: quarter},
       {title: "Annual", value: annual},
   ]
}
