import { Box, Container, Flex, HStack, Tag, Text, VStack, Avatar, IconButton, Image, List, ListItem, Divider, useToast, Collapse, useDisclosure, Portal, Button } from "@chakra-ui/react";
import { Table, Thead, Tbody, Tr, Th, Td} from "@chakra-ui/react"
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaTimesCircle } from "react-icons/fa";
import { IoMdCheckmarkCircle } from "react-icons/io";
import { useEffect, useState } from "react";
import { courseApprovalService, fetchDashboardCourseCategories, fetchDashboardStat, fetchPendingCourses } from "../../services/trainingMgr/course-http.service";
import { ITrainingMgrCourseCategories, ITrainingMgrDashboardStat } from "../../models/dashboard.model";
import { ITrainingMgrCourseList } from "../../models/course.model";
import { courseWithAuthorListUtil } from "../../utils/course.util";
import { DashboardLoader } from "../../components/Common/DashboardLoader";
import { useHistory } from "react-router-dom";
import { TrainingMgrRoutes } from "./routes";
import { EmptyCourseRequests } from "../../components/TrainingManager/EmptyState/CourseRequests";
import { CourseDeclineModal } from "../../components/TrainingManager/CourseDeclineModal";


const Dashboard = () => {

    const toast = useToast();
    
    let { replace, push } = useHistory();

    const [dashboardStat, setDashboardStat] = useState<ITrainingMgrDashboardStat>();

    const [courseCategories, setCourseCategories] = useState<ITrainingMgrCourseCategories[]>([]);

    const [upcomingCourses, setUpcomingCourses] = useState([]);

    const [pendingCourses, setPendingCourses] = useState<ITrainingMgrCourseList[]>([]);

    const [isLoadingPendingCourse, setIsLoadingPendingCourse] = useState(true);

    const [showFullCategories, setShowFullCategories] = useState(false);

    const history = useHistory();

    const [pickedCourseIndex, setPickedCourseIndex] = useState<number>();
    
    const [pickedCourseId, setPickedCourseId] = useState<string>();

    const { isOpen: isDeclineOpen, onOpen: onDeclineOpen, onClose: onDeclineClose } = useDisclosure()

    useEffect(() => {
       fetchDashboardStat()
        .then((data) => {
            setDashboardStat(data);
            console.log(data)

            fetchDashboardCourseCategories()
            .then((data) => {
                setCourseCategories(data)
                
                fetchPendingCourses(4)
                .then((data) => {
                    setPendingCourses(courseWithAuthorListUtil(data))
                    setIsLoadingPendingCourse(false);
                })
            })
        })
        .catch((err) => err)
    }, []);

    const courseApproval = async (truth: boolean, courseId: string, courseIndex: number, message?: string) => {

        try {
            let course = await courseApprovalService({courseId, approve:truth, message: message});

            if(course){
                
                pendingCourses.splice(courseIndex, 1)

                setPendingCourses([...pendingCourses]);
            }

        } catch (error) {
            toast({
                title: "Course Approval",
                description: "Something went Wrong. Try again later",
                status: "error",
            }) 
        }       
    }

    const courseDecline = (courseId: string, courseIndex: number) => {

        setPickedCourseIndex(courseIndex);
        
        setPickedCourseId(courseId);

        onDeclineOpen();
    }

    const categoriesExpandToggle = () => setShowFullCategories(!showFullCategories);

    return (
        <Box>
            <Box mb={6} color="brand.2" fontWeight="semibold">Training Manager</Box>

            <Flex justifyContent="space-between" >

                <Container  width="fit-content" px={14} py={2} boxShadow="base" bg="white" m={0} >
                    <VStack pb={3} pt={1}>
                        <Text color="brand.2" fontSize={13}  >Total Courses</Text>
                        <Text color="brand.subtitle" fontWeight="semibold" fontSize={30} >{dashboardStat?.total_courses}</Text>
                    </VStack>
                </Container>

                <Container  width="fit-content" px={14} py={2} boxShadow="base" bg="white" m={0} >
                    <VStack pb={3} pt={1}>
                        <Text color="brand.2" fontSize={13}  >Total Students</Text>
                        <Text color="brand.subtitle" fontWeight="semibold" fontSize={30} >{dashboardStat?.total_students}</Text>
                    </VStack>
                </Container>
                
                <Container  width="fit-content" px={14} py={2} boxShadow="base" bg="white" m={0} >
                    <VStack pb={3} pt={1}>
                        <Text color="brand.2" fontSize={13}  >All Groups</Text>
                        <Text color="brand.subtitle" fontWeight="semibold" fontSize={30} >{dashboardStat?.total_groups}</Text>
                    </VStack>
                </Container>
                
                <Container  width="fit-content" px={14} py={2} boxShadow="base" bg="white" m={0} >
                    <VStack pb={3} pt={1}>
                        <HStack >
                            <Text color="brand.2" fontSize={13} > Pending Requests</Text>
                            <Tag colorScheme="tag" borderRadius={0} px={1} minHeight={0} lineHeight={1.5} fontSize={11} > +49</Tag>
                        </HStack>
                        <Text color="brand.subtitle" fontWeight="semibold" fontSize={30} >{dashboardStat?.pending_requests}</Text>
                    </VStack>
                </Container>

            </Flex>

            <HStack my={6} spacing={6} alignItems="start"  minH="10em">

                <Container boxShadow="base" maxW="container.xl" bg="white" py={3} px={6} width="65%">
                    
                    <Flex justifyContent="space-between" alignItems="center" px={6}>

                        <Text color="brand.2" fontWeight="semibold" > Course Requests </Text>

                        <Text color="brand.2" onClick={() => history.push(TrainingMgrRoutes.Course.Fetch) } cursor="pointer" fontSize={12} > See all </Text>

                    </Flex>

                    {
                        isLoadingPendingCourse?
                        
                        <DashboardLoader />

                        :

                        pendingCourses.length === 0 ?

                        <Box minHeight={"10em"}><EmptyCourseRequests /></Box>

                        :

                        <Table variant="unstyled" mt={4} size="sm">
                            <Thead fontWeight="normal" >
                                <Tr color="brand.subtitle" >
                                    <Th textTransform="none">Name</Th>
                                    <Th textTransform="none">Instructor</Th>
                                    <Th textTransform="none">Action</Th>
                                    <Th textTransform="none"></Th>
                                </Tr>
                            </Thead>
            
                            <Tbody>
                                {
                                    pendingCourses.map((each, index) => 
                                        <Tr key={index} fontSize={13} color="brand.subtitle">
                                            <Td>
                                                <HStack>
                                                    <Image src={each.cover_image} fallbackSrc="https://via.placeholder.com/150" height="auto" maxWidth="50px" />
                                                    <Text fontSize={11}>{each.title}</Text>                                                
                                                </HStack>
                                            </Td>
                                            <Td>
                                                <HStack>
                                                    <Avatar size="xs" name={each.author} src={each.img_url} />
                                                    <Text isTruncated fontSize={11}>{each.author}</Text>                                                
                                                </HStack>
                                            </Td>
                                            <Td>                                               
                                                <HStack>
                                                <Button colorScheme="brand" onClick={() => push(TrainingMgrRoutes.Course.PreviewLink(each?.courseId as string))} variant="outline" rounded="none" size="xs" fontSize={10}> Preview </Button>
                                                                    
                                                </HStack>                                                
                                            </Td>
                                            <Td> 
                                                {/* <IconButton
                                                    variant="ghost"
                                                    aria-label="dots"
                                                    borderRadius={20}
                                                    size="xs"
                                                    icon={<BsThreeDotsVertical />}       
                                                    fontSize={16}                                             
                                                />  */}
                                            </Td>
                                        </Tr>
                                    )
                                }
                                
                            </Tbody>
                        </Table>
                    }                    
                    
                </Container>
                
                <Container boxShadow="base" maxW="container.xl" bg="white" py={3} px={8} width="35%" minHeight="100%">

                    <Flex color="brand.2" justifyContent="space-between" alignItems="center" mb={6}>
                        <Text   fontWeight="semibold" > Categories</Text>
                        {/* <Text fontSize={13}>See all</Text> */}
                        <Text fontSize={12} onClick={categoriesExpandToggle} cursor="pointer" > {showFullCategories? 'See less': 'See all'}</Text>
                    </Flex>

                    <Collapse startingHeight={136} in={showFullCategories}>
                    <List spacing={4}>
                         
                        {
                            courseCategories.map((each, index) => (
                                
                                <ListItem key={index}>
                                    <Flex justifyContent="space-between">
                                        <Text color="brand.subtitle" fontSize={13}>{each.title}</Text>
                                        <Tag colorScheme="tag" borderRadius={1.5} size="sm"  fontSize={11} >{each.courses}</Tag>
                                    </Flex>
                                </ListItem>
                            ))
                        }
                        
                    </List>
                    </Collapse>

                </Container>

            </HStack>

            {
                upcomingCourses.length > 0 &&

                <Container p={0} boxShadow="base" bg="white" mb={6} maxW="container.xl" pl={8} pb={6}>
                    
                    <Flex direction="row" justifyContent="space-between" py={3} >
                        <Text color="brand.3" fontSize={14} fontWeight="semibold" >Upcoming Courses</Text>
                    </Flex>

                    <Divider orientation="horizontal" color="#D5D5D5" border="1px" mb={3} />

                    <Flex>
                        <Flex direction="row" boxShadow="base" pr={3}>

                            <Image src='/img/courses/digital_marketing.png' width="3.2rem" mr={4} />
                    
                            <Flex direction="column" justifyContent="center">
                                <Text fontSize={13}>
                                    Machine Learning
                                </Text>
                                <Text  fontSize={11}>456 courses</Text>
                            </Flex>
                        </Flex>
                    </Flex>                         

                </Container>            
            }

            <Portal>
                <CourseDeclineModal isOpen={isDeclineOpen} onClose={onDeclineClose} courseIndex={pickedCourseIndex} courseId={pickedCourseId} courseApproval={courseApproval}  />
            </Portal>
        
        </Box>
    );
}

export default Dashboard;