import { Box, Button, Container, Flex, HStack, Input, InputGroup, Text, InputRightElement, Avatar, Progress, Portal, useDisclosure } from "@chakra-ui/react";
import { Table, Thead, Tbody, Tr, Th, Td} from "@chakra-ui/react"
import { RiSearch2Line } from "react-icons/ri";
import studentOverview from '../../data/studentOverview.json';
import { ChangeEvent, useEffect, useState } from "react";
import { fetchStudentsOverview } from "../../services/trainingMgr/course-http.service";
import { studentListUtil } from "../../utils/course.util";
import { IStudent, IStudentList } from "../../models/course.model";
import { EmptyStudents } from "../../components/Instructor/EmptyState/Students";
import { ScreenLoader } from "../../components/Common/ScreenLoader";
import { EmptySearchResult } from "../../components/Common/EmptySearchResult";
import { StudentOverviewModal } from "../../components/TrainingManager/StudentOverviewModal";
// import { TrainingMgrRoutes } from "./routes";
// import { useHistory } from "react-router-dom";




const StudentOverview = () => {

    const [studentsMemo, setstudentsMemo] = useState<IStudent[]>([]);
    
    const [selectedStudent, setSelectedStudent] = useState<IStudent>();

    const [students, setstudents] = useState<IStudentList[]>([]);
    
    const [filteredStudents, setfilteredStudents] = useState<IStudentList[]>([]);

    const [isLoading, setIsLoading] = useState(true);

    const [isSearchEmpty, setIsSearchEmpty] = useState(false);

    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
        fetchStudentsOverview()
        .then((data) => {
            setfilteredStudents(studentListUtil(data));
            setstudents(studentListUtil(data));
            setstudentsMemo(data);
            setIsLoading(false)

            setSelectedStudent(data.at(0))
        })
        .catch((err) => err)
            
    }, []);

    const handleSearchChange  = (event: ChangeEvent<HTMLInputElement>) => {
        
        let searchTerm = event.target.value.toLowerCase();
        
        let filtered = students.filter(each => each.name.toLowerCase().includes(searchTerm) );
        
        setfilteredStudents(filtered);
        
        if(filtered.length > 0)
            setIsSearchEmpty(false);
        else
            setIsSearchEmpty(true)
    }

    const viewProfile = (userId: string) => {

        let userCourses = studentsMemo.filter(each => each.user_id === userId).at(0);

        setSelectedStudent(userCourses);

        onOpen();
    }

    return (

        <Container boxShadow="base" maxW="container.xl" bg="white" pt={3} pb={10} px={8} minHeight="70vh">

            {
                isLoading?

                <ScreenLoader />

                :
                studentOverview.length === 0 ? 
                <EmptyStudents />
                :
                <Box>

                    <Flex justifyContent="space-between">
                        <Text pl={6} color="brand.3" fontSize={18} fontWeight="semibold"> Student Overview </Text>
                    </Flex>

                    <Flex justifyContent="space-between" alignItems="center" px={6} mt={6}>
                        <InputGroup color="brand.subtitle" bg="white" width="40%" >
                            <Input placeholder="Search student" onChange={ e => handleSearchChange(e)} fontSize={12} size="sm" />

                            <InputRightElement h="1.9rem" pointerEvents="none" children={<RiSearch2Line size="0.9rem"  />} />
                        </InputGroup>
                    </Flex>

                    {
                        isSearchEmpty ? 

                        <Box mt={20}>
                            <EmptySearchResult />
                        </Box>

                        :

                        <Table variant="simple" mt={5} >
                            <Thead fontWeight="normal" >
                                <Tr color="brand.subtitle" >
                                    <Th textTransform="none">Name</Th>
                                    {/* <Th textTransform="none">Department</Th> */}
                                    <Th textTransform="none">#Enrolled</Th>
                                    <Th textTransform="none">Status</Th>
                                    {/* <Th textTransform="none">Badges</Th> */}
                                    <Th textTransform="none"></Th>
                                </Tr>
                            </Thead>

                            <Tbody>
                                {
                                    filteredStudents.map((each, index) => 
                                        <Tr key={index} fontSize={13} color="brand.subtitle">
                                            <Td>
                                                <HStack>
                                                    <Avatar size="xs" name={each.name} src={each.img_url} />
                                                    <Text isTruncated fontSize={11}>{each.name}</Text>                                                
                                                </HStack>
                                            </Td>
                                            {/* <Td>{each.department}</Td> */}
                                            <Td>{each.courses}</Td>
                                            <Td width="20%" ><Flex justifyContent="center" alignItems="center" > <Progress width="100%" value={each.progress} borderRadius={20} size="xs" mr={3} /> <Box as="span" whiteSpace="nowrap" fontSize={10}> {each.progress}% </Box> </Flex></Td>
                                            {/* <Td>{each.badges}</Td> */}
                                            <Td> 
                                                <Button variant="ghost" borderRadius={20} colorScheme="brand" size="xs" fontSize={11} onClick={() => viewProfile(each?.userId as string) }>View</Button>
                                            </Td>
                                        </Tr>
                                    )
                                }
                                
                            </Tbody>
                        </Table>
                    }
                    
                </Box> 
            }

        <Portal> <StudentOverviewModal isOpen={isOpen} onClose={onClose} student={selectedStudent} /> </Portal>

        </Container>
   );
}

export default StudentOverview;