import { IQuiz } from "./quiz.model";

export interface ILectureState{
    topicId?: string,
    title: string,
    showAddLessonField: boolean,
    showEditLectureField?: boolean,
    lessons: ILessonState[],
}

export interface ILessonState{
    lessonId?: string,
    title: string,
    time?: string,
    asset_link?: string,
    asset_type?: AssetType,
    isUploaded?: boolean,
    asset_file_name?: string,
    quiz?: IQuizState,
    showEditLessonField?: boolean,
    description?: string
}

export enum AssetType{
    ARTICLE = "ARTICLE",
    VIDEO = "VIDEO",
    SLIDE = "SLIDE",
    QUIZ = "QUIZ",
    AUDIO = "AUDIO",
}

export interface ICourseInfoState{
    courseCategory: string,
    courseDescription: string,
    courseFormat: string,
    courseFormatTitle: string,
    courseLevel: string,
    courseTitle: string,
    endDate: string,
    startDate: string,
}

export interface ICourseSettingsState{
    accessibility: string,
    tags: string,
    welcomeMsg: string,
    congratMsg: string,
    coverImage: string,
}

export interface IQuizState{
    quiz: IQuiz,
    questions?: IQuizQuestionState[]
}



export interface IQuizQuestionState{
    quiz_question_id: string;
    question: string,
    isSaved: boolean,
    isAnswerSelected: boolean,
    answerId?: string,
    options?: IQuizOptionState[],
    showEditQuestion?: boolean,
}



export interface IQuizOptionState{
    option: string,
    isCorrect: boolean,
    isSaved: boolean,
    quiz_answer_id: string,
    showEditOption?: boolean,
}



export enum CourseFormat{
    INSTRUCTOR_PACED = 'instructor-paced',
    SELF_PACED = 'self-paced',
    MANDATORY = 'mandatory',
}