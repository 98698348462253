import {
    Box,
    Text,
    HStack,
    Divider,
    Button,
    Flex,
    useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import CourseInfo from "../../components/Instructor/CreateCourse/CourseInfo";
import Curriculum from "../../components/Instructor/CreateCourse/Curriculum";
import Setting from "../../components/Instructor/CreateCourse/Settings";
import CourseLine from "../../components/Instructor/CourseLine";
import {
    getCourseInfoFromStateService,
    removeCourseFromStateService, getCurriculumFromStateService
} from "../../services/instructor/course.service";
import bannerBg from "../../img/course-Bg.svg";
import { useRouteMatch } from "react-router-dom";
import { InstructorRoutes } from "./routes";
import { TrainingMgrRoutes } from "../TrainingManager/routes";

const CreateCourse = () => {
    let [pageState, setPagestate] = useState(0);
    const toast = useToast();

    const [courseTitle, setCourseTitle] = useState<string>();
    const [curriculumOkay, setCurriculumOkay] = useState<boolean>(false);
    const [examLength, setExamLength] = useState<number>();
    console.log(examLength, "no of exam");
    const [courseFormat, setCourseFormat] = useState<string>();
    console.log(courseFormat, "format");
    let { path } = useRouteMatch();

    const pages = [
        { index: 1, component: CourseInfo },
        { index: 2, component: Curriculum },
        { index: 3, component: Setting },
    ];
    const addFinalExam = () => {
        alert("Kindly add a final exam");
    };
    const switchPage = () => {
        const Component = pages[pageState].component;

        return (
            <Component
                nextPage={nextPage}
                prePage={prePage}
                setCurriculumOkay={setCurriculumOkay}
            />
        );
    };

    const nextPage = () => {
        if (pageState < pages.length) {
            setPagestate(++pageState);
        }
        localStorage.setItem("page_no", pageState.toString());
    };

    const prePage = () => {
        if (pageState > 0) {
            setPagestate(--pageState);
        }
        localStorage.setItem("page_no", pageState.toString());
    };

    useEffect(() => {
        let pageNo = localStorage.getItem("page_no") ?? "0";

        setPagestate(parseInt(pageNo));

    }, [pageState]);

    const verificationBeforeNextPage = () => {
        let courseInfo = getCourseInfoFromStateService();
        const curriculumm = getCurriculumFromStateService()
        let no_of_exam = localStorage.getItem("no_of_exam");
        const format = courseInfo?.courseFormatTitle
        const uploadedContent = curriculumm && curriculumm[0].lessons[0].asset_type ? true : false
        console.log(format, 'format')
        console.log(uploadedContent, 'content upload')
        console.log(curriculumm, 'curriculum')
        if (pageState !== 1) {
            nextPage()
        }
        if (pageState == 1 && format !== 'Mandatory') {
            if (uploadedContent) {
                nextPage()
            } else {
                alert('please uploaded a lesson content before proceeding')
            }
        } else if (pageState == 1 && format === 'Mandatory') {
            console.log(no_of_exam, 'no of exam')
            if (no_of_exam) {
                if (parseInt(no_of_exam) > 0 && uploadedContent) {
                    nextPage();
                } else if (parseInt(no_of_exam) > 0 && !uploadedContent) {
                    alert('Please upload a lesson content before proceeding');
                } else if (parseInt(no_of_exam) == 0 && uploadedContent) {
                    addFinalExam();
                } else {
                    alert('Upload a final exam and add a lesson content to your lesson');
                }
            }else{
                addFinalExam();
            }
        }
    }
    const clearCourseFromLocalStorage = () => {
        removeCourseFromStateService();

        window.location.reload();
    };

    return (
        <>
            <Box
                bg="brand.2"
                height="3.5rem"
                px={12}
                pt={4}
                borderTopRadius={4}
                backgroundImage={bannerBg}
            >
                <Flex justifyContent={"space-between"}>
                    <Text color="white" fontWeight="semibold" isTruncated>
                        {" "}
                        {courseTitle ? courseTitle : "Add New Course"}{" "}
                        {courseTitle && (
                            <Box as="span" fontSize={10} color="#B9FAF8">
                                DRAFT
                            </Box>
                        )}{" "}
                    </Text>

                    {InstructorRoutes.Course.Create.match(path) ||
                        TrainingMgrRoutes.Course.Create.match(path) ? (
                        <Button
                            onClick={clearCourseFromLocalStorage}
                            variant="outline"
                            color="white"
                            _hover={{ color: "brand.2", bgColor: "white" }}
                            borderColor={"white"}
                            borderWidth={"2px"}
                            size="sm"
                            fontSize={11}
                            borderRadius={2}
                            px={6}
                        >
                            CLEAR
                        </Button>
                    ) : (
                        <Box></Box>
                    )}
                </Flex>
            </Box>

            <Box
                height="3rem"
                boxShadow="0px 0px 4px rgba(0, 0, 0, 0.08)"
                px={8}
                py={2}
            >
                <HStack justifyContent="space-between">
                    <CourseLine
                        position="1"
                        title="Course Information"
                        bgColor={pageState === 0 ? "brand.2" : "brand.1"}
                        textColor={pageState === 0 ? "white" : "brand.subtitle"}
                    />

                    <Divider orientation="horizontal" opacity="unset" />

                    <CourseLine
                        position="2"
                        title="Curriculum"
                        bgColor={pageState === 1 ? "brand.2" : "brand.1"}
                        textColor={pageState === 1 ? "white" : "brand.subtitle"}
                    />

                    <Divider orientation="horizontal" opacity="unset" />

                    <CourseLine
                        position="3"
                        title="Settings"
                        bgColor={pageState === 2 ? "brand.2" : "brand.1"}
                        textColor={pageState === 2 ? "white" : "brand.subtitle"}
                    />
                </HStack>
            </Box>

            <Box boxShadow="base" bg="white" mt={6} py={4} px={8}>
                {switchPage()}

                <Flex justifyContent="space-between" mt={6} px={6}>
                    {pageState === 1 ? (
                        <Button
                            onClick={prePage}
                            variant="outline"
                            colorScheme="brand"
                            size="sm"
                            fontSize={11}
                            borderRadius={10}
                            px={8}
                        >
                            PREVIOUS
                        </Button>
                    ) : (
                        <Box></Box>
                    )}

                    {pageState === 1 ? (
                        <Button
                            onClick={verificationBeforeNextPage}
                            colorScheme="brand"
                            size="sm"
                            fontSize={11}
                            borderRadius={10}
                            px={10}
                        >
                            NEXT
                        </Button>
                    ) : (
                        <Box></Box>
                    )}
                </Flex>
            </Box>
        </>
    );
};

export default CreateCourse;
