import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Box,
  useToast,
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftElement,
  Input,
  FormErrorMessage,
  Spacer,
  Flex,
  Button,
  Checkbox,
  Center,
  Select,
  SimpleGrid,
  Table,
  Tbody,
  Tr,
  Td,
  HStack,
  Thead,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { async } from 'rxjs'

import { IUser, IUserLevel, IUserRank } from '../../models/auth.model'

import { AssignAllRoles } from '../../services/hr/users-http.service'

export const AssignRoleModal: React.FC<any> = ({
  roleArray,
  isAssign,
  isOpen,
  onClose,
  userData,
  reloadData,
}: {
  roleArray: any[]
  isAssign: boolean
  isOpen: any
  onClose: any
  userData: IUser
  reloadData: any
}) => {
  type CheckedRolesType = {
    role_id: number
    checked: boolean
  }

  const toast = useToast()

  const [checkedRoles, setCheckedRoles] = useState<CheckedRolesType[]>([])
  const [rolesIDs, setRoleIDs] = useState<any[]>([])

  const getGreatestRole = () => {
    const _checkedRoles = checkedRoles.filter((roles) => roles.checked)
    return _checkedRoles.reduce((prev, curr) => {
      return prev.role_id > curr.role_id ? prev : curr
    })
  }

  useEffect(() => {
    const _checkedRoles: any = []
    const roles: any[] = []
    roleArray.map((role: { role_id: number }) => {
      roles.push(role.role_id)
      _checkedRoles[role.role_id] = {
        role_id: role.role_id,
        checked: false,
      }
    })
    setRoleIDs(roles)

   
    if (userData.role?.role_id) {
      const _checkedRoleArray = updateRoles(
        userData.role.role_id,
        _checkedRoles,
      )
      setCheckedRoles(_checkedRoleArray)
    }
  }, [roleArray, userData])

  const updateRoles = (
    role_id: number,
    checkedRoleArray: CheckedRolesType[],
  ) => {
    const _activeRoles: number[] = []

    while (role_id > 0) {
      _activeRoles.push(role_id)
      role_id--
    }

    const _checkedRoleArray: CheckedRolesType[] = checkedRoleArray.map(
      (checkedRole) => {
        return {
          role_id: checkedRole.role_id,
          checked: _activeRoles.includes(checkedRole.role_id),
        }
      },
    )

   

    return _checkedRoleArray
  }

  const handleCheckbox = async (role_id: number, checked: boolean) => {
    if (checked) {
      const _checkedRoleArray = updateRoles(role_id, checkedRoles)
      setCheckedRoles(_checkedRoleArray)
    } else {
      let id
      if (getGreatestRole().role_id > role_id) {
        id = role_id
      } else {
        id = role_id - 1 > 0 ? role_id - 1 : 1
      }
      const _checkedRoleArray = updateRoles(id, checkedRoles)
      setCheckedRoles(_checkedRoleArray)
    }
  }

  const handleAssign = async () => {
    try {
      const roleId = getGreatestRole().role_id
     
      const userId = userData.id
      const statusCode = await AssignAllRoles({roleId, userId})
      if (statusCode === 200) {
        toast({
          title: 'Assign Role',
          description: 'Role Succesfully Assigned',
          status: 'success',
          duration: 2000,
        })
        reloadData()
        onClose()
      } else {
        throw Error('Could not assign role. Please try again')
      }
    } catch (error) {
      toast({
        title: 'Assign Role',
        description: 'Could not assign role. Please try again',
        status: 'error',
      })
    }
  }
  return (
    <>
      <Modal
        isOpen={isOpen && isAssign}
        onClose={onClose}
        scrollBehavior="outside"
        closeOnOverlayClick={true}
        size="4xl"
      >
        <ModalOverlay />
        <ModalContent borderRadius={5} px={1} py={1}>
          <ModalHeader pb={2}>
            <Text fontSize={20}>{userData.full_name}</Text>
          </ModalHeader>
          <ModalCloseButton size="sm" />
          <ModalBody pb={6}>
            <Table variant="simple" mt={3}>
              <Thead></Thead>
              <Tbody>
                {roleArray.map((role: { role_id: number; title: string }) => (
                  <Tr key={role.role_id}>
                    <Td>
                      <HStack alignItems={'center'}>
                        <Checkbox
                          me={5}
                          isChecked={
                            checkedRoles &&
                            role.role_id &&
                            checkedRoles[role.role_id]
                              ? checkedRoles[role.role_id].checked
                              : false
                          }
                          onChange={(e) =>
                            handleCheckbox(role.role_id, e.target.checked)
                          }
                        />
                        <Text color="brand.subtitle">{role.title}</Text>
                      </HStack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </ModalBody>

          <ModalFooter>
            <Button
              alignSelf={'end'}
              colorScheme="brand"
              size="sm"
              fontSize={12}
              onClick={()=> handleAssign()}
            >
              Assign Role
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

{
  /* <Checkbox mt={4}
                      isChecked={allChecked}
                      isIndeterminate={isIndeterminate}
                      onChange={(e) => checkAllItems(e)}
                    ></Checkbox> */
}

{
  /* {filteredUsers.map((each, index) => {
                    return (
                      
                      <Tr key={index} fontSize={13} color="brand.subtitle">
                        <Checkbox mt={10}
                          isChecked={checkedItems[each.id]}
                          value={each.id}
                          name='user_id[]'
                          
                          onChange={(e) =>
                            updateCheckItems(
                              e.target.checked, each.id
                            )
                          }
                        ></Checkbox> */
}
