import { Box, Image, Text } from "@chakra-ui/react";
import rect from '../../img/rect.png';
import career from '../../img/illustration.svg';


const Banner = () => {

    return (
        <Box bgImage={rect} height="12em" bgRepeat="no-repeat" d="flex" alignItems="center" flexDirection="row" justifyContent="space-between">
            <Box ml={12} mt={4}>
                <Text fontSize="4xl" fontWeight="bold" color="white">Explore Our Courses</Text>
                <Box width="30em" mt={3}>
                    <Text fontSize={12} color="white">
                        Hone and build up your skills by exploring and taking
                        any of our courses below. You can also add to your
                        current skill sets and become better at it
                    </Text>
                </Box>
            </Box>
            <Image src={career} height="12em"></Image>

        </Box>
    );
}

export default Banner;