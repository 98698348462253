import {Text, Container, Flex, Link, Divider, Wrap, LinkBox, LinkOverlay, InputGroup, Input, InputRightElement, Box } from "@chakra-ui/react";
import coursesData from "../../data/courses.json";
import Banner from "../../components/Student/Banner";
import CourseCardWRating from "../../components/Student/CourseCard_w_rating";
import CourseCardWTag from "../../components/Student/CourseCard_w_tag";
import { ChangeEvent, useEffect, useState } from "react";
import { EmptyFeaturedCourse } from "../../components/Student/EmptyState/FeaturedCourse";
import { fetchStudentExploreCourses } from "../../services/student/course-http.service";
import { studentCourseUtil } from "../../utils/course.util";
import { IStudentCourseList } from "../../models/course.model";
import { StudentRoutes } from "./routes";
import { ScreenLoader } from "../../components/Common/ScreenLoader";
import { RiSearch2Line } from "react-icons/ri";
import { EmptyCourseSearch } from "../../components/Student/EmptyState/EmptyCourseSearch";

const Explore = () => {

    const [upcomingCourses, setUpcomingCourses] = useState([]);

    const [selfPacedCourses, setSelfPacedCourses] = useState([]);

    const [featuredCourses, setFeaturedCourses] = useState<IStudentCourseList[]>([]);
    
    const [filteredFeaturesCourses, setfilteredFeaturesCourses] = useState<IStudentCourseList[]>([]);

    const [isLoadingFeatured, setIsLoadingFeatured] = useState(true);
    
    const [isSearchEmpty, setIsSearchEmpty] = useState(false);


    useEffect(() => {
        fetchStudentExploreCourses()
        .then((data) => {
            console.log(data)
            setfilteredFeaturesCourses(studentCourseUtil(data));
            setFeaturedCourses(studentCourseUtil(data));
            setIsLoadingFeatured(false);
        })
        .catch((err) =>  err)
            
    }, []);

    const handleSearchChange  = (event: ChangeEvent<HTMLInputElement>) => {
        
        let searchTerm = event.target.value.toLowerCase();
        
        let filtered = featuredCourses.filter(each => each.title.toLowerCase().includes(searchTerm) );
        
        setfilteredFeaturesCourses(filtered);
        
        if(filtered.length > 0)
            setIsSearchEmpty(false);
        else
            setIsSearchEmpty(true)
    }

    return (
        <>
            <Banner/>

            {
                isLoadingFeatured? 

                <ScreenLoader /> 

                :

                <Box>
                    <Container boxShadow="base" p={5} maxW="container.xl" bg="white" mt={4}>
                        <InputGroup color="brand.subtitle" bg="white" width="40%">
                            <Input placeholder="Search for course" onChange={ e => handleSearchChange(e)} fontSize={12} size="sm" />

                            <InputRightElement h="1.9rem" pointerEvents="none" children={<RiSearch2Line size="0.9rem"  />} />
                        </InputGroup>
                    </Container>

                    <Container boxShadow="base" p={0} maxW="container.xl" pb={0.1} mt={4} mb={6} bg="white">

                        <Flex direction="row" justifyContent="space-between" px={8} py={3} >
                            <Text color="brand.3" fontSize={14} fontWeight="semibold" >Featured Courses </Text>
                            { filteredFeaturesCourses.length > 3 && <Link color="brand.2" fontSize={12} fontWeight="semibold" ></Link> }
                        </Flex>

                        <Divider orientation="horizontal" color="#D5D5D5" border="1px" mb={3} />

                        {
                            filteredFeaturesCourses.length === 0 ?

                            isSearchEmpty ? 

                            <EmptyCourseSearch />

                            :

                            <EmptyFeaturedCourse />
                            
                            :

                            <Wrap px={8} pb={6}>
                                {
                                    filteredFeaturesCourses.map((each, index) => (

                                        <LinkBox key={index}>
                                            <LinkOverlay href={ StudentRoutes.Course.EnrollLink(each.courseId) } >
                                                <CourseCardWRating key={index} {...each} />
                                            </LinkOverlay>
                                        </LinkBox>
                                    ))
                                }
                            </Wrap>
                        }

                    </Container>
                </Box>               
            }
            

            {
                upcomingCourses.length > 0 &&

                <Container boxShadow="base" p={0} maxW="container.xl" pb={0.1} mt={4} mb={6} bg="white">

                    <Flex direction="row" justifyContent="space-between" px={8} py={3} >
                        <Text color="brand.3" fontSize={14} fontWeight="semibold" >Upcoming Courses </Text>
                         { upcomingCourses.length > 3 && <Link color="brand.2" fontSize={12} fontWeight="semibold" >See all</Link> }
                    </Flex>

                    <Divider orientation="horizontal" color="#D5D5D5" border="1px" mb={3} />

                    <Flex px={8} pb={6}>
                        {
                            coursesData.upcoming.map((each, index) => (

                                <CourseCardWTag key={index} data={each} />
                            ))
                        }
                        
                    </Flex>

                </Container>
            }

            {
                selfPacedCourses.length > 0 && 

                <Container boxShadow="base" p={0} maxW="container.xl" pb={0.1} mt={4} mb={6} bg="white">

                    <Flex direction="row" justifyContent="space-between" px={8} py={3} >
                        <Text color="brand.3" fontSize={14} fontWeight="semibold" >Self-Paced Courses </Text>
                        { selfPacedCourses.length > 3 && <Link color="brand.2" fontSize={12} fontWeight="semibold" >See all</Link> }
                    </Flex>

                    <Divider orientation="horizontal" color="#D5D5D5" border="1px" mb={3} />

                    <Flex px={8} pb={6}>
                        {
                            coursesData.selfPaced.map((each, index) => (

                                <CourseCardWRating key={index} {...each} />
                            ))
                        }
                        
                    </Flex>

                </Container>
            }

        </>            
    );
}

export default Explore;