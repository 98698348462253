import { Avatar, Box, Button, Container, Flex, Image, Text, VStack, Input, AvatarGroup, InputGroup, InputRightElement } from "@chakra-ui/react";
import {AiOutlineUsergroupAdd} from 'react-icons/ai';
import {InstructorRoutes} from '../../pages/Instructor/routes';
import { Route, Switch, useHistory} from 'react-router-dom';
import CreateGroup from '../../components/Instructor/CreateGroup';
import { ChangeEvent, useEffect, useState } from "react";
import { fetchGroups } from "../../services/instructor/courses-http.service";
import { groupsListUtil } from "../../utils/course.util";
import { EmptyGroup } from "../../components/Instructor/EmptyState/Groups";
import { ScreenLoader } from "../../components/Common/ScreenLoader";
import { IGroupList } from "../../models/group.model";
import { RiSearch2Line } from "react-icons/ri";
import { EmptySearchResult } from "../../components/Common/EmptySearchResult";


const Groups = () => {

    const [groups, setGroups] = useState<IGroupList[]>([]);
    
    const [filteredGroups, setFilteredGroups] = useState<IGroupList[]>([]);
    
    const [isLoading, setIsLoading] = useState(true);

    const [isSearchEmpty, setIsSearchEmpty] = useState(false);

    useEffect(() => {
        fetchGroups()
        .then((data) => {
            console.log(data)
            console.log(groupsListUtil(data))
            if(data)
                setIsLoading(false) 
            setFilteredGroups(groupsListUtil(data));
            setGroups(groupsListUtil(data)); 
        })
        .catch((err) => err)
            
    }, []);

    let history = useHistory();

    const createGroupView = () => {

        history.push(InstructorRoutes.Group.Create);
    }

    const handleSearchChange  = (event: ChangeEvent<HTMLInputElement>) => {
        
        let searchTerm = event.target.value.toLowerCase();
        
        let filtered = groups.filter(each => each.topic.toLowerCase().includes(searchTerm) );
        
        setFilteredGroups(filtered);
        
        if(filtered.length > 0)
            setIsSearchEmpty(false);
        else
            setIsSearchEmpty(true)
    }
console.log(filteredGroups)

    return (

        <Switch>
            <Route exact path={InstructorRoutes.Groups}>
                
                <Container boxShadow="base" maxW="container.xl" bg="white" py={3} px={10} mb={6} minHeight="70vh">

                    {
                        isLoading?
                        <ScreenLoader />
                        :
                        filteredGroups.length === 0 ? 

                        <EmptyGroup createGroupView={createGroupView} />

                        :

                        <Box>

                            <Text color="brand.3" fontSize={18} fontWeight="semibold">
                                Groups
                            </Text>

                            <Flex mt={6} justifyContent="space-between">

                                <Box width="50%" display="inline-flex">
                                    <InputGroup color="brand.subtitle" bg="white" width="60%">
                                        <Input placeholder="Search your groups"  onChange={ e => handleSearchChange(e)} fontSize={12} size="sm" />

                                        <InputRightElement h="1.9rem" pointerEvents="none" children={<RiSearch2Line size="0.9rem"  />} />
                                    </InputGroup>
                                </Box>

                                <Button colorScheme="brand" onClick={createGroupView} leftIcon={<AiOutlineUsergroupAdd style={{'fontSize': '18'}} />} size="sm" fontSize={11}>New Group</Button>
                            </Flex>

                            <Box my={8}>

                                {
                                    isSearchEmpty ? 

                                    <EmptySearchResult />

                                    :

                                    filteredGroups.map((each, index) => 
                                        
                                        <Box key={index} border="1px" boxSizing="border-box" borderColor="brand.borderBox" py={3} px={8} mb={4}>

                                            <Flex justifyContent="space-between" alignItems="center">

                                                <Box display="flex" width='30%'>
                                                    <Image src={each.group_cover_img} fallbackSrc="https://via.placeholder.com/150" height="auto" maxWidth="50px" />
                                                
                                                    <VStack alignItems="start" ml={8}>
                                                        <Text fontSize={11} color="brand.subtitle" >Topic</Text>
                                                        <Text fontSize={13} whiteSpace='nowrap' color="brand.3">{each.topic}</Text>
                                                    </VStack>
                                                </Box>
                                                
                                                <VStack alignItems="start" width="10%">
                                                    <Text fontSize={11} color="brand.subtitle" >Students</Text>
                                                    <AvatarGroup size="sm" fontSize={10} color="brand.2" max={3}>
                                                        {
                                                            each.students.map((eachStudent, studentIndex) => 
                                                                <Avatar name={eachStudent.fullName} src={eachStudent.img_url} key={studentIndex} />
                                                            )
                                                        }
                                                    </AvatarGroup>
                                                </VStack>

                                                <VStack alignItems="start">
                                                    <Text fontSize={11} color="brand.subtitle" >Discussions</Text>
                                                    <Text fontSize={12} color="brand.3" >{each.discussions}</Text>
                                                </VStack>

                                                <VStack alignItems="start">
                                                    <Text fontSize={11} color="brand.subtitle" >Last Activity</Text>
                                                    <Text fontSize={12} color="brand.3">{each.lastActivity}</Text>
                                                </VStack>

                                                {
                                                    each.unread === 0 ?
                                                    <Box></Box> : <Avatar name={each.unread.toString()} size="sm" bg="brand.2"  />
                                                }

                                            </Flex>

                                        </Box>
                                    )
                                }

                            </Box>

                        </Box>

                    }
                                   
                </Container>

            </Route>

            <Route path={InstructorRoutes.Group.Create}>
                
                <CreateGroup />
                
            </Route>
            
        </Switch>          
        
    );
}

export default Groups;