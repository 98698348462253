import { Box, Container, Flex, HStack, Image, Input, InputGroup, Text, InputRightElement, Avatar, useDisclosure, Portal, AvatarGroup, Button } from "@chakra-ui/react";
import { Table, Thead, Tbody, Tr, Th, Td} from "@chakra-ui/react"
import { RiSearch2Line } from "react-icons/ri";
import { ChangeEvent, useEffect, useState } from "react";
import { EmptyCourseTM } from "../../components/TrainingManager/EmptyState/Courses";
import { fetchAssignedCourses,fetchMandatoryAssignedCourses } from "../../services/trainingMgr/course-http.service";
import { courseAssignListUtil } from "../../utils/course.util";
import { ICourseEnrollmentStatusList, IProgress, ITrainingMgrCourseList } from "../../models/course.model";
import { EmptySearchResult } from "../../components/Common/EmptySearchResult";
import { ScreenLoader } from "../../components/Common/ScreenLoader";
import { BsPlus } from "react-icons/bs";
import { AssignCourseModal } from "../../components/TrainingManager/AssignCourseModal";
import { BridgeCounStatusDto, IBridgeCountData, IUser } from "../../models/auth.model";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react"
import { async } from "rxjs";
import { ViewBridgeCountModal } from "../../components/TrainingManager/ViewBridgeCountModal";
import { courseEnroledStatus } from '../../utils/course.util';
import { fetchBridgeCountStatus } from "../../services/student/course-http.service";
import { fetchBridgeCount } from "../../services/trainingMgr/course-http.service";
import { usePagination } from "@ajna/pagination";


const AssignCourse = () => {

    const [assignCourses, setAssignCourses] = useState<ITrainingMgrCourseList[]>([]);
    
    const [mandatoryAssignedCourses, setMandatoryAssignedCourses] = useState<ITrainingMgrCourseList[]>([]);
    
    const [filteredMandatoryAssignedCourses, setFilteredMandatoryAssignedCourses] = useState<ITrainingMgrCourseList[]>([]);
    
    const [filteredAssignCourses, setfilteredAssignCourses] = useState<ITrainingMgrCourseList[]>([]);

    const [isSearchEmpty, setIsSearchEmpty] = useState(false);
    
    const [isSearchMandatoryEmpty, setIsSearchMandatoryEmpty] = useState(false);

    const [isSearchUserStatusEmpty, setIsSearchUserStatusEmpty] = useState(false);

    const [isLoading, setIsLoading] = useState(true);
    
    const { isOpen, onOpen, onClose } = useDisclosure()

    const [selectedCourse, setselectedCourse] = useState('');

    const [enrolledStudentIds, setEnrolledStudentIds] = useState<string[]>([]);

    const [searchTerm, setSearchTerm] = useState('');

    // const [user, setUser] = useState<IUser[]>([])

    const [userStatus, setUserStatus] = useState<BridgeCounStatusDto[]>([])

    const [filtereduserStatus, setfilteredUserStatus] = useState<BridgeCounStatusDto[]>([])



    const [isCourse, setCourse] = useState(false);

    const [isBridgeCount, setBridgeCount] = useState(false);


        


     useEffect(() => {

        fetchAssignedCourses()
         .then((data) => {
            setfilteredAssignCourses(courseAssignListUtil(data));
            setAssignCourses(courseAssignListUtil(data));
            setIsLoading(false);

            fetchMandatoryAssignedCourses()
            .then(data => {
                setFilteredMandatoryAssignedCourses(courseAssignListUtil(data))
                setMandatoryAssignedCourses(courseAssignListUtil(data))
            })
        })
        .catch((err) => (err))

    }, []);

    const handleSearchChange  = (event: ChangeEvent<HTMLInputElement>) => {
        
        let searchTerm = event.target.value.toLowerCase();

        setSearchTerm(searchTerm);
        
        let filtered = assignCourses.filter(each => each.title.toLowerCase().includes(searchTerm) );
        
        let mandatoryAssignedFiltered = mandatoryAssignedCourses.filter(each => each.title.toLowerCase().includes(searchTerm) );

        setfilteredAssignCourses(filtered);
        
        setFilteredMandatoryAssignedCourses(mandatoryAssignedFiltered);

        if(mandatoryAssignedFiltered.length > 0)
            setIsSearchMandatoryEmpty(false)
        else
            setIsSearchMandatoryEmpty(true)
        
        if(filtered.length > 0)
            setIsSearchEmpty(false);
        else
            setIsSearchEmpty(true)
           
        
  
    }

    

    const setCourseId = (each: ITrainingMgrCourseList) => {
        setselectedCourse(each.courseId as string);
        setEnrolledStudentIds([...each.students?.map(each => each.user_id) as string[]]);
        setCourse(true)
        setBridgeCount(false)
        onOpen();
    }


    const ViewBridgeCount = async( id:number) => {
        fetchBridgeCountStatus(id)
        .then((data) => {
          setUserStatus(data.results)
          setfilteredUserStatus(data.results)
          setBridgeCount(true)
           setCourse(false);
           onOpen()
          
        })
        .catch((err) => err)
     
    }

    const handleUserSearchChange  = (event: ChangeEvent<HTMLInputElement>) => {
        
        let searchTerm = event.target.value.toLowerCase();
          
          setSearchTerm(searchTerm)

          let filteredUserStatus = userStatus.filter(each => each.user.full_name.toLowerCase().includes(searchTerm));
          
          setfilteredUserStatus(filteredUserStatus)
           
          if(filteredUserStatus.length > 0)
            setIsSearchUserStatusEmpty(false);
          else
            setIsSearchUserStatusEmpty(true)


        // if(searchTerm !== ''){
        //     setSearchTerm(searchTerm);

        //     let filteredUserStatus = filtereduserStatus.filter(each => each.user.full_name.toLowerCase().includes(searchTerm));
    
        //     setfilteredUserStatus(filteredUserStatus)
            
        //     if(filteredUserStatus.length > 0)
        //         setIsSearchUserStatusEmpty(false);
        //     else
        //     setIsSearchUserStatusEmpty(true)
        // }else if(searchTerm === ''){
        //     setfilteredUserStatus(userStatus);
        // }

        
    }

    const updateRecord = (students: IUser[]) => {

        let courseId = selectedCourse;

        let foundCourse = assignCourses.filter(each => each.courseId === courseId).at(0);
        
        let foundCourseIndex = assignCourses.findIndex(each => each.courseId === courseId);

        foundCourse?.students?.push(...students)

        assignCourses.splice(foundCourseIndex, 1, foundCourse as ITrainingMgrCourseList);

        setAssignCourses(assignCourses);

        let filtered = assignCourses.filter(each => each.title.toLowerCase().includes(searchTerm) );
    
        setfilteredAssignCourses(filtered);
    }




   
        
    







    return (

        <Container boxShadow="base" maxW="container.xl" bg="white" pt={3} pb={10} px={8} minHeight="70vh">

            {
                isLoading? 
                <ScreenLoader />
                
                :
                assignCourses.length === 0 ? 
                <EmptyCourseTM />
                :
                <Box>

                    <Flex justifyContent="space-between">
                        <Text pl={6} color="brand.3" fontSize={18} fontWeight="semibold"> Assign Course </Text>
                    </Flex>

                    <Flex justifyContent="space-between" alignItems="center" px={6} mt={6} mb={3}>
                        <InputGroup color="brand.subtitle" bg="white" width="40%" >
                            <Input placeholder="Search all courses" onChange={ e => handleSearchChange(e)} fontSize={12} size="sm" />

                            <InputRightElement h="1.9rem" pointerEvents="none" children={<RiSearch2Line size="0.9rem"  />} />
                        </InputGroup>
                    </Flex>

                    <Tabs colorScheme="brand" px={10}>

                        <TabList borderBottom="1px"  color="brand.subtitle" >
                            <Tab mr={10} _focus={{boxShadow: "none"}} mb={3} fontSize={13} px={1} >Assign</Tab>
                            
                            <Tab  _focus={{boxShadow: "none"}} mb={3} fontSize={13} px={1} >Mandated</Tab>
                        </TabList>

                        <TabPanels >
                         
                            <TabPanel p={0}>
                                {   
                                    isSearchEmpty ?

                                    <Box pt={20}>
                                        <EmptySearchResult />
                                    </Box>

                                    :

                                    <Table variant="simple" >
                                        <Thead fontWeight="normal" >
                                            <Tr color="brand.subtitle" >
                                                <Th textTransform="none">Name</Th>
                                                <Th textTransform="none">Instructor</Th>
                                                <Th textTransform="none">Date Created</Th>
                                                <Th textTransform="none">Assign To</Th>
                                            </Tr>
                                        </Thead>

                                        <Tbody>
                                            {
                                                filteredAssignCourses.map((each, index) => 
                                                    <Tr key={index} fontSize={13} color="brand.subtitle">
                                                        <Td>
                                                            <HStack>
                                                                <Image src={each.cover_image} fallbackSrc="https://via.placeholder.com/150" height="auto" maxWidth="50px" />
                                                                <Text fontSize={11}>{each.title}</Text>                                                
                                                            </HStack>                                            
                                                        </Td>
                                                        <Td>
                                                            <HStack>
                                                                <Avatar size="xs" name={each.author} src={each.img_url} />
                                                                <Text isTruncated fontSize={11}>{each.author}</Text>                                                
                                                            </HStack>
                                                        </Td>
                                                        <Td fontSize={12} whiteSpace="nowrap" >{each.date_added}</Td>
                                                        <Td> 
                                                        <HStack alignItems="center" spacing={4}>
                                                                {/* <Switch />
                                                                <Text>OR</Text> */}
                                                                <HStack spacing={2}>
                                                                    <AvatarGroup size="xs" fontSize={10} color="brand.2" max={3}>
                                                                        {
                                                                            each.students?.map( (eachStudent, studentIndex) => 
                                                                                <Avatar key={studentIndex} name={eachStudent.full_name} src={eachStudent.photo_url} />
                                                                            )
                                                                        }
                                                                    </AvatarGroup>

                                                                    <Box width={6} height={6} borderRadius={20} border="dotted" justifyContent="center" alignItems="center" cursor="pointer"  onClick={ () => setCourseId(each)  }>
                                                                   
                                                                        <Flex direction="column" justifyContent="center" height="100%" alignItems="center" fontSize={16}>
                                                                            <BsPlus   />
                                                                        </Flex>
                                                                    </Box>
                                                                    
                                                                </HStack>
                                                                
                                                            </HStack>
                                                        </Td>
                                                    </Tr>
                                                )
                                            }
                                            
                                        </Tbody>
                                    </Table>
                                }
                            </TabPanel>
                            
                            <TabPanel p={0}>
                                {
                                    isSearchMandatoryEmpty ?

                                    <Box pt={20}>
                                        <EmptySearchResult />
                                    </Box>

                                    :

                                    <Table variant="simple" >
                                        <Thead fontWeight="normal" >
                                            <Tr color="brand.subtitle" >
                                                <Th textTransform="none">Name</Th>
                                                <Th textTransform="none">Instructor</Th>
                                                <Th textTransform="none">Date Assigned</Th>
                                                <Th textTransform="none" whiteSpace={"nowrap"}>Assigned To</Th>
                                                <Th textTransform="none" whiteSpace={"nowrap"} >Bridge Count</Th>
                                                <Th textTransform="none"></Th>
                                            </Tr>
                                        </Thead>

                                        <Tbody>
                                            {
                                                filteredMandatoryAssignedCourses.map((each, index) => 
                                                    <Tr key={index} fontSize={13} color="brand.subtitle">
                                                        <Td>
                                                            <HStack>
                                                                <Image src={each.cover_image} fallbackSrc="https://via.placeholder.com/150" height="auto" maxWidth="50px" />
                                                                <Text fontSize={11}>{each.title}</Text>                                                
                                                            </HStack>                                            
                                                        </Td>
                                                        <Td>
                                                            <HStack>
                                                                <Avatar size="xs" name={each.author} src={each.img_url} />
                                                                <Text isTruncated fontSize={11}>{each.author}</Text>                                                
                                                            </HStack>
                                                        </Td>
                                                        <Td fontSize={12} whiteSpace="nowrap" >{each.date_added}</Td>
                                                        <Td> 
                                                            {each.students?.length}
                                                        </Td>
                                                        <Td>
                                                            0
                                                        </Td>
                                                        <Td>
                                                            <Button  onClick={() => ViewBridgeCount(each.id)} variant="outline" colorScheme="brand" size="sm" fontSize={11} borderRadius={20} px={6} >View</Button>
                                                        </Td>
                                                    </Tr>
                                                )
                                            }
                                            
                                        </Tbody>
                                    </Table>
                                }
                                
                            </TabPanel>
                            
                        </TabPanels>
                    
                    </Tabs>
                    
                </Box>
            }

            {selectedCourse && <Portal> 
            <AssignCourseModal 
            selectedCourseId={selectedCourse}
             studentIds={enrolledStudentIds} 
             updateRecord={updateRecord} 
             isOpen={isOpen} 
             onClose={onClose} 
             isCourse={isCourse} />
            </Portal>}

           {userStatus && <Portal>
              {" "}
                <ViewBridgeCountModal
                isOpenn={isOpen}
                onClosed={onClose}
                BridgeArray = {filtereduserStatus}
                isBridgeCount={isBridgeCount}
                handleUserSearchChange={handleUserSearchChange}
                isSearchUserStatusEmpty={isSearchUserStatusEmpty}
                
               
            />{" "}
            </Portal>}

        </Container>   
   );
}

export default AssignCourse;