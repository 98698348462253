import { CancelTokenSource } from "axios";
import { IHrActivityReport, IHrDashboard, IHrTopCourses, IHrTopInstructors, IHrTopStudents, IPaginatedUserDto, IUser, IUserDashboard } from "../../models/auth.model";
import { ICategory, IPaginatedCourseDto, IPaginatedCourseReviewDto } from "../../models/course.model";
import { HttpResponse } from "../../models/httpResponse.model";
import { IUserCreateDto, IUserEditDto, IPaginatedAnyUserDto, IAnyUserList, toggleUsersStatus } from "../../models/user.model";
import {httpClient, axios} from "../httpClient"

export let cancleRequest: CancelTokenSource;



export const fetchUsers = async (role_id:any, url: string = "" ) => {

    let response = await httpClient.get<HttpResponse<IPaginatedUserDto>>(`/hr/roles/${role_id}/users${url}`);
    
    return response.data.data;
}

export const fetchAllUsers = async (url: string = "" ) => {

    let response = await httpClient.get<HttpResponse<IPaginatedUserDto>>(`/hr/users${url}`);
    
    return response.data.data;
}


 export const fetchAllRoles = async () => {

    let response = await httpClient.get<HttpResponse>(`/auth/role`);
    
    return response.data.data;
}

export const AssignAllRoles = async (data: {roleId: number, userId: number}) => {

    let response = await httpClient.post<HttpResponse>(`/hr/assign-role`, data);
    
    return response.data.statusCode;
}



export const fetchBlacklistedWords = async () => {

    let response = await httpClient.get<HttpResponse>(`/hr/profanity`);
    return response.data.data;

    
}


export const uploadBlacklistedWords = async (data:any) => {

    let response = await httpClient.patch<HttpResponse>(`/hr/profanity`,data);
    return response.data.statusCode;

    
}




export const createBatches = async (data: any) => {
    

    let response = await httpClient.post<HttpResponse<any>>("/hr/users/batch-register", data, {headers:{ "Content-Type": "multipart/form-data"}});   
    return response.data.statusCode;
}




export const genExcel = async () => {

    let response = await httpClient.get<HttpResponse<any>>(`/hr/roles/1/users/download`, { responseType: 'arraybuffer' });
   

    return response.data;
}
export const genExcelFilter = async (filter: number[]) => {
    let params = filter.toString()
        let response = await httpClient.get<HttpResponse<any>>(`/hr/roles/1/users/download?userFilter=${params}`, { responseType: 'arraybuffer' });
       
    
        return response.data;
    }
    


export const uploadBatchUserUpload = async (data: FormData) => {

    let response = await httpClient.post<HttpResponse<any>>("/hr/users/read-batch-file", data, {headers:{ "Content-Type": "multipart/form-data"}});   
    return response.data.data;
}



export const createUser = async (data: IUserCreateDto) => {

    let response = await httpClient.post<HttpResponse<IUser>>(`/hr/users/create`, data);

    return response.data.data;
}

export const editUserProfile = async (userId: number, data: IUserEditDto) => {

    let response = await httpClient.patch<HttpResponse<IUser>>(`/hr/users/${userId}`, data);

    return response.data.data;
}

export const getUploadableUnit = async (userId: number) => {
    let response = await httpClient.get<HttpResponse<ICategory[]>>(`/hr/uploadable-units/${userId}`);

    return response.data.data;
}

export const fetchByRole = async (roleId: number, url: string = "") => {
    let response = await httpClient.get<HttpResponse<IPaginatedAnyUserDto>>(`/hr/roles/users/${url}`);

    return response.data.data;
}

export const updateStatus = async (data:toggleUsersStatus[]) => {
    let response = await httpClient.patch<HttpResponse<any>>(`/hr/users/status`, data);

    return response.data.data;
}

export const fetchAllCourseReview = async (url: string = "") => {
    let response = await httpClient.get<HttpResponse<IPaginatedCourseDto>>(`/hr/courses/reviews/${url}`);
    return response.data.data;
}

export const fetchCourseReview = async (reviewId: string, url: string = "") => {
    let response = await httpClient.get<HttpResponse<IPaginatedCourseReviewDto>>(`/hr/courses/${reviewId}/reviews${url}`);
    return response.data.data;
}

export const fetchUserOverview = async (userId: string) => {
    let response = await httpClient.get<HttpResponse<IUserDashboard>>(`/hr/users/${userId}/overview`);
    return response.data.data;
}

export const getStudentOverview = async (userId: string) => {
    let response = await httpClient.get<HttpResponse<IUserDashboard>>(`/hr/users/${userId}/student-overview`);
    return response.data.data;
}

export const getDashboard = async () => {
    let response = await httpClient.get<HttpResponse<IHrDashboard>>(`/hr/dashboard`);
    return response.data.data;
}

export const gettopInstructors = async (role_id: any, url: string ="") => {
    let response = await httpClient.get<HttpResponse<IHrTopInstructors>>(`/hr/roles/${role_id}/users${url}`);
    return response.data.data;
}

export const gettopStudents = async (role_id: any, url: string ="") => {
    let response = await httpClient.get<HttpResponse<IHrTopStudents>>(`/hr/roles/${role_id}/users${url}`);
    return response.data.data;
}

export const gettopCourses = async ( url: string ="") => {
    let response = await httpClient.get<HttpResponse<IHrTopCourses>>(`/hr/top-courses${url}`);
    return response.data.data;
}


export const fetchActReport = async () => {

    let response = await httpClient.get<HttpResponse<IHrActivityReport>>(`/shared/activity-report`);
    
    return response.data.data;
}


// export const fetchSelectedDepartment = async ( category_id: any, url: string = "" ) => {

//     let response = await httpClient.get<HttpResponse<IPaginatedUserDto>>(`/hr/departments/${category_id}/${url}`);
    
//     return response.data.data;
// }