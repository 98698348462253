import {
  Avatar,
  Box,
  Button,
  Container,
  Flex,
  HStack,
  IconButton,
  Image,
  Input,
  InputGroup,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  InputRightElement,
  Link,
  Text,
  Collapse,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Link as ReactLink,
  Route,
  Switch,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import {
  RiDashboardFill,
  RiSearch2Line,
  RiUserSettingsLine,
} from "react-icons/ri";
import { BsBell, BsStar } from "react-icons/bs";
import blucanaryLogo2 from "../../img/logo2.png";
import { HiOutlineAcademicCap } from "react-icons/hi";
import { BiBookAdd, BiBookBookmark, BiUser } from "react-icons/bi";
import { MdKeyboardArrowDown, MdSecurity } from "react-icons/md";
import { FiBarChart2, FiUsers } from "react-icons/fi";
import {
  destroyUserSession,
  getLocalUserProfile,
} from "../../services/auth.service";
import { StoreContext } from "../../store/StoreContext";
import { useContext, useEffect } from "react";
import { NavLink } from "../Common/NavLink";
import { Error404 } from "../Common/Error404";
import { HrAdminRoutes } from "../../pages/HrAdmin/routes";
import AllUsers from "../../pages/HrAdmin/Users/AllUsers";
import Students from "../../pages/HrAdmin/Users/Students";
import Instructors from "../../pages/HrAdmin/Users/Instructors";
import CreateUser from "../../pages/HrAdmin/Users/CreateUser";
import Dashboard from "../../pages/HrAdmin/Dashboard";
import UserDashboard from "../../pages/HrAdmin/UserDashboard";
import Profile from "../../pages/HrAdmin/Profile";
import Reviews from "../../pages/HrAdmin/Reviews";
import Department from "../../pages/HrAdmin/Department"
import ViewReview from "../../pages/HrAdmin/ViewReview";
import Security from "../../pages/HrAdmin/Security";
import { IoMdStarOutline } from "react-icons/io";
import BatchUpload from "../../pages/HrAdmin/Users/BatchUpload";
import { FaChartBar, FaLayerGroup } from "react-icons/fa";
import Report from "../../pages/HrAdmin/Report";
import ViewEachDepartment from "../../pages/HrAdmin/ViewEachDepartment";
import EachStudentOverview from "../../pages/HrAdmin/EachStudentOverview";
import AllEnrolments from "../../pages/HrAdmin/Reports/AllEnrolments";
import InstructorReport from "../../pages/HrAdmin/Reports/InstructorReports";


export const HrAdminMain: React.FC<any> = () => {
  const { globalState, actions } = useContext(StoreContext);

  const { isOpen: isOpenUsers, onToggle: onToggleUsers } = useDisclosure();
  const { isOpen: isOpenReports, onToggle: onToggleReports } = useDisclosure();

  let { isExact } = useRouteMatch();

  let { location, push, replace } = useHistory();

  useEffect(() => {
    let user = getLocalUserProfile();

    if (user != null) {
      actions?.updateProfile(user);
    }
  }, []);

  const handleLogout = () => {
    destroyUserSession();
    replace("/");
  };

  const activeLink = (link: string) => {
    let style = {
      borderLeftColor: "white",
      bg: "white",
    };

    if (link === location.pathname) {
      style.bg = "brand.1";
      style.borderLeftColor = "brand.2";
    }

    return style;
  };

  return (
    <Box minHeight="100vh">
      <Flex>
        <Container
          width={"18%"}
          maxWidth={"18%"}
          height={"100%"}
          lineHeight={"100%"}
          pt={4}
          boxShadow="base"
          bg="white"
          position="fixed"
          px={0}
        >
          <Image pl={10} src={blucanaryLogo2} width="70%" height="auto" />

          <Flex direction="column" fontSize={13} color="brand.bodyText" pt={12}>
            <NavLink
              pl={9}
              label="Dashboard"
              to={HrAdminRoutes.Main}
              activeOnlyWhenExact={true}
              icon={<RiDashboardFill size={18} />}
            />

            <Box py={2} borderLeftWidth="4px">
              <Box cursor="pointer" pb={5} onClick={onToggleUsers}>
                {" "}
                <Box pl={9} display="flex" alignItems="end">
                  {" "}
                  <BiUser size={18} />{" "}
                  <Text mr={2} pl={3}>
                    Users
                  </Text>{" "}
                  <MdKeyboardArrowDown fontSize={18} />
                </Box>
              </Box>

              <Collapse in={isOpenUsers}>
                <Link as={ReactLink} to={HrAdminRoutes.Users.Student}>
                  {" "}
                  <Box
                    mb={5}
                    py={2}
                    borderLeftWidth="4px"
                    bg={activeLink(HrAdminRoutes.Users.Student).bg}
                    borderLeftColor={
                      activeLink(HrAdminRoutes.Users.Student).borderLeftColor
                    }
                    pl="5em"
                  >
                    {" "}
                    Students
                  </Box>{" "}
                </Link>
                <Link as={ReactLink} to={HrAdminRoutes.Users.Instructor}>
                  {" "}
                  <Box
                    mb={5}
                    py={2}
                    borderLeftWidth="4px"
                    bg={activeLink(HrAdminRoutes.Users.Instructor).bg}
                    borderLeftColor={
                      activeLink(HrAdminRoutes.Users.Instructor).borderLeftColor
                    }
                    pl="5em"
                  >
                    {" "}
                    Instructors
                  </Box>{" "}
                </Link>
                <Link as={ReactLink} to={HrAdminRoutes.Users.Manage}>
                  {" "}
                  <Box
                    mb={5}
                    py={2}
                    borderLeftWidth="4px"
                    bg={activeLink(HrAdminRoutes.Users.Manage).bg}
                    borderLeftColor={
                      activeLink(HrAdminRoutes.Users.Manage).borderLeftColor
                    }
                    pl="5em"
                  >
                    {" "}
                    All Users
                  </Box>{" "}
                </Link>
                <Link as={ReactLink} to={HrAdminRoutes.Users.Create}>
                  {" "}
                  <Box
                    mb={5}
                    py={2}
                    borderLeftWidth="4px"
                    bg={activeLink(HrAdminRoutes.Users.Create).bg}
                    borderLeftColor={
                      activeLink(HrAdminRoutes.Users.Create).borderLeftColor
                    }
                    pl="5em"
                  >
                    {" "}
                    Create User
                  </Box>{" "}
                </Link>
              </Collapse>
            </Box>

            <Box py={2} borderLeftWidth="4px">
              <Box cursor="pointer" pb={5} onClick={onToggleReports}>
                {" "}
                <Box pl={9} display="flex" alignItems="end">
                  {" "}
                  <BiUser size={18} />{" "}
                  <Text mr={2} pl={3}>
                    Reports
                  </Text>{" "}
                  <MdKeyboardArrowDown fontSize={18} />
                </Box>
              </Box>

              <Collapse in={isOpenReports}>
                <Link as={ReactLink} to={HrAdminRoutes.Report}>
                  {" "}
                  <Box
                    mb={5}
                    py={2}
                    borderLeftWidth="4px"
                    bg={activeLink(HrAdminRoutes.Report).bg}
                    borderLeftColor={
                      activeLink(HrAdminRoutes.Report).borderLeftColor
                    }
                    pl="5em"
                  >
                    {" "}
                    Report
                  </Box>{" "}
                </Link>
                <Link as={ReactLink} to={HrAdminRoutes.AllEnrolments}>
                  {" "}
                  <Box
                    mb={5}
                    py={2}
                    borderLeftWidth="4px"
                    bg={activeLink(HrAdminRoutes.AllEnrolments).bg}
                    borderLeftColor={
                      activeLink(HrAdminRoutes.AllEnrolments).borderLeftColor
                    }
                    pl="5em"
                  >
                    {" "}
                    Student Reports
                  </Box>{" "}
                </Link>
                <Link as={ReactLink} to={HrAdminRoutes.InstructorReport}>
                  {" "}
                  <Box
                    mb={5}
                    py={2}
                    borderLeftWidth="4px"
                    bg={activeLink(HrAdminRoutes.InstructorReport).bg}
                    borderLeftColor={
                      activeLink(HrAdminRoutes.InstructorReport).borderLeftColor
                    }
                    pl="5em"
                  >
                    {" "}
                    Instructor Reports
                  </Box>{" "}
                </Link>

              </Collapse>
            </Box>
            <NavLink
              pl={9}
              label="Department"
              to={HrAdminRoutes.Departments.ManageDepartment}
              icon={<FaLayerGroup size={18} />}
            />
            <NavLink
              pl={9}
              label="Manage Reviews"
              to={HrAdminRoutes.Review.Manage}
              icon={<IoMdStarOutline size={18} />}
            />
         
            <NavLink
              pl={9}
              label="Edit Profile"
              to={HrAdminRoutes.Profile}
              icon={<RiUserSettingsLine size={18} />}
            />
            <NavLink
              pl={9}
              label="Security"
              to={HrAdminRoutes.Security}
              icon={<MdSecurity size={18} />}
            />
          </Flex>
        </Container>

        <Container
          width={"82%"}
          maxWidth={"82%"}
          pt={4}
          px={16}
          position="absolute"
          right={0}
        >
          <Flex justifyContent="space-between" alignItems="center">
            {!isExact ? (
              <Box></Box>
            ) : (
              <InputGroup color="brand.subtitle" bg="white" w="30%">
                {/* <Input placeholder="Search courses" fontSize={12} size="sm" /> */}

                {/* <InputRightElement h="1.9rem" pointerEvents="none" children={<RiSearch2Line size="0.9rem"  />} /> */}
              </InputGroup>
            )}

            <HStack spacing={6}>
              <IconButton
                variant="ghost"
                aria-label="bell"
                color="brand.2"
                borderRadius={20}
                icon={<BsBell />}
              />

              <Avatar
                size="sm"
                name={globalState.profile?.full_name}
                src={globalState.profile?.photo_url}
              />

              <Menu>
                <MenuButton>
                  <HStack color="brand.subtitle" fontSize={13}>
                    <Flex direction="column" alignItems="flex-end">
                      <Text whiteSpace="nowrap">
                        {" "}
                        {globalState?.profile?.full_name}{" "}
                      </Text>
                      <Text fontSize={11}>Hr Admin</Text>
                    </Flex>
                    <MdKeyboardArrowDown />
                  </HStack>
                </MenuButton>
                <MenuList fontSize={13} borderRadius={0} color="brand.bodyText">
                  <MenuItem onClick={() => push(HrAdminRoutes.Main)}>
                    Go to dashboard
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </MenuList>
              </Menu>
            </HStack>
          </Flex>

          <Container maxW="container.xl" pt={isExact ? 6 : 10} px={0}>
            <Switch>
              <Route exact path={HrAdminRoutes.Main}>
                <Dashboard />
              </Route>
              <Route exact path={HrAdminRoutes.Users.Manage}>
                <AllUsers />
              </Route>

              <Route exact path={HrAdminRoutes.Users.Student}>
                <Students />
              </Route>

              <Route exact path={HrAdminRoutes.Users.Instructor}>
                <Instructors />
              </Route>

              <Route exact path={HrAdminRoutes.Users.Create}>
                <CreateUser />
              </Route>

              <Route exact path={HrAdminRoutes.Users.BatchUpload}>
                <BatchUpload />
              </Route>

              <Route exact path={HrAdminRoutes.Users.ViewPath}>
                <UserDashboard />
              </Route>
              <Route exact path={HrAdminRoutes.Users.ViewPathS}>
                <EachStudentOverview />
              </Route>
              <Route exact path={HrAdminRoutes.Departments.ManageDepartment}>
                <Department />
              </Route>
              <Route exact path={HrAdminRoutes.Departments.ViewPath}>
                <ViewEachDepartment />
              </Route>
              <Route exact path={HrAdminRoutes.InstructorReport}>
                <InstructorReport />
              </Route>

              <Route exact path={HrAdminRoutes.Review.Manage}>
                <Reviews />
              </Route>

              <Route exact path={HrAdminRoutes.Review.ViewPath}>
                <ViewReview />
              </Route>

              <Route exact path={HrAdminRoutes.Profile}>
                <Profile />
              </Route>

              <Route exact path={HrAdminRoutes.Report}>
                <Report />
              </Route>
              <Route exact path={HrAdminRoutes.AllEnrolments}>
                <AllEnrolments />
              </Route>


              <Route exact path={HrAdminRoutes.Security}>
                <Security />
              </Route>
              <Route path="*">
                <Error404 />
              </Route>
            </Switch>
          </Container>
        </Container>
      </Flex>
    </Box>
  );
};
