import {
  Pagination,
  PaginationContainer,
  PaginationNext,
  PaginationPage,
  PaginationPageGroup,
  PaginationPrevious,
  usePagination,
} from "@ajna/pagination";
import {
  Box,
  Button,
  Container,
  Flex,
  HStack,
  Image,
  Input,
  InputGroup,
  Tag,
  Text,
  InputRightElement,
  Link,
  IconButton,
  Portal,
  useDisclosure,
  Avatar,
  VStack,
  Badge,
  Spacer,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Select,
  Progress,
  Checkbox,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/react";

import { ChevronDownIcon } from '@chakra-ui/icons'

import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from '@chakra-ui/react'

import React, { ChangeEvent, useCallback, useMemo, useRef } from "react";
import { useReactToPrint } from 'react-to-print'
import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import blucanaryLogo2 from "../../../img/logo2.png";
import { useEffect, useState } from "react";
import { ScreenLoader } from "../../../components/Common/ScreenLoader";
import { useHistory } from "react-router-dom";
import { RiSearch2Line } from "react-icons/ri";
import { EmptyCourseSearch } from "../../../components/Student/EmptyState/EmptyCourseSearch";
import { IoMdCreate } from "react-icons/io";
import { BsFillStarFill, BsThreeDotsVertical } from "react-icons/bs";
import { IDepartmentData, IEachCourseData, Ipage } from "../../../models/auth.model";
import { fetchDepartment, editDepartment, fetchAllEnrolments } from "../../../services/hr/department/department.service";
import { HrAdminRoutes } from "../routes";
import { fetchStudentEnrolment } from "../../../services/student/course-http.service";
import PaginationData from "../../../components/Common/pagination/PaginationData";
import { CourseLesson, IAllEnrolmentResult } from "../../../models/enrolment/enrolment.model";
import ExportExcel from "../../../utils/ExportExcel";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { getFullDate } from "../../../utils/app.util";
const AllEnrolments = () => {
  let { replace, push } = useHistory();
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(true);
  const [enrollmentslist, setenrollmentslist] = useState<IAllEnrolmentResult[]>([]);
  const [departmentNo, setDepartmentNo] = useState(0);
  const [page, setPage] = useState<Ipage>({ page_total: 0, total: 0 });
  const [search, setSearch] = useState("");
  const [deptFilter, setDeptFilter] = useState<number>();
  const componentRef = useRef(null)
  const cancelRef = useRef(null)
  const [show, setShow] = useState(false);
  const [checkedItems, setCheckedItems] = useState<boolean[]>([])
  const [checkedIndex, setCheckedIndex] = useState<number[]>([])
  const [checkedUsers, setCheckedUsers] = useState<any[]>([])
  const allChecked = checkedItems.every(Boolean)
  const isIndeterminate = checkedItems.some(Boolean) && !allChecked
  const [start_date, setstart_date] = useState("");
  const [end_date, setend_date] = useState('');
  const [reload, setReload] = useState(false);
  const [errorMsg, setError] = useState('');

  const [filteredDepartments, setFilteredDepartments] = useState<IDepartmentData[]>([]);
  useEffect(() => {
    let url = ''
    if (deptFilter) {
      url = `?filterBy=department&filter=${deptFilter}`
    } else if (end_date) {
      url = `?filterBy=date&filter=${start_date},${end_date}`
    }

    fetchAllEnrolments(url)
      .then((data) => {
        console.log(data)
        let studentsList: IAllEnrolmentResult[] = [];
        const enrolmentResult: IAllEnrolmentResult[] = data.results
        for (let index = 0; index < enrolmentResult.length; index++) {
          const student = enrolmentResult[index];
          if (student.courses) {
            const totalDuration = getTotalDuration(student.courses.courseLessons)

            student.totalDuration = convertSecondsToHoursMinutes(totalDuration);
          }
          studentsList.push(student)

        }
        setenrollmentslist(studentsList)
        setIsLoading(false)
      })
      .catch((err) => err)

  }, [reload, deptFilter]);
  useEffect(() => {
    let url = `?page=${1}&limit=${50}`
    fetchDepartment(url)
      .then((data) => {

        setFilteredDepartments(data.results)
        setIsLoading(false)

      })
      .catch((err) => err)

  }, []);
  function getTotalDuration(lessons: CourseLesson[]) {
    let totalDuration = 0;

    for (let i = 0; i < lessons.length; i++) {
      const lessonDuration = lessons[i].duration;

      if (lessonDuration !== null && lessonDuration !== undefined) {
        totalDuration += lessonDuration;
      }
    }

    return totalDuration || 0;
  }
  function convertSecondsToHoursMinutes(totalSeconds: number) {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);

    if (hours === 0) {
      return `${minutes}m`;
    } else {
      return `${hours}h, ${minutes}m`;
    }
  }
  const dateChange = (event: ChangeEvent<HTMLInputElement>) => {
    let date = event.target.value.toLowerCase();

    const fromDateObj = new Date(start_date);
    const toDateObj = new Date(date);

    if (toDateObj < fromDateObj) {
      setError("The 'To' date cannot be earlier than the 'From' date.");
      setend_date('');
    } else {
      setend_date(date);
      setDeptFilter(undefined)
      setReload(!reload);
      setError('')
    }

  };
  const startdateChange = (event: ChangeEvent<HTMLInputElement>) => {
    let date = event.target.value.toLowerCase();
   
    const fromDateObj = new Date(date);
    const toDateObj = new Date(end_date);

    if (toDateObj < fromDateObj) {
      setError("The 'To' date cannot be earlier than the 'From' date.");
    } else {
      setstart_date(date);
      setError('')
    }
  };


  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [searchQuery, setSearchQuery] = useState("");

  const filteredenrollmentslist = useMemo(() => {
    return enrollmentslist.filter((batch) =>
      batch.user.full_name.toString().toLowerCase().includes(searchQuery.toString().toLowerCase()) || batch.courses?.title.toString().toLowerCase().includes(searchQuery.toString().toLowerCase())
    );
  }, [enrollmentslist, searchQuery]);
  const indexOfLastItem = useMemo(() => currentPage * itemsPerPage, [currentPage, itemsPerPage]);
  const indexOfFirstItem = useMemo(() => indexOfLastItem - itemsPerPage, [indexOfLastItem, itemsPerPage]);

  const currentItems: IAllEnrolmentResult[] = useMemo(() => {
    return filteredenrollmentslist.slice(indexOfFirstItem, indexOfLastItem);
  }, [filteredenrollmentslist, indexOfFirstItem, indexOfLastItem]);
  const resetCheckedItems = useCallback(() => {
    const newCheckedItems = currentItems.map(() => false);
    setCheckedItems(newCheckedItems);
  }, [currentItems]);
  useEffect(() => {
    resetCheckedItems();
  }, [currentItems, resetCheckedItems]);


  const totalPages = Math.ceil(filteredenrollmentslist.length / itemsPerPage);


  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };
  const handleDepartmentChange = (dept: number) => {
    if (dept == 0) {
      setDeptFilter(undefined)
      setend_date('')
    }
    setDeptFilter(dept);
    setend_date('')

  };

  const handlePageChange = (pageNumber: number) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };
  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to the first page when the search query changes
  };




  const checkAllItems = (e: any) => {
    console.log(e.target.checked, 'checked')
    let checkedArray = checkedItems.map(() => e.target.checked)
    setCheckedItems(checkedArray)
    if (e.target.checked) {
      const newCheckedItems = currentItems.map((each, index) => index);
      const checkedList = filteredenrollmentslist.map((item) => {
        return {
          Student: item.user.full_name,
          Course: item.courses?.title,
          Category: item.courses?.level.title,
          Duration: item.totalDuration ? item.totalDuration : 'Nil',
          AssignedDuaration: item.assignedDuration ? item.assignedDuration : 'Nil',
          ExpectedCompletionDate: item.expCompletionDate ? item.expCompletionDate : 'Nil',
          DateEnrolled: getFullDate(item.enrolledAt),
          Status: item.completedAt ? 'COMPLETED' : 'UNCOMPLETED',
          LastDateVisited: item.lastProgressAt ? getFullDate(item.lastProgressAt) : 'Nil',

        }
      })
      setCheckedUsers(checkedList)
      setCheckedIndex(newCheckedItems)
    } else {
      setCheckedUsers([])
      setCheckedIndex([])
    }

  }

  function updateCheckItems(checked: boolean, index: number, selectedList: IAllEnrolmentResult): void {
    let checkedArray = [...checkedItems]
    checkedArray[index] = checked;
    setCheckedItems(checkedArray);
    const selectedItems = {
      Student: selectedList.user.full_name,
      Course: selectedList.courses?.title,
      Category: selectedList.courses?.level.title,
      Duration: selectedList.totalDuration ? selectedList.totalDuration : 'Nil',
      AssignedDuaration: selectedList.assignedDuration ? selectedList.assignedDuration : 'Nil',
      ExpectedCompletionDate: selectedList.expCompletionDate ? selectedList.expCompletionDate : 'Nil',
      DateEnrolled: getFullDate(selectedList.enrolledAt),
      Status: selectedList.completedAt ? 'COMPLETED' : 'UNCOMPLETED',
      LastDateVisited: selectedList.lastProgressAt && getFullDate(selectedList.lastProgressAt),

    }

    if (checked === true) {
      //setting selected id
      let checkedArrayIndex: any[] = [...checkedIndex];
      checkedArrayIndex.push(index)
      setCheckedIndex(checkedArrayIndex)
      //setting filteredUsers
      let checkedArrayUser: any[] = [...checkedUsers];
      checkedArrayUser.push(selectedItems)
      setCheckedUsers(checkedArrayUser)

    } else if (checked === false) {
      //removing selected id
      let checkedArrayIndex: any[] = [...checkedIndex];
      let ind = checkedArrayIndex.indexOf(index)
      checkedArrayIndex.splice(ind, 1)
      setCheckedIndex(checkedArrayIndex)
      console.log(ind, 'index of index')
      //removing selected Users
      let checkedArrayUser: any[] = [...checkedUsers];
      let indUser = checkedArrayUser.indexOf(selectedItems)

      console.log(indUser, 'index of userr')
      checkedArrayUser.splice(indUser, 1)
      setCheckedUsers([...checkedArrayUser])
    }
  }

  const exportPDF = () => {


    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = `Student Report`;
    const headers = [["Student", "Course Title", "Category", "Duration", 'Assigned Duration', 'Expected Completion Date', 'Date Enrolled', "Status", "Date Visited"]];

    const dataf = checkedUsers?.map(item => [
      item.Student,
      item.Course,
      item.Category,
      item.Duration,
      item.AssignedDuration,
      item.ExpectedCompletionDate,
      item.DateEnrolled,
      item.Status,
      item.LastDateVisited
    ]);

    toast({
      title: ' PDF',
      description: 'PDF File downloaded sucessfully',
      status: 'success',
    })

    doc.text(title, marginLeft, 40);
    autoTable(doc, {
      startY: 50,
      head: headers,
      body: dataf as string[][]
    });
    doc.save("Student Report.pdf")


  }
  return (
    <Container
      boxShadow="base"
      maxW="container.xl"
      bg="white"

      pt={3}
      pb={10}
      px={8}
      minHeight="70vh"
    >
      {isLoading ? (
        <ScreenLoader />
      ) :
        <Box>
          <Flex justifyContent={'space-between'}>
            <Text pl={6} color="brand.3" fontSize={18} fontWeight="semibold">
              Students Report
            </Text>
            <Select
              variant="outline"
              size="sm"
              width={'150px'}
              value={deptFilter}
              placeholder='By Department'
              onChange={(e) => handleDepartmentChange(Number(e.target.value))}
              fontSize={12}
            >
              {
                filteredDepartments.map((dept, index) => (
                  <option key={index} value={dept.id}>{dept.title.toLowerCase()}</option>

                ))

              }
              <option value={0}>All</option>

            </Select>
          </Flex>
          <Flex
            justifyContent="space-between"
            alignItems="center"

            mt={4}
          >
            <HStack>
              <InputGroup color="brand.subtitle" bg="white" width="40%">
                <Input
                  placeholder="Search by name,course"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  fontSize={12}
                  size="sm"
                />
                <InputRightElement
                  h="1.9rem"
                  pointerEvents="none"
                  children={<RiSearch2Line size="0.9rem" />}
                />
              </InputGroup>
              <HStack>
                <FormLabel fontSize={'12px'} mt={'5px'}> From:</FormLabel>

                <Input
                  width={"200px"}
                  bg={"white"}
                  type={"date"}
                  fontSize={'12px'}
                  onChange={(e) => startdateChange(e)}
                />
              </HStack>
              <HStack ml={'10px'}>
                <FormLabel fontSize={'12px'} mt={'5px'}> To:</FormLabel>
                <Box >
                  <Input
                    width={"200px"}
                    bg={"white"}
                    type={"date"}
                    value={end_date}
                    fontSize={'12px'}
                    onChange={(e) => dateChange(e)}
                  />
               {errorMsg &&   <Text color={'red'} fontSize={'10px'}>{errorMsg}</Text>}
                </Box>

              </HStack>
            </HStack>
            <HStack>
              <ExportExcel
                excelData={checkedUsers}
                fileName={"Student Report"}
              />
              <Button size={'sm'} fontSize={'12px'} colorScheme={'blue'} variant={'outline'} onClick={exportPDF}>Generate PDF</Button>
            </HStack>

          </Flex>

          {currentItems.length === 0 ? (
            <EmptyCourseSearch />
          ) : (
            <Box className="scrollable-container" ref={componentRef} >
              {show ? <Image pl={3} mt={4} src={blucanaryLogo2} width="200px" height="auto" /> : <></>}
              <Table size={'sm'} variant="simple" mt={8} >
                <Thead fontWeight="normal">
                  <Tr color="brand.subtitle">
                    <Th>
                      <Checkbox mt={4}
                        isChecked={allChecked}
                        isIndeterminate={isIndeterminate}
                        onChange={(e) => checkAllItems(e)}
                      ></Checkbox>
                    </Th>
                    <Th textTransform="none" >
                      Name
                    </Th>
                    <Th textTransform="none" >Course Title</Th>
                    <Th textTransform="none">Category</Th>
                    <Th textTransform="none">Duration</Th>
                    <Th textTransform="none">Progress</Th>
                    <Th textTransform="none">Status</Th>
                    <Th textTransform="none">Assigned Duration</Th>
                    <Th textTransform="none" width={'100px'}><Text>Expected Completion Date</Text></Th>
                    <Th textTransform="none">Date Enrolled</Th>
                    <Th textTransform="none">Last Date Visited</Th>


                  </Tr>
                </Thead>

                <Tbody className="scrollable-content">
                  {currentItems.map((each, index) => (
                    <Tr key={index} mt={10} fontSize={'13px'} color="brand.subtitle">
                      <Td>
                        <Checkbox mt={10}
                          isChecked={checkedItems[index]}
                          onChange={(e) =>
                            updateCheckItems(
                              e.target.checked, index, each
                            )
                          }
                        ></Checkbox>
                      </Td>
                      <Td  >

                        <Text
                          color="#4E78F5"
                          whiteSpace="nowrap"
                          isTruncated
                          fontSize={'13px'}
                          textTransform={'capitalize'}
                        >
                          {each.user.full_name.toLowerCase()}
                        </Text>
                      </Td>

                      <Td>
                        <Box >
                          <Text color="#4E78F5" fontSize={'13px'} textTransform={'capitalize'} whiteSpace="nowrap">
                            {each.courses?.title ? each.courses.title.slice(0, 24).toLowerCase() : "Nil"}
                          </Text>
                          <Text color="#4E78F5" fontSize={'13px'} whiteSpace="nowrap">
                            {each.courses?.title ? each.courses.title.slice(24, 48).toLowerCase() : ""}
                          </Text>
                          <Text color="#4E78F5" fontSize={'13px'} whiteSpace="nowrap">
                            {each.courses?.title ? each.courses.title.slice(48, each.courses.title.length).toLowerCase() : ""}
                          </Text>

                        </Box>

                      </Td>

                      <Td><Text color="#4E78F5" fontSize={'13px'} textTransform={'capitalize'} whiteSpace="nowrap">
                        {each.courses ? each.courses.level.title.toLowerCase() : "Nil"}
                      </Text>
                      </Td>
                      <Td><Text color="#4E78F5" fontSize={'13px'} textTransform={'capitalize'} whiteSpace="nowrap">
                        {each.totalDuration ? each.totalDuration : 'Nil'}
                      </Text>

                      </Td>
                      <Td><Progress size={'sm'} value={each.completedAt ? 100 : each.progresses ? each.progresses.length / 5 * 100 : 0} /></Td>
                      <Td>
                        <Button color={'white'} size={'sm'} fontSize='12px' px={each.completedAt ? '19px' : '10px'}
                          colorScheme={each.completedAt ? 'green' : 'yellow'}>
                          {each.completedAt ? 'COMPLETED' : 'UNCOMPLETED'}
                        </Button>
                      </Td>

                      <Td><Text color="#4E78F5" fontSize={'13px'} textTransform={'capitalize'} whiteSpace="nowrap">
                        {each.assignedDuration ? each.assignedDuration : 'Nil'}
                      </Text>

                      </Td>
                      <Td>
                        <Text color="#4E78F5" fontSize={'13px'} >
                          {
                            each.expCompletionDate ? getFullDate(each.expCompletionDate) : 'Nil'
                          }
                        </Text>
                      </Td>
                      <Td>
                        <Text color="#4E78F5" fontSize={'13px'} >
                          {
                            each.enrolledAt ? getFullDate(each.enrolledAt) : 'Nil'
                          }
                        </Text>
                      </Td>
                      <Td>
                        <Text color="#4E78F5" fontSize={'13px'} >
                          {
                            each.lastProgressAt ? getFullDate(each.lastProgressAt) : 'Nil'
                          }
                        </Text>
                      </Td>





                    </Tr>
                  ))}
                </Tbody>
              </Table>
              <Flex justifyContent={'flex-end'} mt={'10px'}>
                <HStack mr={'5px'}>
                  <Text fontWeight={400} fontSize={'10px'}>Rows per page :</Text>
                  <Select
                    variant="outline"
                    size="sm"
                    width={'70px'}
                    value={itemsPerPage}
                    onChange={(e) => handleItemsPerPageChange(Number(e.target.value))}
                    fontSize={12}
                  >
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>

                  </Select>

                </HStack>
                <PaginationData
                  currentPage={currentPage}
                  totalPages={totalPages}
                  handlePageChange={handlePageChange}
                />

              </Flex>
            </Box>
          )}
        </Box>
      }



    </Container>
  );
}

export default AllEnrolments