import routes from "./routes";
import { StudentRoutes } from "./Student/routes";
import { useHistory } from "react-router-dom";
import {
  Button,
  FormLabel,
  Input,
  Text,
  Link,
  Box,
  InputGroup,
  InputLeftElement,
  FormControl,
  FormErrorMessage,
  Center,
  Select,
  Image,
  useToast,
} from "@chakra-ui/react";
import { MdLock, MdMailOutline } from "react-icons/md";
import AuthLayout from "../components/Common/AuthLayout";
import { Link as ReactLink } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  authSSO,
  getLocalRole,
  getRole,
  isAuthenticated,
  LoginAzureSSOService,
  loginService,
  setLocalRole,
} from "../services/auth.service";
import { useFormik } from "formik";
import { Roles } from "../models/roles.model";
import { InstructorRoutes } from "./Instructor/routes";
import { TrainingMgrRoutes } from "./TrainingManager/routes";
import { HrAdminRoutes } from "./HrAdmin/routes";
import { ManagementRoutes } from "./Management/routes";
import Routes from "../pages/routes";
import { allowRoleAccess } from "../guards/RoleGuard";
import microsoft from "../img/microsoft.svg";

const Login: React.FC<any> = (props) => {
  const toast = useToast();

  const history = useHistory();

  const [isAzureSSOLoading, setIsAzureSSOLoading] = useState(false);

  const roleRedirect = () => {
    if (isAuthenticated()) {
      let localRole = getLocalRole();

      if (!localRole) {
        toast({
          title: "Login!",
          description: "Unauthorized role access!",
          status: "warning",
        });
        return;
      }

      switch (localRole) {
        case Roles.STUDENT:
          history.replace(StudentRoutes.Dashboard);
          break;
        case Roles.INSTRUCTOR:
          history.replace(InstructorRoutes.Main);
          break;
        case Roles.TRAINING_MANAGER:
          history.replace(TrainingMgrRoutes.Main);
          break;
        case Roles.HR_ADMIN:
          history.replace(HrAdminRoutes.Main);
          break;
        case Roles.MANAGEMENT:
          history.replace(ManagementRoutes.Main);
          break;
        default:
          history.replace("/error");
          break;
      }
    }
  };

  useEffect(() => {
    if (getLocalRole()) roleRedirect();
  }, []);

  const validate = (values: any) => {
    const errors: any = {};
    if (!values.email) {
      errors.email = "Email is required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
    }

    if (!values.password) {
      errors.password = "Password is required";
    }
    return errors;
  };

  const validateLocalRole = (profile: Roles) => {
    if (allowRoleAccess(getRole() as Roles, profile)) {
      setLocalRole(profile);
    }
  };

  const formik = useFormik({
    initialValues: { email: "", password: "", profile: Roles.STUDENT },
    validate,
    onSubmit: async (values) => {
      try {
        await loginService({ email: values.email, password: values.password });

        validateLocalRole(values.profile);

        roleRedirect();
      } catch (error: any) {
        formik.errors.email = error.data.error;
      }
    },
  });

  const loginAzureSSO = async () => {
    setIsAzureSSOLoading(true);

    try {
      let res = await authSSO.loginPopup();

      if (res) {
        const rawIdToken = res.idToken.rawIdToken;

        if (rawIdToken) {
          await LoginAzureSSOService(rawIdToken);

          validateLocalRole(formik.values.profile);

          setIsAzureSSOLoading(false);

          roleRedirect();
        }
      }
    } catch (error) {
      setIsAzureSSOLoading(false);

      console.log(error);
    }
  };

  return (
    <AuthLayout>
      <Box>
        <Text mb={6} fontSize={18} fontWeight="semibold" color="brand.2">
          Login to continue
        </Text>

        <form onSubmit={formik.handleSubmit}>
          <FormControl
            isInvalid={
              formik.errors.email && formik.touched.email ? true : false
            }
            mb={6}
          >
            <FormLabel color="brand.subtitle" fontSize={12}>
              EMAIL ADDRESS:
            </FormLabel>
            <InputGroup>
              <InputLeftElement
                color="brand.subtitle"
                pointerEvents="none"
                children={<MdMailOutline />}
              />
              <Input
                type="email"
                id="email"
                name="email"
                placeholder="alex@gmail.com"
                onChange={formik.handleChange}
                fontSize={13}
              />
            </InputGroup>
            <FormErrorMessage fontSize={12}>
              {formik.errors.email}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            mb={6}
            isInvalid={
              formik.errors.password && formik.touched.password ? true : false
            }
          >
            <FormLabel color="brand.subtitle" fontSize={12}>
              PASSWORD:
            </FormLabel>
            <InputGroup>
              <InputLeftElement
                color="brand.subtitle"
                pointerEvents="none"
                children={<MdLock />}
              />
              <Input
                type="password"
                name="password"
                id="password"
                placeholder="Enter your password"
                onChange={formik.handleChange}
                fontSize={13}
              />
            </InputGroup>
            <FormErrorMessage fontSize={12}>
              {formik.errors.password}
            </FormErrorMessage>
          </FormControl>

          <FormControl mb={6}>
            <FormLabel color="brand.subtitle" fontSize={12}>
              PROFILE:
            </FormLabel>
            <Select
              variant="outline"
              size="md"
              fontSize={12}
              name="profile"
              onChange={formik.handleChange}
              color="brand.subtitle"
            >
              {Object.values(Roles).map((each, index) => (
                <option key={index} value={each}>
                  {" "}
                  {each}{" "}
                </option>
              ))}
            </Select>
            <FormErrorMessage fontSize={12}></FormErrorMessage>
          </FormControl>

          <Center>
            <Button
              w="100%"
              bg="brand.2"
              color="white"
              mb={2}
              type="submit"
              isLoading={formik.isSubmitting}
              fontSize={13}
            >
              Login
            </Button>
          </Center>

          <Center mb={2}>
            <Text fontSize={12} color="brand.subtitle">
              Or
            </Text>
          </Center>

          <Center>
            <Button
              w="100%"
              isLoading={isAzureSSOLoading}
              colorScheme="brand"
              mb={6}
              type="button"
              borderColor="#EBF0FF"
              borderWidth="2px"
              color="brand.subtitle"
              variant="outline"
              fontSize={12}
              onClick={() => loginAzureSSO()}
              leftIcon={<Image width="60px" height="auto" src={microsoft} />}
            >
              {" "}
              Login with Microsoft{" "}
            </Button>
          </Center>

          {/* <Flex direction="row" align="center" alignSelf="center" mb={6} alignContent="center" justifyContent="center">
                        <Checkbox mr="3" color="brand.2" ></Checkbox>
                        <Text color="brand.bodyText" fontSize={13} >Remember me for 30days</Text>
                    </Flex> */}

          <Center>
            <Link
              color="brand.2"
              as={ReactLink}
              to={Routes.ForgotPassword}
              fontSize={12}
              alignSelf="center"
              mb={2}
            >
              Forget Password?
            </Link>
          </Center>

          <Center>
            <Text color="brand.bodyText" fontSize={12} alignSelf="center">
              Don't have an account?{" "}
              <Link as={ReactLink} to={routes.Signup} color="brand.2">
                Register
              </Link>{" "}
            </Text>
          </Center>
        </form>
      </Box>
    </AuthLayout>
  );
};

export default Login;
