import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton, Text, Box, useToast, FormControl, FormLabel, InputGroup, InputLeftElement, Input, FormErrorMessage, Spacer, Flex, Button, Checkbox, Center, Select, SimpleGrid, Icon, Textarea, HStack, CircularProgress, CircularProgressLabel
  } from "@chakra-ui/react"
import { useEffect, useState } from "react";


import { useHistory, useParams } from "react-router-dom";
import { IUserDashboard } from "../../models/auth.model";
import { fetchUserOverview, getStudentOverview } from "../../services/hr/users-http.service";


 




  export const CourseOverviewModal: React.FC<any> = ({isOpen, onClose, }: {isOpen: any, onClose: any}) => {
    let { userId } = useParams<{ userId: string }>()

    const [user, setUser] = useState<IUserDashboard>()
    const [isLoading, setIsLoading] = useState(true)


    useEffect(() => {
        fetchUserOverview(userId)
          .then((data) => {
            setUser(data)
            setIsLoading(!isLoading)
          })
          .catch((err) => err)
      }, [])

      

    
    return (
      <>
        <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" closeOnOverlayClick={true}  size="4xl" >
            <ModalOverlay />
              <ModalContent borderRadius={5} px={6} >
                <ModalHeader >
                 <Text fontSize={16}>In Progress</Text>               
                </ModalHeader>
                <ModalCloseButton size="sm" />
                <ModalBody pb={6}>
                    {user?.courses_in_progress.map((val) => (
                        <HStack>
                            <CircularProgress
                            value={Number(val.progress)}
                            color="#4E78F5"
                            >
                            <CircularProgressLabel>
                                {Number(val.progress).toFixed(0)}%
                            </CircularProgressLabel>
                            </CircularProgress>

                            <Text>{val.course}</Text>
                        </HStack>
                        ))}
                </ModalBody>
                
              </ModalContent>
        </Modal>
      </>
     
    )
  }

 