import {
  Box,
  Container,
  Flex,
  HStack,
  Select,
  Tag,
  Text,
  VStack,
  Spacer,
  Avatar,
  GridItem,
  Grid,
  Divider,
  Center,
  Button,
  MenuButton,
  MenuList,
  MenuItem,
  Menu,
} from '@chakra-ui/react'
import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react'
import { useState, useContext, useEffect } from 'react'
import { BsFillStarFill, BsStarFill } from 'react-icons/bs'
import { EmptyAnalytics } from '../../components/Instructor/EmptyState/Analytics'
import { EmptyCourseStatus } from '../../components/Instructor/EmptyState/courseStatus'
import { EmptyRating } from '../../components/Instructor/EmptyState/Rating'
import courseStatusJson from '../../data/courseStatus.json'
import { StoreContext } from '../../store/StoreContext'
import {
  ComposedChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Line,
  Pie,
} from 'recharts'
import { renderCustomizedLabel } from './UserDashboard'
import {
  getDashboard,
  gettopCourses,
  gettopInstructors,
  gettopStudents,
} from '../../services/hr/users-http.service'
import {
  IHrDashboard,
  IHrDashboardCourse,
  IHrDashboardInstructor,
  IHrDashboardStudent,
  ITopInstructors,
} from '../../models/auth.model'
import {
  firstAttemptUtil,
  hrDashCoursesUtil,
  hrDashEnrollUtil,
  hrDashStudentsUtil,
} from '../../utils/course.util'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { url } from 'inspector'

import { useHistory } from 'react-router-dom'

const COLORS = ['#1E53F3', '#FF9E89']
const COLORS1 = ['#6085F6', '#B3E7A1']
const COLORS2 = ['#6085F6', '#33DAFF']

const Dashboard = () => {
  const [dashboard, setDashboard] = useState<IHrDashboard>()
  const [topInstuctors, setTopInstructors] = useState<IHrDashboardInstructor[]>(
    [],
  )
  const [topStudents, setTopStudents] = useState<IHrDashboardStudent[]>([])
  const [topCourses, setTopCourses] = useState<IHrDashboardCourse[]>([])

  const [enrolled, setEnrolled] = useState<any[]>([])
  const [first, setfirst] = useState<any[]>([])

  const [student, setStudent] = useState<{ trend: any[]; ratio: any[] }>()
  const [course, setCourse] = useState<{ trend: any[]; ratio: any[] }>()

  const { actions, globalState } = useContext(StoreContext)

  const [isLoading, setIsLoading] = useState(true)

  const [operationI, setOperationTypesI] = useState('')
  const [operationS, setOperationTypesS] = useState('')
  const [operationC, setOperationTypesC] = useState('')

  const [limit, setLimit] = useState(5)
  const [limit1, setLimit1] = useState(5)
  const [limit2, setLimit2] = useState(5)
  const [reload, setReload] = useState(true)




  const fist = [
    { name: 'Passed', value: 400 },
    { name: 'Failed', value: 100 },
    
    
  ];


 

  // const [topStudent, settopStudent] = useState<any[]>([])

  useEffect(() => {
    // let url = `page=${1}&limit=${5}&search=&orderBy=full_name|enrollments|created_at|ratings|courses|passed|completed|failed`
    getDashboard()
      .then((data) => {
        setEnrolled(
          hrDashEnrollUtil(data.per_month_courses, data.per_month_enrolled),
        )
        setfirst(firstAttemptUtil(data.first_attempt_pass_rate))
        setStudent(
          hrDashStudentsUtil(
            data.per_month_students,
            data.per_month_active_students,
          ),
        )
        setCourse(
          hrDashCoursesUtil(
            data.per_month_courses,
            data.per_month_active_courses,
          ),
        )
        setDashboard(data)
        setIsLoading(!isLoading)
      })
      .catch((err) => err)
  }, [])

  function updateOperation(operation_type: string): void {
   if(operation_type === 'performanceC'){
    setOperationTypesC('perfomance');
  }
  else if(operation_type === 'enrollmentsC'){
    setOperationTypesC('enrollments');
  }
  else if(operation_type === 'passedC'){
    setOperationTypesC('passed');
  }
  else if(operation_type === 'performanceS'){
    setOperationTypesS('performance');
  }
  else if(operation_type === 'passedS'){
    setOperationTypesS('passed');
  }
  else if(operation_type === 'enrollmentsS'){
    setOperationTypesS('enrollments');
  } 
  else if(operation_type === 'enrollmentsI'){
    setOperationTypesI('enrollments');
  }
  else if(operation_type === 'performanceI'){
    setOperationTypesI('performance');
  }
  else if(operation_type === 'ratings'){
    setOperationTypesI(operation_type);
  }
  else if(operation_type === 'title'){
    setOperationTypesC(operation_type);
  }
  else if(operation_type === 'category'){
    setOperationTypesC(operation_type);
  }
  else if(operation_type === 'rating'){
    setOperationTypesC(operation_type);
  }
  else if(operation_type === 'instructor_name'){
    setOperationTypesC(operation_type);
  }
  else if(operation_type === 'department'){
    setOperationTypesS(operation_type);
  }
  else if(operation_type === 'courses'){
    setOperationTypesI(operation_type);
  }
  

}
  

  const getMore = (isInstructor:boolean, isStudent:boolean, isCourse:boolean) =>{
    if(isInstructor === true){
      setLimit(limit+limit)
      setReload(!reload)
    }else if(isStudent === true){
      setLimit1(limit1+limit1)
      setReload(!reload)
    }else if (isCourse === true){
      setLimit2(limit2+limit2)
      setReload(!reload)
    }
    
    
  }

  const getLess = (isInstructor:boolean, isStudent:boolean, isCourse:boolean) =>{
    if(isInstructor === true){
      setLimit(5)
      setReload(!reload)
    }else if(isStudent === true){
      setLimit1(5)
      setReload(!reload)
    }else if (isCourse === true){
      setLimit2(5)
      setReload(!reload)
    }
    
    
  }

  let role_id = {
    student: 1,
    instructor: 2,
  }

  useEffect(() => {
    let url = `?page=${1}&limit=${limit}&search=&orderBy=${operationI}`

    gettopInstructors(role_id.instructor, url).then((data) => {
      setTopInstructors(data.results)
    })
  }, [operationI,reload])

  useEffect(() => {
    let url = `?page=${1}&limit=${limit1}&search=&orderBy=${operationS}`

    gettopStudents(role_id.student, url)
    .then((data) => {
      setTopStudents(data.results)
    })
  }, [operationS,reload])

  useEffect(() => {
    let url = `?page=${1}&limit=${limit2}&search=&orderBy=${operationC}`

    gettopCourses(url)
    .then((data) => {
      setTopCourses(data.results)
    })
  }, [operationC,reload])


 








  

  return (
    <Box>
      <Box mb={6} color="brand.2" fontWeight="semibold">
        Hi, {globalState.profile?.full_name}
      </Box>

      <Flex justifyContent="space-between">
        {/* <Container  width="fit-content" px={14} py={2} boxShadow="base" bg="white" m={0} >
                    <VStack pb={3} pt={1}>
                        <HStack spacing={3}>
                            <Text color="brand.subtitle" fontSize={13}  >Total Revenue</Text>
                        </HStack>
                        <Text color="brand.2" fontWeight="semibold" fontSize={30} >$0</Text>
                    </VStack>
                </Container> */}

        <Container px={14} py={2} boxShadow="base" bg="white" m={0}>
          <VStack pb={3} pt={1}>
            <HStack spacing={3}>
              <Text color="brand.subtitle" fontSize={13}>
                Total Enrollment
              </Text>
            </HStack>
            <Text color="brand.2" fontWeight="semibold" fontSize={30}>
              {dashboard?.total_enrolled}
            </Text>
          </VStack>
        </Container>

        <Spacer mx={3} />

        <Container px={14} py={2} boxShadow="base" bg="white" m={0}>
          <VStack pb={3} pt={1}>
            <HStack spacing={3}>
              <Text color="brand.subtitle" fontSize={13}>
                Total Instructors
              </Text>
              <Tag
                colorScheme="tag"
                borderRadius={0}
                px={1}
                minHeight={0}
                lineHeight={1.5}
                fontSize={11}
              >
                {' '}
                +0
              </Tag>
            </HStack>
            <Text color="brand.2" fontWeight="semibold" fontSize={30}>
              {dashboard?.total_instructors}
            </Text>
          </VStack>
        </Container>

        <Spacer mx={3} />

        <Container px={14} py={2} boxShadow="base" bg="white" m={0}>
          <VStack pb={3} pt={1}>
            <Text color="brand.subtitle" fontSize={13}>
              {' '}
              Total Students
            </Text>
            <Text color="brand.2" fontWeight="semibold" fontSize={30}>
              {dashboard?.total_students}
            </Text>
          </VStack>
        </Container>

        <Spacer mx={3} />

        <Container px={14} py={2} boxShadow="base" bg="white" m={0}>
          <VStack pb={3} pt={1}>
            <Text color="brand.subtitle" fontSize={13}>
              {' '}
              Total Courses
            </Text>
            <Text color="brand.2" fontWeight="semibold" fontSize={30}>
              {dashboard?.total_courses}
            </Text>
          </VStack>
        </Container>
      </Flex>

      <Box boxShadow="base" maxW="container.xl" bg="white" my={6} py={3} px={8}>
        <Flex justifyContent="space-between" alignItems="center" px={6} mb={4}>
          <Text color="brand.2" fontSize={15} fontWeight="semibold">
            Activity Trend / Chart
          </Text>
          {/* <Select width="20%" variant="outline" placeholder="Top Performing" size="sm" fontSize={12} color="brand.subtitle" />    */}
        </Flex>

        {enrolled.length === 0 ? (
          <EmptyAnalytics />
        ) : (
          <Grid
            templateRows="repeat(1, 1fr)"
            templateColumns="repeat(4, 1fr)"
            gap={1}
          >
            <GridItem bg="white" colSpan={3}>
              <ComposedChart
                width={670}
                height={300}
                data={enrolled}
                margin={{
                  top: 5,
                  right: 30,
                  left: 10,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend
                  verticalAlign="bottom"
                  height={36}
                  iconSize={7}
                  align="center"
                  iconType="square"
                />

                <Bar dataKey="amt" name="No of courses upload" fill="#0540F2" />
                <Bar
                  dataKey="uv"
                  name="No of student that took the course"
                  fill="#FF9E89"
                />
                <Line
                  dataKey="pv"
                  name="Average no of courses per student"
                  stroke="#33DAFF"
                />
              </ComposedChart>
            </GridItem>

            <GridItem bg="white" colSpan={1}>
              <Center>
                <VStack>
                  <PieChart width={200} height={200}>
                    <Pie
                      data={fist}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      label={renderCustomizedLabel}
                      outerRadius={80}
                      innerRadius={40}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      
                      {fist.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                          
                        />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                  <Text color="brand.subtitle">First Attempt Pass Rate</Text>
                </VStack>
              </Center>
            </GridItem>
          </Grid>
        )}
      </Box>

      <Box boxShadow="base" maxW="container.xl" bg="white" my={6} py={3} px={8}>
        <Flex justifyContent="space-between" alignItems="center" px={6} mb={4}>
          <Text color="brand.2" fontSize={15} fontWeight="semibold">
            Base Analysis - Student
          </Text>
          {/* <Select width="20%" variant="outline" placeholder="Top Performing" size="sm" fontSize={12} color="brand.subtitle" />    */}
        </Flex>

        {student?.trend.length === 0 ? (
          <EmptyAnalytics />
        ) : (
          <Grid
            templateRows="repeat(1, 1fr)"
            templateColumns="repeat(4, 1fr)"
            gap={1}
          >
            <GridItem bg="white" colSpan={3}>
              <ComposedChart
                width={670}
                height={300}
                data={student?.trend}
                margin={{
                  top: 5,
                  right: 30,
                  left: 10,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend
                  verticalAlign="bottom"
                  height={36}
                  iconSize={7}
                  align="center"
                  iconType="square"
                />

                <Bar dataKey="amt" name="Total Students" fill="#0540F2" />
                <Bar dataKey="uv" name="Active Students" fill="#33DAFF" />
                <Line dataKey="pv" name="Average Ratio" stroke="#ff7300" />
              </ComposedChart>
            </GridItem>

            <GridItem bg="white" colSpan={1}>
              <Center>
                <VStack>
                  <PieChart width={200} height={200}>
                    <Pie
                      data={student?.ratio}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      label={renderCustomizedLabel}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {student?.ratio.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS2[index % COLORS2.length]}
                        />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                  <Text color="brand.subtitle">
                    Closing ratio for previous month
                  </Text>
                </VStack>
              </Center>
            </GridItem>
          </Grid>
        )}
      </Box>

      <Box boxShadow="base" maxW="container.xl" bg="white" my={6} py={3} px={8}>
        <Flex justifyContent="space-between" alignItems="center" px={6} mb={4}>
          <Text color="brand.2" fontSize={15} fontWeight="semibold">
            Base Analysis - Course
          </Text>
          {/* <Select width="20%" variant="outline" placeholder="Top Performing" size="sm" fontSize={12} color="brand.subtitle" />    */}
        </Flex>

        {course?.trend.length === 0 ? (
          <EmptyAnalytics />
        ) : (
          <Grid
            templateRows="repeat(1, 1fr)"
            templateColumns="repeat(4, 1fr)"
            gap={1}
          >
            <GridItem bg="white" colSpan={3}>
              <ComposedChart
                width={670}
                height={300}
                data={course?.trend}
                margin={{
                  top: 5,
                  right: 30,
                  left: 10,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend
                  verticalAlign="bottom"
                  height={36}
                  iconSize={7}
                  align="center"
                  iconType="square"
                />

                <Bar dataKey="amt" name="Total Students" fill="#0540F2" />
                <Bar dataKey="uv" name="Active Students" fill="#B3E7A1" />
                <Line dataKey="pv" name="Average Ratio" stroke="#ff7300" />
              </ComposedChart>
            </GridItem>

            <GridItem bg="white" colSpan={1}>
              <Center>
                <VStack>
                  <PieChart width={200} height={200}>
                    <Pie
                      data={course?.ratio}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      label={renderCustomizedLabel}
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {course?.ratio.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS1[index % COLORS1.length]}
                        />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                  <Text color="brand.subtitle">Closing Ratio</Text>
                </VStack>
              </Center>
            </GridItem>
          </Grid>
        )}
      </Box>

     

      <Box boxShadow="base" maxW="container.xl" bg="white" my={6} py={8} px={8}>
        <Flex justifyContent="space-between" alignItems="center" px={6} mb={4}>
          <Text color="brand.2" fontSize={20} fontWeight="semibold">
            Top Instructors
          </Text>
       
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              colorScheme="##7883A7"
              variant={'outline'}
              size="sm"
              fontSize={12}
            >
              {operationI === 'performance'
                ? 'By Performance'
                : operationI === 'courses'
                ? 'By Course'
                : operationI === 'enrollments'
                ? 'By Enrolment'
                : operationI === 'ratings'
                ? 'By Rating'
                : 'By Performance'}
            </MenuButton>
            <MenuList fontSize={12}>
              <MenuItem onClick={() => updateOperation('courses')}>
                By Course
              </MenuItem>

              <MenuItem onClick={() => updateOperation('enrollmentsI')}>
                By Enrolment
              </MenuItem>
              <MenuItem onClick={() => updateOperation('ratings')}>
                {' '}
                Rating
              </MenuItem>
              <MenuItem onClick={() => updateOperation('visit')}>
                Visit
              </MenuItem>
              <MenuItem onClick={() => updateOperation('performanceI')}>
                By Performance
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>

        {topInstuctors.length === 0 ? (
          <EmptyAnalytics />
        ) : (
          <Box>
            <Table variant="simple" mt={5}>
              <Thead fontWeight="normal">
                <Tr color="brand.subtitle">
                  <Th pl={105} textTransform="none">
                    Instructor
                  </Th>
                  <Th textTransform="none">Course</Th>
                  <Th textTransform="none">Enrollment</Th>
                  <Th textTransform="none">Rating</Th>
                  <Th textTransform="none">Visit</Th>
                </Tr>
              </Thead>

              <Tbody>
                {topInstuctors?.map((each, index) => (
                  <Tr key={index} fontSize={13} color="brand.subtitle">
                    <Td role={'button'}>
                      <HStack
                        spacing={4}
                        maxW="fit-content"
                        alignItems="center"
                      >
                        <Avatar size="md" name={each.full_name} mr={4} />
                        <Text
                          color="brand.3"
                          whiteSpace="nowrap"
                          isTruncated
                          title={each.full_name}
                        >
                          {each.full_name}
                        </Text>
                      </HStack>
                    </Td>
                    <Td>{each.courses}</Td>
                    <Td>{each.enrollments}</Td>
                    <Td>
                      <HStack>
                        <BsFillStarFill fill="#E9A426" />{' '}
                        <Text>{Number(each.ratings).toFixed(1)}</Text>
                      </HStack>
                    </Td>
                    {/* <Td>{each.visits}</Td> */}
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <VStack>
              {limit <= 5 && <Button
                justifySelf="center"
                alignSelf="center"
                bgColor="white"
                color="brand.2"
                width="100%"
                onClick={()=> getMore(true,false,false)}
                
              >
                See More
              </Button>}
              {limit > 5 && <Button
                justifySelf="center"
                alignSelf="center"
                bgColor="white"
                color="brand.2"
                width="100%"
                onClick={()=> getLess(true,false,false)}
              >
                See Less
              </Button>}
            </VStack>
          </Box>
        )}
      </Box>

      <Box boxShadow="base" maxW="container.xl" bg="white" my={6} py={8} px={8}>
        <Flex justifyContent="space-between" alignItems="center" px={6} mb={4}>
          <Text color="brand.2" fontSize={20} fontWeight="semibold">
            Top Students
          </Text>
          
       
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              colorScheme="##7883A7"
              variant={'outline'}
              size="sm"
              fontSize={12}
            >
              {operationS === 'performance'
                ? 'By Performance'
                : operationS === 'department'
                ? 'By Department'
                : operationS === 'enrollments'
                ? 'By Enrolled'
                : operationS === 'passed'
                ? 'By Pass Rate'
                : 'By Performance'}
            </MenuButton>
            <MenuList fontSize={12}>
              <MenuItem onClick={() => updateOperation('department')}>
                By Department
              </MenuItem>
              <MenuItem onClick={() => updateOperation('enrollmentsS')}>
                By Enrolled
              </MenuItem>
              <MenuItem onClick={() => updateOperation('passedS')}>
                By Pass Rate
              </MenuItem>
              <MenuItem onClick={() => updateOperation('performanceS')}>
                By Performance
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>

        {topStudents.length === 0 ? (
          <EmptyAnalytics />
        ) : (
          <Box>
            <Table variant="simple" mt={5}>
              <Thead fontWeight="normal">
                <Tr color="brand.subtitle">
                  <Th pl={105} textTransform="none">
                    Name
                  </Th>
                  <Th textTransform="none">Department</Th>
                  <Th textTransform="none">Enrolled</Th>
                  <Th textTransform="none">Pass Rate</Th>
                </Tr>
              </Thead>

              <Tbody>
                {topStudents?.map((each, index) => (
                  <Tr key={index} fontSize={13} color="brand.subtitle">
                    <Td role={'button'}>
                      <HStack
                        spacing={4}
                        maxW="fit-content"
                        alignItems="center"
                      >
                        <Avatar size="md" name={each.full_name} mr={4} />
                        <Text
                          color="brand.3"
                          whiteSpace="nowrap"
                          isTruncated
                          title={each.full_name}
                        >
                          {each.full_name}
                        </Text>
                      </HStack>
                    </Td>
                    <Td>{each.department}</Td>
                    <Td>{each.enrollments}</Td>
                    <Td>
                      {parseInt(each.passed as string) === 0 &&
                      parseInt(each.failed as string) === 0
                        ? 0
                        : (Number(each.passed) / Number(each.passed) +
                            Number(each.failed)) *
                            100 +
                          '%'}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <VStack>
              {limit1 <= 5 && <Button
                justifySelf="center"
                alignSelf="center"
                bgColor="white"
                color="brand.2"
                width="100%"
                onClick={()=> getMore(false,true,false)}
              >
                See More
              </Button>}
              {limit1 > 5 && <Button
                justifySelf="center"
                alignSelf="center"
                bgColor="white"
                color="brand.2"
                width="100%"
                onClick={()=> getLess(false,true,false)}
              >
                See Less
              </Button>}
            </VStack>
          </Box>
        )}
      </Box>

      <Box boxShadow="base" maxW="container.xl" bg="white" my={6} py={8} px={8} overflow = "scroll">
        <Flex justifyContent="space-between" alignItems="center" px={6} mb={4}>
          <Text color="brand.2" fontSize={20} fontWeight="semibold">
            Top Courses
          </Text>
         
       
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              colorScheme="##7883A7"
              variant={'outline'}
              size="sm"
              fontSize={12}
            >
              {operationC === 'performance'
                ? 'By Performance'
                : operationC === 'category'
                ? 'By Category'
                : operationC === 'instructor_name'
                ? 'By Instructor'
                : operationC === 'enrollments'
                ? 'By Enrollment'
                : operationC === 'passed'
                ? 'By Pass Rate'
                : operationC === 'rating'
                ? 'By Rating'
                : operationC === 'title'
                ? 'By Title'
                : 'By Performance'}
            </MenuButton>
            <MenuList fontSize={12}>
              <MenuItem onClick={() => updateOperation('title')}>
                {' '}
                By Title
              </MenuItem>
              <MenuItem onClick={() => updateOperation('category')}>
                {' '}
                By Category
              </MenuItem>
              <MenuItem onClick={() => updateOperation('instructor_name')}>
                By Instructor
              </MenuItem>
              <MenuItem onClick={() => updateOperation('enrollmentsC')}>
                {' '}
                Enrolled
              </MenuItem>
              <MenuItem onClick={() => updateOperation('passedC')}>
                Pass Rate
              </MenuItem>
              <MenuItem onClick={() => updateOperation('rating')}>
                Rating
              </MenuItem>
              <MenuItem onClick={() => updateOperation('performanceC')}>
                By Performance
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>

        {topCourses.length === 0 ? (
          <EmptyAnalytics />
        ) : (
          <Box>
            <Table variant="simple" mt={5}>
              <Thead fontWeight="normal">
                <Tr color="brand.subtitle">
                  <Th pl={105} textTransform="none">
                    Title
                  </Th>
                  <Th textTransform="none">Category</Th>
                  <Th textTransform="none">Instructor</Th>
                  <Th textTransform="none">Enrolled</Th>
                  <Th textTransform="none">Pass Rate</Th>
                  <Th textTransform="none">Rating</Th>
                </Tr>
              </Thead>

              <Tbody>
                {topCourses.map((each, index) => (
                  <Tr key={index} fontSize={13} color="brand.subtitle">
                    <Td role={'button'}>
                      <HStack
                        spacing={4}
                        maxW="fit-content"
                        alignItems="center"
                      >
                        <Avatar size="md" name={each.cover_image_url} mr={4} />
                        <Text
                          color="brand.3"
                          whiteSpace="nowrap"
                          isTruncated
                          title={each.title}
                        >
                          {each.title}
                        </Text>
                      </HStack>
                    </Td>
                    <Td>{each.category}</Td>
                    <Td>{each.instructor_name}</Td>
                    <Td>{each.enrollments}</Td>
                    <Td>
                      {parseInt(each.passed as string) === 0 &&
                      parseInt(each.failed as string) === 0
                        ? 0
                        : (Number(each.passed) / Number(each.passed) +
                            Number(each.failed)) *
                            100 +
                          '%'}
                    </Td>
                    <Td>
                      <HStack>
                        <BsFillStarFill fill="#E9A426" />{' '}
                        <Text>{Number(each.rating).toFixed(1)}</Text>
                      </HStack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <VStack>
            {limit2 <= 5 && <Button
                justifySelf="center"
                alignSelf="center"
                bgColor="white"
                color="brand.2"
                width="100%"
                onClick={()=> getMore(false,false,true)}
              >
                See More
              </Button>}
              {limit2 > 5 && <Button
                justifySelf="center"
                alignSelf="center"
                bgColor="white"
                color="brand.2"
                width="100%"
                onClick={()=> getLess(false,false,true)}
              >
                See Less
              </Button>}
            </VStack>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default Dashboard
function isInstructor(isInstructor: any): void {
  throw new Error('Function not implemented.')
}

