import { Button } from "@chakra-ui/button";
import { FormControl, FormErrorMessage } from "@chakra-ui/form-control";
import { Box, Flex, Text } from "@chakra-ui/layout";
import { HStack,IconButton } from "@chakra-ui/react";
import { Textarea } from "@chakra-ui/textarea";
import { useToast } from "@chakra-ui/toast";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { BsPencilSquare } from "react-icons/bs";
import { FaTimes } from "react-icons/fa";
import { ILessonArticleDto } from "../../../../models/course.model";
import { AssetType } from "../../../../models/create_course.model";
import { getLessonFromStateService, removeLessonAssetFromStateService, updateLessonAssetToStateService } from "../../../../services/instructor/course.service";
import { submitCourseLessonArticle } from "../../../../services/instructor/courses-http.service";

export const ArticleUpload: React.FC<any> = ({goBack, lessonId, lessonIndex, lectureIndex}) => {

    const toast = useToast();
    
    const [isUploaded, setIsUploaded] = useState(false);

    const [article, setArticle] = useState<string>();

    const [addArticle, setAddArticle] = useState(false);
    const [updateArticle, setUpdateArticle] = useState(false);

    useEffect(() => {
      
        let selectedLesson = getLessonFromStateService(lectureIndex, lessonIndex);

            let lessonUploaded = selectedLesson?.isUploaded;

            if(lessonUploaded){
                
                setIsUploaded(lessonUploaded);

                setArticle(selectedLesson?.asset_link)
            }
    }, []);
    

    const formikArticle = useFormik({
        initialValues: {
            article: '',
        },
        validate: (values: any) => {
           const errors: any = {};
            if(!values.article){
                errors.article = 'An article is required';
            }
            return errors;
        },
        onSubmit: async (values) => {
            try {

                let lessonData: ILessonArticleDto = {lessonId, article: values.article}
                
                const lessonArticle = await submitCourseLessonArticle(lessonData);

                if(lessonArticle){
                    updateLessonAssetToState(true, AssetType.ARTICLE, lessonArticle.article || '');
                    toast({
                        title: "Course Curriculum",
                        description: "Article added successfully",
                        status: "success",
                    })
                
                }
                
            } catch (error) {
                toast({
                    title: "Course Lesson",
                    description: "Something went Wrong. Try again later",
                    status: "error",
                }) 
            }
        }
    })

    const formikUpdateArticle = useFormik({
        initialValues: {
            article: '',
        },
        validate: (values: any) => {
           const errors: any = {};
            if(!values.article){
                errors.article = 'An article is required';
            }
            return errors;
        },
        onSubmit: async (values) => {
            try {

                let lessonData: ILessonArticleDto = {lessonId, article: values.article}
                
                const lessonArticle = await submitCourseLessonArticle(lessonData);

                if(lessonArticle){
                    setUpdateArticle(false)
                    updateLessonAssetToState(true, AssetType.ARTICLE, lessonArticle.article || '');
                    toast({
                        title: "Course Curriculum",
                        description: "Article updated successfully",
                        status: "success",
                    })
                
                }
                
            } catch (error) {
                toast({
                    title: "Course Lesson",
                    description: "Something went Wrong. Try again later",
                    status: "error",
                }) 
            }
        }
    })

    const editArticle = (article: string) => {

        formikUpdateArticle.setValues({ article: article })

        setArticle('');
        setUpdateArticle(true)
        setAddArticle(false);
    }



    const removeLessonAssetFromState = () => {
        removeLessonAssetFromStateService(lectureIndex, lessonIndex);
        goBack()
        toast({
            title: "Course Curriculum",
            description: "Article deleted successfully",
            status: "success",
        })
    }

     const updateLessonAssetToState = (isUploaded: boolean, assetType: AssetType, article: string) => {
        setIsUploaded(true);
        setArticle(article);
        updateLessonAssetToStateService(lectureIndex, lessonIndex, isUploaded, assetType, article)   
    }

    return (
        <>
            <Box mt={2}>

                <Flex justifyContent="space-between" mb={4}>
                    <Text fontSize={13} color="brand.2" ></Text>
                    <Button color="danger.100" onClick={() => removeLessonAssetFromState()} borderColor="danger.100" variant="outline" type="submit" size="sm" fontSize={11} rightIcon={<FaTimes />} >Remove Article</Button>
                </Flex>

                {
                    article ? 

                    <Box fontSize={13} px={6} border={"1px"} borderRadius={3} py={4} borderColor={"brand.borderBox"} >
                        <HStack spacing={2} justifyContent={"end"} mb={2} >
                            <IconButton
                                variant="ghost"
                                aria-label="pencil"
                                color="brand.subtitle"
                                borderRadius={20}
                                size="sm"
                                icon={<BsPencilSquare />}
                                onClick={() => editArticle(article as string)}
                            />
                                        
                        </HStack>
                        {article}
                        
                    </Box>
                    
                    : updateArticle ? 
                    <form onSubmit={formikUpdateArticle.handleSubmit}>
                    <FormControl id="article" isInvalid={(formikUpdateArticle.errors.article && formikUpdateArticle.touched.article) ? true : false}>
                        <Textarea
                            p={4}
                            fontSize={12}
                            size="sm"
                            minH="10rem"
                            name="article"
                            onChange={formikUpdateArticle.handleChange}
                            placeholder="..."
                            value={formikUpdateArticle.values.article}
                        />
                        <FormErrorMessage fontSize={12}> {formikUpdateArticle.errors.article} </FormErrorMessage>
                    </FormControl>

                    <Flex justifyContent="flex-end">
                        <Button mt={4} colorScheme="brand" type="submit" isLoading={formikUpdateArticle.isSubmitting} size="sm" px={4} fontSize={11} > UPDATE</Button>
                    </Flex>
                </form>

                    :

                    <form onSubmit={formikArticle.handleSubmit}>
                        <FormControl id="article"  isInvalid={(formikArticle.errors.article && formikArticle.touched.article) ? true : false}>
                            <Textarea
                                p = {4}
                                fontSize={12}
                                size="sm"
                                minH="10rem"
                                name="article"
                                onChange={formikArticle.handleChange}
                            />
                            <FormErrorMessage fontSize={12}> {formikArticle.errors.article} </FormErrorMessage>
                        </FormControl>

                        <Flex justifyContent="flex-end">
                            <Button mt={4} colorScheme="brand" type="submit" isLoading={formikArticle.isSubmitting} size="sm" px={4} fontSize={11} > SUBMIT ARTICLE</Button> 
                        </Flex>
                    </form>
                    
                } 
            
        
                    
                
               
            </Box>
        </>
    );
}