import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton, Button, Flex, VStack, StackDivider, Avatar, Text, HStack, Box
} from "@chakra-ui/react"
import { BsPlus } from "react-icons/bs";
import { IUser } from "../../models/auth.model";


export const UsersListModal: React.FC<any> = ({isOpen, onClose, users, setUsers, setMembers, members, title}: {isOpen: any, onClose: any, users: IUser[], setUsers: any, setMembers: any, members: IUser[], title: string }) => {

    const pickMember = async (index: number) => {
        let user = users?.at(index);

        setMembers([user as IUser, ...members as IUser[]])

        users.splice(index, 1);

        setUsers([...users])
    }

    const closeModal = () => {

        onClose()
    }


    return (
    <>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={closeModal} scrollBehavior="inside" >
        <ModalOverlay />
        <ModalContent borderRadius={5} px={6} >
          <ModalHeader color="brand.2" fontSize={16}> {title} </ModalHeader>
          <ModalCloseButton size="sm" />
          <ModalBody pb={6}>

            <VStack
                mt={4}
                divider={<StackDivider borderColor="gray.200" />}
                spacing={4}
                align="stretch"                
            >
                {
                    users.length === 0 ? 
                    
                    <Text fontSize={13} color="brand.subtitle" > Empty list...</Text>
                    
                    :
                    
                    users?.map((each, index) => 

                        <Flex key={index} justifyContent="space-between">
                            <HStack>
                                <Avatar size="xs" name={each.full_name} src={each.photo_url} />
                                <Text isTruncated fontSize={11}>{each.full_name}</Text>                                                
                            </HStack>
                            <Box width={6} color={"brand.bodyText"} height={6} borderRadius={20} border="dotted" justifyContent="center" alignItems="center" cursor="pointer" onClick={ () => pickMember(index)}>
                                <Flex direction="column" justifyContent="center" height="100%" alignItems="center" fontSize={16}>
                                    <BsPlus />
                                </Flex>
                            </Box>
                        </Flex>
                    )
                }              

            </VStack>

          </ModalBody>

          <ModalFooter>
            <Button colorScheme="brand" size="xs" fontSize={11} borderRadius={2} px={5} onClick={closeModal} > Done </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}