export enum CourseStatus{
    ACTIVE = "UPCOMING",
    PENDING = "ACTIVE",
    SUSPENDED = "EXPIRES"
}

export enum CourseActiveStatusModel{
    ACTIVE = 'ACTIVE',
    UPCOMING = 'UPCOMING',
    EXPIRES = 'EXPIRES',
    EXPIRED = 'EXPIRED',
}

export enum CourseTimelineStatusModel{
    UPCOMING = 'UPCOMING',
    CLOSES = 'CLOSES',
}