
export const HrAdminRoutes = {
    Main: "/hr",
    Profile: "/hr/profile",
    Report: "/hr/report",
    Security: "/hr/security",
    AllEnrolments : "/hr/all-enrolments",
    InstructorReport : '/hr/instructor-reports',
    Departments:{
        ManageDepartment:"/hr/departments",
        ViewPath: "/hr/departments/:category_id/members",
        ViewLink: (category_id: string) => `/hr/departments/${category_id}/members`
    },
     
    Review: {
        Manage: "/hr/reviews",
        ViewPath: "/hr/reviews/:reviewId",
        ViewLink: (reviewId: string) => `/hr/reviews/${reviewId}`,
    },
   
    Group: {
        Fetch: "/hr/groups",
        Create: "/hr/group/create",
        Discussion: "/hr/group/discussion",
    },
    Course: {
        Fetch: "/hr/courses",
        Status: "/hr/courses/status",
        Assign: "/hr/courses/assign",
        Create: "/hr/course/create"
    },
    Users: { 
        Manage: "/hr/users",
        Student: "/hr/students",
        Instructor: "/hr/instructors",
        Create: "/hr/users/create",
        BatchUpload: "/hr/users/upload-batch",
        EditPath: "/hr/users/:userId/edit",
        EditLink: (userId: string) => `/hr/users/${userId}/edit`,
        ViewPath: "/hr/users/:userId/overview",
        ViewLink: (userId: number) => `/hr/users/${userId}/overview`,
        ViewPathS: "/hr/users/:userId/student-overview",
        ViewLinkS: (userId: number) => `/hr/users/${userId}/student-overview`,
    },
    
} 
