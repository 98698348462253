
export const InstructorRoutes = {
    Main: "/instructor",
    MyCourses: "/instructor/courses",
    Student: "/instructor/student",
    Reviews: "/instructor/reviews",
    Groups: "/instructor/groups",
    Report: "/instructor/report",
    Profile: "/instructor/profile",
    Security: "/instructor/security",
    Group: {
        Create: "/instructor/group/create",
    },
    Course: {
        Create: "/instructor/course/create",
        Assign: "/instructor/course/assign",
        EditPath: "/instructor/course/:courseId/edit",
        EditLink: (courseId: string) => `/instructor/course/${courseId}/edit`,
    }
}