import { IUser } from "../models/auth.model";
import { IEnrollmentWithUser } from "../models/course.model";
import { HttpResponse } from "../models/httpResponse.model";
import {httpClient} from "./httpClient";

export const updateUserProfileoNStorage = (user: IUser) => {

    let profile = localStorage.getItem("user") as string;

    let parsedProfile = JSON.parse(profile) as IUser;

    localStorage.setItem("user", JSON.stringify({...parsedProfile, ...user}));

}

export const fetchUsersByCourseId = async (courseId: string) => {

    let response = await httpClient.get<HttpResponse<IEnrollmentWithUser[]>>(`/user/profile/course/${courseId}`);

    return response.data.data;
}