import { Box, Container, Flex, HStack, Image, Progress, Text } from "@chakra-ui/react";
import { Table, Thead, Tbody, Tr, Th, Td} from "@chakra-ui/react"
import { EmptyCourseTM } from "../../components/TrainingManager/EmptyState/Courses";
import { useEffect, useState } from "react";
import { ICourseTimeline } from "../../models/course.model";
import { ScreenLoader } from "../../components/Common/ScreenLoader";
import { fetchCourseTimeline } from "../../services/student/course-http.service";
import { courseProgressUtil } from "../../utils/courseProgressUtil";
import { CourseTimelineStatus } from "../../components/Common/CourseTimelineStatus";

const CourseTimeline = () => {

    const [isLoading, setIsLoading] = useState(true);

    const [coursesTimeline, setCoursesTimeline] = useState<ICourseTimeline[]>([]);
    

    useEffect(() => {

        fetchCourseTimeline()
         .then((data) => {
console.log(data)
            setCoursesTimeline(data)
            
            setIsLoading(false);
        })
        .catch((err) => err)

    }, []);


    return (

        <Container boxShadow="base" maxW="container.xl" bg="white" pt={3} pb={10} px={8} minHeight="70vh" mb={4}>

            {
                isLoading? 
                <ScreenLoader />
                
                :

                coursesTimeline.length === 0 ? 
                <EmptyCourseTM />
                :
                <Box>

                    <Text pl={6} color="brand.3" fontSize={18} fontWeight="semibold"> Course Timeline </Text>

                    <Table variant="simple" mt={5} >
                        <Thead fontWeight="normal" >
                            <Tr color="brand.subtitle" >
                                <Th textTransform="none">Courses</Th>
                                <Th textTransform="none">Start Date</Th>
                                <Th textTransform="none">End Date </Th>
                                <Th textTransform="none">Progress</Th>
                                <Th textTransform="none">Status</Th>
                            </Tr>
                        </Thead>

                        <Tbody>
                            {
                                coursesTimeline.map((each, index) => 
                                    <Tr key={index} fontSize={13} color="brand.subtitle">
                                        <Td>
                                            <HStack>
                                                <Image src={each?.courses?.cover_image_url} fallbackSrc="https://via.placeholder.com/150" height="auto" maxWidth="50px" />
                                                
                                                <Flex direction="column" alignItems="start" >
                                                    
                                                    <Text fontWeight="semibold" fontSize={13}>{each.courses?.title}</Text>
                                                    
                                                    <Text fontSize={11}> by <Box color="brand.2" as="span">{each?.courses?.user?.full_name}</Box></Text>
                                                    
                                                </Flex>
                                            </HStack>                                            
                                        </Td>
                                        <Td fontSize={12} whiteSpace="nowrap">{each?.courses?.startAt? (new Date(each.courses.startAt).toDateString()) : '--'}</Td>
                                        <Td fontSize={12} whiteSpace="nowrap">{each?.courses?.endAt? (new Date(each.courses.endAt).toDateString()) : '--'}</Td>
                                        <Td width="20%" > 
                                            <Flex justifyContent="center" alignItems="center" > <Progress width="100%" value={courseProgressUtil(each?.courses?.courseLessons, each?.progresses)} borderRadius={20} size="xs" mr={3} /> <Box as="span" whiteSpace="nowrap" fontSize={10}> {courseProgressUtil(each?.courses?.courseLessons, each.progresses)}% </Box> </Flex>
                                        </Td>
                                        <Td width="12em"><CourseTimelineStatus startDate={each?.courses?.startAt as string} endDate={each?.courses?.endAt as string} /></Td>                                            
                                    </Tr>
                                )
                            }
                            
                        </Tbody>
                    </Table>
                    
                </Box> 
            }

        </Container>
   
   );
}

export default CourseTimeline;