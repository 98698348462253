export const StudentRoutes = {
    Dashboard: "/student",
    Invoice: "/student/invoice",
    Billing: "/student/billing",
    Profile: "/student/profile",
    Security: "/student/security",
    Course: {
        Fetch: "/student/courses",
        Timeline: "/student/courses/timeline",
        Explore: "/student/courses/explore",
        EnrollPath: "/student/course/:courseId",
        EnrollLink: (courseId: string | undefined) => `/student/course/${courseId}`,
        InfoPath: `/student/course/:courseId/info`,
        InfoLink: (courseId: string | undefined) => `/student/course/${courseId}/info`,
        LessonPath: `/student/course/:courseId/lesson`,
        LessonLink: (courseId: string | undefined) => `/student/course/${courseId}/lesson`,
        SpecificLessonPath: `/student/course/:courseId/lesson/:lessonId`,
        SpecificLessonLink: (courseId: string | undefined, lessonId: string | undefined) => `/student/course/${courseId}/lesson/${lessonId}`,
    }
};