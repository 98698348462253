import { BsThreeDotsVertical } from "react-icons/bs";
import { Badge, Box, Flex, IconButton, Image,  Text } from "@chakra-ui/react";
import React from "react";

interface IDebitCardTile{
    data: {
        logo: any,
        title: string,
        card_no: string,
        expiry: string,
        is_primary: boolean
    }
}

const DebitCardTile: React.FC<IDebitCardTile> = (props) => {

    const { logo, title, card_no, expiry, is_primary } = props.data;

    return (

         <Flex justifyContent="space-between" borderBottom="1px" borderBottomColor="#E5E5E5" py={4}>

            <Flex alignItems="center">                        
                <Image src={logo} height="50%" width="auto" />

                <Flex direction="column" ml={4}>
                    <Text color="brand.3" fontSize={14}>
                        {title} ( {card_no} )
                    </Text>
                    <Text color="brand.subtitle" fontSize={12}>
                        Expires in {expiry}
                    </Text>
                </Flex>
            </Flex>
            <Flex alignItems="center">
                 { is_primary?  <Badge bg="#19CB98" color="white" fontSize={10}  p={1}  >Primary</Badge> :<Box></Box> }
                <IconButton ml={3}
                    variant="ghost"
                    aria-label="Download"
                    color="brand.2"
                    icon={<BsThreeDotsVertical />}
                />
            </Flex>

        </Flex>
    );
}

export default DebitCardTile;