import {
  Box,
  Button,
  Container,
  Flex,
  HStack,
  Image,
  Input,
  InputGroup,
  Tag,
  Text,
  InputRightElement,
  Link,
  IconButton,
  Portal,
  useDisclosure,
  Avatar,
  VStack,
  Badge,
  Spacer,
  toast,
  useToast,
  GridItem,
  Grid,
  StackDivider,
  Center,
} from '@chakra-ui/react'

import { ChevronDownIcon, Icon } from '@chakra-ui/icons'

import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from '@chakra-ui/react'

import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'

import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react'
import { ChangeEvent, useEffect, useState } from 'react'

import { ManagementRoutes } from './routes'

import { useHistory } from 'react-router-dom'

import { fetchActReport, genExcel, gettopInstructors } from '../../services/hr/users-http.service'
import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  Legend,
  Line,
  Pie,
  PieChart,
  Sector,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { EmptyAnalytics } from '../../components/Instructor/EmptyState/Analytics'
import { BsFillStarFill } from 'react-icons/bs'
import { IHrActivityReport, IHrDashboardInstructor, IHrUnique, IHrVisit } from '../../models/auth.model'
import { hrCourseReportUtil, hrDashReportUtil } from '../../utils/course.util'
import { FaCircle } from 'react-icons/fa'

export default function Report() {
  let { replace, push } = useHistory()
  const componentRef = useRef(null)
  const toast = useToast()
  const [report, setReport] = useState<any>()
  const [courseReport, setcourseReport] = useState<{ trend: any[]; ratio: any[] , enrolTotal:number, visitTotal:number }>()




  useEffect(() => {
    fetchActReport()
    .then((data)=>{

      setReport(
        hrDashReportUtil(data.visits, data.unique_visitors)
      )

      setcourseReport(
        hrCourseReportUtil(data.course_visits, data.course_enrollments )
      )
      console.log(hrCourseReportUtil(data.course_visits, data.course_enrollments ));
      // setVisit(data.visits)
      // setuniqueVisitor(data.unique_visitors)
    })

  }, []) 

  // const data = [
  //   {
  //     name: 'Jan',
  //     uv: 4000,
  //     pv: 2400,
  //     amt: 2400,
  //   },
  //   {
  //     name: 'Feb',
  //     uv: 3000,
  //     pv: 1398,
  //     amt: 2210,
  //   },
  //   {
  //     name: 'Mar',
  //     uv: 2000,
  //     pv: 9800,
  //     amt: 2290,
  //   },
  //   {
  //     name: 'Apr',
  //     uv: 2780,
  //     pv: 3908,
  //     amt: 2000,
  //   },
  //   {
  //     name: 'May',
  //     uv: 1890,
  //     pv: 4800,
  //     amt: 2181,
  //   },
  //   {
  //     name: 'Jun',
  //     uv: 2390,
  //     pv: 3800,
  //     amt: 2500,
  //   },
  //   {
  //     name: 'Jul',
  //     uv: 3490,
  //     pv: 4300,
  //     amt: 2100,
  //   },
  //   {
  //     name: 'Aug',
  //     uv: 3000,
  //     pv: 4300,
  //     amt: 2100,
  //   },
  //   {
  //     name: 'Sep',
  //     uv: 3290,
  //     pv: 4300,
  //     amt: 2100,
  //   },
  //   {
  //     name: 'Oct',
  //     uv: 2780,
  //     pv: 4300,
  //     amt: 2100,
  //   },
  //   {
  //     name: 'Nov',
  //     uv: 2590,
  //     pv: 4300,
  //     amt: 2100,
  //   },
  //   {
  //     name: 'Dec',
  //     uv: 3190,
  //     pv: 4300,
  //     amt: 2100,
  //   },
  // ]

  const piedata = [
    { name: 'Total Visits', value: 400 },
    { name: 'Unique Visitors', value: 300 },
    { name: 'Conversion Rate', value: 200 },
  ]

  const COLORS = ['#33DAFF','#4E78F5'];


  const [operation, setOperationTypes] = useState('')
  const [topInstuctors, setTopInstructors] = useState<IHrDashboardInstructor[]>([],)
  const [limit, setLimit] = useState(5)
  const [reload, setReload] = useState(true)

  function updateOperation(operation_type: string): void {
    setOperationTypes(operation_type)
  }

  function handleExcel() {
    genExcel()
      .then((response: any) => {
        var blob = new Blob([response], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
        })
        var a = document.createElement('a')
        let url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = 'users_report.xlsx'
        a.click()

        if (response) {
          toast({
            title: ' Excel',
            description: 'Excel File downloaded sucessfully',
            status: 'success',
          })
          push(ManagementRoutes.Report)
        }
      })
      .catch((error) =>
        toast({
          title: 'Excel',
          description: 'Download Failed, please try again :',
          status: 'error',
        }),
      )
  }

  // const reloadData = () => {
  //   setStatus(!reload)
  // }

  // const viewUser = async (userId: number) => {
  //   push(HrAdminRoutes.Users.ViewLink(userId))
  // }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current as any,
    onAfterPrint: ()=> {
      toast({
        title: ' PDF',
        description: 'Action successful',
        status: 'success',
      })
    }
  })





  let role_id = {
    student: 1,
    instructor: 2,
  }

  useEffect(() => {
    let url = `?page=${1}&limit=${limit}&search=&orderBy=${operation}`

    gettopInstructors(role_id.instructor, url).then((data) => {
      setTopInstructors(data.results)
    })
  }, [operation, reload])


  const renderColorfulLegendText = (value: string, entry: any) => {
    // const { color } = entry;
  
    return <span style={{ color: "#7883A7"}}>{value}</span>;
  };


  const getMore = () =>{
      setLimit(limit+limit)
      setReload(!reload)
  }

  const getLess = () =>{
      setLimit(5)
      setReload(!reload)
  }







  return (
    <Container
      boxShadow="base"
      maxW="container.xl"
      bg="white"
      pt={3}
      pb={10}
      px={8}
      minHeight="70vh"
    >
      <Box ref={componentRef}   px={6} mb={4}>
        <Flex justifyContent='space-between' alignItems="center" mb={8}>
          <Text
            
            color="brand.3"
            mt={10}
            fontSize={21}
            fontWeight="semibold"
          >
            Report
          </Text>

          

          
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              colorScheme="brand"
              variant={'outline'}
              size="sm"
              fontSize={12}
              mt = {10}
            >
              Generate Report
            </MenuButton>
            <MenuList fontSize={12}>
              <MenuItem onClick={handlePrint}> PDF</MenuItem>
              <MenuItem onClick={() => handleExcel()}>Excel</MenuItem>
            </MenuList>
          </Menu>
        </Flex>

        <Text color="brand.2" mb={30}>
            Traffic
        </Text>
       


        <Text color='#7883A7' fontSize={18} mb = {10}>
            Understand how users visits and use the platform
        </Text>

        <Box
          boxShadow="base"
          maxW="container.xl"
          bg="white"
          my={6}
          py={3}
          px={8}
          overflowX={"scroll"}
        >
          <Flex
            justifyContent="space-between"
            
            px={6}
            mb={4}
          >
            <Text color="brand.3" fontSize={15} fontWeight="semibold" mb={5}>
              Traffic History
            </Text>
            <GridItem >
              <Flex justifyContent="space-between" alignItems="center">
                <FaCircle fontSize={5} color='#4E78F5'></FaCircle>
                <Text ml = {1} mr={10} fontSize={15} color="#7883A7">
                  Total Visits
                </Text>

                <FaCircle fontSize={5} color='#33DAFF'></FaCircle>
                <Text ml = {1} fontSize={15} color="#7883A7">
                  Unique Visitors
               </Text>
              </Flex>

              <Flex justifyContent="space-between" >
                <Text fontSize={15} color="brand.3" fontWeight="bold"  ml={3}>
                    {report?.totalVisits}
                </Text>
                
                <Text fontSize={15} color="brand.3" fontWeight="bold" mr={95}>
                    {report?.totalUniqueVisits}
                </Text>
              </Flex>

            </GridItem>
            
            <GridItem colSpan={2}>
            
            </GridItem>
            
          </Flex>

          <Flex>
            <GridItem bg="white" colSpan={3}>
              <ComposedChart
                width={1000}
                height={500}
                data={report?.points}
                margin={{
                  top: 5,
                  right: 30,
                  left: 10,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="6 6" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend
                  verticalAlign="bottom"
                  height={36}
                  iconSize={7}
                  align="center"
                  iconType="square"
                  formatter={renderColorfulLegendText}
                  
                  
                />

                <Line
                  dataKey="amt"
                  name="Total Visits"
                  stroke="#1E53F3"
                  fill='grey'
                  activeDot={{ r: 8 }}
                />
                <Line
                  dataKey="uv"
                  name="Unique Visits"
                  stroke="#33DAFF"
                  activeDot={{ r: 8 }}
                />
              </ComposedChart>
            </GridItem>
          </Flex>
        </Box>

        <HStack spacing={4} justifyContent="start" alignItems="start">
          <Container
            boxShadow="base"
            maxW="container.xl"
            bg="white"
            py={3}
            px={4}
            width="50%"
          >
            <Grid
              templateRows="repeat(1, 1fr)"
              templateColumns="repeat(4, 1fr)"
              gap={1}
            >
              <GridItem colSpan={1}>
                <Text fontWeight="semibold" mb={6}>
                  Overall Traffic
                </Text>

                <Text fontSize={12} color="#959595" mb={10}>
                  Total Traffic to courses
                </Text>
                <Text fontSize={11} color="#7883A7">
                  Total Visits
                </Text>
                <Text fontSize={11} color="#7883A7">
                  Unique Visitors
                </Text>
                <Text fontSize={11} color="brand.3" fontWeight="bold" mb={3}>
                  {courseReport?.visitTotal}
                </Text>
               
                <Text fontSize={11} color="brand.3" fontWeight="bold" mb={3}>
                  {courseReport?.enrolTotal}
                </Text>
                {/* <Text fontSize={11} color="#7883A7">
                  Conversion Rate
                </Text>
                <Text fontSize={11} color="brand.3" fontWeight="bold">
                  25%
                </Text> */}
              </GridItem>

              <GridItem bg="white" colSpan={3}>
                <Center>
                  <VStack>
                    <PieChart width={200} height={200}>
                      <Pie
                        data={piedata}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        outerRadius={80}
                        innerRadius={60}
                        fill="#7883A7"
                        dataKey="value"
                      >
                        {courseReport?.ratio.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </VStack>
                </Center>
              </GridItem>
            </Grid>
          </Container>

          <Box width="50%">
            <Container
              boxShadow="base"
              maxW="container.xl"
              bg="white"
              py={3}
              px={1}
              mb={4}
            >
              <HStack
                alignItems="start"
                justifyContent="space-between"
                spacing={8}
              >
                <Box width="100%">
                  <Text
                    fontSize={18}
                    color="brand.3"
                    fontWeight="semibold"
                    mb={2}
                    pl={5}
                  >
                    Top KeyWord
                  </Text>

                  <Text fontSize={12} color="#92959E" pl={5}>
                    Most used keywords in Blucanary
                  </Text>

                  <HStack mt={4} alignItems="center" spacing={5}>
                    <Table>
                      <Thead fontSize={12}>
                        <Tr>
                          <Th textTransform="none">KeyWord</Th>
                          <Th textTransform="none">Visit</Th>
                          <Th textTransform="none">Bounce Rate</Th>
                        </Tr>
                      </Thead>
                      <Tbody fontSize={11}>
                        <Tr>
                          <Td></Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </HStack>
                </Box>
              </HStack>
            </Container>
          </Box>
        </HStack>

         <Box boxShadow="base" maxW="container.xl" bg="white" my={6} py={8} px={8}>
        <Flex justifyContent="space-between" alignItems="center" px={6} mb={4}>
          <Text color="brand.2" fontSize={20} fontWeight="semibold">
            Top Instructors
          </Text>
       
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              colorScheme="##7883A7"
              variant={'outline'}
              size="sm"
              fontSize={12}
            >
              {operation === 'performance'
                ? 'By Performance'
                : operation === 'courses'
                ? 'By Course'
                : operation === 'enrollments'
                ? 'By Enrolment'
                : operation === 'ratings'
                ? 'By Rating'
                : 'By Performance'}
            </MenuButton>
            <MenuList fontSize={12}>
              <MenuItem onClick={() => updateOperation('courses')}>
                By Course
              </MenuItem>

              <MenuItem onClick={() => updateOperation('enrollmentsI')}>
                By Enrolment
              </MenuItem>
              <MenuItem onClick={() => updateOperation('ratings')}>
                {' '}
                Rating
              </MenuItem>
              <MenuItem onClick={() => updateOperation('visit')}>
                Visit
              </MenuItem>
              <MenuItem onClick={() => updateOperation('performanceI')}>
                By Performance
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>

        {topInstuctors.length === 0 ? (
          <EmptyAnalytics />
        ) : (
          <Box>
            <Table variant="simple" mt={5}>
              <Thead fontWeight="normal">
                <Tr color="brand.subtitle">
                  <Th pl={105} textTransform="none">
                    Instructor
                  </Th>
                  <Th textTransform="none">Course</Th>
                  <Th textTransform="none">Enrollment</Th>
                  <Th textTransform="none">Rating</Th>
                  <Th textTransform="none">Visit</Th>
                </Tr>
              </Thead>

              <Tbody>
                {topInstuctors?.map((each, index) => (
                  <Tr key={index} fontSize={13} color="brand.subtitle">
                    <Td role={'button'}>
                      <HStack
                        spacing={4}
                        maxW="fit-content"
                        alignItems="center"
                      >
                        <Avatar size="md" name={each.full_name} mr={4} />
                        <Text
                          color="brand.3"
                          whiteSpace="nowrap"
                          isTruncated
                          title={each.full_name}
                        >
                          {each.full_name}
                        </Text>
                      </HStack>
                    </Td>
                    <Td>{each.courses}</Td>
                    <Td>{each.enrollments}</Td>
                    <Td>
                      <HStack>
                        <BsFillStarFill fill="#E9A426" />{' '}
                        <Text>{Number(each.ratings).toFixed(1)}</Text>
                      </HStack>
                    </Td>
                    {/* <Td>{each.visits}</Td> */}
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <VStack>
              {limit <= 5 && <Button
                justifySelf="center"
                alignSelf="center"
                bgColor="white"
                color="brand.2"
                width="100%"
                onClick={()=> getMore()}
                
              >
                See More
              </Button>}
              {limit > 5 && <Button
                justifySelf="center"
                alignSelf="center"
                bgColor="white"
                color="brand.2"
                width="100%"
                onClick={()=> getLess()}
              >
                See Less
              </Button>}
            </VStack>
          </Box>
        )}
      </Box>
       
      </Box>
    </Container>
  )
}
