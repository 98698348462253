import { Container, Flex, Box, Text, Progress, Image, Button, Portal, useDisclosure, useToast } from "@chakra-ui/react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react"
import { useContext, useEffect, useState } from "react";
import { enrollCourseHttpService, fetchCompletedCourses, fetchCourseEnrollmentStatus, fetchMandatoryCourses } from "../../services/student/course-http.service";
import { courseEnroledStatus } from "../../utils/course.util";
import { ICourseEnrollmentStatusList, ICourseWithEnrollment, IEnrollment, IEnrollmentWithCourse, IEnrollmentWithCourseLessons } from "../../models/course.model";
import { StudentRoutes } from "./routes";
import { ScreenLoader } from "../../components/Common/ScreenLoader";
import { StoreContext } from "../../store/StoreContext";
import { firstName } from "../../utils/firstName.util";
import bannerBg from '../../img/course-Bg.svg';
import { useHistory } from "react-router";
import { CgPlayButtonR } from "react-icons/cg";
import { BsCardList } from "react-icons/bs";
import { InProgress } from "../../components/Student/EmptyState/InProgress";
import { MandatoryCourses } from "../../components/Student/EmptyState/MandatoryCourses";
import { CompletedCourses } from "../../components/Student/EmptyState/CompletedCourses";
import { IoMdCheckmark } from "react-icons/io";
import { FaAward } from "react-icons/fa";
import { StudentBadgeModal } from "../../components/Common/StudentBadgeModal";
import { useParams } from "react-router-dom";

const Courses = () =>{

    const history = useHistory();

    const [courses, setCourses] = useState<ICourseEnrollmentStatusList[]>([]);
    
    const [completedCourses, setCompletedCourses] = useState<IEnrollmentWithCourse[]>([]);
    
    const [mandatoryCourses, setMandatoryCourses] = useState<IEnrollmentWithCourseLessons[]>([]);

    const [isLoadingCourse, setIsLoadingCourse] = useState(true);

    const { isOpen, onOpen, onClose } = useDisclosure()

    const [selectedStudentBadge, setSelectedStudentBadge] = useState<IEnrollmentWithCourse>();
    
    // const [isLoadingCompleted, setIsLoadingCompleted] = useState(true);
    
    // const [isLoadingMandatory, setIsLoadingMandatory] = useState(true);

    const {globalState} = useContext(StoreContext);


    const [isLoadingEnroll, setIsLoadingEnroll] = useState(false);

    const [courseData, setCourseData] = useState<ICourseWithEnrollment>();

    const toast = useToast();

    // let { courseId } = useParams<{ courseId: string }>();

    useEffect(() => {

        fetchCourseEnrollmentStatus()
        .then(data => {
            let enroledstatus = courseEnroledStatus(data).filter((each) => {return (each.courseId !== undefined || each.statusPercentage >= 0)})
            console.log(enroledstatus)
            let sortedCourses = enroledstatus.sort((a, b) => (a.statusPercentage > b.statusPercentage) ? 1 : (a.statusPercentage === b.statusPercentage) ? 0 : -1 )
           
            setCourses(sortedCourses)
            setIsLoadingCourse(false)
            fetchCompletedCourses()
            .then( data => {
                console.log(data);
                setCompletedCourses(data);
                // setIsLoadingCompleted(false)
               
            })

            fetchMandatoryCourses()
            .then( data => {
                setMandatoryCourses(data)
                // setIsLoadingMandatory(false)
            })
        })
        .catch((err) => err)
            
    }, []);
    function compare( a: any, b : any ) {
        if ( a.statusPercentage < b.statusPercentage ){
          return -1;
        }
        if ( a.statusPercentage > b.statusPercentage ){
          return 1;
        }
        return 0;
      }
      
     
  
    const resumeCourse = (courseId: string) => {
        history.push(StudentRoutes.Course.LessonLink(courseId));
    }


    const viewBadge = (badge: IEnrollmentWithCourse) => {

        setSelectedStudentBadge(badge);

        onOpen();
    }


    const enrollCourse = async (courseId:string) => {

        try {
            let enrollment = await enrollCourseHttpService(courseId) as IEnrollment;

            setIsLoadingEnroll(false);

            setCourseData({ ...courseData, courseEnrollment: enrollment })

            history.replace(StudentRoutes.Course.InfoLink(courseId), courseData)

        } catch (error) {
            setIsLoadingEnroll(false);

            toast({
                title: "Course Enrollment",
                description: "Something went Wrong. Try again later",
                status: "error",
                duration: 3000
            })
        }
    }
    

    return (
        <>
            <Box bg="brand.2" height="4rem" backgroundImage={bannerBg} borderTopLeftRadius={4} borderTopRightRadius={4}>
                <Flex direction="row" justifyContent="space-between" height="inherit" pl={10} pr={10} alignItems="center" >
                    <Text fontSize={20} fontWeight="semibold" color="white" >Welcome, {firstName(globalState.profile?.full_name)}</Text>                        
                </Flex>
            </Box>

            {
                isLoadingCourse?

                <ScreenLoader />

                :

                <Container boxShadow="sm" p={0} maxW="container.xl" pb={10} mt={2} mb={6} bg="white" minH="50vh">

                    <Tabs colorScheme="brand" px={10}>

                        <TabList borderBottom="1px"  color="brand.subtitle" >
                            <Tab mr={20} _focus={{boxShadow: "none"}} mb={3} fontSize={13} >In Progress</Tab>
                            
                            <Tab mr={20} _focus={{boxShadow: "none"}} mb={3} fontSize={13} >Mandatory Course</Tab>
                            
                            <Tab _focus={{boxShadow: "none"}} mb={3} fontSize={13} >Completed</Tab>
                        </TabList>

                        <TabPanels >

                            <TabPanel pb={4} px={0}>
                                {
                                    courses.length === 0 ? 

                                    <Box py={10} mt={2}>
                                        <InProgress />
                                    </Box>

                                    :

                                    courses?.map((each, index) => 
                
                                        <Box boxShadow="sm" key={index} height="4rem" pt={4}>
                                            <Flex direction="row" justifyContent="space-between" alignItems="center" >
                                            
                                                <Flex direction="row">

                                                    <Image src={each.coverImage} fallbackSrc="https://via.placeholder.com/150" width="3.2rem" mr={2} />
                                            
                                                    <Flex direction="column" justifyContent="space-around" width="15em">
                                                        <Text isTruncated fontSize={13} mb={2} color="brand.2" fontWeight="semibold">
                                                            {each.title}
                                                        </Text>
                                                        <Flex direction="row" fontSize={10} color="brand.bodyText" pb={2}>
                                                            <Flex alignItems="center" mr={4}>
                                                                <CgPlayButtonR />
                                                                <Text pl={1} >{each.videosCount} Videos</Text>                                                    
                                                            </Flex>
                                                            
                                                            <Flex alignItems="center" >
                                                                <BsCardList />
                                                                <Text pl={1}> {each.lessonCount} Lessons</Text>                                                    
                                                            </Flex>
                                                        </Flex>
                                                    </Flex>
                                                </Flex>
                                                
                                                <Flex alignItems="center">
                                                    <Progress value={each.statusPercentage} size="sm" color="brand.2" width="20rem" borderRadius="0.5rem"  height="0.4rem" />
                                                    <Text pl={5} fontSize={12} fontWeight="semibold" color="brand.bodyText" >{each.statusPercentage} %</Text>

                                                </Flex>

                                                <Button pb={0} bg="brand.2" color="white" onClick={() => resumeCourse(each.courseId)} fontSize={11} width="6rem" size="sm" >Resume</Button>

                                            </Flex>
                                        </Box>
                                    )
                                }    
                            </TabPanel>

                            <TabPanel>

                                {
                                     mandatoryCourses.length === 0 ? 

                                    <Box py={10} mt={2}>
                                        <MandatoryCourses />
                                    </Box>

                                    :

                                    mandatoryCourses?.map((each, index) => 

                                    each.completedAt === null
                                    ?     
                                    <Box boxShadow="sm" key={index} height="4rem" pt={4} mb='20px'>
                                    <Flex direction="row" justifyContent="space-between" alignItems="center" >
                                    
                                        <Flex direction="row" width="30%">

                                            <Image src={each.courses.cover_image_url} fallbackSrc="https://via.placeholder.com/150" width="3.2rem" mr={2} />
                                    
                                            <Flex direction="column" justifyContent="space-around" width="15em">
                                                <Text isTruncated fontSize={13} mb={2} color="brand.2" fontWeight="semibold">
                                                    {each?.courses.title}
                                                </Text>
                                                <Flex direction="row" fontSize={10} color="brand.bodyText" pb={2}>
                                                    <Flex alignItems="center" mr={4}>
                                                        <CgPlayButtonR />
                                                        <Text pl={1} >{each.courses.courseLessons.length} Videos</Text>                                                    
                                                    </Flex>
                                                    
                                                    <Flex alignItems="center" >
                                                        <BsCardList />
                                                        <Text pl={1}> {each.courses.courseLessons.length} Lessons</Text>                                                    
                                                    </Flex>
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                        
                                        <Flex alignItems="center">

                                        </Flex>

                                        <Button pb={0} bg="brand.2" color="white" onClick={ () => enrollCourse(each.courses.course_id) } fontSize={11} width="6rem" size="sm" > Start </Button>

                                    </Flex>
                                </Box>: null 
                
                                    
                                    )
                                }

                            </TabPanel>

                            <TabPanel>

                                {
                                    completedCourses.length === 0 ? 

                                    <Box py={10} mt={2}>
                                        <CompletedCourses />
                                    </Box>

                                    :

                                    completedCourses?.map((each, index) => 
                
                                        <Box boxShadow="sm" key={index} height="4rem" pt={4} mb={3}>
                                            
                                            <Flex direction="row" justifyContent="space-between" alignItems="center" >
                                            
                                                <Flex direction="row" width="30%">

                                                    {each.courses !== null && <Image src={each.courses.cover_image_url} fallbackSrc="https://via.placeholder.com/150" width="3.2rem" mr={2} />}
                                            
                                                    <Flex direction="column" justifyContent="space-around" width="15em">
                                                        <Text isTruncated fontSize={13} mb={2} color="brand.2" fontWeight="semibold">
                                                            {each.courses !== null && each.courses.title}
                                                        </Text>
                                                        <Flex direction="row" fontSize={10} color="brand.bodyText" pb={2}>
                                                            <Text whiteSpace="nowrap" mr={3} >Started: { (new Date(each?.createdAt as string).toDateString()) } </Text>
                                                            <Text whiteSpace="nowrap" >Completed: { (new Date(each?.completedAt as string).toDateString()) }</Text>
                                                        </Flex>
                                                    </Flex>
                                                </Flex>
                                                
                                                <Flex alignItems="center" justifyContent="center" fontSize={14} color="brand.2" >
                                                    <Box mr={1}> <IoMdCheckmark /> </Box>
                                                    <Text fontWeight="semibold" >Completed</Text>
                                                </Flex>

                                                <Button variant="outline" colorScheme="brand" onClick={ () => viewBadge(each)} fontSize={12} width="8rem" size="sm" > View to Download </Button>

                                            </Flex>
                                            
                                        </Box>
                                    )
                                }

                            </TabPanel>

                        </TabPanels>

                    </Tabs>

                    <Portal> <StudentBadgeModal isOpen={isOpen} onClose={onClose} data={selectedStudentBadge} user={globalState?.profile} /> </Portal>

                </Container>
            }
            
        </>
    );
}

export default Courses;