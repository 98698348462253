import { Box, Container, Flex, Grid, GridItem, VStack, Text, Stack, Progress, HStack, Avatar, Button } from "@chakra-ui/react";
import StarRating from 'react-svg-star-rating';
import { Pagination, PaginationContainer, PaginationNext, PaginationPage, PaginationPageGroup, PaginationPrevious, usePagination } from "@ajna/pagination";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { ICourse } from "../../models/course.model";
import { Ipage } from "../../models/auth.model";
import { fetchAllCourseReview } from "../../services/hr/users-http.service";
import { ScreenLoader } from "../../components/Common/ScreenLoader";
import { EmptyRecordResult } from "../../components/Common/EmptyRecords";
import { HrAdminRoutes } from "./routes";


const Reviews = () => {

    let {replace, push} = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [reviews, setReviews] = useState<ICourse[]>([]);
    const [page, setPage] = useState<Ipage>({page_total: 0, total: 0});
    

    const {
        currentPage,
        setCurrentPage,
        pagesCount,
        pages
      } = usePagination({
        //pagesCount: isNaN(Math.ceil(page.total / page.page_total)) ? 1 : Math.ceil(page.total / page.page_total),
        initialState: { currentPage: 1, pageSize: 10 },
        total: page.total
      });

      useEffect(() => {
          let url = `?page=${currentPage}&limit=${10}`;
          fetchAllCourseReview(url)
            .then((data) => {
            setPage({page_total: data.page_total, total: data.total})
            setReviews(data.results);
            setIsLoading(false)
            })
            .catch((err) => err)
            
            
        }, [currentPage]);

    return (
        <>
            <Container boxShadow="base" py={3} px={12} maxW="container.xl"  mb={6} bg="white" pb={12}>

                <Flex direction="column" mb={8}>
                    <Text color="brand.3" fontSize={18} fontWeight="semibold" > Manage Reviews</Text>
                </Flex>
                {

                isLoading?
                <ScreenLoader />
                
                :
                
                reviews.length === 0 ? 
                <EmptyRecordResult />
                :
                <>

{
             reviews.map((each, index) => 
             {
                 let average = 0 as any;
                 const starLevels = ["perone", "pertwo", "perthree", "perfour", "perfive"];
                 const starLevelObject: any = {
                     "perone": 0, "pertwo": 0, "perthree": 0, "perfour": 0, "perfive": 0
                 }

                 if (each.courseReviews?.length) {
                    let total = each.courseReviews.reduce((prev,next) => prev + next.rating,0);
                    average = ((total/each.courseReviews.length) * 10 / 10).toFixed(1);

                    for (let level = 0; level < starLevels.length; level++) {
                        if (average >= level + 1) {
                            starLevelObject[starLevels[level]] = 100
                        } else {
                            const remainder = average - (level)
                            starLevelObject[starLevels[level]] = Math.round((remainder * 100));
                            break;
                        }
                    }       
                    

                 }else{
                     return (<></>)
                 }
                  
                 return (
                <Container key={index} p={4} boxShadow="base" bg="white" maxW="container.xl" >
                    <Flex px={6} mb={8}>
                        <Text color="brand.3" fontSize={16} fontWeight="semibold" mr={5} > {each.title}</Text>
                        <Text color="brand.3" fontSize={12} > By {each.user?.full_name} | {new Date(each.createdAt).toDateString()} </Text>
                    </Flex>

                    <Grid gap={4} templateColumns='repeat(4, 1fr)' mb={8} justifyContent = {"start"}>
                        <GridItem colSpan={1} >
                            <VStack>
                                <Text color="brand" fontSize={44} fontWeight="bold" > {average}</Text>
                                <Flex>
                                    <StarRating isReadOnly={true} unit="float" size={20} initialRating={average} containerClassName="flexStar"/>
                                    <Text>({each.courseReviews.length})</Text>
                                </Flex>
                             </VStack>
                            
                            
                            
                        </GridItem>
                        

                        <GridItem colSpan={2} >
                            <Stack spacing={1.5} mt = {4}>
                                <Progress colorScheme='brand' size='sm' value={starLevelObject.perfive} />
                                <Progress colorScheme='brand' size='sm' value={starLevelObject.perfour} />
                                <Progress colorScheme='brand' size='sm' value={starLevelObject.perthree} />
                                <Progress colorScheme='brand' size='sm' value={starLevelObject.pertwo} />
                                <Progress colorScheme='brand' size='sm' value={starLevelObject.perone} />
                            </Stack>
                        </GridItem>
                            

                        <GridItem colSpan={1} >
                            <Stack spacing={1} mt = {3}>
                                <StarRating isReadOnly={true} unit="float" initialRating={5} size={10} containerClassName="flexStar"/>
                                <StarRating isReadOnly={true} unit="float" initialRating={4} size={10} containerClassName="flexStar"/>
                                <StarRating isReadOnly={true} unit="float" initialRating={3} size={10} containerClassName="flexStar"/>
                                <StarRating isReadOnly={true} unit="float" initialRating={2} size={10} containerClassName="flexStar"/>
                                <StarRating isReadOnly={true} unit="float" initialRating={1} size={10} containerClassName="flexStar"/>
                            </Stack>
                        </GridItem>
                            

                    </Grid>

                    <Grid gap={4} templateColumns='repeat(4, 1fr)' mb={4}>
                        <GridItem colSpan={1} >
                                <HStack spacing={2} alignItems="center" justifyContent={"center"} >
                                    <Avatar mt={5} verticalAlign={"center"} size="xs" src={!each.courseReviews[0].isAnonymous && each.courseReviews[0].user ? each.courseReviews[0].user?.photo_url : ""} name={!each.courseReviews[0].isAnonymous && each.courseReviews[0].user ? each.courseReviews[0].user?.full_name : "Anonymous"} ml={12} />
                                    <VStack maxW={"fit-content"}>
                                    <Text color="brand.3" fontSize={10} whiteSpace="nowrap" isTruncated title={"Time"}>
                                        {new Date(each.courseReviews[0].createdAt).toDateString()}
                                        </Text>
                                        <Text color="brand.3" fontSize={12} whiteSpace="nowrap" isTruncated title={!each.courseReviews[0].isAnonymous && each.courseReviews[0].user ? each.courseReviews[0].user?.full_name : "Anonymous"}>
                                        {!each.courseReviews[0].isAnonymous && each.courseReviews[0].user ? each.courseReviews[0].user?.full_name : "Anonymous"}
                                        </Text>
                                    </VStack>

                                </HStack>
                        </GridItem>


                        <GridItem colSpan={3} >
                            <Stack spacing={1}>
                                <StarRating isReadOnly={true}  unit="float" size={11} initialRating={each.courseReviews[0].rating} containerClassName="flexStar"/>
                                <Text color="brand.3" fontSize={10}   whiteSpace="nowrap">{each.courseReviews[0].review}</Text>
                            </Stack>
                        </GridItem>
                            

                    </Grid>
                  
                    <Flex alignContent={"center"} justifyContent={"center"} mt={8}>
                    <Button colorScheme="brand" variant={"outline"}  onClick={() => replace(HrAdminRoutes.Review.ViewLink(each?.courseReviews?.length ? String( each.id) : ""))} size="xs" fontSize={12}>See All</Button>


                    {/* changed each.courseReviews[0]?.review_id to  each.id */}
                    </Flex>
                </Container>
                 )
             }
             )

}

                <Flex alignContent={"center"} justifyContent={"center"} mt={4}>
                    <Pagination
                    pagesCount={pagesCount}
                    currentPage={currentPage}
                    onPageChange={setCurrentPage}
                  >
                    <PaginationContainer>
                      <PaginationPrevious>Previous</PaginationPrevious>
                      <PaginationPageGroup>
                        {pages.map((page: number) => (
                          <PaginationPage
                            key={`pagination_page_${page}`} 
                            page={page} 
                          />
                        ))}
                      </PaginationPageGroup>
                      <PaginationNext>Next</PaginationNext>
                    </PaginationContainer>
                  </Pagination>
                    
                    </Flex>

                    </>

            }

            

            </Container>
        </>
    );
}

export default Reviews;

function toFixed(arg0: number) {
    throw new Error("Function not implemented.");
}
