import { Box, Flex, Image, Text } from "@chakra-ui/react";
import invoice from '../../../img/vaadin_invoice.svg';


export const EmptyInvoice: React.FC<any> = () => {

    return (
        <Flex minHeight="inherit"  justifyContent="center">
        
            <Flex direction="column" alignItems="center" justifyContent="center" >

                <Box color="brand.subtitle" mb={2}>
                    <Image height="3em" width="auto" src={invoice} />
                </Box>

                <Text mb={4} fontSize={13} color="brand.subtitle"> No Invoice </Text>
                
            </Flex>
        </Flex>
    );
}