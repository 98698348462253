import { Box, Flex, Text, Image } from "@chakra-ui/react";
import requests from '../../../img/carbon_non-certified.svg';


export const EmptyCourseRequests: React.FC<any> = () => {

    return (
        <Flex minHeight="inherit"  justifyContent="center">
        
            <Flex direction="column" alignItems="center" justifyContent="center" >

                <Box color="brand.subtitle" mb={3} width={10}>
                    <Image src={requests} />
                </Box>

                <Text mb={4} fontSize={13} color="brand.subtitle"> No new course request </Text>
                
            </Flex>
        </Flex>
    );
}