import { Container, Text, Tabs, TabList, Tab, TabPanels, TabPanel, Box } from "@chakra-ui/react";
import { useState } from "react";
import { EmptyReport } from "../../components/Instructor/EmptyState/Report";
import Revenue from "../../components/Instructor/Report/Revenue";
import Traffic from "../../components/Instructor/Report/Traffic";

const Report = () => {

    const [reports] = useState([]);
    return (
        <Container boxShadow="base" maxW="container.xl" bg="white" py={3} px={10} mb={6} minHeight="70vh">

            {
                reports.length === 0 ?

                <EmptyReport />

                :

                <Box>
                    <Text color="brand.3" fontSize={18} fontWeight="semibold">
                        Report
                    </Text>

                    <Tabs mt={4}>
                        <TabList color="brand.2">
                            <Tab>Revenue</Tab>
                            <Tab>Traffic</Tab>
                        </TabList>

                        <TabPanels>
                            <TabPanel>
                                <Revenue />
                            </TabPanel>
                            
                            <TabPanel>
                                <Traffic />
                            </TabPanel>
                            
                        </TabPanels>

                    </Tabs> 
                </Box>
                
            }              
        
        </Container>
    );
}

export default Report;