import {
  Box,
  Button,
  Container,
  Flex,
  HStack,
  Image,
  Input,
  InputGroup,
  Tag,
  Text,
  InputRightElement,
  Link,
  IconButton,
  Portal,
  useDisclosure,
  Avatar,
  VStack,
  Badge,
  Spacer,
  toast,
  useToast,
} from '@chakra-ui/react'

import { ChevronDownIcon } from '@chakra-ui/icons'

import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from '@chakra-ui/react'

import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'

import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react'
import { ChangeEvent, useEffect, useState } from 'react'
import { ScreenLoader } from '../../../components/Common/ScreenLoader'
import { EmptyUsers } from '../../../components/Hr/EmptyState/Users'
import { HrAdminRoutes } from '../routes'
import {
  fetchUsers,
  genExcel,
  genExcelFilter,
  updateStatus,
} from '../../../services/hr/users-http.service'
import { useHistory } from 'react-router-dom'
import { RiSearch2Line } from 'react-icons/ri'
import { BiCloudDownload, BiDownArrow, BiUserPlus } from 'react-icons/bi'
import { EmptyCourseSearch } from '../../../components/Student/EmptyState/EmptyCourseSearch'
import { IoMdCreate } from 'react-icons/io'
import { FaCheck, FaTimesCircle } from 'react-icons/fa'
import { EditUserProfileModal } from '../../../components/Hr/EditUserProfileModal'
import { Ipage, IUser } from '../../../models/auth.model'
import { appConstant } from '../../../utils/app.util'
import {
  Pagination,
  PaginationContainer,
  PaginationNext,
  PaginationPage,
  PaginationPageGroup,
  PaginationPrevious,
  usePagination,
} from '@ajna/pagination'
import { IAnyUserList } from '../../../models/user.model'
import { userInfo } from 'os'
import { eachYearOfInterval } from 'date-fns/esm'
import { Checkbox, CheckboxGroup } from '@chakra-ui/react'
import { format, formatDistance, formatRelative, subDays } from 'date-fns'
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

export default function Students() {
  let { replace, push } = useHistory()
  const [isLoading, setIsLoading] = useState(true)
  const [users, setUsers] = useState<IAnyUserList[]>([])
  const [filteredUsers, setFilteredUsers] = useState<IAnyUserList[]>([])
  const [studentNo, setStudentNo] = useState(0)
  const [selectedUser, setSelectedUser] = useState<IUser>()
  const [page, setPage] = useState<Ipage>({ page_total: 0, total: 0 })
  const [search, setSearch] = useState('')
  const [query, setQuery] = useState('')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const componentRef = useRef(null)
  const toast = useToast()
  const [reload, setStatus] = useState(false)


  const [operation, setOperationTypes] = useState('')




  const [checkedItems, setCheckedItems] = useState<boolean[]>([])
  const [checkedIndex, setCheckedIndex] = useState<number[]>([])
  const [checkedUsers, setCheckedUsers] = useState<any[]>([])
  const allChecked = checkedItems.every(Boolean)
  const isIndeterminate = checkedItems.some(Boolean) && !allChecked

  const { currentPage, setCurrentPage, pagesCount, pages } = usePagination({
    //pagesCount: isNaN(Math.ceil(page.total / page.page_total)) ? 1 : Math.ceil(page.total / page.page_total),
    initialState: { currentPage: 1, pageSize: 10 },
    total: page.total,
  })


  let role_id = 1



  useEffect(() => {
    let url = `?page=${currentPage}&limit=${10}&search=${query}`
    fetchUsers(role_id, url)
      .then((data) => {
        setPage({ page_total: data.page_total, total: data.total })
        setFilteredUsers(data.results)
        setStudentNo(data.results.length)
        setIsLoading(false)



      })
      .catch((err) => err)

  }, [currentPage, query, reload])




  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search.length > 0) {
        setQuery(search)
      } else {
        setQuery('')
      }
    }, 700)

    return () => clearTimeout(delayDebounceFn)
  }, [search])




  const editUser = async (userId: number) => {
    let user = filteredUsers.filter((each) => each.id === userId).at(0)

    if (user?.courseEnrollments) {
      delete user.courseEnrollments
    }

    setSelectedUser(user)
    onOpen()
  }




  const updatestatus = async (status: number, user_id: number) => {
    const user = [
      {
        user_id,
        status,
      },
    ]

    updateStatus(user)
      .then((data) => {
        setStatus(!reload)
        setIsLoading(false)
        if (status === 1) {
          toast({
            title: "Update status",
            description: "Status Enabled successfully",
            status: "success"
          })
        } if (status === -1) {
          toast({
            title: "Update status",
            description: "Status Disabled successfully",
            status: "success"
          })
        }

      })
      .catch((error) =>
        toast({
          title: error?.statusText ? error?.statusText : 'Update status',
          description: error?.data?.error
            ? error?.data?.error
            : 'Error ocurred',
          status: 'error',
        }),
      )

  }



  function handleExcel() {
    genExcel()
      .then((response: any) => {
        var blob = new Blob(
          [response],
          { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" },
        );
        var a = document.createElement("a");
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = "users_report.xlsx";
        a.click();


        if (response) {
          toast({
            title: ' Excel',
            description: 'Excel File downloaded sucessfully',
            status: 'success',

          })
          push(HrAdminRoutes.Users.Student)
        }
      })
      .catch((error) =>
        toast({
          title: 'Excel',
          description: 'Download Failed, please try again :',
          status: 'error',
        }))
  }

  function handleExcelFilter() {
    genExcelFilter(checkedIndex)
      .then((response: any) => {
        var blob = new Blob(
          [response],
          { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" },
        );
        var a = document.createElement("a");
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = "users_report.xlsx";
        a.click();


        if (response) {
          toast({
            title: ' Excel',
            description: 'Excel File downloaded sucessfully',
            status: 'success',

          })
          push(HrAdminRoutes.Users.Student)
        }
      })
      .catch((error) =>
        toast({
          title: 'Excel',
          description: 'Download Failed, please try again :',
          status: 'error',
        }))
  }




  const reloadData = () => {
    setStatus(!reload)
  }

  const viewUser = async (userId: number) => {
    push(HrAdminRoutes.Users.ViewLink(userId))
  }


  const handlePrint = useReactToPrint({
    content: () => componentRef.current as any,
    onAfterPrint: () => {
      toast({
        title: ' PDF',
        description: 'PDF File downloaded sucessfully',
        status: 'success',
      })
    }

  })





  const checkAllItems = (e: any) => {
    let checkedArray = checkedItems.map(() => e.target.checked)
    setCheckedItems(checkedArray)

  }

  function updateCheckItems(checked: boolean, index: number, selectedUser: {}): void {
    let checkedArray = [...checkedItems]
    checkedArray[index] = checked;
    setCheckedItems(checkedArray);

    if (checked === true) {
      //setting selected id
      let checkedArrayIndex: any[] = [...checkedIndex];
      checkedArrayIndex.push(index)
      setCheckedIndex(checkedArrayIndex)
      //setting filteredUsers
      let checkedArrayUser: any[] = [...checkedUsers];
      checkedArrayUser.push(selectedUser)
      setCheckedUsers(checkedArrayUser)

    } else if (checked === false) {
      //removing selected id
      let checkedArrayIndex: any[] = [...checkedIndex];
      let ind = checkedArrayIndex.indexOf(index)
      checkedArrayIndex.splice(ind, 1)
      setCheckedIndex(checkedArrayIndex)

      //removing selected Users
      let checkedArrayUser: any[] = [...checkedUsers];
      let indUser = checkedArrayUser.indexOf(index)
      checkedArrayUser.splice(indUser, 1)
      setCheckedUsers(checkedArrayUser)
    }
  }


  function updateOperation(operation_type: string): void {

    setOperationTypes(operation_type);
  }



  useEffect(() => {

    let newUsers: boolean[] = [];
    let checkedArray = filteredUsers.map((userDetail, index) => {
      newUsers[userDetail.id] = false;
    });

    // newUsers[0] = true;



    setCheckedItems(newUsers)
    console.log(newUsers, 'new users')
  }, [filteredUsers])

  function apply() {

    checkedItems.forEach((value, index) => {

      if (value) {
        if (operation == "enable") {
          updatestatus(1, index)
        } else {
          updatestatus(-1, index)

        }
      }

    })


  }



  //   Pass rate = (pass /(pass + failed)) * 100

  //  Completion rate = (completed/enrollment) * 100





  const exportPDF = () => {
    let url = `?page=${currentPage}&limit=${100}&search=${query}`
    let fetchedData: IAnyUserList[];
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = `Students(${page.total})`;
    const headers = [["Name", "Dept", "Enrolled", "%Completed", "Pass/Fail Rate", "Bridge Count", "Date Onboarded", "Last Seen", "Status"]];
    fetchUsers(role_id, url)
      .then((data) => {
        if (checkedUsers.length > 0) {
          fetchedData = checkedUsers;
        } else {
          fetchedData = data.results;
        }
        const dataf = fetchedData?.map(users => [users.full_name,
        users.department,
        users.enrollments,
        Number(users.completed) + "%",
        Number(users.passed) + "%",
        users.bridge_count,
        new Date(users.created_at as string).toDateString(),
        users.lastSeen ? new Date(users.lastSeen as string).toDateString() : "Never",
        users.status === 1 ? "Active" : users.status === 0 ? "Pending" : users.status === -1 ? "Disabled" : null,
        ]);

        toast({
          title: ' PDF',
          description: 'PDF File downloaded sucessfully',
          status: 'success',
        })

        doc.text(title, marginLeft, 40);
        autoTable(doc, {
          startY: 50,
          head: headers,
          body: dataf as string[][]
        });
        doc.save("student.pdf")
      })
      .catch((err) => err)

  }











  return (
    <Container
      boxShadow="base"
      maxW="container.xl"
      bg="white"
      pt={3}
      pb={10}
      px={8}
      minHeight="70vh"
    >
      {isLoading ? (
        <ScreenLoader />
      ) :
        <Box>
          <Text pl={6} color="brand.3" fontSize={18} fontWeight="semibold">
            Students ({studentNo})
          </Text>

          <Flex
            justifyContent="space-between"
            alignItems="center"
            px={6}
            mt={4}
          >
            <InputGroup color="brand.subtitle" bg="white" width="40%">
              <Input
                placeholder="Search users on this platform"
                onChange={(e) => setSearch(e.target.value)}
                fontSize={12}
                size="sm"
              />

              <InputRightElement
                h="1.9rem"
                pointerEvents="none"
                children={<RiSearch2Line size="0.9rem" />}
              />
            </InputGroup>

            <Flex justifyContent="space-between">
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  colorScheme="brand"
                  variant={'outline'}
                  size="sm"
                  fontSize={12}
                >
                  Generate Report
                </MenuButton>
                <MenuList fontSize={12}>
                  <MenuItem onClick={exportPDF}> PDF</MenuItem>
                  {checkedIndex.length > 0 ? <MenuItem onClick={() => handleExcelFilter()}>
                    Excel
                  </MenuItem> :
                    <MenuItem onClick={() => handleExcel()}>
                      Excel
                    </MenuItem>}

                </MenuList>
              </Menu>
              <Spacer mx={2} />

              <Button
                colorScheme="brand"
                leftIcon={<BiUserPlus />}
                onClick={() => replace(HrAdminRoutes.Users.Create)}
                size="sm"
                fontSize={12}
              >
                Create New User
              </Button>
            </Flex>
          </Flex>

          {filteredUsers.length === 0 ? (
            <EmptyCourseSearch />
          ) : (
            <Box ref={componentRef} overflowX={"scroll"} >



              <Table variant="simple" mt={8} size="sm">
                <Thead fontWeight="normal">
                  <Tr color="brand.subtitle">
                    <Checkbox mt={4}
                      isChecked={allChecked}
                      isIndeterminate={isIndeterminate}
                      onChange={(e) => checkAllItems(e)}
                    ></Checkbox>
                    <Th pl={90} textTransform="none" textAlign={'start'}>
                      Name
                    </Th>
                    <Th textTransform="none">Dept.</Th>
                    <Th textTransform="none">Enrolled</Th>
                    <Th textTransform="none">% Completed</Th>
                    <Th textTransform="none">Pass/Fail Rate</Th>
                    <Th textTransform="none">Bridge Count</Th>
                    <Th textTransform="none">Date Onboarded</Th>
                    <Th textTransform="none">Last Seen</Th>
                    <Th textTransform="none">Status</Th>
                    <Th textTransform="none">Actions</Th>
                  </Tr>
                </Thead>

                <Tbody>
                  {filteredUsers.map((each, index) => (

                    <Tr key={index} fontSize={13} color="brand.subtitle">
                      <Checkbox mt={10}
                        isChecked={checkedItems[each.id]}
                        onChange={(e) =>
                          updateCheckItems(
                            e.target.checked, each.id, each
                          )
                        }
                      ></Checkbox>

                      <Td role={"button"} onClick={() => viewUser(each.id)}>
                        <HStack
                          spacing={3}
                          alignItems="center"
                          maxW="fit-content"
                        >
                          <Avatar

                            verticalAlign={"center"}
                            size="sm"
                            name={each.full_name}
                            src={each.photo_url}
                            ml={16}
                            mr={6}
                          />
                          <Text
                            color="brand.3"
                            whiteSpace="nowrap"
                            isTruncated
                            title={each.full_name}
                          >
                            {each.full_name}
                          </Text>
                        </HStack>
                      </Td>

                      <Td>{each.department ? each.department : "Nil"}</Td>
                      {/* {each.dept ? each.dept : 0} */}

                      <Td>{each.enrollments ? each.enrollments : "Nil"}</Td>

                      <Td>{each.completed ? (Number(each.completed) === 0 && Number(each.enrollments)) === 0 ? 0 : (Number(each.completed) / Number(each.enrollments) * 100).toFixed(0) + "%" : 0}</Td>


                      <Td>
                        {parseInt(each.passed as string) === 0 && parseInt(each.failed as string) === 0 ? 0 : (Number(each.passed) / Number(each.passed) + Number(each.failed)) * 100 + "%"}
                      </Td>

                      <Td>{each.bridge_count ? each.bridge_count : "Nil"}</Td>

                      <Td>{new Date(each.created_at as string).toDateString()}</Td>

                      <Td>{each.lastSeen ? new Date(each.lastSeen as string).toDateString() : "Never"}</Td>

                      <Td>
                        <Badge colorScheme={each.status === 1 ? "success" : each.status === 0 ? "yellow" : each.status === -1 ? "danger" : "primary"} variant={"outline"}>

                          {each.status === 1 ? "Active" : each.status === 0 ? "Pending" : each.status === -1 ? "Disabled" : null}
                        </Badge>
                      </Td>
                      <Td>
                        {each.status === 1 && (
                          <HStack>
                            <IconButton
                              title="Edit"
                              variant="ghost"
                              aria-label="edit"
                              color="success.500"
                              borderRadius={20}
                              size="xs"
                              icon={<IoMdCreate />}
                              fontSize={16}
                              onClick={() => editUser(each.id)}
                            />
                            <IconButton
                              title="Delete"
                              variant="ghost"
                              aria-label="delete"
                              color="danger.300"
                              borderRadius={20}
                              size="xs"
                              icon={<FaTimesCircle />}
                              fontSize={14}
                              onClick={() => updatestatus(-1, each.id)}
                            />
                          </HStack>
                        )}

                        {each.status === 0 && (
                          <HStack>
                            <IconButton
                              title="Edit"
                              variant="ghost"
                              aria-label="edit"
                              color="success.500"
                              borderRadius={20}
                              size="xs"
                              icon={<IoMdCreate />}
                              fontSize={16}
                              onClick={() => editUser(each.id)}
                            />
                            <IconButton
                              title="Enable"
                              variant="ghost"
                              aria-label="enable"
                              color="success.500"
                              borderRadius={20}
                              size="xs"
                              icon={<FaCheck />}
                              fontSize={14}
                              onClick={() => updatestatus(1, each.id)}
                            />
                          </HStack>
                        )}

                        {each.status === -1 &&
                          <HStack>
                            <IconButton
                              title="Edit"
                              variant="ghost"
                              aria-label="edit"
                              color="success.500"
                              borderRadius={20}
                              size="xs"
                              icon={<IoMdCreate />}
                              fontSize={16}
                              onClick={() => editUser(each.id)}
                            />
                            <IconButton
                              title="Enable"
                              variant="ghost"
                              aria-label="enable"
                              color="success.500"
                              borderRadius={20}
                              size="xs"
                              icon={<FaCheck />}
                              fontSize={14}
                              onClick={() => updatestatus(1, each.id)}
                            />
                          </HStack>
                        }
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>

            </Box>
          )}

          <Flex justifyContent="space-between" mt={14}>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                color="brand.subtitle"
                variant={'outline'}
                size="sm"
                fontSize={13}
                mr={4}

              >
                {operation === 'enable' ? 'Enable' : 'Disable'}

              </MenuButton>
              <MenuList fontSize={12}>
                <MenuItem
                  onClick={() => updateOperation('enable')}
                >
                  Enable
                </MenuItem>

                <MenuItem
                  onClick={() => updateOperation('disable')}
                >
                  Disable
                </MenuItem>
              </MenuList>
            </Menu>

            <Menu>
              <MenuButton
                as={Button}
                colorScheme="brand"
                variant={'outline'}
                size="sm"
                fontSize={13}
                onClick={() => apply()}
              >
                Apply
              </MenuButton>
            </Menu>
            <Spacer mx={2} />
          </Flex>

          <Flex alignContent={'center'} justifyContent={'center'}>
            <Pagination
              pagesCount={pagesCount}
              currentPage={currentPage}
              onPageChange={setCurrentPage}
            >
              <PaginationContainer>
                <PaginationPrevious

                >
                  Previous
                </PaginationPrevious>
                <PaginationPageGroup>
                  {pages.map((page: number) => (
                    <PaginationPage
                      key={`pagination_page_${page}`}
                      page={page}
                    />
                  ))}
                </PaginationPageGroup>
                <PaginationNext


                >
                  Next
                </PaginationNext>
              </PaginationContainer>
            </Pagination>
          </Flex>
        </Box>
      }

      {selectedUser && (
        <Portal>
          {' '}
          <EditUserProfileModal
            isOpen={isOpen}
            onClose={onClose}
            isEdit={true}
            users={users}
            userData={selectedUser}
            reloadData={reloadData}
            isInstructor={
              selectedUser.role?.role_id === appConstant.role.instructor
            }
          />{' '}
        </Portal>
      )}
    </Container>
  )
}






