import { Box, Flex, Text } from "@chakra-ui/react";
import { BiBarChart } from "react-icons/bi";

export const EmptyCourseStatus: React.FC<any> = () => {

    return (
        <Flex minHeight="inherit"  justifyContent="center">
        
            <Flex direction="column" alignItems="center" justifyContent="center" >

                <Box color="brand.subtitle" mb={6}>
                    <BiBarChart fontSize={40} />
                </Box>

                <Text mb={4} fontSize={13} color="brand.subtitle"> No activities on your courses yet </Text>
                
            </Flex>
        </Flex>
    );
}