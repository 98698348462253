import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton, Text, Box, useToast, FormControl, FormLabel, InputGroup, InputLeftElement, Input, FormErrorMessage, Spacer, Flex, Button, Checkbox, Center, Select
  } from "@chakra-ui/react"
import { IUser, IUserLevel, IUserRank } from "../../models/auth.model";
import { IUserEditDto } from "../../models/user.model";
import { useFormik } from "formik";
import { editUserProfile, getUploadableUnit } from "../../services/hr/users-http.service";
import { IoMdCall, IoMdContact, IoMdMail } from "react-icons/io";
import { MultiSelect } from "../Common/MultiSelect";
import {IMultiSelect } from "../../models/course.model";
import { useEffect, useState } from "react";
import { fetchCategories, fetchUserLevel, fetchUserRank } from "../../services/shared.service";
  
  
  export const EditUserProfileModal: React.FC<any> = ({isOpen, onClose, userData,isEdit = false, isInstructor = false, reloadData }: {isEdit:boolean, isOpen: any, onClose: any, userData: IUser, isInstructor: boolean, reloadData: any }) => {
  
    const toast = useToast();

    const [uploadableUnit, setUploadableUnit] = useState<IMultiSelect[]>([]);
    const [levels, setLevels] = useState<IUserLevel[]>([]);
    const [level, setLevel] = useState<any>("");
    const [selectedUnit, setSelectedUnit] = useState<IMultiSelect[]>([]);
    const [ranks, setRanks] = useState<IUserRank[]>([]);

    useEffect(() => {
        if(userData){
            setLevel(String(userData?.rank?.levelId))
            
            getUploadableUnit(userData.id)
            .then((data) => {
                    setSelectedUnit(data.map(each => {
                        return {
                        value: String(each.id),
                        label: each.title
                    }
                }
                    ))
            })
            .catch((err) => err)
        } 
    }, [userData]);

    const formik = useFormik({
        enableReinitialize: true,
        validate: (values: any) => {

            if(values.level && values.level !== level) {
                setLevel(values.level)
            }
            
            const errors: any = {};
            if(!values.fullname){
                errors.fullname = 'Fullname is required'
            }
            if(!values.phone){
                errors.phone = 'Phone number is required'
            }else{
                const re = /^[0-9\b]+$/;
                if (!re.test(values.phone) || String(values.phone).length != 11) {
                    errors.phone = 'Please input a valid phone number'
                }
            }

            if(!values.email){
                errors.email = 'Email address is required'
            }

            

            if(!values.level){
                errors.level = 'Level is required'
            }

            if(!values.unit){
                errors.unit = 'Department is required'
            }

            if(!values.rank){
                errors.rank = 'Rank is required'
            }
            return errors;
        },
        initialValues: {
            fullname: userData.full_name,
            phone: userData?.phone??"",
            email: userData.email,
            level: String(userData?.rank?.levelId),
            unit: String(userData?.unitId),
            rank: String(userData?.rankId),
            newsletter: userData.newsletter
        },
        onSubmit: async (values) => {

            try {

        

                let userDto: IUserEditDto = {
                    fullName: values.fullname,
                    email: values.email,
                    phone: String(values.phone),
                    level: Number(values.level),
                    rank: Number(values.rank),
                    unit: Number(values.unit),
                    newsletter: values.newsletter,
                    ...(isInstructor ? {uploadableUnits: selectedUnit.map(each => {
                        return {id: Number(each.value)}
                    })} : {})
                }

                const user = await editUserProfile(userData.id, userDto);

                if(user){

                    toast({
                        title: "Edit User",
                        description: "User profile updated successfully",
                        status: "success",
                        duration: 2000
                    })

                    isOpen = onClose()
                    formik.resetForm()
                    reloadData()
                }
                
            }catch(error:any) {
                 toast({
                    title: "Edit User",
                    description: error.data.error,
                    status: "error",
                })                
            }
        }
    })

    useEffect(() => {
        fetchCategories()
        .then(async (data) => {
            let units = await data.map(each => {
                return {
                    value: String(each.id),
                    label: each.title
                }
            })
            setUploadableUnit(units)
            
            
        })
        .catch((err) => err)

        fetchUserLevel()
        .then((data) => {

            setLevels(data)
        })
        .catch((err) => err)
    }, []);

    useEffect(() => {
        if(level && level !== null){
            fetchUserRank(level)
            .then((data) => {

                setRanks(data)
            })
            .catch((err) => err)
        } 
    }, [level]);

    
    
  
  
      return (
      <>
         
        <Modal isOpen={isOpen && isEdit} onClose={onClose} scrollBehavior="outside" closeOnOverlayClick={true}  size="4xl">
          <ModalOverlay />
          <ModalContent borderRadius={5} px={6} >
            <ModalHeader >
                <Text fontSize={16}>{userData.full_name}</Text>               
              </ModalHeader>
            <ModalCloseButton size="sm" />
            <ModalBody pb={6}>
  
              <Text fontSize={13} pb={3} pl={3} color="brand.2" >Edit Profile</Text>
  
              <Box mt={4}>

                <form onSubmit={formik.handleSubmit} >
                    
                        <FormControl isInvalid={(formik.errors.fullname && formik.touched.fullname) ? true : false} >
                            <FormLabel color="brand.subtitle" fontSize={13}>Full Name</FormLabel>
                            <InputGroup>
                            <InputLeftElement 
                                color="brand.subtitle"
                                pointerEvents="none"
                                children={<IoMdContact color="brand.subtitle" />}
                            />
                            <Input name="fullname" id="fullname" placeholder="Full Name" type="text" value={formik.values.fullname} onChange={formik.handleChange} fontSize={12} />
                            </InputGroup>
                            <FormErrorMessage fontSize={12} >{formik.errors.fullname}</FormErrorMessage>
                        </FormControl>
                        
                        <Flex mt={8}>
                        <FormControl isInvalid={(formik.errors.email && formik.touched.email) ? true : false} >
                            <FormLabel color="brand.subtitle" fontSize={13}>Email Address</FormLabel>
                            <InputGroup>
                            <InputLeftElement 
                                color="brand.subtitle"
                                pointerEvents="none"
                                children={<IoMdMail color="brand.subtitle" />}
                            />
                            <Input name="email" id="email" placeholder="Email Address" type="email" value={formik.values.email} onChange={formik.handleChange} fontSize={12} />
                            </InputGroup>
                            <FormErrorMessage fontSize={12} >{formik.errors.email}</FormErrorMessage>
                        </FormControl>
                        <Spacer mx={8} />
                        <FormControl isInvalid={(formik.errors.phone && formik.touched.phone) ? true : false} >
                            <FormLabel color="brand.subtitle" fontSize={13}>Phone</FormLabel>
                            <InputGroup>
                            <InputLeftElement 
                                color="brand.subtitle"
                                pointerEvents="none"
                                children={<IoMdCall color="brand.subtitle" />}
                            />
                            <Input name="phone" id="phone" placeholder="Phone" type="text" value={formik.values.phone} onChange={formik.handleChange} fontSize={12} />
                            </InputGroup>
                            <FormErrorMessage fontSize={12} >{formik.errors.phone}</FormErrorMessage>
                        </FormControl>
                        </Flex>
                     

                   

                        <Flex mt={8}>

                    

                            <FormControl isInvalid={(formik.errors.unit && formik.touched.unit) ? true : false}>
                                <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>Unit</FormLabel>
                                <Select variant="outline" placeholder="Select a Unit" name="unit" value={formik.values.unit} size={"md"} onChange={formik.handleChange} fontSize={12} color="brand.subtitle" >
                                {
                                    uploadableUnit.map( each => <option key={each.value} value={each.value}> {each.label} </option>)
                                }
                            </Select>
                                <FormErrorMessage fontSize={12} >{formik.errors.unit}</FormErrorMessage>
                            </FormControl>
                            <Spacer mx={8} />
                            
                            <FormControl id="level" isInvalid={(formik.errors.level && formik.touched.level) ? true : false} mb={6}>
                        <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>Level</FormLabel>
                        <Select  variant="outline" placeholder="Select a Level" name="level" value={formik.values.level} size={"md"} onChange={formik.handleChange} fontSize={12} color="brand.subtitle" >
                {
                    levels.map( each => <option key={each.id} value={each.id}> {each.name} </option>)
                }
            </Select>
                        <FormErrorMessage fontSize={12} >{formik.errors.level}</FormErrorMessage>
                    </FormControl>
                
                
                    </Flex>
                        {isInstructor ? 
                            

                            <Flex mt={8}>
                            <FormControl id="rank" isInvalid={(formik.errors.rank && formik.touched.rank) ? true : false} mb={14}>
                            <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>Rank</FormLabel>
                            <Select variant="outline" placeholder="Select a Rank" name="rank" value={formik.values.rank} size={"md"} onChange={formik.handleChange} fontSize={12} color="brand.subtitle" >
                {
                    ranks.map( each => <option key={each.id} value={each.id}> {each.name} </option>)
                }
            </Select>
                            <FormErrorMessage fontSize={12} >{formik.errors.rank}</FormErrorMessage>
                        </FormControl>
    
                        <Spacer mx={8} />
    
                        <FormControl >
                                    <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>Uploadable Unit</FormLabel>
                                    <MultiSelect data={uploadableUnit} name="uploadableunit" placeholder="Select Other Units"  selected={selectedUnit} setSelected={(e: IMultiSelect[]) => setSelectedUnit(e)} size="md"/>
                                    
                                </FormControl>
    
                            </Flex>
                        :
                        <FormControl id="rank" isInvalid={(formik.errors.rank && formik.touched.rank) ? true : false} mb={14}>
                        <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>Rank</FormLabel>
                        <Select variant="outline" placeholder="Select a Rank" name="rank" value={formik.values.rank} size={"md"} onChange={formik.handleChange} fontSize={12} color="brand.subtitle" >
                {
                    ranks.map( each => <option key={each.id} value={each.id}> {each.name} </option>)
                }
            </Select>
                        <FormErrorMessage fontSize={12} >{formik.errors.rank}</FormErrorMessage>
                    </FormControl>
                        }

                    <FormControl isInvalid={(formik.errors.newsletter) ? true : false} mb={6}>
                        
                            <Checkbox mr="3" color="brand.2" name="newsletter" onChange={formik.handleChange} checked={formik.values.newsletter}>
                            <Text color="brand.bodyText" fontSize={13} >Newsletters</Text>
                            </Checkbox>
                            
                        
                        <Center><FormErrorMessage fontSize={12} >{formik.errors.newsletter}</FormErrorMessage></Center>
                    </FormControl>

                        <Flex justifyContent="center">
                            <Button mt={8} colorScheme="brand" type="submit" isLoading={formik.isSubmitting} size="sm" px={6} fontSize={12} > Save Changes</Button> 
                        </Flex>
                </form>

            </Box>
  
            </ModalBody>
  
            <ModalFooter>
            </ModalFooter>
          </ModalContent>
        </Modal>
  
      </>
    )
  }