import AuthLayout from '../components/Common/AuthLayout';
import {Flex, Button, FormLabel, Input, Checkbox, Text, Box, Link, InputGroup, Center, InputLeftElement, FormControl, FormErrorMessage, useToast} from '@chakra-ui/react';
import {MdLock, MdMailOutline} from 'react-icons/md';
import {Link as ReactLink, useHistory } from 'react-router-dom';
import {FiUser} from 'react-icons/fi';
import routes from './routes';
import { useEffect } from 'react';
import { isAuthenticated, signUpService } from '../services/auth.service';
import { useFormik } from 'formik';
import { httpStatusCode } from '../models/httpStatus.model';


function SignUp(){

    const toast = useToast();

    const history = useHistory();

    useEffect(() => {
        if(isAuthenticated()){
            history.replace('/')
        }
    }, [history]);

    // const signUp = () => , 

     const formik = useFormik({
        initialValues: {email: '', password: '', fullName: '', isAccepted: false},
        validate: (values: any) => {
            const errors: any = {};
            if(!values.email){
                errors.email = 'Email is required';
            }else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
                errors.email = 'Invalid email address';
            }

            if(!values.fullName){
                errors.fullName = 'Full Name is required';
            }else if(
                /^(?=.*\d)/g.test(values.fullName)
            ){
                errors.fullName = 'Only letters are accepted';
            }

            if(!values.password){
                errors.password = 'Password is required';
            }else if(
                !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])/g.test(values.password)
            ){
                errors.password = 'Password should contain Uppercase, Lowercase, Number and Symbol';
            }else if(values.password.length < 6){
                errors.password = 'Minimun of 6 characters required';
            }
            if(!values.isAccepted){
                errors.isAccepted = 'Accept Terms and Conditions';
            }
            return errors;
        },
        onSubmit: async (values) => {

            try {
                 const response = await signUpService({email: values.email, password: values.password, fullName: values.fullName});
                
                 if(response.statusCode === httpStatusCode.CREATED){

                    toast({
                        title: "Profile SignUp",
                        description: response.message,
                        status: "success",
                        duration: 2000,
                        onCloseComplete: () => {
                            history.replace('/')
                        }
                    })
                }
            } catch (error: any) {
                toast({
                    title: "Profile SignUp",
                    description: error.data.error,
                    status: "error",
                })
            }
        }
    });

   return (
       <AuthLayout>

           <Box>
                <Text mb={4} fontSize={18} fontWeight="semibold" color="brand.2">Create an account</Text>

                <form onSubmit={formik.handleSubmit}>
                    <FormControl isInvalid={(formik.errors.fullName && formik.touched.fullName) ? true : false} mb={8}>
                        <FormLabel color="brand.subtitle" fontSize={12} >NAME:</FormLabel>
                        <InputGroup>
                            <InputLeftElement 
                                color="brand.subtitle"
                                pointerEvents="none"
                                children={<FiUser color="brand.subtitle" />}
                            />
                            <Input type="text" placeholder="Alex James" name="fullName" onChange={formik.handleChange} fontSize={13} />
                        </InputGroup>
                        <FormErrorMessage fontSize={12} >{formik.errors.fullName}</FormErrorMessage>
                    </FormControl>
                    
                    <FormControl isInvalid={(formik.errors.email && formik.touched.email) ? true : false} mb={8}>
                        <FormLabel color="brand.subtitle" fontSize={12} >EMAIL ADDRESS:</FormLabel>
                        <InputGroup>
                            <InputLeftElement 
                                color="brand.subtitle"
                                pointerEvents="none"
                                children={<MdMailOutline color="brand.subtitle" />}
                            />
                            <Input type="email" placeholder="alex@gmail.com" name="email" onChange={formik.handleChange}  fontSize={13} />
                        </InputGroup>
                        <FormErrorMessage fontSize={12} >{formik.errors.email}</FormErrorMessage>
                    </FormControl>
                    
                    <FormControl isInvalid={(formik.errors.password && formik.touched.password) ? true : false} mb={8}>
                        <FormLabel color="brand.subtitle" fontSize={12} >PASSWORD:</FormLabel>
                        <InputGroup>
                            <InputLeftElement 
                                color="brand.subtitle"
                                pointerEvents="none"
                                children={<MdLock color="brand.subtitle" />}
                            />
                            <Input type="password" placeholder="Enter your password" name="password" onChange={formik.handleChange} fontSize={13} />
                        </InputGroup>
                        <FormErrorMessage fontSize={12} >{formik.errors.password}</FormErrorMessage>
                    </FormControl>
                    
                    <FormControl isInvalid={(formik.errors.isAccepted && formik.touched.isAccepted) ? true : false} mb={6}>
                        <Flex direction="row" align="center" alignSelf="center"  alignContent="center" justifyContent="center" >
                            <Checkbox mr="3" color="brand.2" name="isAccepted" onChange={formik.handleChange} ></Checkbox>
                            <Text color="brand.bodyText" fontSize={13} >I accept <Link color="brand.2"> Terms and Conditions </Link> </Text>
                        </Flex>
                        <Center><FormErrorMessage fontSize={12} >{formik.errors.isAccepted}</FormErrorMessage></Center>
                    </FormControl>
                    
                    <Center>
                        <Button w="100%" type="submit" bg="brand.2" color="white" mb={6} fontSize={13} isLoading={formik.isSubmitting} >Create Account</Button>
                    </Center>

                    <Center>
                        <Text color="brand.bodyText" fontSize={12} alignSelf="center" >Have an account? <Link as={ReactLink} to={routes.Login} color="brand.2">Login</Link> </Text>
                    </Center>
                </form>
           </Box>
           
            
            

       </AuthLayout>
    );
}

export default SignUp;