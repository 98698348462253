import { HttpResponse } from "../models/httpResponse.model";
import { ICountry, IState } from "../models/location.model";
import {httpClient} from "./httpClient";

export const fetchCountries = async () => {

    let response = await httpClient.get<HttpResponse<ICountry[]>>("/shared/country");

    return response.data.data;
}

export const fetchStates = async (countryId: string) => {

    let response = await httpClient.get<HttpResponse<IState[]>>(`/shared/country/state/${countryId}`);

    return response.data.data;
}