import {
  InstructorCourse,
  IReview,
  IStudent,
  StudentCourse,
  ICourseWithAuthor,
  ITrainingMgrCourseList,
  ICourseWithEnrollmentStatus,
  ICourseEnrollmentStatusList,
  ICourseWithAuthorAndStudents,
  ICourseStatusList,
  ICourse,
  ICourseWithStudents,
  ICourseProgress,
  ICourseProgressList,
  ICategory,
  IReviews,
} from '../models/course.model'
import { IGroup, IGroupList } from '../models/group.model'
import {
  IHrCourseEnrollment,
  IHrCourseVisit,
  IHrUnique,
  IHrVisit,
  IMonthCompleted,
  IMonthCourses,
  IMonthEnrolled,
  IMonthProgress,
  IMonthStudents,
  IMonthUsers,
  IMonthVisit,
} from '../models/auth.model'
import { Status as StatusModel } from '../models/status.model'
import { secondsToHrMin } from './secondsToHrMin'
import { appConstant } from './app.util'

export const courseUtil = (data: InstructorCourse[]) => {
  let courses = data.map((each) => {
    let rating =
      each.courseReviews.length > 0
        ? Math.round(
          each.courseReviews.reduce((prev, acc) => prev + acc.rating, 0) /
          each.courseReviews.length,
        ).toFixed(1)
        : '0'

    let completed = each.courseEnrollments.filter(
      (enroll) => enroll.completedAt !== null,
    ).length

    let live = each.approvedAt ? true : false

    let expired = new Date(each.endAt) < new Date ? true : false

    return {
      title: each.title,
      live,
      expired,
      students: each.courseEnrollments.length,
      rating: rating,
      earnings: 0,
      completed,
      coverImage: each.cover_image_url,
      courseId: each.course_id,
    }
  })

  return courses
}

export const studentListUtil = (data: IStudent[]) => {

  let students = data.map((each) => {


    let completed = 0
    let courses = 0

    // each.courseEnrollments.forEach(value => {
    //     completed+= value.progresses.filter(progress => progress.completedAt !== null).length;
    // })

    each.courseEnrollments.forEach((value) => {
      courses += value?.courses?.courseLessons.length || 0
      completed += value?.progresses.length || 0
    })

    let progress = (completed / courses) * 100

    const result = {
      name: each?.full_name || "",
      courses: each?.courseEnrollments.length || 0,
      progress: Math.round(progress) || 0,
      img_url: each.photo_url,
      userId: each.user_id,
    }



    return result
  })

  return students
}

export const reviewListUtil = (data: IReviews[]) => {
  let reviews = data.map((each) => {
    return {
      id: each.review_id,
      name: each?.user?.full_name as string,
      img_url: each?.user?.photo_url as string,
      title: each?.course?.title as string,
      comments: each.review,
      star: each.rating,
    }
  })

  return reviews
}

export const pieDataUtil = (data: any) => {
  let pie = []
  
  for (let index in data) {
    pie.push({
      name: `${index} `,
      value: Number(data[index]),
    })
  }
  let filteredData = pie.filter((each) =>{
    return each.value > 0
  })

  return filteredData
}

export const firstAttemptUtil = (data: number) => {
  let rate = Math.round(data * 100) / 100
  let other = Math.round((100 - rate) * 100) / 100
  return [
    { name: `Pass`, value: rate },
    { name: 'Failed', value: other },
  ]
}

export const hrDashEnrollUtil = (
  enrolled: IMonthCourses[],
  courses: IMonthCourses[],
) => {
  let data = []
  for (let index = 1; index <= 12; index++) {
    let enroll = enrolled.find((val) => val.mnt === index)
    let course = courses.find((val) => val.mnt === index)
    data.push({
      name: appConstant.months[index - 1],
      amt: Number(course?.courses),
      uv: Number(enroll?.courses),
      pv: (Number(course?.courses) + Number(enroll?.courses)) / 2,
    })
  }

  return data
}

type hrDashCoursesUtilData = {
  points?: { name: string; amt: Number; uv: Number }[]
  totalVisits?: number
  totalUniqueVisits?: number
}

export const hrDashReportUtil = (
  Visit: IHrVisit[],
  UniqueVisit: IHrUnique[],
) => {
  let data: hrDashCoursesUtilData = {}
  let points = []
  data.totalVisits = Visit.reduce(
    (prev, next) => prev + parseInt(next.visits),
    0,
  )
  data.totalUniqueVisits = UniqueVisit.reduce(
    (prev, next) => prev + parseInt(next.unique_visitors),
    0,
  )

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();

  for (let index = 1; index <= 12; index++) {
    if ((currentMonth + 1) < index) break;
    let visit = Visit.find((val) => val.mnt === index)
    let unique = UniqueVisit.find((val) => val.mnt === index)
    points.push({
      name: appConstant.months[index - 1],
      amt: Number(visit?.visits),
      uv: Number(unique?.unique_visitors)
    })
  }

  data.points = points;



  return data
}

export const hrStudentQiUtil = (enrolled: { count: number; mnt: number }[]) => {
  let data = []
  for (let index = 1; index <= 12; index++) {
    let enroll = enrolled.find((val) => val.mnt === index)
    data.push({
      name: appConstant.months[index - 1],
      amt: Number(enroll?.count),
    })
  }

  return data
}

export const hrDashStudentsUtil = (
  total: IMonthUsers[],
  active: IMonthStudents[],
) => {
  let data = []

  const d = new Date()
  let month = d.getMonth() + 1

  let totRatio = 0
  let actRatio = 0

  for (let index = 1; index <= 12; index++) {
    let tot = total.find((val) => val.mnt === index)
    let acti = active.find((val) => val.mnt === index)

    if (month === index) {
      totRatio = Number(tot?.users)
      actRatio = Number(acti?.students)
    }

    data.push({
      name: appConstant.months[index - 1],
      amt: Number(tot?.users),
      uv: Number(acti?.students),
      pv: (Number(tot?.users) + Number(acti?.students)) / 2,
    })
  }

  return {
    trend: data,
    ratio: [
      { name: `Total`, value: totRatio },
      { name: 'Active', value: actRatio },
    ],
  }
}

export const hrCourseReportUtil = (
  CourseVisit: IHrCourseVisit[],
  CourseEnrollment: IHrCourseEnrollment[],
) => {
  let data = []

  const d = new Date()
  // let month = d.getMonth()+1;

  let courseVRatio = 0
  let courseERatio = 0
  let enrolTotal = 0
  let visitTotal = 0

  for (let index = 1; index <= 12; index++) {
    let courseV = CourseVisit.find((val) => val.mnt === index)
    let courseE = CourseEnrollment.find((val) => val.mnt === index)
    let enroll = CourseEnrollment.find((val) => val.mnt === index)
    let visits = CourseVisit.find((val) => val.mnt === index)

    visitTotal += Number(visits?.course_visitors)
    enrolTotal += Number(enroll?.course_enrollments)
    courseERatio += Number(courseE?.course_enrollments)
    courseVRatio += Number(courseV?.course_visitors)

    // if(month === index) {
    //     courseVRatio = Number(courseV?.course_visitors);
    //     courseERatio = Number(courseE?.course_enrollments);

    // }

    data.push({
      name: appConstant.months[index - 1],
      amt: Number(courseV?.course_visitors),
      uv: Number(courseE?.course_enrollments),
      // pv: (Number(tot?.users) + Number(acti?.students))/2
    })
  }

  return {
    trend: data,
    ratio: [
      { name: `Total Visit`, value: courseVRatio },
      { name: 'Unique Visit', value: courseERatio },
    ],
    enrolTotal: enrolTotal,
    visitTotal: visitTotal,
  }
}

export const hrDashCoursesUtil = (
  total: IMonthCourses[],
  active: IMonthCourses[],
) => {
  let data = []

  const d = new Date()
  let month = d.getMonth() + 1

  let totRatio = 0
  let actRatio = 0

  for (let index = 1; index <= 12; index++) {
    let tot = total.find((val) => val.mnt === index)
    let acti = active.find((val) => val.mnt === index)

    if (month === index) {
      totRatio = Number(tot?.courses)
      actRatio = Number(acti?.courses)
    }

    data.push({
      name: appConstant.months[index - 1],
      amt: Number(tot?.courses),
      uv: Number(acti?.courses),
      pv: (Number(tot?.courses) + Number(acti?.courses)) / 2,
    })
  }

  return {
    trend: data,
    ratio: [
      { name: `Total`, value: totRatio },
      { name: 'Active', value: actRatio },
    ],
  }
}

export const hrTrendVisitDataUtil = (
  enrolled: IMonthEnrolled[],
  visited: IMonthVisit[],
) => {
  let data = []
  for (let index = 1; index <= 12; index++) {
    let enroll = enrolled.find((val) => val.mnt === index)
    let visit = visited.find((val) => val.mnt === index)
    data.push({
      name: appConstant.months[index - 1],
      uv: Number(visit?.visits),
      pv: Number(enroll?.enrolled),
    })
  }

  return data
}

export const hrTrendProgressDataUtil = (
  completed: IMonthCompleted[],
  progress: IMonthProgress[],
) => {
  let data = []
  for (let index = 1; index <= 12; index++) {
    let comp = completed.find((val) => val.mnt === index)
    let prg = progress.find((val) => val.mnt === index)
    data.push({
      name: appConstant.months[index - 1],
      uv: Number(comp?.completed),
      pv: Number(prg?.progress),
    })
  }

  return data
}

export const groupsListUtil = (data: IGroup[]): IGroupList[] => {
  console.log(data)
  let filtergroups = data.filter((each) => {return each.course !== null} )
  let groups = filtergroups.map((each) => {
    let students = each.userGroups.map((student) => ({
      fullName: student?.user?.full_name,
      img_url: student?.user?.photo_url ?? ('' as string),
    }))

    let lastActivity = each.updatedAt ?? each.createdAt

    return {
      topic: each.title,
      group_cover_img: each.course.cover_image_url,
      students,
      discussions: each.groupDiscussions.length,
      lastActivity: new Date(lastActivity).toDateString(),
      unread: 0,
    }
  })

  return groups
  
}


export const studentCourseUtil = (data: StudentCourse[]) => {
  let courses = data.map((each, index) => {
    let rating =
      each.courseReviews.length > 0
        ? Math.round(
          each.courseReviews.reduce((prev, acc) => prev + acc.rating, 0) /
          each.courseReviews.length,
        ).toFixed(1)
        : '0'

    return {
      index,
      title: each.title,
      author: each.user?.full_name,
      rating: rating,
      star: Number(rating),
      coverImage: each.cover_image_url,
      price: 0,
      count: 0,
      courseId: each.course_id,
    }
  })

  return courses
}

export const courseWithAuthorListUtil = (
  data: ICourseWithAuthor[],
): ITrainingMgrCourseList[] => {
  let courses = data.map((each) => {
    let status: string = courseStatusUtil(
      each.approvedAt as string,
      each.suspendedAt as string,
      each.declinedAt as string
    )

    return {
      id: each.id,
      courseId: each.course_id,
      cover_image: each.cover_image_url,
      title: each.title,
      author: each?.user?.full_name,
      img_url: each?.user?.photo_url,
      date_added: new Date(each.createdAt).toDateString(),
      status: status,
      approvedAt: each.approvedAt,
      suspendedAt: each.suspendedAt,
      authorId: each.user.user_id,
    }
  })

  return courses
}

export const courseAssignListUtil = (data: ICourseWithAuthorAndStudents[]) => {
  let courses = data.map((each) => {
    let students = each.courseEnrollments.flatMap((each) => each.user)

    return {
      id: each.id,
      cover_image: each.cover_image_url,
      title: each.title,
      author: each?.user?.full_name,
      img_url: each?.user?.photo_url,
      date_added: new Date(each.createdAt).toDateString(),
      courseId: each.course_id,
      students,
    }
  })

  return courses
}

export const courseStatusUtil = (approvedAt: string, suspendedAt: string, declinedAt: string) => {
  let status: string = ''

  if (!approvedAt && !suspendedAt && declinedAt) {
    status = StatusModel.PENDING
  }

  if (approvedAt && !suspendedAt && !declinedAt) {
    status = StatusModel.ACTIVE
  }

  if (!approvedAt && suspendedAt && !declinedAt) {
    status = StatusModel.SUSPENDED
  }
  if (!approvedAt && !suspendedAt && declinedAt) {
    status = StatusModel.DECLINED
  }
  return status
}

export const courseEnroledStatus = (
  data: ICourseWithEnrollmentStatus[],
): ICourseEnrollmentStatusList[] => {
  console.log(data)
  let courseEnrolledStatus = data.map((each) => {
    let videosCount = each?.courses?.courseLessons.filter(
      (each) => each.video_url !== null,
    ).length
    let statusPercentage = Math.ceil(
      (each.progresses.length / each?.courses?.courseLessons.length) * 100,

    )

    return {
      courseId: each?.courses?.course_id,
      title: each?.courses?.title,
      coverImage: each?.courses?.cover_image_url,
      lessonCount: each?.courses?.courseLessons.length,
      videosCount,
      statusPercentage,
    }
  })

  return courseEnrolledStatus
}

export const courseStatusListUtil = (data: ICourse[]): ICourseStatusList[] => {
  let coursesStatus = data.map((each) => {
    let live = each.approvedAt ? true : false

    let rating

    if (each.courseReviews) {
      rating =
        each.courseReviews.length > 0
          ? Math.round(
            each.courseReviews.reduce((prev, acc) => prev + acc.rating, 0) /
            each.courseReviews?.length,
          ).toFixed(1)
          : 0
    } else {
      rating = 0
    }

    return {
      courseId: each.course_id,
      title: each.title,
      coverImage: each.cover_image_url,
      author: each.user?.full_name as string,
      star: Number(rating),
      rating: rating as string,
      isLive: live,
      startAt: new Date(each.startAt).toDateString(),
      endAt: new Date(each.endAt).toDateString(),
      startDate: each.startAt,
      endDate: each.endAt,
    }
  })

  return coursesStatus
}

export const courseAssignWithStudentsListUtil = (
  data: ICourseWithStudents[],
) => {
  let courses = data.map((each) => {
    let students = each.courseEnrollments.flatMap((each) => each.user)

    return {
      id: each.id,
      cover_image: each.cover_image_url,
      title: each.title,
      date_added: new Date(each.createdAt).toDateString(),
      courseId: each.course_id,
      students,
    }
  })

  return courses
}

export const courseProgressutil = (
  data?: ICourseProgress,
): ICourseProgressList[] | [] => {
  let totalCourseLessons = data?.courseLessons?.length ?? 1

  let courseDuration = 0

  let results

  if (data !== undefined) {
    data?.courseLessons?.forEach(
      (each) => (courseDuration += each.duration as number),
    )

    results = data?.courseEnrollments?.map((each) => {
      let progress = Math.ceil(
        (each.progresses.length / totalCourseLessons) * 100,
      )

      return {
        name: each.user.full_name,
        img_url: each.user.photo_url as string,
        progress,
        startDate: new Date(each.enrolledAt).toDateString(),
        endDate: each.completedAt
          ? new Date(each.completedAt).toDateString()
          : 'In Progress',
        courseDuration: secondsToHrMin(courseDuration),
      }
    })
  }

  return results ?? []
}

export const courseCategoriesFilter = (
  categories: ICategory[],
  unitId: number,
): ICategory[] => {
  let categoriesFiltered = categories.filter(
    (each) => each.id === unitId || each.title === 'SOFT SKILLS',
  )

  return categoriesFiltered
}
