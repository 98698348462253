import { Image } from "@chakra-ui/image";
import { Box, HStack } from "@chakra-ui/layout";
import React, { useEffect, useState } from "react";
import Article from '../../../../img/article.svg';
import Audio from '../../../../img/audio.svg';
import Video from '../../../../img/video.svg';
import Slide from '../../../../img/slide.svg';
import Quiz from '../../../../img/quiz.svg';
import { VideoUpload } from "./VideoUpload";
import { ArticleUpload } from "./ArticleUpload";
import { AssetType } from "../../../../models/create_course.model";
import { SlideUpload } from "./SlideUpload";
import { QuizUpload } from "./QuizUpload";
import { getCurriculumFromStateService, getLessonFromStateService, updateLessonAssetToStateService } from "../../../../services/instructor/course.service";
import { createQuizService } from "../../../../services/instructor/courses-http.service";
import { IQuizDto } from "../../../../models/quiz.model";
import { useToast, Flex, IconButton } from "@chakra-ui/react";
import { DashboardLoader } from "../../../Common/DashboardLoader";
import { AudioUpload } from "./AudioUpload";
import { BsPencilSquare } from "react-icons/bs";
import { VscTrash } from "react-icons/vsc";

export const UploadContent: React.FC<any> = ({ lessonId, lessonIndex, lectureIndex, lectureId, lessonTitle, deleteLesson, editLesson }: { lessonId: string, lessonIndex: number, lectureIndex: number, lectureId: string, lessonTitle: string, deleteLesson: (lectureIndex: number, lessonIndex: number) => Promise<void>, editLesson: (lectureIndex: number, lessonIndex: number) => void }) => {

    const [page, setPage] = useState(0);

    const [uploadingQuiz, setUploadingQuiz] = useState(false);

    const toast = useToast();

    const pages = [
        { component: ArticleUpload },
        { component: AudioUpload },
        { component: VideoUpload },
        { component: SlideUpload },
        { component: QuizUpload }
    ]

    useEffect(() => {
       
        let selectedLesson = getLessonFromStateService(lectureIndex, lessonIndex);

        let lessonType = selectedLesson?.asset_type;

        if (lessonType)
            setPage(lessonTypePageIndex(lessonType));

    }, []);

    const switchPage = () => {
        const Component = pages[page - 1].component;
        return <Component goBack={() => setPage(0)} lessonId={lessonId} lessonIndex={lessonIndex} lectureIndex={lectureIndex} />
    }

    const lessonTypePageIndex = (lessonType: AssetType) => {

        switch (lessonType) {
            case AssetType.ARTICLE:
                return 1;
            case AssetType.AUDIO:
                return 2;
            case AssetType.VIDEO:
                return 3;
            case AssetType.SLIDE:
                return 4;
            case AssetType.QUIZ:
                return 5;
            default:
                return 0;
        }
    }

    const uploadQuiz = async () => {

        try {
            setUploadingQuiz(true);

            const data: IQuizDto = {
                courseTopicId: lectureId,
                title: lessonTitle,
                courseLessonId: lessonId
            }

            const quiz = await createQuizService(data);

            setUploadingQuiz(false);

            updateLessonAssetToStateService(lectureIndex, lessonIndex, true, AssetType.QUIZ, '', '', quiz);

            setPage(5);

        } catch (error) {
            toast({
                title: "Quiz Setup",
                description: "Something went wrong, Try again later",
                status: "error",
                duration: 3000
            })
        }
    }


    return (
        <>
            <Box>
                {
                    page === 0 &&

                    <Flex justifyContent={"space-between"}>

                        <HStack spacing={"16"} justifyContent="center" alignItems="center" height="10rem">

                            <Box boxSizing="border-box">
                                <Box border={"1px"} px={10} py={6} borderRadius={5} borderColor={"brand.borderBox"} >
                                    <HStack spacing={12}>
                                        <Box onClick={() => setPage(1)} cursor="pointer" ><Image src={Article} /></Box>
                                        <Box onClick={() => setPage(2)} cursor="pointer" ><Image src={Audio} /></Box>
                                        <Box onClick={() => setPage(3)} cursor="pointer" ><Image src={Video} /></Box>
                                        <Box onClick={() => setPage(4)} cursor="pointer" ><Image src={Slide} /></Box>
                                    </HStack>
                                </Box>
                                <Box as="span" backgroundColor={"white"} px={6} left={"13.5rem"} color={"brand.2"} fontSize={13} fontWeight={"semibold"} position={"relative"} top={-3.5} >Files</Box>
                            </Box>

                            <Box>
                                <Box border={"1px"} px={16} py={6} borderRadius={5} borderColor={"brand.borderBox"}  >
                                    {uploadingQuiz ? <DashboardLoader /> : <Box onClick={() => uploadQuiz()} cursor="pointer" ><Image src={Quiz} /></Box>}
                                </Box>
                                <Box as="span" backgroundColor={"white"} px={6} left={"3.8rem"} color={"brand.2"} fontSize={13} fontWeight={"semibold"} position={"relative"} top={-3.5} >Quiz</Box>
                            </Box>

                        </HStack>

                        <Box display={"flex"} flexDirection={"column"}>
                            <IconButton
                                variant="ghost"
                                aria-label="pencil"
                                color="brand.subtitle"
                                borderRadius={20}
                                size="md"
                                icon={<BsPencilSquare />}
                                onClick={() => editLesson(lectureIndex, lessonIndex)}
                            />
                            <IconButton
                                variant="ghost"
                                aria-label="trash"
                                color="brand.subtitle"
                                borderRadius={20}
                                size="md"
                                icon={<VscTrash />}
                                onClick={() => deleteLesson(lectureIndex, lessonIndex)}
                            />
                        </Box>
                    </Flex>
                }

                <Box mx={4}>
                    {
                        page > 0 &&
                        switchPage()
                    }
                </Box>
            </Box>
        </>
    );
}