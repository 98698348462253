import { Box } from "@chakra-ui/layout";
import React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { ManagementMain } from "../components/Management/Main";
import { Roles } from "../models/roles.model";
import { getRole } from "../services/auth.service";
import { allowRoleAccess } from "./RoleGuard";

export const ManagementGuard: React.FC<any> = ({ path, component: Component, ...rest }: {component: any, path: string}) => {
  
  const location = useLocation();

  return (
    <Route {...rest}>
      {allowRoleAccess(getRole() as Roles, Roles.MANAGEMENT) ?
        <ManagementMain {...rest} />
       
      :
        <Redirect to={{ pathname: "/", state: { from: location } }} />
      }
    </Route>
  );
};