import { ICourse, ICourseApproval } from "../models/course.model";
import { ICreateGroup, IGroup, IGroupApproval, IGroupDiscussion, IGroupWithStatus } from "../models/group.model";
import { HttpResponse } from "../models/httpResponse.model";
import {httpClient} from "./httpClient";

export const createGroupService = async (data: ICreateGroup) => {

    let response = await httpClient.post<HttpResponse<IGroup>>("/group", data);

    return response.data;
}

export const createGroupTmService = async (data: ICreateGroup) => {

    let response = await httpClient.post<HttpResponse<IGroup>>("/group/training-manager", data);

    return response.data;
}

export const fetchTMGroups = async () => {

    let response = await httpClient.get<HttpResponse<IGroupWithStatus[]>>("/group");
    console.log(response, 'tm groups')

    return response.data.data;
}

export const fetchTMGroupDiscussions = async () => {

    let response = await httpClient.get<HttpResponse<IGroupDiscussion[]>>("/group/discussions");

    return response.data.data;
}

export const groupApprovalService = async (data: IGroupApproval) => {

    let response = await httpClient.put<HttpResponse<IGroupWithStatus>>("/training-manager/group-action", data);
    console.log(response.data.data, 'group approval')
    return response.data;
}