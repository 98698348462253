import { Box, Button, Flex, Text, Image } from "@chakra-ui/react";
import { useHistory } from "react-router";
import book2 from '../../../img/book2.svg';
import { StudentRoutes } from "../../../pages/Student/routes";


export const InProgress: React.FC<any> = () => {

    const history = useHistory();
    
    return (
        <Flex minHeight="inherit"  justifyContent="center">
        
            <Flex direction="column" alignItems="center" justifyContent="center" >

                <Box color="brand.subtitle" mb={4}>
                    <Image height="2em" width="auto" src={book2} />
                </Box>

                <Text mb={8} fontSize={12} color="brand.subtitle">You currently don’t have any course in progress </Text>

                <Button colorScheme="brand" size="sm" px={8} borderRadius={0} onClick={() => history.push(StudentRoutes.Course.Explore)} fontSize={11}>Explore</Button>

            </Flex>
        </Flex>
    );
}