import { ICourse } from "../../models/course.model";
import { AssetType, ICourseInfoState, ILectureState, ILessonState, IQuizOptionState, IQuizQuestionState, IQuizState } from "../../models/create_course.model";
import { IQuiz, IQuizQuestion } from "../../models/quiz.model";


export const getCourseInfoFromStateService = (): ICourseInfoState | null => {

    let courseInfo = localStorage.getItem('course-info');

    if (courseInfo) {
        let parsedCourseInfo = JSON.parse(courseInfo) as ICourseInfoState;

        return parsedCourseInfo;
    }

    return null;
}

export const getCurriculumFromStateService = (): ILectureState[] | null => {

    let curriculum = localStorage.getItem('curriculum');

    if (curriculum) {
        let parsedCurriculum = JSON.parse(curriculum) as ILectureState[];

        return parsedCurriculum;
    }

    return null;
}

export const getLectureFromStateService = (lectureIndex: number): ILectureState | null => {

    let curriculum = localStorage.getItem('curriculum');

    if (curriculum) {

        let parsedCurriculum = JSON.parse(curriculum);

        let selectedLecture = parsedCurriculum.at(lectureIndex) as ILectureState;

        return selectedLecture;
    }
    return null;
}

export const getLessonFromStateService = (lectureIndex: number, lessonIndex: number): ILessonState | null => {

    let curriculum = localStorage.getItem('curriculum');

    if (curriculum) {

        let parsedCurriculum = JSON.parse(curriculum);

        let selectedLecture = parsedCurriculum.at(lectureIndex) as ILectureState;

        let selectedLesson = selectedLecture.lessons.at(lessonIndex) as ILessonState;

        return selectedLesson;
    }
    return null;
}

export const updateLessonAssetToStateService = (lectureIndex: number, lessonIndex: number, isUploaded: boolean, assetType: AssetType, assetLink: string, assetFileName: string = '', quizData?: IQuiz) => {

    let parsedCurriculum = getCurriculumFromStateService() as ILectureState[];

    let selectedLecture = parsedCurriculum.at(lectureIndex) as ILectureState;

    let selectedLesson = selectedLecture.lessons.at(lessonIndex);

    if (selectedLesson) {

        if (assetType === AssetType.QUIZ)
            selectedLecture.lessons.splice(lessonIndex, 1, {
                ...selectedLesson, asset_link: assetLink, isUploaded: isUploaded,
                asset_type: assetType, asset_file_name: assetFileName,
                quiz: { quiz: { quiz_id: quizData?.quiz_id as string, title: quizData?.title as string, passMark: quizData?.passMark as string } }
            });

        else
            selectedLecture.lessons.splice(lessonIndex, 1, { ...selectedLesson, asset_link: assetLink, isUploaded: isUploaded, asset_type: assetType, asset_file_name: assetFileName });

        parsedCurriculum.splice(lectureIndex, 1, selectedLecture);

        localStorage.setItem('curriculum', JSON.stringify(parsedCurriculum));
    }
}

export const updateLessonNoteToStateService = (lectureIndex: number, lessonIndex: number, note: string) => {

    let parsedCurriculum = getCurriculumFromStateService() as ILectureState[];

    let selectedLecture = parsedCurriculum.at(lectureIndex) as ILectureState;

    let selectedLesson = selectedLecture.lessons.at(lessonIndex);

    if (selectedLesson) {

        selectedLecture.lessons.splice(lessonIndex, 1, { ...selectedLesson, description: note });

        parsedCurriculum.splice(lectureIndex, 1, selectedLecture);

        localStorage.setItem('curriculum', JSON.stringify(parsedCurriculum));
    }
}


export const deleteLessonNoteToStateService = (lectureIndex: number, lessonIndex: number, lesson: string) => {

    let parsedCurriculum = getCurriculumFromStateService() as ILectureState[];

    let selectedLecture = parsedCurriculum.at(lectureIndex) as ILectureState;

    let selectedLesson = selectedLecture.lessons.at(lessonIndex);
    console.log(selectedLesson)

    if (selectedLesson) {
        selectedLesson.description = '';
        console.log(selectedLesson, 'updated')
        selectedLecture.lessons.splice(lessonIndex, 1, selectedLesson);

        parsedCurriculum.splice(lectureIndex, 1, selectedLecture);

        localStorage.setItem('curriculum', JSON.stringify(parsedCurriculum));
        console.log(parsedCurriculum)
    }
}

export const removeLessonAssetFromStateService = (lectureIndex: number, lessonIndex: number) => {

    let parsedCurriculum = getCurriculumFromStateService() as ILectureState[];

    let selectedLecture = parsedCurriculum.at(lectureIndex) as ILectureState;

    let selectedLesson = selectedLecture.lessons.at(lessonIndex);

    if (selectedLesson) {
        selectedLecture.lessons.splice(lessonIndex, 1, { title: selectedLesson.title, lessonId: selectedLesson.lessonId });

        parsedCurriculum.splice(lectureIndex, 1, selectedLecture);

        localStorage.setItem('curriculum', JSON.stringify(parsedCurriculum));
    }
}

export const removeCourseFromStateService = () => {

    localStorage.removeItem('course-id');

    localStorage.removeItem('page_no');

    localStorage.removeItem('course-info');

    localStorage.removeItem('curriculum');

    localStorage.removeItem('course-settings');

    localStorage.removeItem('exam');
}

export const setCourseInfoToStateService = (course: ICourse): void => {

    let data = {
        courseCategory: course.category?.category_id,
        courseDescription: course.description,
        courseFormat: course.format?.format_id,
        courseLevel: course.level?.level_id,
        courseTitle: course.title,
        endDate: course?.endAt ?? "",
        startDate: course?.startAt ?? "",
    }

    localStorage.setItem('course-info', JSON.stringify(data));
}

export const setCurriculumToStateService = (course: ICourse): void => {

    let data: ILectureState[] = course.topics?.map(each => {

        let lessons: ILessonState[] = each.lessons?.map(lesson => {

            let assetType = lesson.article ? AssetType.ARTICLE : lesson.video_url ? AssetType.VIDEO : lesson.doc_url ? AssetType.SLIDE : lesson.quiz ? AssetType.QUIZ : null;

            let assetLink = lesson.article ? lesson.article : lesson.video_url ? lesson.video_url : lesson.doc_url;

            return {
                lessonId: lesson.lesson_id,
                title: lesson.title,
                time: lesson.duration ?? '0',
                asset_link: assetLink,
                asset_type: assetType,
                isUploaded: true,
                description: lesson.description,
                asset_file_name: lesson.asset_file_name ?? "",
                quiz: {
                    quiz: {
                        quiz_id: lesson.quiz?.quiz_id,
                        title: lesson.quiz?.title,
                        passMark: lesson.quiz?.passMark,
                    },
                    questions: lesson.quiz?.questions?.map(each => (
                        {
                            isSaved: true,
                            question: each.question,
                            showEditQuestion: false,
                            quiz_question_id: each.quiz_question_id,
                            isAnswerSelected: each.answers?.filter(each => each.correct).length ? true : false,
                            answerId: each.answers?.find(each => each.correct === true)?.quiz_answer_id, //each.answers?.findIndex(each => each.correct === true),
                            options: each.answers?.map(each => ({
                                option: each.answer,
                                isCorrect: each.correct,
                                isSaved: true,
                                quiz_answer_id: each.quiz_answer_id,
                            })) as IQuizOptionState[],
                        }
                    ))
                }

            } as ILessonState;
        }) as ILessonState[]

        return {
            topicId: each.topic_id,
            title: each.title,
            showAddLessonField: false,
            showEditLectureField: false,
            lessons
        } as ILectureState

    }) as ILectureState[]

    localStorage.setItem('curriculum', JSON.stringify(data));
}

export const setCourseSettingsToStateService = (course: ICourse): void => {

    let data = {
        accessibility: course.accessibility?.accessibility_id,
        tags: course.tags,
        welcomeMsg: course.welcome_message,
        congratMsg: course.congrat_message,
        coverImage: course.cover_image_url
    }

    localStorage.setItem('course-settings', JSON.stringify(data));
}

export const addQuizQuestionToStateService = (lectureIndex: number, lessonIndex: number, quizQuestion: IQuizQuestionState) => {

    let parsedCurriculum = getCurriculumFromStateService() as ILectureState[];
    console.log(parsedCurriculum)
    let selectedLecture = parsedCurriculum.at(lectureIndex) as ILectureState;

    let selectedLesson = selectedLecture.lessons.at(lessonIndex);


    if (selectedLesson) {

        selectedLecture.lessons.splice(lessonIndex, 1, { ...selectedLesson, quiz: { quiz: selectedLesson.quiz?.quiz as IQuiz, questions: [...selectedLesson.quiz?.questions as IQuizQuestionState[] ?? [], quizQuestion] } });

        parsedCurriculum.splice(lectureIndex, 1, selectedLecture);

        localStorage.setItem('curriculum', JSON.stringify(parsedCurriculum));
    }
}

export const deleteQuizQuestionToStateService = (lectureIndex: number, lessonIndex: number, questionIndex: number) => {
    let parsedCurriculum = getCurriculumFromStateService() as ILectureState[];

    let selectedLecture = parsedCurriculum.at(lectureIndex) as ILectureState;

    let selectedLesson = selectedLecture.lessons.at(lessonIndex);

    if (selectedLesson) {
        selectedLesson?.quiz?.questions?.splice(questionIndex, 1)
        parsedCurriculum.splice(lectureIndex, 1, selectedLecture);

        localStorage.setItem('curriculum', JSON.stringify(parsedCurriculum));
    }


}

export const updateQuizQuestionToStateService = (lectureIndex: number, lessonIndex: number, quizQuestion: IQuizQuestionState, quizQuestionIndex: number) => {

    let parsedCurriculum = getCurriculumFromStateService() as ILectureState[];
    // console.log(parsedCurriculum)
    let selectedLecture = parsedCurriculum.at(lectureIndex) as ILectureState;

    let selectedLesson = selectedLecture.lessons.at(lessonIndex);


    if (selectedLesson) {
        selectedLesson?.quiz?.questions?.splice(quizQuestionIndex, 1, quizQuestion);

        selectedLecture.lessons.splice(lessonIndex, 1, selectedLesson);

        parsedCurriculum.splice(lectureIndex, 1, selectedLecture);

        localStorage.setItem('curriculum', JSON.stringify(parsedCurriculum));
    }
}

export const getQuestionsFromStateService = (lectureIndex: number, lessonIndex: number) => {

    let selectedLesson = getLessonFromStateService(lectureIndex, lessonIndex);
console.log( selectedLesson?.quiz?.questions)
    return selectedLesson?.quiz?.questions
}

export const addQuizOptionToStateService = (lectureIndex: number, lessonIndex: number, questionIndex: number, quizOption: IQuizOptionState) => {

    let parsedCurriculum = getCurriculumFromStateService() as ILectureState[];

    let selectedLecture = parsedCurriculum.at(lectureIndex) as ILectureState;

    let selectedLesson = selectedLecture.lessons.at(lessonIndex);

    if (selectedLesson) {

        let selectedQuestion = selectedLesson.quiz?.questions?.at(questionIndex) as IQuizQuestionState;

        if (!selectedQuestion.options)
            selectedQuestion['options'] = [quizOption];

        else
            selectedQuestion?.options?.push(quizOption);

        selectedLesson.quiz?.questions?.splice(questionIndex, 1, selectedQuestion)

        selectedLecture.lessons.splice(lessonIndex, 1, selectedLesson);

        parsedCurriculum.splice(lectureIndex, 1, selectedLecture);

        localStorage.setItem('curriculum', JSON.stringify(parsedCurriculum));
    }
}

export const deleteQuizOptionToStateService = (lectureIndex: number, lessonIndex: number, questionIndex: number, quizOptionIndex: number) => {

    let parsedCurriculum = getCurriculumFromStateService() as ILectureState[];

    let selectedLecture = parsedCurriculum.at(lectureIndex) as ILectureState;

    let selectedLesson = selectedLecture.lessons.at(lessonIndex);

    if (selectedLesson) {

        let selectedQuestion = selectedLesson.quiz?.questions?.at(questionIndex) as IQuizQuestionState;


        selectedQuestion?.options?.splice(quizOptionIndex, 1)

        selectedLesson.quiz?.questions?.splice(questionIndex, 1, selectedQuestion)

        selectedLecture.lessons.splice(lessonIndex, 1, selectedLesson);

        parsedCurriculum.splice(lectureIndex, 1, selectedLecture);

        localStorage.setItem('curriculum', JSON.stringify(parsedCurriculum));
    }
}


export const updateQuizOptionToStateService = (lectureIndex: number, lessonIndex: number, questionIndex: number, quizOption: IQuizOptionState, quizOptionIndex: number) => {

    let parsedCurriculum = getCurriculumFromStateService() as ILectureState[];

    let selectedLecture = parsedCurriculum.at(lectureIndex) as ILectureState;

    let selectedLesson = selectedLecture.lessons.at(lessonIndex);

    if (selectedLesson) {

        let selectedQuestion = selectedLesson.quiz?.questions?.at(questionIndex) as IQuizQuestionState;


        selectedQuestion?.options?.splice(quizOptionIndex, 1, quizOption);

        selectedLesson.quiz?.questions?.splice(questionIndex, 1, selectedQuestion)

        selectedLecture.lessons.splice(lessonIndex, 1, selectedLesson);

        parsedCurriculum.splice(lectureIndex, 1, selectedLecture);

        localStorage.setItem('curriculum', JSON.stringify(parsedCurriculum));
    }
}

export const setQuestionCorrectAnswerToStateService = (lectureIndex: number, lessonIndex: number, questionIndex: number, answerId: string) => {

    let parsedCurriculum = getCurriculumFromStateService() as ILectureState[];

    let selectedLecture = parsedCurriculum.at(lectureIndex) as ILectureState;

    let selectedLesson = selectedLecture.lessons.at(lessonIndex);

    if (selectedLesson) {

        let selectedQuestion = selectedLesson.quiz?.questions?.at(questionIndex) as IQuizQuestionState;

        selectedLesson.quiz?.questions?.splice(questionIndex, 1, { ...selectedQuestion, isAnswerSelected: true, answerId });

        selectedLecture.lessons.splice(lessonIndex, 1, selectedLesson);

        parsedCurriculum.splice(lectureIndex, 1, selectedLecture);

        localStorage.setItem('curriculum', JSON.stringify(parsedCurriculum));
    }
}

export const getCourseIdService = () => {
    return localStorage.getItem('course-id');
}

export const setExamToStateService = (exam: IQuizState) => {

    localStorage.setItem('exam', JSON.stringify(exam));
}

export const getExamFromStateService = (): IQuizState | null => {

    let exam = localStorage.getItem('exam');
    console.log(exam, "exam body")

    if (!exam) return null;

    let examParsed: IQuizState = JSON.parse(exam);

    return examParsed;
}

export const addExamQuestionToStateService = (quizQuestion: IQuizQuestionState) => {

    let exam = getExamFromStateService();

    if (!exam) return;

    let questions = exam.questions as IQuizQuestionState[] ?? [];

    exam.questions = [...questions, quizQuestion];

    localStorage.setItem('exam', JSON.stringify(exam));
}


export const updateExamQuestionToStateService = (questionIndex: number, quizQuestion: IQuizQuestionState) => {

    let exam = getExamFromStateService();

    if (!exam) return;

    let questions = exam.questions as IQuizQuestionState[] ?? [];

    questions.splice(questionIndex, 1, quizQuestion)

    localStorage.setItem('exam', JSON.stringify(exam));
}

export const deleteExamQuestionToStateService = (questionIndex: number) => {
    let exam = getExamFromStateService();
    if (!exam) return;
    let questions = exam.questions as IQuizQuestionState[] ?? [];
    questions.splice(questionIndex, 1)
    exam.questions = [...questions];
    localStorage.setItem('exam', JSON.stringify(exam));
}

export const getExamQuestionsFromStateService = () => {

    let exam = getExamFromStateService();

    return exam?.questions
}

export const addExamOptionToState = (questionIndex: number, quizOption: IQuizOptionState) => {

    let exam = getExamFromStateService();

    if (!exam) return;

    let selectedQuestion = exam.questions?.at(questionIndex) as IQuizQuestionState;

    let options = [...selectedQuestion.options as IQuizOptionState[] ?? [], quizOption];

    selectedQuestion.options = options;

    exam.questions?.splice(questionIndex, 1, selectedQuestion);

    localStorage.setItem('exam', JSON.stringify(exam));
}


export const deleteExamOptionToState = (questionIndex: number, optionIndex: number) => {

    let exam = getExamFromStateService();

    if (!exam) return;

    let selectedQuestion = exam.questions?.at(questionIndex) as IQuizQuestionState;

    selectedQuestion.options?.splice(optionIndex, 1)

    exam.questions?.splice(questionIndex, 1, selectedQuestion);

    localStorage.setItem('exam', JSON.stringify(exam));
}

export const editExamOptionToState = (questionIndex: number, quizOption: IQuizOptionState, optionIndex: number) => {

    let exam = getExamFromStateService();

    if (!exam) return;

    let selectedQuestion = exam.questions?.at(questionIndex) as IQuizQuestionState;

    selectedQuestion.options?.splice(optionIndex, 1, quizOption);

    exam.questions?.splice(questionIndex, 1, selectedQuestion);

    localStorage.setItem('exam', JSON.stringify(exam));
}

export const setExamQuestionCorrectAnswerToStateService = (questionIndex: number, answerId: string) => {

    let exam = getExamFromStateService();

    if (!exam) return;

    let selectedQuestion = exam.questions?.at(questionIndex) as IQuizQuestionState;

    exam.questions?.splice(questionIndex, 1, { ...selectedQuestion, isAnswerSelected: true, answerId })

    localStorage.setItem('exam', JSON.stringify(exam));
}

export const removeExamFromStateService = () => {
    localStorage.removeItem('exam');
}