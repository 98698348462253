import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Button, Flex, Text, HStack, Box, Image, Textarea, Checkbox
} from "@chakra-ui/react"
import { useRef, useState } from "react";
import { BsStar, BsStarFill } from "react-icons/bs";
import congrat from '../../img/congrat.png';


export const CourseCompletedModal: React.FC<any> = ({isOpen, onClose, submitCourseReview, isSubmittingReview,isCourseId }: {isOpen: any,isCourseId: any, onClose: any, submitCourseReview: (rating: number, review: string, isAnonymous: boolean) => Promise<void>, isSubmittingReview: any }) => {

    const defaultRating = Array(5).fill(false);

    const [rate, setRate] = useState<boolean[]>(defaultRating);
    
    const [constRate, setConstRate] = useState<boolean[]>(defaultRating);

    const reviewRef = useRef<HTMLTextAreaElement>(null);
    
    const anonymousRef = useRef<HTMLInputElement>(null);

    const checkRate = (index: number) => {
        setRate(defaultRating);
        let checked = Array(index + 1).fill(true);
        let totalCheck = Array(5-checked.length).fill(false);
        setRate([...checked, ...totalCheck]);
        setConstRate([...checked, ...totalCheck]);
    }

    const hoverRate = (index: number) => {
        setRate(defaultRating);
        let checked = Array(index + 1).fill(true);
        let totalCheck = Array(5-checked.length).fill(false);
        setRate([...checked, ...totalCheck]);
    }

    const hoverOutRate = () => {
        setRate([...constRate]);
    }

    const submitReview = async ( ) => {
        
        let rating = constRate.filter(each => each === true).length;
        await submitCourseReview(rating, reviewRef.current?.value as string, anonymousRef.current?.checked as boolean);
    }


    return (
    <>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="xl" scrollBehavior="inside" >
        <ModalOverlay />
        <ModalContent borderRadius={5} px={6} >
          {/* <ModalCloseButton size="sm" fontSize={9}/> */}
          <ModalBody pb={2} my={4} >
            <Flex justifyContent={"center"} alignItems={"center"} direction={"column"}  >

                <Text color="brand.2" fontSize={16} fontWeight={"semibold"}> Congratulations!  </Text>

                <Image src={congrat} mb={10} height="auto" maxWidth="15rem" ></Image>

                {<Text color="brand.subtitle" fontSize={10} textAlign='center' mb={2} mt={2}> You just completed the course: {isCourseId} Completing an online course is no simple <br></br> 
                endeavor. It requires time, dedication, and commitment, so when we say <br></br> "Congratulations" - we mean it!</Text>}

                <Text color="brand.subtitle" fontSize={14} mb={2} > Rate this course </Text>

                <HStack mb={4}>
                    {
                        rate.map( (each, index) => 
                            <Box key={index} fontSize={25} > { each ? <Box onClick={() => checkRate(index) } onMouseOver={() => hoverRate(index)} onMouseLeave={hoverOutRate} color="#E9A426" > <BsStarFill /> </Box> : <Box onClick={() => checkRate(index) }  onMouseOver={() => hoverRate(index)} onMouseLeave={hoverOutRate} color="brand.subtitle" > <BsStar  /> </Box> } </Box>
                        )
                    }
                </HStack>

                <Textarea
                    ref={reviewRef}
                    p={4}
                    mb={6}
                    fontSize={12}
                    size="sm"
                    minH="6rem"
                    placeholder="Leave a review"
                />

                <HStack mb={4}>
                  <Checkbox ref={anonymousRef} color="brand.2" name="isAnonymous"  ></Checkbox>
                  <Text fontSize={12} color="brand.subtitle">Submit as annoynmous</Text>
                </HStack>

                <Button colorScheme="brand" size="xs" fontSize={11} borderRadius={1} px={6} onClick={submitReview} isLoading={isSubmittingReview} > Submit </Button>
                
            </Flex>        

          </ModalBody>

        </ModalContent>
      </Modal>
    </>
  )
}