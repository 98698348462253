import { Box, Button, Container, Flex, HStack, Image, Input, InputGroup, Tag, Text, InputRightElement, Link, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react"
import { ChangeEvent, useEffect, useState } from "react";
import { BiBookAdd } from "react-icons/bi";
import { BsStarFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { EmptyCourse } from "../../components/Instructor/EmptyState/Courses";
import { fetchCourses } from "../../services/instructor/courses-http.service";
import { courseUtil } from "../../utils/course.util";
import { InstructorRoutes } from './routes';
import { ICourseList } from '../../models/course.model';
import { ScreenLoader } from "../../components/Common/ScreenLoader";
import { RiSearch2Line } from "react-icons/ri";
import { EmptyCourseSearch } from "../../components/Student/EmptyState/EmptyCourseSearch";
import { ChevronDownIcon } from "@chakra-ui/icons";
// import myCourses from '../../data/myCourses.json';



const MyCourses = () => {

    let { replace, push } = useHistory();

    const [isLoading, setIsLoading] = useState(true);

    const [courses, setCourses] = useState<ICourseList[]>([]);

    const [filteredCourses, setFilteredCourses] = useState<ICourseList[]>([]);

    const [isSearchEmpty, setIsSearchEmpty] = useState(false);
    const [operationI, setOperationTypesI] = useState('')
    const [reload, setReload] = useState(true)



    useEffect(() => {
        fetchCourses()
            .then((data) => {
                setFilteredCourses(courseUtil(data));
                setCourses(courseUtil(data));
                setIsLoading(false)
            })
            .catch((err) => err)

    }, []);
    function updateOperation(operation_type: string): void {
        if (operation_type === 'students') {
            setOperationTypesI('students');
            let sortedCourses = filteredCourses.sort((a, b) => (a.students < b.students) ? 1 : (a.students === b.students) ? 0 : -1)
            setFilteredCourses(sortedCourses)

        }
        else if (operation_type === 'completed') {
            setOperationTypesI('completed');
            let sortedCourses = filteredCourses.sort((a, b) => (a.completed < b.completed) ? 1 : (a.completed === b.completed) ? 0 : -1)
            setFilteredCourses(sortedCourses)
        }
        else if (operation_type === 'earnings') {
            setOperationTypesI('earnings');
            let sortedCourses = filteredCourses.sort((a, b) => (a.earnings < b.earnings) ? 1 : (a.earnings === b.earnings) ? 0 : -1)
            setFilteredCourses(sortedCourses)
        }
        else if (operation_type === 'ratings') {
            setOperationTypesI(operation_type);
            let sortedCourses = filteredCourses.sort((a, b) => (a.rating < b.rating) ? 1 : (a.rating === b.rating) ? 0 : -1)
            setFilteredCourses(sortedCourses)
        }
        else if (operation_type === 'courses') {
            setOperationTypesI('courses');
            console.log(courses)
            setFilteredCourses(courses)
        }
        else if (operation_type === 'live') {
            setOperationTypesI('live');
            let livecourses = courses.filter((each) => { return each.live == true })
            setFilteredCourses(livecourses)
        }
        else if (operation_type === 'expired') {
            setOperationTypesI('expired');
            let expiredcourses = courses.filter((each) => { return each.expired && !each.live  })
            console.log(expiredcourses)
            setFilteredCourses(expiredcourses)
        }
        else if (operation_type === 'pending') {
            setOperationTypesI('pending');
            let pendingCourses = courses.filter((each) => { return !each.live && !each.expired })
            setFilteredCourses(pendingCourses)
        }


    }

    useEffect(() => {
        // let url = `?page=${1}&limit=${limit}&search=&orderBy=${operationI}`

        // gettopInstructors(role_id.instructor, url).then((data) => {
        //   setTopInstructors(data.results)
        // })
    }, [operationI, reload])


    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {

        let searchTerm = event.target.value.toLowerCase();

        let filtered = courses.filter(each => each.title.toLowerCase().includes(searchTerm));

        setFilteredCourses(filtered);

        if (filtered.length > 0)
            setIsSearchEmpty(false);
        else
            setIsSearchEmpty(true)
    }

    const editCourse = async (courseId: string) => {
        push(InstructorRoutes.Course.EditLink(courseId));
    }

    console.log(filteredCourses, "dolapo")

    return (

        <Container boxShadow="base" maxW="container.xl" bg="white" pt={3} pb={10} px={8} minHeight="70vh">

            {
                isLoading ?
                    <ScreenLoader />

                    :

                    courses.length === 0 ?
                        <EmptyCourse />
                        :
                        <Box>
                            <Flex justifyContent="space-between" alignItems="center" px={6} mb={4}>
                                <Text color="brand.2" fontSize={15} fontWeight="semibold">
                                    My  Courses
                                </Text>

                                <Menu>
                                    <MenuButton
                                        as={Button}
                                        rightIcon={<ChevronDownIcon />}
                                        colorScheme="##7883A7"
                                        variant={'outline'}
                                        size="sm"
                                        fontSize={12}
                                    >
                                        {operationI === 'courses'
                                            ? 'By Courses'
                                            : operationI === 'students'
                                                ? 'By Student'
                                                :
                                                operationI === 'expired'
                                                    ? ' Expired Courses'
                                                    :
                                                    operationI === 'pending'
                                                        ? 'Pending Courses'
                                                        :
                                                        operationI === 'live'
                                                            ? 'Live Courses'
                                                            : operationI === 'completed'
                                                                ? 'By Completion'
                                                                : operationI === 'earnings'
                                                                    ? 'By Earnings'
                                                                    : operationI === 'ratings'
                                                                        ? 'By Ratings' :
                                                                        'By Courses'}
                                    </MenuButton>
                                    <MenuList fontSize={12}>
                                        <MenuItem onClick={() => updateOperation('courses')}>
                                            By Courses
                                        </MenuItem>
                                        <MenuItem onClick={() => updateOperation('students')}>
                                            By Student
                                        </MenuItem>   
                                        <MenuItem onClick={() => updateOperation('live')}>
                                            {' '}
                                            Live Courses
                                        </MenuItem>
                                        <MenuItem onClick={() => updateOperation('pending')}>
                                            Pending Courses
                                        </MenuItem>
                                        <MenuItem onClick={() => updateOperation('expired')}>
                                            Expired Courses
                                        </MenuItem>
                                        <MenuItem onClick={() => updateOperation('completed')}>
                                            By Completion
                                        </MenuItem>
                                        <MenuItem onClick={() => updateOperation('earnings')}>
                                            {' '}
                                            Earnings
                                        </MenuItem>
                                        <MenuItem onClick={() => updateOperation('ratings')}>
                                            Ratings
                                        </MenuItem>

                                    </MenuList>
                                </Menu>
                            </Flex>

                            <Flex justifyContent="space-between" alignItems="center" px={6} mt={4}>
                                <InputGroup color="brand.subtitle" bg="white" width="40%">
                                    <Input placeholder="Search your courses" onChange={e => handleSearchChange(e)} fontSize={12} size="sm" />

                                    <InputRightElement h="1.9rem" pointerEvents="none" children={<RiSearch2Line size="0.9rem" />} />
                                </InputGroup>

                                {/* <Button colorScheme="brand" leftIcon={<BiBookAdd />} onClick={() => replace(InstructorRoutes.Course.Create)} size="sm" fontSize={12}>Create New Course</Button> */}
                            </Flex>

                            {
                                isSearchEmpty ?

                                    <EmptyCourseSearch />

                                    :

                                    <Table variant="simple" mt={5} >
                                        <Thead fontWeight="normal" >
                                            <Tr color="brand.subtitle" >
                                                <Th textTransform="none">Courses</Th>
                                                <Th textTransform="none">Students</Th>
                                                <Th textTransform="none">Completed</Th>
                                                <Th textTransform="none">Earnings</Th>
                                                <Th textTransform="none">Ratings</Th>
                                            </Tr>
                                        </Thead>

                                        <Tbody>
                                            {
                                                filteredCourses.map((each, index) =>
                                                    <Tr key={index} fontSize={13} color="brand.subtitle">
                                                        <Td>
                                                            <HStack spacing={6} alignItems="start" maxW="fit-content">
                                                                {/* <AspectRatio maxW="80px" ratio={1 / 1}> */}
                                                                <Image src={each.coverImage} fallbackSrc="https://via.placeholder.com/150" height="auto" maxWidth="80px" />
                                                                {/* </AspectRatio> */}

                                                                <Box>
                                                                    <Text color="brand.3" whiteSpace="nowrap" isTruncated title={each.title}>
                                                                        {each.title}
                                                                    </Text>

                                                                    <HStack alignItems={"center"} justifyItems={"center"} mt={2} >
                                                                        <Tag colorScheme={each.live ? "success" : each.expired ? "red" : "yellow"} variant="outline" borderRadius={2} px={3} minHeight={0} lineHeight={1.8} fontSize={8}> {each.live ? "Live" : each.expired ? "Expired" : "Pending"} </Tag>
                                                                        {!each.live && each.expired && <Link onClick={() => editCourse(each.courseId)} color={"brand.2"} fontSize={12} >Re-open</Link>}
                                                                        {!each.live && !each.expired && <Link onClick={() => editCourse(each.courseId)} color={"brand.2"} fontSize={12} >Edit</Link>}
                                                                    </HStack>

                                                                </Box>

                                                            </HStack>

                                                        </Td>
                                                        <Td>{each.students}</Td>
                                                        <Td>{each.completed}</Td>
                                                        <Td>{each.earnings}</Td>
                                                        <Td><Box display="inline-flex" alignItems="center"> <Text mr={1} color="brand.star"><BsStarFill /></Text>  {each.rating} </Box> </Td>
                                                    </Tr>
                                                )
                                            }

                                        </Tbody>
                                    </Table>
                            }

                        </Box>
            }

        </Container>
    );
}

export default MyCourses;