import { Box, HStack, Text } from "@chakra-ui/react";
import React from "react";
import { Status as StatusModel } from "../../models/status.model";
import { firstLetterUpper } from "../../utils/firstLetterUpper.util";

export const Status: React.FC<{ statusType: string }> = ({ statusType, ...rest }) => {

    const statusIndicator = (status: string) => {

        switch (status) {
            case StatusModel.ACTIVE:
                return 'success.500'
            case StatusModel.PENDING:
                return 'warn.300'
            case StatusModel.DECLINED:
                return 'warn.300'
            case StatusModel.SUSPENDED:
                return 'danger.500'
            default:
                return 'brand.subtitle'
        }
    }

    return (
        <Box>
            <HStack>
                <Box bgColor={statusIndicator(statusType)} width={1.5} height={1.5} borderRadius={100}></Box>
                <Text>{firstLetterUpper(statusType)}</Text>
            </HStack>
        </Box>
    );
}