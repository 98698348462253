import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton, Flex, VStack, Avatar, Text, HStack, Box, Divider, Progress
} from "@chakra-ui/react"
import { useEffect, useState } from "react";
import { IoMdCheckmark } from "react-icons/io";
import { IStudent } from "../../models/course.model";


export const StudentOverviewModal: React.FC<any> = ({isOpen, onClose, student }: {isOpen: any, onClose: any, student: IStudent}) => {

    const [courses, setCourses] = useState<IEachStudentOverview[]>([]);
    
    interface IEachStudentOverview{
        title: string,
        startedAt: string,
        progress: number
    }

    useEffect(() => {

        if(student !== undefined){
            let courseData = student?.courseEnrollments?.map( each => {
                
                let completed = each?.progresses.length || 0;

                let lessons = each?.courses?.courseLessons.length || 0;

                let progress = (completed / lessons) * 100;

                if(completed === 0 || lessons === 0){
                    progress = 0
                }else{
                    progress = (completed / lessons) * 100;
                }
                

                return {
                    title: each?.courses?.title,
                    startedAt: (new Date(each?.createdAt).toDateString()),
                    progress: Math.round(progress)
                }
            })
        
            setCourses(courseData);
        }

    }, [student]);


    return (
    <>
    {
        student &&
        
      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside"  size="xl">
        <ModalOverlay />
        <ModalContent borderRadius={5} px={6} >
          <ModalHeader mx={3}>
                <HStack justifyContent="start" alignItems="start" spacing={3}>
                    <Avatar size="md" name={student.full_name} src={student.photo_url} />
                    <Box>
                        <Text isTruncated fontSize={13} pt={1} color="brand.subtitle">{student.full_name}</Text>              
                        <Text isTruncated fontSize={11} fontWeight="normal" color="brand.subtitle"> Student </Text>              
                    </Box>
                </HStack>
            </ModalHeader>
          <ModalCloseButton size="sm" />
          <ModalBody pb={6}>

            <Divider />

            <Text fontSize={13} color="brand.2" fontWeight="semibold" mt={3}>Courses Enrolled</Text>

            <VStack
                mt={4}
                spacing={4}
                align="stretch"                
                >
                {
                    courses.map((each, index) => 

                        <Flex key={index} justifyContent="space-between" border="1px" px={6} py={4} borderColor="brand.borderBox">
                            <Box width="55%" color="brand.subtitle" >
                                <Text isTruncated  title={each.title} fontSize={13} fontWeight="semibold">{each.title}</Text>                                                
                                <Text fontSize={11} pt={1}> Started: {each.startedAt} </Text>
                            </Box>

                            {
                                each.progress === 100 ?
                                <Flex width="40%" justifyContent="flex-end" alignItems="center" color="brand.2"> 
                                     <IoMdCheckmark /> <Box as="span" ml={1} whiteSpace="nowrap" fontSize={12} fontWeight="semibold" >Completed </Box> 
                                </Flex>
                                :
                                
                                <Flex width="40%" justifyContent="center" alignItems="center" > 
                                    <Progress width="100%" value={each.progress} borderRadius={20} size="xs" mr={3} /> <Box as="span" whiteSpace="nowrap" fontSize={10}> {each.progress}% </Box> 
                                </Flex>
                            }
                           
                        </Flex>
                    )
                }              

            </VStack>

          </ModalBody>

          <ModalFooter>
          </ModalFooter>
        </ModalContent>
      </Modal>
    }

    </>
  )
}