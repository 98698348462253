import {
  Box,
  Button,
  Container,
  Flex,
  HStack,
  Image,
  Input,
  InputGroup,
  Tag,
  Text,
  InputRightElement,
  Link,
  IconButton,
  Portal,
  useDisclosure,
  Avatar,
  VStack,
  Badge,
  Spacer,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from "@chakra-ui/react";

import { ChevronDownIcon } from '@chakra-ui/icons'

import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from '@chakra-ui/react'

import React, { useRef } from "react";
import { useReactToPrint } from 'react-to-print'
import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import blucanaryLogo2 from "../../img/logo2.png";
import {  useEffect, useState } from "react";
import { ScreenLoader } from "../../components/Common/ScreenLoader";
import { EmptyUsers } from "../../components/Hr/EmptyState/Users";

import { fetchUsers, genExcel, updateStatus } from "../../services/hr/users-http.service";
import { fetchByRole } from "../../services/hr/users-http.service";
import { useHistory } from "react-router-dom";
import { RiSearch2Line } from "react-icons/ri";
import { BiCloudDownload, BiDownArrow, BiUserPlus } from "react-icons/bi";
import { EmptyCourseSearch } from "../../components/Student/EmptyState/EmptyCourseSearch";
import { IoMdCreate } from "react-icons/io";
import { FaTimesCircle, FaCheck, FaLayerGroup } from "react-icons/fa";
import { EditUserProfileModal } from "../../components/Hr/EditUserProfileModal";
import { IDepartment, IDepartmentData, IEachCourseData, Ipage, IUser } from "../../models/auth.model";
import { appConstant } from "../../utils/app.util";
import {
  Pagination,
  PaginationContainer,
  PaginationNext,
  PaginationPage,
  PaginationPageGroup,
  PaginationPrevious,
  usePagination,
} from "@ajna/pagination";
import { IAnyUserList } from "../../models/user.model";
import { Checkbox, CheckboxGroup } from '@chakra-ui/react';
import { HrAdminRoutes } from "./routes";
import { CreateDepartmentModal } from "../../components/Hr/CreateDepartmentModal";
import { BsFillStarFill, BsThreeDotsVertical } from "react-icons/bs";
import { deleteDepartment, fetchDepartment, genExcelDepartment } from "../../services/hr/department/department.service";
import { ViewEachCourseModal } from "../../components/Hr/ViewEachCourseModal";
import { fetchEachCourse } from "../../services/hr/course/course.service";
import { truncate } from "fs/promises";
import { EditDepartmentModal } from "../../components/Hr/EditDepartmentModal";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";


export default function Department() {
  let { replace, push } = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [filteredUsers, setFilteredUsers] = useState<IAnyUserList[]>([]);
  const [filteredDepartments, setFilteredDepartments] = useState<IDepartmentData[]>([]);
  const [departmentNo, setDepartmentNo] = useState(0);
  const [selectedUser, setSelectedUser] = useState<IAnyUserList>();
  const [selectedCourse, setSelectedCourse] = useState<IEachCourseData[]>([]);

  const [page, setPage] = useState<Ipage>({ page_total: 0, total: 0 });
  const [isSearchEmpty, setIsSearchEmpty] = useState(false);
  const [search, setSearch] = useState("");
  const [query, setQuery] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const componentRef = useRef(null)
  const toast = useToast();
  const [reload, setStatus] = useState(false);
  const [isDepartment, setDepartment] = useState(false);
  const [isEditDept, setEditDepartment] = useState(false);
  const [departmentTitle, setDeptTitle] = useState('')
  const [isCourse, setCourse] = useState(false);
  const [categoryID, setCategoryId] = useState('')
  const [deleteId, setDeleteId] = useState('')
  const [isOpenAlert, setIsOpen] = useState(false)
  const onCloseAlert = () => setIsOpen(false)
  const cancelRef = useRef(null)


  const [show, setShow] = useState(false);

  const [checkedItems, setCheckedItems] = useState<boolean[]>([])
  const allChecked = checkedItems.every(Boolean)
  const isIndeterminate = checkedItems.some(Boolean) && !allChecked



  const newDepartment = async () => {

    setDepartment(true)
    setEditDepartment(false)
    setCourse(false)
    onOpen();
  }

  //department
  const {
    currentPage,
    setCurrentPage,
    pagesCount,
    pages
  } = usePagination({
    pagesCount: isNaN(Math.ceil(page.total / page.page_total)) ? 1 : Math.ceil(page.total / page.page_total),
    initialState: { currentPage: 1, pageSize: 10 },
    total: page.total
  });

  useEffect(() => {
    let url = `?page=${currentPage}&limit=${10}&search=${query}`
    fetchDepartment(url)
      .then((data) => {
        console.log(data)
        setPage({ page_total: data.page_total, total: data.total })
        setDepartmentNo(data.total)

        setFilteredDepartments(data.results)
        setIsLoading(false)

      })
      .catch((err) => err)

  }, [currentPage, query, reload]);







  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search.length > 0) {
        console.log(search)
        setQuery(search);
      } else {
        setQuery("");
      }
    }, 700);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  const reloadData = () => {
    setStatus(!reload);
  }


  const viewDepartment = async (category_id: string) => {
    push(HrAdminRoutes.Departments.ViewLink(category_id));
  };


  const viewCourse = async (category_id: string) => {
    fetchEachCourse(category_id)
      .then((data) => {
        setSelectedCourse(data.results)
        setCourse(true)
        setDepartment(false)
        setEditDepartment(false)
        onOpen()
      })
      .catch((err) => err)

  }

  const editDepartment = async (category_id: string, titleDept: any) => {
    setDeptTitle(titleDept);
    setCourse(false);
    setDepartment(false);
    setEditDepartment(true);
    onOpen();
    setCategoryId(category_id)


  }

  const courseDeleteAlert = (category_id: string) => {
    setDeleteId(category_id)
    setIsOpen(true);
  }

  const deleteCourse = async () => {

    try {
      let courseResponse = await deleteDepartment(deleteId as string);

      
      onCloseAlert();
      toast({
        title: "Department Deletion",
        description: "Department Deleted Successfully",
        status: "success",
      })

      if (courseResponse) {
        let mainCourseIndex = filteredDepartments.findIndex(each => each.category_id === deleteId)

        filteredDepartments.splice(mainCourseIndex, 1)

       

        setFilteredDepartments([...filteredDepartments]);

      }

    } catch (error) {
      onCloseAlert();

      toast({
        title: "Course Deletion",
        description: "Something went Wrong. Try again later",
        status: "error",
      })
    }

  }





  const handlerPrint = useReactToPrint({

    content: () => componentRef.current as any,
    onAfterPrint: () => {
      setShow(false)
      toast({
        title: ' PDF',
        description: 'PDF File downloaded sucessfully',
        status: 'success',
      })
    }
  })

  const tryPDF = () => {
    setShow(true)
    setTimeout(() => {
      console.log(show)
      handlerPrint()
    }, 500);

  }



  function handleExcel() {
    genExcelDepartment()
      .then((response: any) => {
        console.log(response, 'excel response')
        var blob = new Blob(
          [response],
          { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" },
        );
        var a = document.createElement("a");
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = "users_report.xlsx";
        a.click();


        if (response) {
          toast({
            title: ' Excel',
            description: 'Excel File downloaded sucessfully',
            status: 'success',

          })
          push(HrAdminRoutes.Departments.ManageDepartment)
        }
      })
      .catch((error) =>
        toast({
          title: 'Excel',
          description: 'Download Failed, please try again :',
          status: 'error',
        }))
  }



  const exportPDF = () => {
    let url = `?page=${currentPage}&limit=${100}&search=${query}`
    let fetchedData: IDepartmentData[];

    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = `Department(${page.total})`;
    const headers = [["Department", "Course", "Members", "Enrolled", "Average Pass Rate", "Average Course Rate",]];
    fetchDepartment(url)
      .then((data) => {
        fetchedData = data.results;
        const dataD = fetchedData.map(dept => [dept.title, dept.courses, dept.members, dept.enrolled, dept.passed + "%", parseFloat(dept.ratings).toFixed(1)]);

        toast({
          title: ' PDF',
          description: 'PDF File downloaded sucessfully',
          status: 'success',
        })
        doc.text(title, marginLeft, 40);
        autoTable(doc, {
          startY: 50,
          head: headers,
          body: dataD as string[][]
        });
        doc.save("department.pdf")
      })
      .catch((err) => err)

  }




  return (
    <Container
      boxShadow="base"
      maxW="container.xl"
      bg="white"
      pt={3}
      pb={10}
      px={8}
      minHeight="70vh"
    >
      {isLoading ? (
        <ScreenLoader />
      ) :
        <Box>
          <Text pl={6} color="brand.3" fontSize={18} fontWeight="semibold">
            Department ({departmentNo})
          </Text>

          <Flex
            justifyContent="space-between"
            alignItems="center"
            px={6}
            mt={4}
          >
            <InputGroup color="brand.subtitle" bg="white" width="40%">
              <Input
                placeholder="Search departments on this platform"
                onChange={(e) => setSearch(e.target.value)}
                fontSize={12}
                size="sm"
              />

              <InputRightElement
                h="1.9rem"
                pointerEvents="none"
                children={<RiSearch2Line size="0.9rem" />}
              />
            </InputGroup>
            <Flex justifyContent="space-between">
              <Menu>
                <MenuButton as={Button} rightIcon={<ChevronDownIcon />}
                  colorScheme="brand"
                  variant={"outline"}
                  size="sm"
                  fontSize={12}
                >
                  Generate Report
                </MenuButton>
                <MenuList fontSize={12}>
                  <MenuItem onClick={exportPDF}> PDF</MenuItem>
                  <MenuItem onClick={handleExcel}>Excel</MenuItem>
                </MenuList>
              </Menu>
              <Spacer mx={2} />

              <Button
                colorScheme="brand"
                leftIcon={<FaLayerGroup />}
                onClick={() => newDepartment()}
                size="sm"
                fontSize={12}
              >
                NEW DEPARTMENT
              </Button>
            </Flex>
          </Flex>

          {filteredDepartments.length === 0 ? (
            <EmptyCourseSearch />
          ) : (
            <Box ref={componentRef} >
              {show ? <Image pl={3} mt={4} src={blucanaryLogo2} width="200px" height="auto" /> : <></>}
              <Table variant="simple" mt={8} >
                <Thead fontWeight="normal">
                  <Tr color="brand.subtitle">
                    <Th textTransform="none" >
                      Department
                    </Th>
                    <Th textTransform="none">#Course</Th>
                    <Th textTransform="none">Members</Th>
                    <Th textTransform="none">Enrolled</Th>
                    <Th textTransform="none">Average Pass Rate</Th>
                    <Th textTransform="none">Average Course Rating</Th>
                    <Th textTransform="none">Actions</Th>
                  </Tr>
                </Thead>

                <Tbody>
                  {filteredDepartments.map((each, index) => (
                    <Tr key={index} mt={10} fontSize={15} color="brand.subtitle">
                      <Td role={"button"} onClick={() => viewDepartment(each.category_id)}>

                        <Text
                          color="#4E78F5"
                          whiteSpace="nowrap"
                          isTruncated
                          title={each.title}
                        >
                          {each.title}
                        </Text>

                      </Td>

                      <Td role={"button"} onClick={() => viewCourse(each.category_id)} >
                        <Text color="#4E78F5" whiteSpace="nowrap">
                          {each.courses ? each.courses : "Nil"}
                        </Text>

                      </Td>

                      <Td>{each.members ? each.members : "Nil"}</Td>
                      <Td>{each.enrolled ? each.enrolled : "Nil"}</Td>
                      <Td>{each.passed ? each.passed + "%" : "Nil"}</Td>
                      <Td>
                        <HStack>
                          <BsFillStarFill fill="#E9A426" />{' '}
                          <Text>{each.ratings ? Number(each.ratings).toFixed(1) : "-"}</Text>
                        </HStack>
                      </Td>

                      <Td>
                        <Menu >
                          <MenuButton >
                            <IconButton
                              variant="ghost"
                              aria-label="dots"
                              borderRadius={20}
                              size="xs"
                              icon={<BsThreeDotsVertical />}
                              fontSize={16}
                            />
                          </MenuButton>
                          <MenuList fontSize={12} minWidth="8rem" maxWidth="8rem" borderRadius={0} color="brand.bodyText" >
                            <MenuItem onClick={() => editDepartment(each.category_id, each.title)}> Edit </MenuItem>
                            <MenuItem onClick={() => viewDepartment(each.category_id)} > Preview </MenuItem>
                            <MenuItem onClick={() => courseDeleteAlert(each.category_id)} > Delete  </MenuItem>


                          </MenuList>
                        </Menu>

                      </Td>


                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          )}



          <Flex alignContent={"flex-end"} justifyContent={"flex-end"} mt={4}>


            <Pagination
              pagesCount={pagesCount}
              currentPage={currentPage}
              onPageChange={setCurrentPage}
            >
              <PaginationContainer>
                <PaginationPrevious>Previous</PaginationPrevious>
                <PaginationPageGroup>
                  {pages.map((page: number) => (
                    <PaginationPage
                      key={`pagination_page_${page}`}
                      page={page} />
                  ))}
                </PaginationPageGroup>
                <PaginationNext>Next</PaginationNext>
              </PaginationContainer>
            </Pagination>
          </Flex>
        </Box>
      }

      {isDepartment && <Portal>

        {" "}

        <CreateDepartmentModal

          isOpenn={isOpen}

          onClosed={onClose}

          isDepartment={isDepartment}

          reloadData={reloadData}



        />{" "}
      </Portal>}
      {isEditDept && <Portal>

        {" "}

        <EditDepartmentModal

          isOpenEditDept={isOpen}

          onClosedEdit={onClose}

          isEditDept={isEditDept}

          deptTitle={departmentTitle}

          categoryId={categoryID}

          reloadData={reloadData}



        />{" "}
      </Portal>}


      {selectedCourse && <Portal>
        {" "}
        <ViewEachCourseModal
          isOpen={isOpen}
          onClose={onClose}
          isCourse={isCourse}
          course={selectedCourse}
          reloadData={reloadData}

        />{" "}
      </Portal>}
      <Portal>
        <AlertDialog
          isOpen={isOpenAlert}
          leastDestructiveRef={cancelRef}
          onClose={onCloseAlert}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='md' fontWeight='semibold'>
                Delete Department
              </AlertDialogHeader>

              <AlertDialogBody fontSize={15}>
                Are you sure? You can't undo this action afterwards.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button size={"sm"} ref={cancelRef} onClick={onCloseAlert}>
                  Cancel
                </Button>
                <Button size={"sm"} colorScheme='red' onClick={deleteCourse} ml={3}>
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Portal>


    </Container>
  );
}
