import { Select } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { IAccessibility } from "../../models/course.model";
import { fetchAccessibility } from "../../services/shared.service";


export const Accessibility: React.FC<any> = ({accessibilityId, setaccessibilityId, ...rest }) => {

    const [accessibility, setAccessibility] = useState<IAccessibility[]>([]);

    useEffect(() => {
        fetchAccessibility()
        .then((data) => {
            let accessibilities: IAccessibility[] = data.map(({title, accessibility_id }) => ({title, accessibility_id}) )

            setAccessibility(accessibilities)
        })
        .catch((err) => err)
        
    }, []);

    
    return (
        <>
            <Select {...rest}  variant="outline" bg="inputText.500" placeholder="Select accessibility" name="accessibility" value={accessibilityId} size="sm" onChange={setaccessibilityId} fontSize={12} color="brand.subtitle" >
                {
                    accessibility.map( each => <option key={each.accessibility_id} value={each.accessibility_id}> {each.title} </option>)
                }
            </Select>
        </>
    );
}