import {
  Box,
  Button,
  Flex,
  HStack,
  Text,
  VStack,
  useToast,
  Image,
  Accordion,
  AccordionPanel,
  AccordionIcon,
  AccordionItem,
  AccordionButton,
  AspectRatio,
  Select,
  AvatarGroup,
  Avatar,
  FormControl,
  Portal,
  useDisclosure,
} from "@chakra-ui/react";
import { Select as ChakraSelect } from "chakra-react-select";
import { useEffect, useRef, useState } from "react";
import { BsFileEarmarkPdf, BsPlus } from "react-icons/bs";
import { FiMenu } from "react-icons/fi";
import { useHistory, useParams } from "react-router";
import { ScreenLoader } from "../../components/Common/ScreenLoader";
import { IUser } from "../../models/auth.model";
import {
  CourseAssignTypes,
  IAssignCourseToUnitDto,
  ICourse,
} from "../../models/course.model";
import { TrainingMgrRoutes } from "../../pages/TrainingManager/routes";
import { fetchUserLevel, fetchUserRanks } from "../../services/shared.service";
import {
  assignCourseToUnitService,
  courseApprovalService,
  fetchCoursePreview,
  fetchCourseStudents,
} from "../../services/trainingMgr/course-http.service";
import { firstLetterUpper } from "../../utils/firstLetterUpper.util";
import { secondsToHrMin } from "../../utils/secondsToHrMin";
import { Category } from "../Common/Category";
import { AssignCourseModal } from "./AssignCourseModal";
import { CourseDeclineModal } from "./CourseDeclineModal";

const PreviewCourse = () => {
  let history = useHistory();

  const toast = useToast();

  const levelRef = useRef<HTMLSelectElement>();

  const { isOpen, onOpen, onClose } = useDisclosure();

  let { courseId } = useParams<{ courseId: string }>();

  const [selectedUnitId, setSelectedUnitId] = useState<string>();

  const [selectedRankIds, setSelectedRankIds] = useState<number[]>([]);

  const [courseData, setCourseData] = useState<ICourse>();

  const [isLoadingPage, setIsLoadingPage] = useState(true);

  const [isApproving, setIsApproving] = useState(false);

  const [students, setStudents] = useState<IUser[]>();

  const [isAssigning, setIsAssigning] = useState(false);

  const [assignType, setAssignType] = useState(CourseAssignTypes.STUDENT);

  const [constLevels, setConstLevels] = useState<
    { value: number; label: string }[]
  >([]);
  const [levels, setLevels] = useState<{ value: number; label: string }[]>([]);
  const [constRanks, setConstRanks] = useState<
    { value: number; label: string; levelId: number }[]
  >([]);
  const [ranks, setRanks] = useState<{ value: number; label: string }[]>([]);
  const [enrolledStudentIds, setEnrolledStudentIds] = useState<string[]>([]);

  const {
    isOpen: isDeclineOpen,
    onOpen: onDeclineOpen,
    onClose: onDeclineClose,
  } = useDisclosure();

  useEffect(() => {
    fetchCoursePreview(courseId)
      .then((data) => {
        console.log(courseId, "this is preview courseid");
        setCourseData(data);

        setIsLoadingPage(false);

        fetchCourseStudents(courseId).then((res) => setStudents(res));
        console.log(data);
      })
      .catch((e) => {
        history.goBack();
      });

    fetchLevels();

    fetchRanks();
  }, []);

  const courseApproval = async (
    truth: boolean,
    courseId: string,
    courseIndex?: number,
    message?: string
  ) => {
    setIsApproving(true);

    try {
      let course = await courseApprovalService({
        courseId,
        approve: truth,
        message: message,
      });

      let toastTitle: string;
      let toastStatus: "info" | "warning" | "success" | "error" | undefined;

      if (truth) {
        toastTitle = "Course Approval";
        toastStatus = "success";
      } else {
        toastTitle = "Course Decline";
        toastStatus = "warning";
      }

      toast({
        title: toastTitle,
        description: course.message,
        status: toastStatus,
        duration: 3000,
        onCloseComplete: () => {
          setIsApproving(false);
          history.replace(TrainingMgrRoutes.Course.Fetch);
        },
      });
    } catch (error) {
      toast({
        title: "Course Approval",
        description: "Something went Wrong. Try again later",
        status: "error",
        duration: 3000,
      });

      setIsApproving(false);
    }
  };

  const courseDecline = () => {
    onDeclineOpen();
  };

  const changeCourseAssignType = (value: CourseAssignTypes) => {
    setAssignType(value);
  };

  const fetchLevels = async () => {
    try {
      const levelsRes = await fetchUserLevel();

      setConstLevels([
        ...levelsRes.map((each) => ({ label: each.name, value: each.id })),
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRanks = async () => {
    try {
      const userRanks = await fetchUserRanks();

      setConstRanks([
        ...userRanks.map((each) => ({
          label: each.name,
          value: each.id,
          levelId: each.levelId,
        })),
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  const pickUnit = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;

    setSelectedUnitId(value);

    if (value) setLevels(constLevels);
    else setLevels([]);
  };

  const pickLevel = (event: { label: string; value: number }[]) => {
    let levelIds = event.map((level) => level.value);

    let rankList = constRanks?.filter((each) =>
      levelIds.includes(each.levelId)
    );

    setRanks([
      ...rankList?.map((each) => ({ label: each.label, value: each.value })),
    ]);
  };

  const pickRank = (event: { label: string; value: number }[]) => {
    setSelectedRankIds([...event.map((each) => each.value)]);
  };

  const openAssignStudentsModal = () => {
    setEnrolledStudentIds([
      ...(students?.map((each) => each.user_id) as string[]),
    ]);
    onOpen();
  };

  const updateRecord = (studentsAssigned: IUser[]) => {
    setStudents([...(students as IUser[]), ...studentsAssigned]);
  };

  const assignCourseToUnit = async () => {
    if (!selectedUnitId) {
      toast({
        title: "Course Assignment",
        description: "Pick a Unit",
        status: "warning",
        duration: 2000,
      });

      return;
    }

    if (!selectedRankIds.length) {
      toast({
        title: "Course Assignment",
        description: "Pick at least a Rank",
        status: "warning",
        duration: 2000,
      });

      return;
    }

    setIsAssigning(true);

    try {
      let data: IAssignCourseToUnitDto = {
        courseId,
        unitId: selectedUnitId,
        rankIds: selectedRankIds,
      };

      let response = await assignCourseToUnitService(data);

      toast({
        title: "Course Assignment",
        description: response.message,
        status: "success",
        duration: 2000,
      });
    } catch (error) {
      toast({
        title: "Course Assignment",
        description: "Something went Wrong. Try again later",
        status: "error",
        duration: 3000,
      });
    } finally {
      setIsAssigning(false);
    }
  };

  return (
    <>
      {isLoadingPage ? (
        <ScreenLoader />
      ) : (
        <Box pb={6} boxShadow="sm" maxW="container.xl" bg="white" mb={4}>
          <Text
            px={6}
            color="brand.2"
            fontSize={16}
            mb={4}
            fontWeight="semibold"
          >
            {" "}
            Course Preview
          </Text>

          <Box height="10em" mb={6}>
            <Flex height="inherit">
              <Box width="30%" boxSizing="content-box">
                <Image
                  src={courseData?.cover_image_url}
                  maxW="100%"
                  maxH="100%"
                />
              </Box>

              <Box bg="brand.2" width="70%" px={8} py={6}>
                <VStack
                  color="white"
                  justifyContent="start"
                  alignItems="start"
                  spacing={3}
                >
                  <Text
                    fontSize={18}
                    isTruncated
                    title={courseData?.title}
                    noOfLines={1}
                    fontWeight="bold"
                  >
                    {courseData?.title}
                  </Text>

                  <Text fontSize={13} fontWeight="semibold">
                    {" "}
                    Course Category:{" "}
                    <Box as="span" fontSize={12} fontWeight="normal" mr={1}>
                      {" "}
                      {courseData?.category?.title}{" "}
                    </Box>{" "}
                    | <Box ml={1} as="span"></Box> Course Level:{" "}
                    <Box as="span" fontSize={12} fontWeight="normal">
                      {" "}
                      {firstLetterUpper(
                        courseData?.level?.title as string
                      )}{" "}
                    </Box>{" "}
                  </Text>

                  <Text fontSize={13} fontWeight="semibold">
                    {" "}
                    Course Format:{" "}
                    <Box as="span" fontSize={12} fontWeight="normal" mr={1}>
                      {" "}
                      {courseData?.format?.title}{" "}
                    </Box>{" "}
                    | <Box ml={1} as="span"></Box> Language:{" "}
                    <Box as="span" fontSize={12} fontWeight="normal">
                      {" "}
                      English{" "}
                    </Box>{" "}
                  </Text>

                  <Text fontSize={13} fontWeight="semibold">
                    {" "}
                    Start:{" "}
                    <Box as="span" fontSize={12} fontWeight="normal" mr={1}>
                      {" "}
                      {courseData?.startAt
                        ? new Date(courseData?.startAt as string).toDateString()
                        : "--"}{" "}
                    </Box>{" "}
                    | <Box ml={1} as="span"></Box> End:{" "}
                    <Box as="span" fontSize={12} fontWeight="normal">
                      {" "}
                      {courseData?.startAt
                        ? new Date(courseData?.endAt as string).toDateString()
                        : "--"}{" "}
                    </Box>{" "}
                    |{" "}
                    <Box ml={1} as="span" fontSize={12} fontWeight="normal">
                      {" "}
                      {courseData?.accessibility &&
                        firstLetterUpper(
                          courseData?.accessibility?.title as string
                        )}{" "}
                    </Box>{" "}
                  </Text>
                </VStack>
              </Box>
            </Flex>
          </Box>

          <Box px={6}>
            <Text color="brand.2" fontSize={14} mb={4} fontWeight="semibold">
              {" "}
              Course Description
            </Text>

            <Box color="brand.subtitle" fontSize={13} textAlign="start" mb={8}>
              {courseData?.description}
            </Box>

            <Text color="brand.2" fontSize={14} mb={4} fontWeight="semibold">
              {" "}
              Course Curriculum
            </Text>

            {courseData?.topics?.map((eachTopic, topicIndex) => (
              <Box
                key={topicIndex}
                bg="brand.grey"
                boxShadow="0px 0px 4px rgba(0, 0, 0, 0.15)"
                px={6}
                py={6}
                mt={3}
                mb={6}
              >
                <Text fontSize={15} color="brand.3" fontWeight="semibold">
                  Lecture {topicIndex + 1}: {eachTopic.title}{" "}
                </Text>

                <Box bg="white" boxShadow="base" mt={4}>
                  <Accordion allowToggle={true} allowMultiple>
                    {eachTopic?.lessons?.map((eachLesson, lessonIndex) => (
                      <AccordionItem key={lessonIndex}>
                        <AccordionButton>
                          <Flex justifyContent="space-between" w="100%">
                            <HStack>
                              <FiMenu color="#7883A7" />
                              <Text fontSize={14} color="brand.3">
                                {lessonIndex + 1}.{lessonIndex + 1}:{" "}
                                {eachLesson.title}{" "}
                                <Box
                                  as="span"
                                  ml={1}
                                  fontSize={12}
                                  color="brand.subtitle"
                                  display="inline-flex"
                                >
                                  {" "}
                                  {eachLesson.duration &&
                                    `(${secondsToHrMin(
                                      eachLesson.duration
                                    )})`}{" "}
                                </Box>{" "}
                              </Text>
                            </HStack>

                            <AccordionIcon />
                          </Flex>
                        </AccordionButton>

                        <AccordionPanel>
                          {eachLesson.video_url && (
                            <Box>
                              <HStack alignItems="start" spacing={4}>
                                <AspectRatio
                                  maxW="10em"
                                  ratio={4 / 3}
                                  flex="1 1 auto"
                                >
                                  <video controls controlsList="nodownload">
                                    <source src={eachLesson.video_url} />
                                  </video>
                                </AspectRatio>

                                <Text
                                  pt={4}
                                  fontSize={12}
                                  title={eachLesson.asset_file_name}
                                  fontWeight="semibold"
                                  isTruncated
                                  maxWidth="30em"
                                >
                                  {eachLesson.asset_file_name}
                                </Text>
                              </HStack>
                            </Box>
                          )}
                          {eachLesson.doc_url && (
                            <Box>
                              <HStack py='10px' spacing={'5px'}>
                                <BsFileEarmarkPdf fontSize={'18px'} />
                                <Text
                                  cursor={"pointer"}
                                  textTransform={"capitalize"}
                                  fontSize={13}
                                  pt='3px'
                                  
                                >
                                  <a target="_blank" href={eachLesson.doc_url}>
                                    {eachLesson.asset_file_name}
                                  </a>
                                </Text>
                              </HStack>
                            </Box>
                          )}

                          {eachLesson.article && (
                            <Box fontSize={13} px={10} pt={3}>
                              {eachLesson.article}
                            </Box>
                          )}
                        </AccordionPanel>
                      </AccordionItem>
                    ))}
                  </Accordion>
                </Box>
              </Box>
            ))}

            <Box
              bg="brand.grey"
              boxShadow="0px 0px 4px rgba(0, 0, 0, 0.15)"
              px={6}
              py={6}
              mt={3}
              mb={6}
            >
              <Flex justifyContent={"start"} alignItems={"center"}>
                <Flex
                  width={"100%"}
                  justifyContent={"start"}
                  alignItems={"center"}
                >
                  <Text
                    mr={6}
                    fontSize={13}
                    fontWeight={"semibold"}
                    color="brand.3"
                  >
                    {" "}
                    Assign Course
                  </Text>

                  <Select
                    defaultValue={CourseAssignTypes.STUDENT}
                    variant="outline"
                    width="12%"
                    size="sm"
                    onChange={(event) =>
                      changeCourseAssignType(
                        event.target.value as CourseAssignTypes
                      )
                    }
                    fontSize={12}
                    color="brand.subtitle"
                    mr={6}
                  >
                    <option value={CourseAssignTypes.STUDENT}> Student </option>
                    <option value={CourseAssignTypes.UNIT}> Unit </option>
                  </Select>

                  {CourseAssignTypes.STUDENT === assignType && (
                    <HStack spacing={4} mr={6}>
                      <Box
                        width={6}
                        height={6}
                        borderRadius={20}
                        border="dotted"
                        justifyContent="center"
                        alignItems="center"
                        color="brand.subtitle"
                        cursor="pointer"
                        onClick={openAssignStudentsModal}
                      >
                        <Flex
                          direction="column"
                          justifyContent="center"
                          height="100%"
                          alignItems="center"
                          fontSize={16}
                        >
                          <BsPlus />
                        </Flex>
                      </Box>

                      <AvatarGroup
                        size="xs"
                        fontSize={10}
                        color="brand.2"
                        max={3}
                      >
                        {students?.map((eachStudent, studentIndex) => (
                          <Avatar
                            key={studentIndex}
                            name={eachStudent.full_name}
                            src={eachStudent.photo_url}
                          />
                        ))}
                      </AvatarGroup>
                    </HStack>
                  )}

                  {CourseAssignTypes.UNIT === assignType && (
                    <HStack spacing={2} mr={6} width={"60%"}>
                      <Category setUnitId={pickUnit} />

                      <FormControl>
                        <ChakraSelect
                          isMulti
                          name="levels"
                          options={levels}
                          placeholder="Levels"
                          closeMenuOnSelect={false}
                          size="sm"
                          ref={levelRef}
                          onChange={(
                            event: { label: string; value: number }[]
                          ) => pickLevel(event)}
                        />
                      </FormControl>

                      <FormControl>
                        <ChakraSelect
                          isMulti
                          name="levels"
                          options={ranks}
                          placeholder="Ranks"
                          closeMenuOnSelect={false}
                          size="sm"
                          onChange={(
                            event: { label: string; value: number }[]
                          ) => pickRank(event)}
                        />
                      </FormControl>
                    </HStack>
                  )}
                </Flex>

                {CourseAssignTypes.UNIT === assignType && (
                  <Button
                    colorScheme="brand"
                    px={6}
                    size="sm"
                    fontSize={12}
                    isLoading={isAssigning}
                    onClick={() => assignCourseToUnit()}
                  >
                    {" "}
                    Assign{" "}
                  </Button>
                )}
              </Flex>
            </Box>

            <HStack justifyContent="flex-end" spacing={4}>
              {!courseData?.suspendedAt && !courseData?.approvedAt ? (
                <>
                  <Button
                    colorScheme="success"
                    size="sm"
                    fontSize={12}
                    isLoading={isApproving}
                    onClick={() => courseApproval(true, courseId)}
                  >
                    {" "}
                    Approve{" "}
                  </Button>
                  <Button
                    colorScheme="danger"
                    variant="outline"
                    size="sm"
                    fontSize={12}
                    onClick={() => courseDecline()}
                  >
                    {" "}
                    Decline{" "}
                  </Button>
                </>
              ) : (
                <>
                  {courseData?.suspendedAt && (
                    <Button
                      colorScheme="success"
                      size="sm"
                      fontSize={12}
                      isLoading={isApproving}
                      onClick={() => courseApproval(true, courseId)}
                    >
                      {" "}
                      Approve{" "}
                    </Button>
                  )}
                  {courseData?.approvedAt && (
                    <Button
                      colorScheme="danger"
                      variant="outline"
                      size="sm"
                      fontSize={12}
                      onClick={() => courseDecline()}
                    >
                      {" "}
                      Decline{" "}
                    </Button>
                  )}
                </>
              )}
            </HStack>
          </Box>

          <Portal>
            {" "}
            <AssignCourseModal
              selectedCourseId={courseId}
              studentIds={enrolledStudentIds}
              updateRecord={updateRecord}
              isOpen={isOpen}
              onClose={onClose}
            />{" "}
          </Portal>

          <Portal>
            <CourseDeclineModal
              isOpen={isDeclineOpen}
              onClose={onDeclineClose}
              courseId={courseId}
              courseApproval={courseApproval}
            />
          </Portal>
        </Box>
      )}
    </>
  );
};

export default PreviewCourse;
