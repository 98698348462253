import { Box, Flex, Text } from "@chakra-ui/layout";
import React, { useEffect, useRef, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { Button } from "@chakra-ui/button"
import { FormControl, FormErrorMessage, HStack, Input, Select, Textarea, IconButton, useToast } from "@chakra-ui/react";
import { IQuizOptionState, IQuizQuestionState, IQuizState } from "../../../../models/create_course.model";
import { firstLetterUpper } from "../../../../utils/firstLetterUpper.util";
import { IoMdAddCircleOutline, IoMdCheckmarkCircle, IoMdTrash } from "react-icons/io";
import {
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
} from "@chakra-ui/react"
import { addExamOptionToState, addExamQuestionToStateService, deleteExamOptionToState, deleteExamQuestionToStateService, editExamOptionToState, getExamFromStateService, getExamQuestionsFromStateService, removeExamFromStateService, setExamQuestionCorrectAnswerToStateService, updateExamQuestionToStateService } from "../../../../services/instructor/course.service";
import { correctAnswerService, courseQuestionDeletionService, createQuizAnswerService, createQuizQuestionService, optionDeletionService, updateOptionLesson, updateQuestionLesson } from "../../../../services/instructor/courses-http.service";
import { ICorrectAnswerDto, IQuizAnswerDto, IQuizQuestionDto } from "../../../../models/quiz.model";
import { useFormik } from "formik";
import { Subject, debounceTime, take, BehaviorSubject, interval } from 'rxjs'
import { VscTrash } from "react-icons/vsc";
import { BsPencilSquare } from "react-icons/bs";


const numWords = require("num-words");

const passMarkAction$ = new BehaviorSubject<number>(0);

const obs$ = interval(1000);


export const ExamUpload: React.FC<any> = ({ goBack, setCurriculumOkay,noOfExams }) => {

    const [quizQuestions, setQuizQuestions] = useState<IQuizQuestionState[]>([])

    const [passMark, setPassMark] = useState<number>(50);

    const defaultQuiz: any = { question: '', isSaved: false, isAnswerSelected: false, showEditQuestion: false, quiz_question_id: '' }

    const toast = useToast();

    const mountedRef = useRef(true)

    const [exam, setExam] = useState<IQuizState | null>();


    useEffect(() => {

        const exam = getExamFromStateService();
        console.log(exam, 'exam stuffs')

        setExam(exam);

        const quizQuestionsFromService = getExamQuestionsFromStateService() as IQuizQuestionState[];

        if (quizQuestionsFromService?.length)
            setQuizQuestions(quizQuestionsFromService);
        else
            setQuizQuestions([...quizQuestions, defaultQuiz]);

        if (mountedRef.current) {
            const sub = passMarkAction$.pipe(
                debounceTime(2000),
            ).subscribe(
                {
                    next: (e) => {
                        // console.log(e);

                        // quizPassMarkUpdateService({passmark: e, quizId: ''})
                    }
                }
            )
        }

        // return s.unsubscribe();
        return () => {

            //  sub.unsubscribe()
        }

    }, [])


    const removeQuiz = () => {
        setCurriculumOkay(false)
        goBack();
    }

    // const removeOption = () => {

    //     goBack();
    // }

    const addQuestion = async () => {
        noOfExams(quizQuestions.length)
        let warning;

        const lastQuestion = quizQuestions.at(quizQuestions.length - 1);
        console.log(quizQuestions.length)
        const options = lastQuestion?.options?.filter(each => each.isSaved === true) ?? [];

        if (!lastQuestion?.answerId && quizQuestions.length > 0)
            warning = "Correct option is required"

        if (!lastQuestion?.isSaved && quizQuestions.length > 0)
            warning = "Save previous question to proceed"

        if (options?.length < 2 && quizQuestions.length > 0)
            warning = "Minimum of two options are required"

       

        if (warning) {

            toast({
                title: "Exam",
                description: warning,
                status: "warning",
                duration: 3000
            })

        } else {

            setQuizQuestions([...quizQuestions, defaultQuiz]);
           
            setCurriculumOkay(true)
        }

    }

    const formikQuestion = useFormik({
        initialValues: {
            question: ''
        },
        validate: (values: any) => {
            const errors: any = {};
            if (!values.question) {
                errors.question = 'Exam question is required';
            }
            return errors;
        },
        onSubmit: async (values) => {
            try {

                const data: IQuizQuestionDto = {
                    quizId: exam?.quiz.quiz_id as string,
                    question: values.question,
                };

                const quizQuestion = await createQuizQuestionService(data)

                let newQuestion: IQuizQuestionState = {
                    isSaved: true,
                    showEditQuestion: false,
                    isAnswerSelected: false,
                    quiz_question_id: quizQuestion.quiz_question_id,
                    question: quizQuestion.question
                }

                quizQuestions.splice(quizQuestions.length - 1, 1, { ...newQuestion, options: [{ isCorrect: false, isSaved: false, option: '', quiz_answer_id: '' }] });

                setQuizQuestions([...quizQuestions]);

                addExamQuestionToStateService(newQuestion);

                formikQuestion.resetForm();

            } catch (error) {
                toast({
                    title: "Exam Question",
                    description: "Something went Wrong. Try again later",
                    status: "error",
                })
            }
        }
    })
    const deleteQuestion = async (questionIndex: number) => {

        try {

            let question = quizQuestions.at(questionIndex);
            let deletedQuestion = await courseQuestionDeletionService(question?.quiz_question_id as string)

            quizQuestions.splice(questionIndex, 1);
            deleteExamQuestionToStateService(questionIndex);
            setQuizQuestions([...quizQuestions]);
            console.log('quiz question', quizQuestions)
            if (deletedQuestion) {
                toast({
                    title: "Course Curriculum",
                    description: "Question deleted",
                    status: "success",
                })
                noOfExams(quizQuestions.length)
            }


        } catch (error) {
            toast({
                title: "Course Curriculum",
                description: "Something went wrong. Try again Later.",
                status: "error",
            })
        }
    }


    const editQuestion = (questionIndex: number, questionId: string) => {

        let question = quizQuestions.at(questionIndex) as IQuizQuestionState;

        quizQuestions.splice(questionIndex, 1, { ...question, showEditQuestion: true });
        console.log(quizQuestions, 'lets see')
        setQuizQuestions([...quizQuestions]);

        formikUpdateQuestion.setValues({ question: question.question, questionIndex: questionIndex, quiz_question_id: questionId });



    }

    const formikUpdateQuestion: any = useFormik({
        initialValues: {
            question: '',
            quiz_question_id: '',
            questionIndex: -1,
        },
        validate: (values: any) => {
            const errors: any = {};
            if (!values.question) {
                errors.question = 'Exam question is required';
            }
            return errors;
        },
        onSubmit: async (values) => {
            try {

                const data: IQuizQuestionDto = {
                    quizId: exam?.quiz.quiz_id as string,
                    quizQuestionId: values.quiz_question_id,

                    question: values.question,
                };
                console.log(data, 'submitted to edit')

                const quizQuestion = await updateQuestionLesson(data)
                if (quizQuestion) {
                    toast({
                        title: "Course Curriculum",
                        description: "Question updated",
                        status: "success",
                    })
                }

                
                let newQuestion: IQuizQuestionState = {
                    isSaved: true,
                    showEditQuestion: false,
                    isAnswerSelected: false,
                    quiz_question_id: quizQuestion.quiz_question_id,
                    question: quizQuestion.question

                }

                quizQuestions.splice(values.questionIndex, 1, { ...newQuestion, options: [{ isCorrect: false, isSaved: false, option: '', quiz_answer_id: '' }] });

                setQuizQuestions([...quizQuestions]);

                updateExamQuestionToStateService(values.questionIndex, { ...newQuestion, options: [{ isCorrect: false, isSaved: false, option: '', quiz_answer_id: '' }] });

                formikUpdateQuestion.resetForm();
            } catch (error) {
                toast({
                    title: "Course Curriculum",
                    description: "Something went Wrong. Try again later",
                    status: "error",
                })
            }
        }
    })

    const deleteQuestionOption = async (questionIndex: number, quizAnswerId: string, optionIndex: number) => {

        try {

            let selectedQuestion = quizQuestions.at(questionIndex) as IQuizQuestionState;
            console.log(quizQuestions, 'former')
            const deletedQuestion = await optionDeletionService(quizAnswerId as string)
            if (deletedQuestion) {
                toast({
                    title: "Course Curriculum",
                    description: "Option deleted successfully",
                    status: "success",
                })

            }
            selectedQuestion?.options?.splice(optionIndex, 1);

            quizQuestions.splice(questionIndex, 1, { ...selectedQuestion })
            formikQuestion.resetForm()

            setQuizQuestions([...quizQuestions]);
            deleteExamOptionToState(questionIndex, optionIndex)
            console.log(quizQuestions, 'quiz questions option')


        } catch (error) {
            toast({
                title: "Course Curriculum",
                description: "Something went wrong. Try again Later.",
                status: "error",
            })
        }
    }

    const editQuestionOption = (questionIndex: number, optionIndex: number) => {

        let selectedQuestion = quizQuestions.at(questionIndex) as IQuizQuestionState;

        let option = selectedQuestion?.options?.at(optionIndex) as IQuizOptionState;
        //  quizQuestions.splice(questionIndex, 1, { ...selectedQuestion, showEditQuestion: true });
        selectedQuestion.options?.splice(optionIndex, 1, { ...option, showEditOption: true })


        formikUpdateOption.setValues({ option: option.option, quizQuestionId: selectedQuestion.quiz_question_id,quizAnswerId: option.quiz_answer_id, quizQuestionIndex: questionIndex, optionIndex: optionIndex });

        quizQuestions.splice(questionIndex, 1, { ...selectedQuestion })
        console.log(quizQuestions, 'option update')

        setQuizQuestions([...quizQuestions]);
    }

    const formikUpdateOption = useFormik({
        initialValues: {
            option: '',
            quizQuestionId: '',
            quizQuestionIndex: -1,
            optionIndex: -1,
            quizAnswerId: ''
        },
        validate: (values: any) => {
            const errors: any = {};
            if (!values.option) {
                errors.option = 'Exam option is required';
            }
            return errors;
        },
        onSubmit: async (values) => {
            try {

                const data: IQuizAnswerDto = {
                    quizId: exam?.quiz?.quiz_id as string,
                    answer: values.option,
                    quizQuestionId: values.quizQuestionId,
                    quizAnswerId: values.quizAnswerId
                };
                console.log(data, 'submitted to edit')

                const quizQuestion = await updateOptionLesson(data)
                if(quizQuestion)
                toast({
                    title: "Course Curriculum",
                    description: "Option updated successfully",
                    status: "success",
                })
                console.log(quizQuestion, 'new option')
                let answer: IQuizOptionState = {
                    isSaved: true,
                    isCorrect: false,
                    option: quizQuestion.answer,
                    quiz_answer_id: quizQuestion.quiz_answer_id,
                    showEditOption: false
                }
                console.log(answer)
                editOptionToState(answer, values.quizQuestionIndex, values.optionIndex);

                formikUpdateOption.resetForm();


            } catch (error) {
                toast({
                    title: "Course Curriculum",
                    description: "Something went Wrong. Try again later",
                    status: "error",
                })
            }
        }
    })



    const addAnswerOption = (questionIndex: number) => {

        let selectedQuestion = quizQuestions.at(questionIndex) as IQuizQuestionState;

        if (selectedQuestion.options?.length as number > 4) {

            toast({
                title: "Exam Option",
                description: "Maximum of 5 options allowed",
                status: "warning",
            })

            return;
        }

        if (selectedQuestion.options?.length) {
            if (!selectedQuestion.options.at(selectedQuestion.options.length - 1)?.isSaved) {

                toast({
                    title: "Exam Option",
                    description: "Save the last option",
                    status: "warning",
                })

                return;
            }
        }

        quizQuestions.splice(questionIndex, 1, { ...selectedQuestion, options: [...selectedQuestion.options as IQuizOptionState[] ?? [], { option: '', quiz_answer_id: '', isCorrect: false, isSaved: false }] })

        setQuizQuestions([...quizQuestions]);
    }

    const formikAnswer = useFormik({
        initialValues: {
            option: '',
            quizQuestionId: '',
            quizQuestionIndex: -1,
            optionIndex: -1,
        },
        validate: (values: any) => {
            const errors: any = {};
            if (!values.option) {
                errors.option = 'Exam option is required';
            }
            return errors;
        },
        onSubmit: async (values) => {
            try {

                const data: IQuizAnswerDto = {
                    quizId: exam?.quiz?.quiz_id as string,
                    answer: values.option,
                    quizQuestionId: values.quizQuestionId
                };

                if (!values.quizQuestionId) {
                    toast({
                        title: "Exam Question",
                        description: "A Question is required",
                        status: "warning",
                    })
                    return;
                }

                const quizAnswer = await createQuizAnswerService(data)

                let answer: IQuizOptionState = {
                    isSaved: true,
                    isCorrect: false,
                    option: quizAnswer.answer,
                    quiz_answer_id: quizAnswer.quiz_answer_id,
                    showEditOption: false
                }

                addOptionToState(answer, values.quizQuestionIndex, values.optionIndex);

                formikAnswer.resetForm();

            } catch (error) {
                toast({
                    title: "Exam Option",
                    description: "Something went Wrong. Try again later",
                    status: "error",
                })
            }
        }
    })

    const addOptionToState = (optionState: IQuizOptionState, questionIndex: number, optionIndex: number) => {

        let selectedQuestion = quizQuestions.at(questionIndex) as IQuizQuestionState;

        let selectedOption = selectedQuestion.options?.at(optionIndex) as IQuizOptionState;

        selectedQuestion.options?.splice(optionIndex, 1, { ...selectedOption, isSaved: true, option: optionState.option, quiz_answer_id: optionState.quiz_answer_id })

        quizQuestions.splice(questionIndex, 1, selectedQuestion);

        setQuizQuestions([...quizQuestions]);

        addExamOptionToState(questionIndex, optionState);
    }
    const editOptionToState = (optionState: IQuizOptionState, questionIndex: number, optionIndex: number) => {

        let selectedQuestion = quizQuestions.at(questionIndex) as IQuizQuestionState;

        let selectedOption = selectedQuestion.options?.at(optionIndex) as IQuizOptionState;

        selectedQuestion.options?.splice(optionIndex, 1, optionState)

        quizQuestions.splice(questionIndex, 1, selectedQuestion);

        setQuizQuestions([...quizQuestions]);

        editExamOptionToState(questionIndex, optionState, optionIndex);
    }

    const pickCorrectOption = async (answerId: string, questionId: string, questionIndex: number) => {

        try {

            if (!answerId) return;

            const data: ICorrectAnswerDto = {
                quizAnswerId: answerId,
                quizQuestionId: questionId
            }

            await correctAnswerService(data);

            const question = quizQuestions.at(questionIndex) as IQuizQuestionState;

            quizQuestions.splice(questionIndex, 1, { ...question, isAnswerSelected: true, answerId });

            setQuizQuestions([...quizQuestions]);

            setExamQuestionCorrectAnswerToStateService(questionIndex, answerId);

        } catch (error) {
            toast({
                title: "Exam Option",
                description: "Something went Wrong. Try again later",
                status: "error",
            })
        }
    }

    const setQuizPassMark = (value: number) => {

        setPassMark(value);

        passMarkAction$.next(value)
    }



    return (
        <>
            <Box mt={2}>

                <Flex justifyContent="space-between" mb={4}>
                    <Text fontSize={13} color="brand.2" ></Text>
                    <Button color="danger.100" onClick={() => removeQuiz()} borderColor="danger.100" variant="outline" type="submit" size="sm" fontSize={11} rightIcon={<FaTimes />} >Remove Exam</Button>
                </Flex>

                {
                    quizQuestions.map((eachQuestion, questionIndex) =>

                        <Box key={questionIndex} p={6} boxShadow="0px 0px 4px rgba(0, 0, 0, 0.15)" px={10} mb={8}>

                            <Box >
                                <HStack justifyContent={"start"} alignItems={"start"} spacing={4} >

                                    <Text color={"brand.subtitle"} > {questionIndex + 1}. </Text>

                                    <Box width="100%">
                                        {
                                            eachQuestion.isSaved && !eachQuestion.showEditQuestion &&


                                            <Flex justifyContent="space-between" mb={4}>

                                                <Text fontSize={15} color="brand.subtitle" > {eachQuestion.question} </Text>
                                                <HStack color="brand.subtitle" >
                                                    <IconButton
                                                        variant="ghost"
                                                        aria-label="pencil"
                                                        color="brand.2"
                                                        borderRadius={20}
                                                        size="xs"
                                                        icon={<BsPencilSquare />}
                                                        onClick={() => editQuestion(questionIndex, eachQuestion.quiz_question_id)}
                                                    />
                                                    <IconButton
                                                        variant="ghost"
                                                        aria-label="trash"
                                                        color="brand.2"
                                                        borderRadius={20}
                                                        size="xs"
                                                        icon={<VscTrash />}
                                                        onClick={() => deleteQuestion(questionIndex)}
                                                    />
                                                </HStack>

                                            </Flex>}
                                        {
                                            !eachQuestion.isSaved && !eachQuestion.showEditQuestion &&
                                            <form onSubmit={formikQuestion.handleSubmit}>
                                                <HStack justifyContent={"center"} alignItems={"center"} mb={4}>
                                                    <FormControl isInvalid={(formikQuestion.errors.question && formikQuestion.touched.question) ? true : false} >
                                                        <Textarea
                                                            p={4}
                                                            fontSize={14}
                                                            size="sm"
                                                            minH="4rem"
                                                            placeholder={"Type Question " + firstLetterUpper(numWords(questionIndex + 1)) + "."}
                                                            name="question"
                                                            value={formikQuestion.values.question}
                                                            onChange={formikQuestion.handleChange}
                                                        />
                                                        <FormErrorMessage fontSize={12}> {formikQuestion.errors.question} </FormErrorMessage>
                                                    </FormControl>
                                                    <Flex>
                                                        <IconButton variant="ghost" type="submit" size="xs" isLoading={formikQuestion.isSubmitting} fontSize={22} color={"success.500"} aria-label="Search database" icon={<IoMdCheckmarkCircle />} />

                                                    </Flex>

                                                </HStack>

                                            </form>
                                        }
                                        {
                                            eachQuestion.showEditQuestion && eachQuestion.isSaved &&

                                            <form onSubmit={formikUpdateQuestion.handleSubmit}>
                                                <HStack justifyContent={"center"} alignItems={"center"} mb={4}>
                                                    <FormControl isInvalid={(formikUpdateQuestion.errors.question && formikUpdateQuestion.touched.question) ? true : false} >
                                                        <Textarea
                                                            p={4}
                                                            fontSize={14}
                                                            size="sm"
                                                            minH="4rem"
                                                            placeholder={"Type Question " + firstLetterUpper(numWords(questionIndex + 1)) + "."}
                                                            name="question"
                                                            value={formikUpdateQuestion.values.question}
                                                            onChange={formikUpdateQuestion.handleChange}
                                                        />
                                                        <FormErrorMessage fontSize={12}> {formikUpdateQuestion.errors.question} </FormErrorMessage>
                                                    </FormControl>
                                                    <Flex>
                                                        <IconButton variant="ghost" type="submit" size="xs" isLoading={formikUpdateQuestion.isSubmitting} fontSize={22} color={"success.500"} aria-label="Search database" icon={<IoMdCheckmarkCircle />} />

                                                    </Flex>

                                                </HStack>

                                            </form>


                                        }

                                        {
                                            eachQuestion.options?.map((eachOption, optionIndex) =>

                                                <Box key={optionIndex}>
                                                    {

                                                        eachOption.isSaved && !eachOption.showEditOption &&
                                                        <Flex justifyContent="space-between" mb={4}>

                                                            <HStack mb={3} spacing={4}  >

                                                                <Text color={"brand.subtitle"} > {String.fromCharCode(65 + optionIndex)}. </Text>

                                                                <Text fontSize={13} color="brand.subtitle" > {eachOption.option} </Text>
                                                                {/* <IconButton variant="ghost" type="submit" onClick={() => removeOption() } size="xs" fontSize={15} color={"brand.2"} aria-label="Search database" icon={<VscTrash />} /> */}

                                                            </HStack>
                                                            <HStack color="brand.subtitle" >
                                                                <IconButton
                                                                    variant="ghost"
                                                                    aria-label="pencil"
                                                                    color="brand.2"
                                                                    borderRadius={20}
                                                                    size="xs"
                                                                    icon={<BsPencilSquare />}
                                                                    onClick={() => editQuestionOption(questionIndex, optionIndex)}
                                                                />
                                                                <IconButton
                                                                    variant="ghost"
                                                                    aria-label="trash"
                                                                    color="brand.2"
                                                                    borderRadius={20}
                                                                    size="xs"
                                                                    icon={<VscTrash />}
                                                                    onClick={() => deleteQuestionOption(questionIndex, eachOption.quiz_answer_id, optionIndex)}
                                                                />
                                                            </HStack>
                                                        </Flex>

                                                    }

                                                    {
                                                        !eachOption.isSaved && !eachOption.showEditOption &&
                                                        <form onSubmit={formikAnswer.handleSubmit}>
                                                            <HStack mb={3} spacing={4} >

                                                                <Text color={"brand.subtitle"} > {String.fromCharCode(65 + optionIndex)}. </Text>

                                                                <FormControl isInvalid={(formikAnswer.errors.option && formikAnswer.touched.option) ? true : false} >
                                                                    <Input type="text" fontSize={13} name="option"  value={formikAnswer.values.option} onChange={formikAnswer.handleChange} placeholder={"Type Option " + String.fromCharCode(65 + optionIndex) + "."} size="sm" pl={6} />
                                                                    <FormErrorMessage fontSize={12}> {formikAnswer.errors.option} </FormErrorMessage>
                                                                </FormControl>

                                                                <Flex>
                                                                    <IconButton variant="ghost" type="submit" onClick={() => { formikAnswer.setFieldValue("quizQuestionId", eachQuestion.quiz_question_id); formikAnswer.setFieldValue('quizQuestionIndex', questionIndex); formikAnswer.setFieldValue('optionIndex', optionIndex); }} isLoading={formikAnswer.isSubmitting} size="xs" fontSize={22} color={"success.500"} aria-label="Search database" icon={<IoMdCheckmarkCircle />} />


                                                                </Flex>

                                                            </HStack>
                                                        </form>
                                                    }
                                                    {
                                                        eachOption.isSaved && eachOption.showEditOption &&
                                                        <form onSubmit={formikUpdateOption.handleSubmit}>
                                                            <HStack mb={3} spacing={4} >

                                                                <Text color={"brand.subtitle"} > {String.fromCharCode(65 + optionIndex)}. </Text>

                                                                <FormControl isInvalid={(formikUpdateOption.errors.option && formikUpdateOption.touched.option) ? true : false} >
                                                                    <Input type="text" fontSize={13} name="option"  value={formikUpdateOption.values.option} onChange={formikUpdateOption.handleChange} placeholder={"Type Option " + String.fromCharCode(65 + optionIndex) + "."} size="sm" pl={6} />
                                                                    <FormErrorMessage fontSize={12}> {formikUpdateOption.errors.option} </FormErrorMessage>
                                                                </FormControl>

                                                                <Flex>
                                                                    <IconButton variant="ghost" type="submit" onClick={() => { formikUpdateOption.setFieldValue("quizQuestionId", eachQuestion.quiz_question_id); formikUpdateOption.setFieldValue('quizQuestionIndex', questionIndex); formikUpdateOption.setFieldValue('optionIndex', optionIndex); }} isLoading={formikUpdateOption.isSubmitting} size="xs" fontSize={22} color={"success.500"} aria-label="Search database" icon={<IoMdCheckmarkCircle />} />


                                                                </Flex>

                                                            </HStack>
                                                        </form>
                                                    }
                                                </Box>
                                            )
                                        }

                                        <Flex justifyContent={"space-between"} alignItems={"center"} ml={7} >

                                            <Select width="28%" variant="outline" value={eachQuestion.answerId} placeholder="Select correct option" size="sm" onChange={(e) => pickCorrectOption(e.target.value, eachQuestion.quiz_question_id as string, questionIndex)} fontSize={11} color="brand.2" borderColor={"brand.2"} >
                                                {
                                                    eachQuestion.options?.filter(e => e.isSaved).map((each, _) => <option key={_} value={each.quiz_answer_id}> {String.fromCharCode(65 + _)} </option>)
                                                }
                                            </Select>

                                            <HStack cursor={"pointer"} onClick={() => addAnswerOption(questionIndex)} fontSize={11} spacing={1} color="brand.2" fontWeight={"semibold"} > <Box as="span" fontSize={16} > <IoMdAddCircleOutline />  </Box> <Text>Add more option</Text>  </HStack>

                                        </Flex>

                                    </Box>
                                </HStack>

                            </Box>

                        </Box>

                    )
                }

                <Flex justifyContent={"space-between"} alignItems={"center"}>

                    <Button colorScheme="brand" onClick={addQuestion} size="sm" fontSize={11} borderRadius={0}>Add Question</Button>

                    <Box width="18%" display={"flex"} flexDirection={"column"} alignItems={"center"} >

                        <Text mb={2} fontSize={11} color="brand.2" fontWeight={"semibold"}>Set Pass Mark</Text>

                        <Slider aria-label="slider-ex-1" defaultValue={passMark} colorScheme={"brand"} mb={1} onChange={setQuizPassMark} >
                            <SliderTrack borderRadius={0} >
                                <SliderFilledTrack />
                            </SliderTrack>
                            <SliderThumb backgroundColor="brand.2" >
                                <Box as="span" width={"60%"} height={"60%"} borderRadius={20} backgroundColor="white" ></Box>
                            </SliderThumb>
                        </Slider>

                        <Text mb={2} fontSize={11} color="brand.2" fontWeight={"semibold"}> {passMark} % </Text>
                    </Box>

                </Flex>

            </Box>
        </>
    );

}