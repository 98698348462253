import {Flex, Container, Text, Link} from '@chakra-ui/react';
import {StudentRoutes} from '../../pages/Student/routes';
import {Link as ReactLink, Route, Switch, useLocation, useRouteMatch, useHistory} from 'react-router-dom';
import CourseAnnouncement from './CourseEnrollment/CourseAnnouncement';
import React, { useEffect, useState } from 'react';
import { ICourseWithEnrollment } from '../../models/course.model';
import { getStudentCourseEnrollment } from '../../services/student/course-http.service';
import { ScreenLoader } from '../Common/ScreenLoader';
// import Assessment from '../../pages/Student/Assessement';
// import Discussion from '../../pages/Student/Discussion';
// import Progress from '../../pages/Student/Progress';
// import WatchLesson from '../../pages/Student/WatchLesson';
// import EnrollCourse from '../../pages/Student/EnrollCourse';
// import Header from '../Common/Header';
// import Discussion from './CourseEnrollment/Discussion';
// import Assessment from '../../pages/Student/Assessement';
// import CourseProgress from '../../pages/Student/CourseProgress';
// import Discussion from '../../pages/Student/Discussion';


const CoursePage: React.FC<any> = () => {

    const [isLoading, setIsLoading] = useState(true);

    let { path, params } = useRouteMatch<{courseId: string}>();
    
    const [courseData, setCourseData] = useState<ICourseWithEnrollment>();

    let location = useLocation<ICourseWithEnrollment>();

    let history = useHistory();

    useEffect(() => {

        let courseData = location.state;
        
        if(courseData){

            setCourseData(courseData);

            setIsLoading(false);
            
        }else{

            let {courseId} = params;
    
             getStudentCourseEnrollment(courseId)
            .then(data => {
        
                setIsLoading(false);

                setCourseData(data)
            })
            .catch(e => {
                history.goBack();
            })
        }
        
    }, []);

    return (
        <>
            <Container maxWidth="18%" pl={0} minHeight="100%" boxShadow="base" bg="white" position="fixed">
                <Flex direction="column" fontSize={13} color="brand.bodyText"  pt={12} pl={14}>
                    <Link mb={8}  display="inline-flex" alignItems="center" as={ReactLink} to={StudentRoutes.Course.InfoLink(courseData?.course?.course_id)} > <Text pl={3}>Announcements</Text></Link>
                    {/* <Link mb={8}  display="inline-flex" alignItems="center" as={ReactLink} to={StudentRoutes.Course.Explore} >  <Text pl={3}>Progress</Text></Link> */}
                    {/* <Link mb={8}  display="inline-flex" alignItems="center" as={ReactLink} to={StudentRoutes.Course.Fetch} > <Text pl={3}>Discussions</Text></Link> */}
                    {/* <Link mb={8}  display="inline-flex" alignItems="center" as={ReactLink} to={StudentRoutes.CourseLesson} >  <Text pl={3}>Assessements</Text></Link> */}
                    {/* <Link mb={8}  display="inline-flex" alignItems="center" as={ReactLink} to={StudentRoutes.Invoice} >  <Text pl={3}>Messages</Text></Link> */}
                    {/* <Link mb={8}  display="inline-flex" alignItems="center" as={ReactLink} to={StudentRoutes.Billing} > <Text pl={3}>Course Info</Text></Link> */}
                </Flex>
            </Container>

            <Container maxW="container.xl" ml={16} mr={16} pt={12}>
                {
                    isLoading?

                    <ScreenLoader />

                    :
                    
                    <Switch>
                        <Route path={path}>
                            <CourseAnnouncement courseData={courseData} />
                        </Route>

                    </Switch> 
                }
                                    

            </Container>
        </>
    );
}

export default CoursePage;