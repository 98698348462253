import { Box, Button, Container, Flex, HStack, Image, Input, InputGroup, Text, InputRightElement, Avatar, AvatarGroup } from "@chakra-ui/react";
import { Table, Thead, Tbody, Tr, Th, Td} from "@chakra-ui/react"
import { useHistory } from "react-router-dom";
import {TrainingMgrRoutes} from './routes';
import { RiSearch2Line } from "react-icons/ri";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { ChangeEvent, useEffect, useState } from "react";
import { fetchTMGroupDiscussions } from "../../services/group.service";
import { IGroupDiscussionList } from "../../models/group.model";
import { groupDiscussionListUtil } from "../../utils/group.util";
import { ScreenLoader } from "../../components/Common/ScreenLoader";
import { EmptyGroup } from "../../components/Instructor/EmptyState/Groups";
import { EmptySearchResult } from "../../components/Common/EmptySearchResult";
// import { BsThreeDotsVertical } from "react-icons/bs";
// import { IoMdCheckmarkCircle } from "react-icons/io";
// import { FaTimesCircle } from "react-icons/fa";
// import { Status } from "../../components/Common/Status";


const AllGroupDiscussion = () => {

    let {replace, push} = useHistory();

    const [discussions, setDiscussions] = useState<IGroupDiscussionList[]>([]);
    
    const [filteredDiscussions, setFilteredDiscussions] = useState<IGroupDiscussionList[]>([]);

    const [isLoading, setIsLoading] = useState(true);

    const [isSearchEmpty, setIsSearchEmpty] = useState(false);

    useEffect(() => {

        fetchTMGroupDiscussions()
        .then((data) => {
            setFilteredDiscussions(groupDiscussionListUtil(data));
            setDiscussions(groupDiscussionListUtil(data));
            setIsLoading(false)
        })
        .catch((err) => err)

    }, [])

    const handleSearchChange  = (event: ChangeEvent<HTMLInputElement>) => {
        
        let searchTerm = event.target.value.toLowerCase();
        
        let filtered = discussions.filter(each => each.topic.toLowerCase().includes(searchTerm) );
        
        setFilteredDiscussions(filtered);
        
        if(filtered.length > 0)
            setIsSearchEmpty(false);
        else
            setIsSearchEmpty(true)
    }


    return (

        <Container boxShadow="base" maxW="container.xl" bg="white" pt={3} pb={10} px={8} minHeight="70vh">

            {
               isLoading?
                
                <ScreenLoader />
                
                :
                
                discussions.length === 0 ? 
                
                <EmptyGroup createGroupView={() => push(TrainingMgrRoutes.Group.Create) } />

                :
                
                <Box>

                    <Flex justifyContent="space-between">
                        <Text pl={6} color="brand.3" fontSize={18} fontWeight="semibold"> All Group Discussions</Text>
                        <Button colorScheme="brand" leftIcon={<AiOutlineUsergroupAdd style={{'fontSize': '18'}} />} onClick={() => replace(TrainingMgrRoutes.Group.Create)} size="sm" fontSize={10}>NEW GROUP</Button>
                    </Flex>

                    <Flex justifyContent="space-between" alignItems="center" px={6} mt={6}>
                        <InputGroup color="brand.subtitle" bg="white" width="40%" >
                            <Input placeholder="Search all groups on this plaftorm" onChange={ e => handleSearchChange(e)} fontSize={12} size="sm" />

                            <InputRightElement h="1.9rem" pointerEvents="none" children={<RiSearch2Line size="0.9rem"  />} />
                        </InputGroup>
                    </Flex>

                    {
                        isSearchEmpty ? 

                        <EmptySearchResult />

                        :

                        <Table variant="simple" mt={5} >
                            <Thead fontWeight="normal" >
                                <Tr color="brand.subtitle" >
                                    <Th textTransform="none">Name</Th>
                                    <Th textTransform="none">Instructor</Th>
                                    <Th textTransform="none">Date Created</Th>
                                    <Th textTransform="none">Students</Th>
                                    <Th textTransform="none">Last Activity</Th>
                                    <Th textTransform="none">DIscussion</Th>
                                    <Th textTransform="none"></Th>
                                </Tr>
                            </Thead>

                            <Tbody>
                                {
                                    filteredDiscussions.map((each, index) => 
                                        <Tr key={index} fontSize={13} color="brand.subtitle">
                                            <Td>
                                                <Text fontSize={11}>{each.topic}</Text>                                                
                                            </Td>
                                            <Td>
                                                <HStack>
                                                    <Avatar size="xs" name={each.author} src={each.author_photo} />
                                                    <Text isTruncated fontSize={11}>{each.author}</Text>                                                
                                                </HStack>
                                            </Td>
                                            <Td>{each.lastActivity}</Td>
                                            <Td>
                                                <AvatarGroup size="xs" fontSize={10} color="brand.2" max={3}>
                                                    {
                                                        each.students.map((eachStudent, studentIndex) => 
                                                            <Avatar name={eachStudent.fullName} src={eachStudent.img_url} key={studentIndex} />
                                                        )
                                                    }
                                                </AvatarGroup>
                                            </Td>
                                            <Td> {each.lastActivity} </Td>
                                            <Td> {each.discussions} </Td>
                                            <Td> 
                                                <Box width="1.5em" justifyContent="center" alignItems="center" display="flex" height="1.5em" borderRadius={20} bgColor="brand.2" color="white" fontSize={11} > {each.unread} </Box>
                                            </Td>
                                        </Tr>
                                    )
                                }
                                
                            </Tbody>
                        </Table>
                    }
                    
                </Box> 
            }

        </Container>
   
   );
}

export default AllGroupDiscussion;