import { Box, Flex, Text, Image } from "@chakra-ui/react";
import book from '../../../img/book.svg';

export const MandatoryCourses: React.FC<any> = () => {

    return (
        <Flex minHeight="inherit"  justifyContent="center">
        
            <Flex direction="column" alignItems="center" justifyContent="center" >

                <Box color="brand.subtitle" mb={4}>
                    <Image height="2em" width="auto" src={book} />
                </Box>

                <Text mb={8} fontSize={12} color="brand.subtitle"> Mandatory course will appear here </Text>

            </Flex>
        </Flex>
    );
}