import { BridgeCounStatusDto } from "../../models/auth.model";
import {  ICourse, ICourseCompletedDto, ICourseReviewDto, ICourseTimeline, ICourseWithEnrollment, ICourseWithEnrollmentStatus, IEnrollment, IEnrollmentWithCourse, IEnrollmentWithCourseLessons, ILessonProgressDto, IProgress, IReview, StudentCourse } from "../../models/course.model";
import { IStudentDashboardStat } from "../../models/dashboard.model";
import { IInstructorReportData } from "../../models/enrolment/instructorReport.model";
import { HttpResponse } from "../../models/httpResponse.model";
import { IExam, IQuizTaken, IQuizTakenAnswer, IQuizTakenAnswerDto, IQuizTakenCompletedDto, IQuizTakenDto } from "../../models/quiz.model";
import {httpClient} from "../httpClient"


export const fetchStudentExploreCourses = async () => {

    let response = await httpClient.get<HttpResponse<StudentCourse[]>>("/student/courses/explore");

    return response.data.data;
}
export const fetchStudentEnrolment = async (url: string) => {

    let response = await httpClient.get<HttpResponse<IInstructorReportData[]>>(`hr/courses/all-courses${url}`);

    return response.data.data;
}

export const fetchStudentCourses = async () => {

    let response = await httpClient.get<HttpResponse<StudentCourse[]>>("/student/courses");

    return response.data.data;
}

export const getStudentCourse = async (courseId: string) => {

    let response = await httpClient.get<HttpResponse<ICourseWithEnrollment>>(`/student/course/${courseId}`);

    return response.data.data;
}

export const enrollCourseHttpService = async (courseId: string) => {

    let response = await httpClient.post<HttpResponse<IEnrollment>>(`/student/course/enroll`, {courseId});

    return response.data.data;
}

export const getStudentCourseEnrollment = async (courseId: string) => {

    let response = await httpClient.get<HttpResponse<ICourseWithEnrollment>>(`/student/course/${courseId}/enrollment`);

    return response.data.data;
}

export const getStudentCourseEnrollmentLessons = async (courseId: string) => {

    let response = await httpClient.get<HttpResponse<ICourseWithEnrollment>>(`/student/course/${courseId}/lessons`);

    return response.data.data;
}

export const fetchDashboardStat = async () => {

    let response = await httpClient.get<HttpResponse<IStudentDashboardStat>>("/student/dashboard/stats");

    return response.data.data;
}

export const fetchCourseEnrollmentStatus = async (limit?: number) => {

    let response = await httpClient.get<HttpResponse<ICourseWithEnrollmentStatus[]>>(`/student/courses/enrollment/status/${limit}`);

    return response.data.data;
}


export const fetchBridgeCountStatus = async (courseId: any) => {

    let response = await httpClient.get<HttpResponse>(`/student/courses/${courseId}/enrollment/progress`);
    return response.data.data;
}

export const fetchCompletedCourses = async (limit?: number) => {

    let response = await httpClient.get<HttpResponse<IEnrollmentWithCourse[]>>(`/student/courses/completed`);

    return response.data.data;
}

export const fetchMandatoryCourses = async (limit?: number) => {

    let response = await httpClient.get<HttpResponse<IEnrollmentWithCourseLessons[]>>(`/student/courses/mandatory`);

    return response.data.data;
}

export const submitLessonProgressService = async (data: ILessonProgressDto) => {

    let response = await httpClient.put<HttpResponse<IProgress>>(`/student/course/progress`, data);

    return response.data.data;
}

export const courseCompletedService = async (data: ICourseCompletedDto) => {

    let response = await httpClient.put<HttpResponse<IEnrollment>>(`/student/course/complete`, data);

    return response.data.data;
}

export const checkCourseCompletedService = async (enrollmentId: string) => {

    let response = await httpClient.get<HttpResponse<IEnrollment>>(`/student/course/enrollment/${enrollmentId}`);

    return response.data.data;
}

export const fetchCourseTimeline = async () => {

    let response = await httpClient.get<HttpResponse<ICourseTimeline[]>>(`/student/courses/timeline`);

    return response.data.data;
}


export const submitCourseReviewService = async (data: ICourseReviewDto) => {

    let response = await httpClient.post<HttpResponse<IReview>>(`/student/course/review`, data);

    return response.data;
}


export const submitQuizTakenService = async (data: IQuizTakenDto) => {
    let response = await httpClient.post<HttpResponse<IQuizTaken>>(`/student/course/lessons/quiz-taken`, data);
    return response.data.data;
}

export const submitQuizQuestionAnswer = async (data: IQuizTakenAnswerDto) => {
    let response = await httpClient.post<HttpResponse<IQuizTakenAnswer>>(`/student/course/lessons/quiz-answer`, data);

    return response.data.data;
}

export const submitQuizTakenCompleted = async (data: IQuizTakenCompletedDto) => {

    let response = await httpClient.patch<HttpResponse<IQuizTaken>>(`/student/course/lessons/quiz-completed`, data);

    return response.data.data;
}


export const fetchCourseExam = async (courseID:string) => {
    let response = await httpClient.get<HttpResponse<ICourse>>(`/student/course/${courseID}/exam`);
    return response.data.data;
}
