import { Select } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { fetchCoursesListTM } from "../../services/trainingMgr/course-http.service";


export const AllCourseSelectBox: React.FC<{courseId: string, setCourseId: any}> = ({courseId, setCourseId, ...rest }) => {

    const [courses, setCourses] = useState<{title: string, course_id: string}[]>([]);

    useEffect(() => {
        fetchCoursesListTM()
        .then((data) => {
            let courses: {title: string, course_id: string}[] = data.map(({title, course_id, }) => ({title, course_id}) )

            setCourses(courses)
        })
        .catch((err) => err)
        
    }, []);

    
    return (
        <>
            <Select {...rest}  variant="outline" bg="inputText.500" placeholder="Select Course" name="course" value={courseId} size="sm" onChange={setCourseId} fontSize={12} color="brand.subtitle" >
                {
                    courses.map( each => <option key={each.course_id} value={each.course_id}> {each.title} </option>)
                }
            </Select>
        </>
    );
}