import { Box, Container, Flex, HStack, Select, Tag, Text, VStack, Spacer, Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react"
import { useEffect, useState } from "react";
import { BsStarFill } from "react-icons/bs";
import { EmptyAnalytics } from "../../components/Instructor/EmptyState/Analytics";
import { EmptyCourseStatus } from "../../components/Instructor/EmptyState/courseStatus";
import { EmptyRating } from "../../components/Instructor/EmptyState/Rating";
import { EmptyRevenue } from "../../components/Instructor/EmptyState/Revenue";
import { IUserInstructorDashboard } from "../../models/auth.model";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Sector, Cell, BarChart, Bar } from 'recharts';
import { renderCustomizedLabel } from "../HrAdmin/UserDashboard";
import { ScreenLoader } from "../../components/Common/ScreenLoader";
import { getDashboard } from "../../services/instructor/courses-http.service";
import { hrTrendProgressDataUtil, hrTrendVisitDataUtil, pieDataUtil } from "../../utils/course.util";
import { ChevronDownIcon } from "@chakra-ui/icons";




const COLORS = [ 'blue','red', 'purple', 'yellow', 'green',];
const Dashboard = () => {
    const [dashboard, setDashboard] = useState<IUserInstructorDashboard>();
    const[dashboardtopcourses, setdashboardtop_courses] = useState<any[]>()

    const [courseStatus, setCourseStatus] = useState<any[]>([]);

    const [analytics, setAnalytics] = useState<any[]>([]);

    const [revenue, setRevenue] = useState<any[]>([]);

    const [rating, setRating] = useState<any[]>([]);

    const [isLoading, setIsLoading] = useState(true);
    const [operationI, setOperationTypesI] = useState('')
    const [reload, setReload] = useState(true)


    useEffect(() => {
        getDashboard()
            .then((data) => {
                console.log(data)
                setdashboardtop_courses(data.top_courses)
                setAnalytics(hrTrendVisitDataUtil(data.enrolled_per_month, data.visits_per_month));
                setRating(!data.ratings || data.ratings === 0 ? [] : pieDataUtil(data.ratings))
                setRevenue(hrTrendProgressDataUtil(data.per_month_completed_courses, data.per_month_in_progress_courses));
                setDashboard(data);
                setIsLoading(!isLoading);

            })
            .catch((err) => err)

    }, []);
    function updateOperation(operation_type: string): void {
        if (operation_type === 'category') {
            setOperationTypesI('category');
    
          if(dashboard){
            
            let sortedCourses = dashboard.top_courses.sort((a, b) => (a.category.title > b.category.title) ? 1 : (a.category.title === b.category.title) ? 0 : -1)
            setdashboardtop_courses(sortedCourses)
            console.log(sortedCourses)
          }

        }
        else if (operation_type === 'enrollments') {
            setOperationTypesI('enrollments');
            if(dashboard){
                let sortedCourses = dashboard.top_courses.sort((a, b) => (a.courseEnrollments < b.courseEnrollments) ? 1 : (a.courseEnrollments === b.courseEnrollments) ? 0 : -1)
                setdashboardtop_courses(sortedCourses)
                console.log(sortedCourses)
       
                
              }
        }
        else if (operation_type === 'visit') {
            setOperationTypesI('visit');
         
            if(dashboard){
                let sortedCourses = dashboard.top_courses.sort((a, b) => (a.visits < b.visits) ? 1 : (a.visits === b.visits) ? 0 : -1)
                setdashboardtop_courses(sortedCourses)
                console.log(sortedCourses)
       
              }
        }
        else if (operation_type === 'ratings') {
            setOperationTypesI('ratings');
         
            if(dashboard){
                let sortedCourses = dashboard.top_courses.sort((a, b) => (a.courseReviews < b.courseReviews) ? 1 : (a.courseReviews === b.courseReviews) ? 0 : -1)
                setdashboardtop_courses(sortedCourses)
                console.log(sortedCourses)
       
              }
        }
        else if (operation_type === 'courses') {
            setOperationTypesI('courses');
    
            if(dashboard){
                let sortedCourses = dashboard.top_courses.sort((a, b) => (a.title > b.title) ? 1 : (a.title === b.title) ? 0 : -1)
                setdashboardtop_courses(sortedCourses)
                console.log(sortedCourses)

              }
          
        }
      


    }


    useEffect(() => {
        // let url = `?page=${1}&limit=${limit}&search=&orderBy=${operationI}`
    
        // gettopInstructors(role_id.instructor, url).then((data) => {
        //   setTopInstructors(data.results)
        // })
      }, [operationI,reload])

    return (

        <Box>
            {
                isLoading ?
                    <ScreenLoader />

                    :

                    <>
                        <Box mb={6} color="brand.2" fontWeight="semibold">Instructor’s Dashboard</Box>

                        <Flex justifyContent="space-between" >

                            <Container px={14} py={2} boxShadow="base" bg="white" m={0} >
                                <VStack pb={3} pt={1}>
                                    <HStack spacing={3}>
                                        <Text color="brand.subtitle" fontSize={13}  >Total Courses</Text>
                                    </HStack>
                                    <Text color="brand.2" fontWeight="semibold" fontSize={30} >{dashboard?.total_courses}</Text>
                                </VStack>
                            </Container>

                            <Spacer mx={4} />
                            <Container px={14} py={2} boxShadow="base" bg="white" m={0} >
                                <VStack pb={3} pt={1}>
                                    <HStack spacing={3}>
                                        <Text color="brand.subtitle" fontSize={13}  >Total Visits</Text>
                                    </HStack>
                                    <Text color="brand.2" fontWeight="semibold" fontSize={30} >{dashboard?.total_visits}</Text>
                                </VStack>
                            </Container>

                            <Spacer mx={4} />

                            <Container px={14} py={2} boxShadow="base" bg="white" m={0} >
                                <VStack pb={3} pt={1}>
                                    <HStack spacing={3}>
                                        <Text color="brand.subtitle" fontSize={13}  >Student Enrolled</Text>
                                        <Tag colorScheme="tag" borderRadius={0} px={1} minHeight={0} lineHeight={1.5} fontSize={11} > +0</Tag>

                                    </HStack>
                                    <Text color="brand.2" fontWeight="semibold" fontSize={30} >{dashboard?.total_enrolled}</Text>
                                </VStack>
                            </Container>

                            <Spacer mx={4} />

                            <Container px={14} py={2} boxShadow="base" bg="white" m={0} >
                                <VStack pb={3} pt={1}>
                                    <Text color="brand.subtitle" fontSize={13} > Average Rating</Text>
                                    <Text color="brand.2" fontWeight="semibold" fontSize={30} >{Number(dashboard?.average_rating).toFixed(1)}</Text>
                                </VStack>
                            </Container>

                        </Flex>

                        <Box boxShadow="base" maxW="container.xl" bg="white" my={6} py={3} px={8}>
                            <Flex justifyContent="space-between" alignItems="center" px={6} mb={4}>
                                <Text color="brand.2" fontSize={15} fontWeight="semibold">
                                    Analytics
                                </Text>
                                {/* <Select width="20%" variant="outline" placeholder="Top Performing" size="sm" fontSize={12} color="brand.subtitle" />    */}
                            </Flex>
                            {
                                analytics.length === 0 ?

                                    <EmptyAnalytics />

                                    :
                                    <LineChart
                                        width={800}
                                        height={400}
                                        data={analytics}
                                        margin={{
                                            top: 5,
                                            right: 10,
                                            left: 10,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend verticalAlign="bottom" height={36} align="center" iconType="square" />
                                        <Line type="monotone" name="Total Visitors" dataKey="uv" stroke="#4E78F5" />
                                        <Line type="monotone" name="Student Enrolled" dataKey="pv" stroke="#2F2963" />
                                    </LineChart>
                            }
                        </Box>

                        <HStack justifyContent="space-between" my={6} spacing={8} >

                            <Box boxShadow="base" maxW="container.xl" bg="white" py={3} px={8} width="100%">
                                <Flex justifyContent="space-between" alignItems="center" px={6} mb={4}>
                                    <Text color="brand.2" fontSize={15} fontWeight="semibold">
                                        Course Activity
                                    </Text>
                                    {/* <Select width="20%" variant="outline" placeholder="Top Performing" size="sm" fontSize={12} color="brand.subtitle" />    */}
                                </Flex>
                                {
                                    revenue.length === 0 ?

                                        <EmptyRevenue />

                                        :
                                        <BarChart
                                            width={500}
                                            height={300}
                                            data={revenue}
                                            margin={{
                                                top: 5,
                                                right: 10,
                                                left: 5,
                                                bottom: 5,
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend verticalAlign="bottom" height={36} align="center" iconType="square" />
                                            <Bar dataKey="pv" name="In Progress" fill="#8884d8" />
                                            <Bar dataKey="uv" name="Completed" fill="#82ca9d" />
                                        </BarChart>
                                }
                            </Box>

                            <Box boxShadow="base" maxW="container.xl" bg="white" py={1} px={8} width="100%">
                                <Flex justifyContent="space-between" alignItems="center" px={6} mb={1}>
                                    <Text color="brand.2" fontSize={15} fontWeight="semibold">
                                        Ratings
                                    </Text>
                                    {/* <Select width="20%" variant="outline" placeholder="Top Performing" size="sm" fontSize={12} color="brand.subtitle" />    */}
                                </Flex>

                                {
                                    rating.length === 0 ?

                                        <EmptyRating />

                                        :
                                        <Box pb='30px'>
                                            <PieChart width={300} height={300}>
                                                <Pie
                                                    data={rating}
                                                    cx="50%"
                                                    cy="50%"
                                                    labelLine={false}
                                                    label={renderCustomizedLabel}
                                                    outerRadius={80}
                                                    innerRadius={40}
                                                    fill="#8884d8"
                                                    dataKey="value"
                                                >
                                                    {rating.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                    ))}
                                                </Pie>
                                                <Legend verticalAlign="bottom" height={36} align="center" iconType="square" />
                                            </PieChart>
                                        </Box>
                                }
                            </Box>

                        </HStack>

                        <Container boxShadow="base" maxW="container.xl" bg="white" my={6} py={3} px={8}>

                            <Flex justifyContent="space-between" alignItems="center" px={6} mb={4}>
                                <Text color="brand.2" fontSize={15} fontWeight="semibold">
                                    Course Status
                                </Text>

                                <Menu>
                                    <MenuButton
                                        as={Button}
                                        rightIcon={<ChevronDownIcon />}
                                        colorScheme="##7883A7"
                                        variant={'outline'}
                                        size="sm"
                                        fontSize={12}
                                    >
                                        {operationI === 'courses'
                                            ? 'By Course'
                                            : operationI === 'category'
                                                ? 'By Category'
                                                : operationI === 'enrollments'
                                                    ? 'By Enrolment'
                                                    : operationI === 'ratings'
                                                        ? 'By Rating'
                                                        :operationI === 'visit'
                                                        ? 'By Visit' :
                                                         'By Course'}
                                    </MenuButton>
                                    <MenuList fontSize={12}>
                                        <MenuItem onClick={() => updateOperation('courses')}>
                                            By Course
                                        </MenuItem>
                                        <MenuItem onClick={() => updateOperation('category')}>
                                            By Category
                                        </MenuItem>

                                        <MenuItem onClick={() => updateOperation('enrollments')}>
                                            By Enrolment
                                        </MenuItem>
                                        <MenuItem onClick={() => updateOperation('ratings')}>
                                            {' '}
                                            Rating
                                        </MenuItem>
                                        <MenuItem onClick={() => updateOperation('visit')}>
                                            Visit
                                        </MenuItem>
                                      
                                    </MenuList>
                                </Menu>
                            </Flex>

                            {
                                dashboard?.top_courses.length === 0 ?

                                    <EmptyCourseStatus />

                                    :

                                    <Table variant="unstyled" >
                                        <Thead fontWeight="normal" >
                                            <Tr color="brand.subtitle" >
                                                <Th textTransform="none">Course Title</Th>
                                                <Th textTransform="none">Category</Th>
                                                <Th textTransform="none">Enrollment</Th>
                                                <Th textTransform="none">Rating</Th>
                                                <Th textTransform="none">Visited</Th>
                                            </Tr>
                                        </Thead>


                                        <Tbody>
                                            {
                                               dashboardtopcourses && (
                                                dashboardtopcourses.map((each:any, index) =>
                                                <Tr key={index} fontSize={13} color="brand.subtitle">
                                                    <Td>{each.title ? each.title : 'Nil'}</Td>
                                                    <Td>{each.category?.title ? each.category.title: 'Nil'}</Td>
                                                    <Td>{each.courseEnrollments ? each.courseEnrollments:'Nil'}</Td>
                                                    <Td><Box display="inline-flex" alignItems="center"> <Text mr={1} color="brand.star"><BsStarFill /></Text>  {each.courseReviews} </Box> </Td>
                                                    <Td> {each.visits ? each.visits:'Nil'} </Td>
                                                </Tr>
                                            )
                                               )
                                            }

                                        </Tbody>
                                    </Table>
                            }

                        </Container>
                    </>
            }
        </Box>


    );
}

export default Dashboard;