import { Box } from "@chakra-ui/react";
import React from "react";
import { CourseActiveStatusModel } from "../../models/course_status.model";
import { firstLetterUpper } from "../../utils/firstLetterUpper.util";

export const CourseActiveStatus: React.FC<{startDate: string, endDate?: string}> = ({ startDate, endDate, ...rest }) => {

    const getStatus = (): string => {
        let status: string;
        let now = Date.now();
        let startDateTime = new Date(startDate).getTime();

        if(startDateTime > now) 
            status = CourseActiveStatusModel.UPCOMING

        else if(endDate){
            let endDateTime = new Date(endDate).getTime()
            if(now > endDateTime)
                status = CourseActiveStatusModel.EXPIRED
            else{
                let expiryDays = Math.ceil((endDateTime - now) / (1000*60*60*24));
                status = `${CourseActiveStatusModel.EXPIRES} in ${expiryDays} ${expiryDays > 1 ? 'days': 'day' }`
            }
            
        }else{
            status = CourseActiveStatusModel.ACTIVE
        }
        return status;
    }


    return (
        <Box border="1px" borderRadius={10} fontSize={10} px={0} borderColor="brand.2" color="brand.subtitle" display="flex" justifyContent="center">
            {
                firstLetterUpper(getStatus())
            }
        </Box>
    );
}