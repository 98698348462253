import { CancelTokenSource } from "axios";
import { IDepartment, IEachCourse, IEachDepartment, IHrActivityReport, IHrDashboard, IHrTopCourses, IHrTopInstructors, IHrTopStudents, IPaginatedUserDto, IUser, IUserDashboard } from "../../../models/auth.model";
import { ICategory, IPaginatedCourseDto, IPaginatedCourseReviewDto } from "../../../models/course.model";
import { HttpResponse } from "../../../models/httpResponse.model";
import { IUserCreateDto, IUserEditDto, IPaginatedAnyUserDto, IAnyUserList, toggleUsersStatus } from "../../../models/user.model";
import {httpClient, axios} from "../../httpClient"

export let cancleRequest: CancelTokenSource;

export const fetchEachCourse = async (category_id: string, ) => {
    let response = await httpClient.get<HttpResponse<IEachCourse>>(`/hr/departments/${category_id}/courses`);
    console.log(response, 'category response')
    console.log(category_id)
    return response.data.data;
} 