import { Box, Button, Container, Divider, Flex, Text } from "@chakra-ui/react";
import { useState } from "react";
import DebitCardTile from "../../components/Student/DebitCard_tile";
import { EmptyBilling } from "../../components/Student/EmptyState/Billing";
import cards from "../../data/cards.json";


const Billing = () => {

    const [billing, setBilling] = useState([]);

    return (
        <>
            <Container boxShadow="base" p={3}  maxW="container.xl"  mb={6} bg="white">
                <Text color="brand.3" fontSize={18} pl={6} fontWeight="semibold" >Billings </Text>
            </Container>

            <Container boxShadow="base" p={3}  maxW="container.xl"  mb={6} bg="white" minHeight="50vh">

                {
                    billing.length === 0 ?

                    <EmptyBilling />

                    :

                    <Box>
                        <Flex direction="row" justifyContent="space-between" px={6} alignItems="center" >
                            <Flex direction="column">
                                <Text color="brand.3" fontSize={18} fontWeight="semibold" >Payment Methods</Text>
                                <Text color="brand.subtitle" fontSize={12}>The primary payment method is used by default</Text>

                            </Flex>
                            <Button size="xs" bg="#19CB98" color="white" mr={6} fontSize={10}  >ADD CARD</Button>
                        </Flex>

                        <Divider mt={3} orientation="horizontal" color="#D5D5D5" border="1px"  />

                        <Box ml={8} mt={3} mb={9}>

                            {cards.map((each, index) => 

                                <DebitCardTile key={index} data={each}/>

                            )}
                        </Box>
                    </Box>
                }

            </Container>
        </>
    );
}

export default Billing;