import { Box, Button, Flex, Text, Image } from "@chakra-ui/react";
import { useHistory } from "react-router";
import book from '../../../img/book.svg';
import { StudentRoutes } from "../../../pages/Student/routes";


export const CourseEnrolled: React.FC<any> = () => {

    const history = useHistory();
    
    return (
        <Flex minHeight="inherit"  justifyContent="center">
        
            <Flex direction="column" alignItems="center" justifyContent="center" >

                <Box color="brand.subtitle" mb={6}>
                    <Image height="3em" width="auto" src={book} />
                </Box>

                <Text mb={4} fontSize={13} color="brand.subtitle"> No enrolled course. Explore available course below </Text>

                <Button colorScheme="brand" size="sm" px={8} borderRadius={0} onClick={() => history.push(StudentRoutes.Course.Explore)} fontSize={11}>Explore</Button>

            </Flex>
        </Flex>
    );
}