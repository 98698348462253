import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton, Text, Box, useToast, FormControl, FormLabel, InputGroup, InputLeftElement, Input, FormErrorMessage, Spacer, Flex, Button, Checkbox, Center, Select, HStack, Portal, useDisclosure
} from "@chakra-ui/react"
import { IUser, IUserLevel, IUserRank } from "../../models/auth.model";

import { useFormik } from "formik";
import { editUserProfile, getUploadableUnit } from "../../services/hr/users-http.service";
import { IoMdCall, IoMdContact, IoMdMail } from "react-icons/io";
import { MultiSelect } from "../Common/MultiSelect";
import { IMultiSelect } from "../../models/course.model";
import { useEffect, useRef, useState } from "react";
import { fetchCategories, fetchUserLevel, fetchUserRank } from "../../services/shared.service";
import { HiUserAdd } from "react-icons/hi";
import { FaTimesCircle } from "react-icons/fa";
import { ICreateGroup, IUserGroupSelect } from "../../models/group.model";
import { createGroupTmService } from "../../services/group.service";
import { useHistory } from "react-router-dom";
import { UsersListModal } from "../Common/UsersListModal";
import { AllCourseSelectBox } from "../Common/AllCoursesSelectionBox";
import { addMembersToDepartment, createNewDepartment, editDepartment, fetchAllUsers } from "../../services/hr/department/department.service";


export const EditDepartmentModal: React.FC<any> = ({ isOpenEditDept, onClosedEdit, isEditDept = false, deptTitle, categoryId, reloadData }: { isEditDept: boolean, isOpenEditDept: any,deptTitle: any, onClosedEdit: any,categoryId: any, reloadData: any }) => {
    const toast = useToast();
    const cancelRef = useRef().current;
    const [members, setMembers] = useState<IUser[]>([]);
    const [users, setUsers] = useState<IUser[]>([]);
    const { onOpen, isOpen, onClose } = useDisclosure();
    const history = useHistory();
    const [courseId, setCourseId] = useState<string>();
    const [uploadableUnit, setUploadableUnit] = useState<IMultiSelect[]>([]);
    const [levels, setLevels] = useState<IUserLevel[]>([]);
    const [level, setLevel] = useState<any>("");
    const [selectedUnit, setSelectedUnit] = useState<IMultiSelect[]>([]);
    const [ranks, setRanks] = useState<IUserRank[]>([]);
    

   

    



    const formik = useFormik({
        validate: (values: any) => {
            const errors: any = {};
            if (!values.departmentName) {
                errors.departmentName = 'Department Name is required'
            }
            return errors;
        },
        initialValues: {
            departmentName: `${deptTitle}`,
        },
        onSubmit: async (values) => {

            try {

                let departmentData: any = {
                    title: values.departmentName,
                    description: 'Editing department',
                    categoryId: categoryId
                }
                

                const department = await editDepartment(departmentData);
                //trying something
              
                if (department) {
                    toast({
                        title: "Department Setup",
                        description: department.message,
                        status: "success",
                        duration: 2000,
                        onCloseComplete: () => onClosedEdit()
                    })

                }

            } catch (error) {
                toast({
                    title: "Department Setup",
                    description: "Something went Wrong. Try again later",
                    status: "error",
                })
            }
        }
    })

   

    return (
        <>

            <Modal isOpen={isOpenEditDept && isEditDept} onClose={onClosedEdit} scrollBehavior="outside" closeOnOverlayClick={true} size="lg">
                <ModalOverlay />
                <ModalContent borderRadius={5} px={6} >
                    <ModalHeader >
                       { <Text fontSize={13} pb={3} textTransform="uppercase" pl={3} color="brand.2" >Edit {deptTitle}</Text>}
                    </ModalHeader>
                    <ModalCloseButton size="sm" />
                    <ModalBody pb={6}>

                        <Box mt={4}>

                            <form onSubmit={formik.handleSubmit} >

                                <FormControl>
                                    <FormLabel color="brand.subtitle" fontSize={13}>Department Name</FormLabel>
                                    <InputGroup>
                                        <InputLeftElement
                                            color="brand.subtitle"
                                            pointerEvents="none"
                                            children={<IoMdContact color="brand.subtitle" />}
                                        />
                                        <Input name="departmentName" id="departmentName" placeholder="Department Name" type="text" fontSize={12}  value = {formik.values.departmentName}  onChange={formik.handleChange} />
                                    </InputGroup>
                                    <FormErrorMessage fontSize={12} >{formik.errors.departmentName}</FormErrorMessage>
                                </FormControl>

                                <Flex mt={12} justifyContent="end">
                                    <HStack>
                                        <Button size="sm" px={6} fontSize={12} ref={cancelRef} onClick={()=> onClosedEdit()}>
                                            Cancel
                                        </Button>
                                        <Button colorScheme="brand" type="submit"

                                            size="sm" px={6} fontSize={12} > UPDATE</Button>
                                    </HStack>
                                </Flex>
                            </form>

                        </Box>



                    </ModalBody>

                    <ModalFooter>
                    </ModalFooter>
                </ModalContent>
               
            </Modal>


        </>
    )




}
