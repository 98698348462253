import { IUser } from "./auth.model";
import { IExam, IQuiz } from "./quiz.model";

export interface IPaginatedCourseDto {
    results: ICourse[],
    page_total: number,
    total: number
}

export interface IPaginatedReviewDto {
    results: IReviews[],
    page_total: number,
    total: number
}

export interface IPaginatedCourseReviewDto {
    results: ICourse,
    page_total: number,
    total: number
}
export interface ICourse{
    id: number,                                         
    course_id: string,                                          
    title: string,                                          
    description: string,                                           
    cover_image_url: string,                                          
    tags: string                                           
    welcome_message: string,
    exam: IExam,                                            
    congrat_message: string,                                            
    createdAt: string,
    startAt: string ,
    endAt: string ,
    created_by: string,
    price: number,
    updatedAt: string ,
    updated_by: string,
    deletedAt: string ,
    deleted_by: string,
    category?: ICategory,
    level?: ILevel,
    format?: IFormat,
    approvedAt?: string,
    approved_by?: number,
    user?: IUser,
    declinedAt?: string,
    topics?: ICourseTopic[],
    courseReviews?: IReview[],
    suspendedAt?: string,
    suspended_by?: number,
    accessibility?: IAccessibility
}

export interface IEnrollment{
    id: number,
    enrollment_id: string,
    enrolledAt: string,
    completedAt?: string,
    createdAt: string,
    updatedAt?: string,
    enrolled_by?: number
}

export interface IReview{
    review_id: number,
    rating: number,
    review: string,
    createdAt: string,
    updatedAt: string,
    flagged_by: string,
    isAnonymous: boolean,
    user?: IUser
}

export interface InstructorCourse extends ICourse{

    courseEnrollments: IEnrollment[],
    courseReviews: IReview[]
}

export interface StudentCourse extends ICourse{
    courseReviews: IReview[]
}

export interface ICourseList{
    title: string,
    live: boolean,
    expired: boolean,
    students: number,
    rating: string,
    earnings: number,
    completed: number,
    coverImage: string,
    courseId: string,
}

export interface IStudentCourseList{
    title: string,
    author?: string,
    rating: string,
    coverImage: string,
    price: number,
    star: number,
    count: number,
    courseId?: string
}

export interface ILesson {
    title: string,
    description?: string,
    course?: ICourse,
    article?: string,
    video_url?: string,
    audio_url?: string,
    doc_url?: string,
    duration?: number,
    created_by?: string,
    updated_by?: string,
    deletedAt?: string,
    deleted_by?: string,
    id?: number,
    lesson_id?: string,
    createdAt?: string,
    asset_id?: string,
    asset_file_name?: string,
    quiz?: IQuiz,
    exam?: IExam
}

export interface IProgress{
    id?: number,
    progress_id?: string,
    completedAt?: string,
    createdAt?: string,
    updatedAt: string,
    lesson?: ILesson,
    enrollment?: IEnrollment
}

export interface ICourseLesson extends ICourse{
    courseLessons: ILesson[]
}

export interface IEnrollmentCourse extends IEnrollment{
    courses: ICourseLesson,
    progresses: IProgress[]
}

export interface IStudent extends IUser{
    courseEnrollments: IEnrollmentCourse[]
}

export interface IStudentList{
    name: string,
    courses: number,
    progress: number,
    img_url: string | undefined,
    userId?: string,
}

export interface IReviews{
    review_id: number,
    rating: number,
    review: string,
    createdAt: string,
    updatedAt: string,
    flagged_by: string,
    course?: ICourse,
    isAnonymous: boolean,
    user?: IUser
}

export interface IReviewResponseDto{
    review_id: number,
    new_comment: string,
    isAnonymous: boolean,
}

export interface IReviewResponse{
    review_id: number,
    user?: IUser,
    comment: string,
    isAnonymous: boolean,
    review?: IReviews,
    updatedAt: string | null,
    flagged_by: string | null,
    createdAt: string,
    id: number
}

export interface IReviewList{
    id: number,
    name: string,
    img_url: string | undefined,
    title: string,
    comments: string,
    star: number
}

export interface ILevel{
    id: number,
    level_id: string,
    title: string,
    badge_url_link?: string
}

export interface IMultiSelect{
    label: string,
    value: string
}

export interface ICategory{
    id: number,
    category_id: string,
    title: string,
    description?: string,
    createdAt?: any,
    created_by?: any,
    updatedAt?: any,
    updated_by?: any,
    deletedAt?: any,
    deleted_by?: any
}

export interface IUserRoles{
    role_id: number,
    title: string,
    is_active: boolean,
    description?: string,
    createdAt?: any,
    created_by?: any,
    updatedAt?: any,
    updated_by?: any,
    deletedAt?: any,
    deleted_by?: any
}

export interface IFormat{
    id: number,
    format_id: string,
    title: string
}

export interface ICourseInfoFormData{
    categories: ICategory[],
    format: IFormat[],
    level: ILevel[]
}

export interface ICourseDto{
  courseId?: string,
  title: string,
  categoryId?: string,
  courseLevelId?: string,
  courseFormatId?: string,
  description?: string,
  startDate?: string,
  endDate?: string,
}

export interface ILessonDto{
  title: string,
  topicId: string,
  courseId: string,
  lessonId?: string,
  lessonNote?: string,
  video?: string,
  audio?: string,
  image?: string,
  doc?: string,
  article?: string,
  duration?: string,
  description?: string
}

export interface ILessonUpdateDto{
    lessonId: string,
    title: string
}

export interface ILessonArticleDto{
    lessonId: string,
    article: string,
}
export interface ILessonNoteDto{
    lessonId: string,
    note: string,
}

export interface ICourseTopicDto{
    courseId?: string,
    topicId?: string,
    title: string,
    description?: string
}

export interface ICourseTopic{
    title: string,
    description?: string,
    course?: ICourse,
    created_by?: string,
    updated_by?: string,
    deletedAt?: string,
    deleted_by?: string,
    id: number,
    topic_id: string,
    createdAt: string,
    updatedAt?: string,
    lessons?: ILesson[]
}

export interface IAccessibility{
    title: string,
    accessibility_id: string
}

export interface ICourseWithAuthor extends ICourse{
    user: IUser;
}

export interface ITrainingMgrCourseList{
    id: number,
    cover_image: string,
    title: string,
    author: string,
    img_url?: string,
    date_added: string,
    status?: string,
    approvedAt?: string,
    courseId?: string,
    suspendedAt?: string,
    declinedAt?: string,
    students?: IUser[],
    authorId?: string
}

export interface ICourseWithEnrollment{
    course?: ICourse,
    courseEnrollment? : IEnrollment,
    courseProgress?: IProgress[]
    totalLessonDuration?: {totalDuration: string}
}

export interface ICourseAssets{
    lessonId?: string,
    title: string,
    video_url?: string,
    article?: string,
}

export interface ICourseApproval{
    courseId: string,
    approve: boolean,
    message?: string,
    action?: string
}

export interface ICourseWithEnrollmentStatus extends IEnrollmentCourse{

}

export interface ICourseEnrollmentStatusList{
    courseId: string,
    title: string,
    coverImage: string,
    lessonCount: number,
    videosCount: number,
    statusPercentage: number
}

export interface ILessonProgressDto{
    enrollmentId: string,
    lessonId: string,
}

export interface ICourseCompletedDto{
    enrollmentId: string,
}

export interface IAssignCourseDto{
    courseId: string;
    type: string;
    userIds: string[]
}

export interface IEnrollmentWithUser extends IEnrollment{
    user: IUser
}

export interface ICourseWithAuthorAndStudents extends ICourse{
    user: IUser;
    courseEnrollments: IEnrollmentWithUser[]
}

export interface ICourseStatusList{
    courseId: string
    title: string;
    coverImage: string;
    author: string;
    star: number;
    rating: string;
    isLive: boolean;
    startAt?: string;
    endAt?: string;
    startDate?: string;
    endDate?: string
}

export interface ICourseWithStudents extends ICourse{
    courseEnrollments: IEnrollmentWithUser[]
}

export interface IInstructorCourseAssignList{
    id: number,
    cover_image: string,
    title: string,
    date_added: string,
    status?: string,
    approvedAt?: string,
    courseId?: string,
    suspendedAt?: string,
    students?: IUser[]
}

export interface IEnrollmentWithCourse extends IEnrollment{
    courses: ICourse,
    user?: IUser
}

export interface IEnrollmentWithCourseLessons extends IEnrollment{
    courses: ICourseLesson,
    user?: IUser
}

export interface IEnrollmentCourseWithUser extends IEnrollment{
    user: IUser,
    progresses: IProgress[]
}

export interface ICourseProgress extends ICourse{
    courseLessons: ILesson[],
    courseEnrollments: IEnrollmentCourseWithUser[]
}

export interface ICourseProgressList{
    name: string,
    img_url: string,
    progress: number,
    startDate: string,
    endDate: string,
    courseDuration: string,
}

export interface ICourseLessonWithUser extends ICourse{
    courseLessons: ILesson[],
    user: IUser
}

export interface ICourseTimeline extends IEnrollment{
    progresses: IProgress[],
    courses: ICourseLessonWithUser
}

export interface ILearningRules{
    id: number,
    learning_rule_id: string,
    message: string,
}

export interface ICourseReviewDto{
    courseId: string,
    review: string,
    rating: number,
    isAnonymous: boolean,
}

export enum CourseAssignTypes{
    STUDENT = "STUDENT",
    UNIT = "UNIT",
}

export interface IAssignCourseToUnitDto{
    courseId: string;
    unitId: string;
    rankIds: number[]
}