import { Box, Flex, Text } from "@chakra-ui/react";
import { BiBarChart } from "react-icons/bi";
import { RiBarChart2Line } from "react-icons/ri";

export const EmptyRevenue: React.FC<any> = () => {

    return (
        <Flex minHeight="inherit"  justifyContent="center">
        
            <Flex direction="column" alignItems="center" justifyContent="center" >

                <Box color="brand.subtitle" mb={3}>
                    <RiBarChart2Line fontSize={40} />
                </Box>

                <Text mb={4} fontSize={13} color="brand.subtitle"> No sales records </Text>
                
            </Flex>
        </Flex>
    );
}