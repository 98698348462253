import { Roles, ROLESMAP, RoutingRoles } from "../models/roles.model";

export const allowRoleAccess = (userRole: Roles, accessingRole: Roles): boolean => {

    let trimmedRole: RoutingRoles = userRole.trim().replace(' ', '_') as RoutingRoles;

    let roles: string[] = ROLESMAP[trimmedRole];

    let isValid = roles.includes(accessingRole);

    return isValid;
}