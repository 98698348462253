import { IGroupDiscussion, IGroupDiscussionList } from "../models/group.model";
import { Status as StatusModel } from '../models/status.model'


export const groupDiscussionListUtil = (data: IGroupDiscussion[] ): IGroupDiscussionList[] => {

    let groups = data.map( each => {

        let students = each.userGroups.map(student => ({fullName: student?.user?.full_name, img_url: student?.user?.photo_url ?? '' as string}) );

        let lastActivity = each.updatedAt?? each.createdAt

        return ({
            topic: each.title,
            author: each.user?.full_name,
            author_photo: each.user?.photo_url as string,
            students,
            discussions: each.groupDiscussions.length,
            lastActivity: (new Date(lastActivity).toDateString()),
            unread: 0,
        })
    })

    return groups;
}

export const groupStatusUtil = (approvedAt: string, suspendedAt: string) => {
    let status: string = ''
  
    if (!approvedAt && !suspendedAt) {
      status = StatusModel.PENDING
    }
  
    if (approvedAt && !suspendedAt) {
      status = StatusModel.ACTIVE
    }
  
    if (!approvedAt && suspendedAt) {
      status = StatusModel.SUSPENDED
    }
    return status
  }