import { Box, Text, HStack, AccordionItem, Accordion, Button, Flex, AccordionPanel, AccordionButton, IconButton, AccordionIcon, Select } from '@chakra-ui/react';
import { FormControl, Input, FormErrorMessage, useToast } from '@chakra-ui/react';
import { VscTrash } from 'react-icons/vsc';
import { BsPencilSquare } from 'react-icons/bs';
import { FiMenu } from 'react-icons/fi';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { courseLessonDeletionService, createExamService, deleteExamService, submitCourseLesson, submitCourseTopic, updateCourseLesson, updateCourseTopic } from '../../../services/instructor/courses-http.service';
import { ICourseTopicDto, ILessonDto, ILessonUpdateDto } from '../../../models/course.model';
import { AssetType, CourseFormat, ICourseInfoState, ILectureState, ILessonState, IQuizState } from '../../../models/create_course.model';
import { UploadContent } from './Upload/UploadContent';
import { FaTimes } from 'react-icons/fa';
import { ExamUpload } from './Upload/ExamUpload';
import { IQuizDto } from '../../../models/quiz.model';
import { getCourseIdService, getExamFromStateService, removeExamFromStateService, setExamToStateService } from '../../../services/instructor/course.service';


const Curriculum = ({ nextPage, setCurriculumOkay, ...rest }: { nextPage: any; setCurriculumOkay: any }) => {

    const toast = useToast();

    const [lectures, setLectures] = useState<ILectureState[]>([]);
    const [retestno, setRetestNo] = useState<string>()
    const [showAddLecture, setShowAddLecture] = useState<boolean>(false);

    const [showAddFinalExam, setShowAddFinalExam] = useState<boolean>(false);

    const [showAddExamQuestion, setShowAddExamQuestion] = useState<boolean>(false);

    const [isAddingExam, setIsAddingExam] = useState<boolean>(false);
    const [examLength, setExamLength] = useState<number>();
    const [courseFormat, setCourseFormat] = useState<string>();


    useEffect(() => {

        let curriculum = localStorage.getItem('curriculum');

        if (curriculum) {

            let parsedCurriculum = JSON.parse(curriculum);
            console.log(parsedCurriculum, 'curriculum')
            setLectures([...parsedCurriculum]);
        }

        let exam = getExamFromStateService()

        if (exam) {
            setShowAddFinalExam(true);

            setShowAddExamQuestion(true);
        }

    }, []);


    const hasExamFunc = (exam: number) => {
        setExamLength(exam)
        localStorage.setItem('no_of_exam', exam.toString())
        window.dispatchEvent(new Event('storageChange'));

    }
    const formikLecture = useFormik({
        initialValues: {
            lectureTitle: ''
        },
        validate: (values: any) => {
            const errors: any = {};
            if (!values.lectureTitle) {
                errors.lectureTitle = 'Lecture Title is required';
            }
            return errors;
        },
        onSubmit: async (values) => {
            try {
                let courseId = localStorage.getItem("course-id") || "";

                let courseLecture: ICourseTopicDto = {
                    title: values.lectureTitle,
                    courseId: courseId
                }

                let courseTopic = await submitCourseTopic(courseLecture);

                let lectureState: ILectureState = {
                    topicId: courseTopic.topic_id,
                    title: values.lectureTitle,
                    showAddLessonField: false,
                    showEditLectureField: false,
                    lessons: []
                }
                console.log(lectureState.topicId, "topic id")

                if (courseTopic) {
                    toast({
                        title: "Lecture Curriculum",
                        description: "Lecture added successfully",
                        status: "success",
                    })
                }

                setLectures([...lectures, lectureState])

                setShowAddLecture(false)

                let curriculum = [...lectures, lectureState];

                localStorage.setItem('curriculum', JSON.stringify(curriculum));

                formikLecture.resetForm();


            } catch (error) {
                toast({
                    title: "Course Curriculum",
                    description: "Something went Wrong. Try again later",
                    status: "error",
                })
            }
        }
    })




    const formikUpdateLecture = useFormik({
        initialValues: {
            lectureTitle: '',
            lectureIndex: -1
        },
        validate: (values: any) => {
            const errors: any = {};
            if (!values.lectureTitle) {
                errors.lectureTitle = 'Lecture Title is required';
            }
            return errors;
        },
        onSubmit: async (values) => {
            try {

                let selectedLecture = lectures.at(values.lectureIndex) as ILectureState;

                let courseLecture: ICourseTopicDto = {
                    title: values.lectureTitle,
                    topicId: selectedLecture?.topicId as string
                }

                console.log(courseLecture, "this is course lecture")

                let updatedCourseTopic = await updateCourseTopic(courseLecture);

                if (updatedCourseTopic) {
                    toast({
                        title: "Lecture Curriculum",
                        description: "Lecture updated successfully",
                        status: "success",
                    })
                }

                lectures.splice(values.lectureIndex, 1, { ...selectedLecture, title: updatedCourseTopic.title, showEditLectureField: false });

                setLectures([...lectures]);

                localStorage.setItem('curriculum', JSON.stringify(lectures));

                formikUpdateLecture.resetForm();

            } catch (error) {
                toast({
                    title: "Course Curriculum",
                    description: "Something went Wrong. Try again later",
                    status: "error",
                })
            }
        }
    })

    const formikLesson = useFormik({
        initialValues: {
            lectureIndex: 0,
            lessonTitle: '',
            topicId: '',
            duration: ''
        },
        validate: (values: any) => {
            const errors: any = {};
            if (!values.lessonTitle) {
                errors.lessonTitle = 'Lesson Title is required';
            }
            return errors;
        },
        onSubmit: async (values) => {
            console.log(values.duration, 'this is time')
            try {
                let courseId = localStorage.getItem("course-id") || "";
                const [hours, minutes] = values.duration.split(':').map(Number);

                const totalSeconds = hours * 3600 + minutes * 60;

                let courseLesson: ILessonDto = {
                    courseId: courseId,
                    topicId: values.topicId,
                    title: values.lessonTitle,
                    duration: totalSeconds.toString()
                }


                let courseLessonResponse = await submitCourseLesson(courseLesson);

                let lessonState: ILessonState = {
                    lessonId: courseLessonResponse.lesson_id,
                    title: courseLessonResponse.title,
                    showEditLessonField: false,
                }

                if (courseLessonResponse) {
                    toast({
                        title: "Lesson Curriculum",
                        description: "Lesson added successfully",
                        status: "success",
                    })
                }

                saveLessonToState(values.lectureIndex, lessonState);

                formikLesson.resetForm();

            } catch (error) {
                toast({
                    title: "Course Curriculum",
                    description: "Something went Wrong. Try again later",
                    status: "error",
                })
            }
        }
    })

    const addLecture = () => {

        let storedData = localStorage.getItem('course-info');

        if (storedData) {
            let parsedData: ICourseInfoState = JSON.parse(storedData);

            if (parsedData.courseFormatTitle.toLowerCase() === CourseFormat.MANDATORY) {

                let lecture = lectures.at(lectures.length - 1);

                if (!lecture) {
                    setShowAddLecture(true);
                    return;
                }

                let quizes = lecture?.lessons.filter((each: ILessonState) => each.asset_type === AssetType.QUIZ);
                console.log(quizes, 'this is quiz')
                if (quizes?.length < 1) {

                    toast({
                        title: "Course Curriculum",
                        description: "Add quiz before next lecture",
                        status: "warning",
                    })
                    return;
                }

                setShowAddLecture(true)

            } else {
                setShowAddLecture(true)
            }
        }
    }

    const removeLecture = () => {
        setShowAddLecture(false)
    }

    const addLesson = (lectureIndex: number) => {

        let currentLecture = lectures.at(lectureIndex) as ILectureState;

        lectures.splice(lectureIndex, 1, { ...currentLecture, showAddLessonField: true });

        setLectures([...lectures]);
    }

    const removeLesson = (lectureIndex: number) => {

        let currentLecture = lectures.at(lectureIndex) as ILectureState;

        lectures.splice(lectureIndex, 1, { ...currentLecture, showAddLessonField: false });

        setLectures([...lectures]);
    }

    const setFormikLessonFields = (lectureIndex: number, eachLecture: ILectureState) => {
        formikLesson.setFieldValue("topicId", eachLecture.topicId)
        formikLesson.setFieldValue("lectureIndex", lectureIndex)
    }

    const saveLessonToState = (lectureIndex: number, lesson: ILessonState) => {

        let currentLecture = lectures.at(lectureIndex) as ILectureState;

        currentLecture.lessons.push(lesson);

        lectures.splice(lectureIndex, 1, { ...currentLecture, showAddLessonField: false });

        setLectures([...lectures]);

        localStorage.setItem('curriculum', JSON.stringify(lectures));
    }

    const deleteLecture = (index: number) => {

        lectures.splice(index, 1);
        toast({
            title: "Course Curriculum",
            description: "Lecture deleted successfully",
            status: "success",
        })

        setLectures([...lectures]);

        localStorage.setItem('curriculum', JSON.stringify(lectures));

        //TODO: Delete Lecture API
    }

    const editLecture = (index: number) => {

        let selectedLecture = lectures.at(index) as ILectureState;
        console.log(selectedLecture);
        formikUpdateLecture.setValues({ lectureIndex: index, lectureTitle: selectedLecture.title });

        lectures.splice(index, 1, { ...selectedLecture, showEditLectureField: true });

        setLectures([...lectures]);
    }

    const removeEditLecture = (index: number) => {

        let selectedLecture = lectures.at(index) as ILectureState;

        lectures.splice(index, 1, { ...selectedLecture, showEditLectureField: false });

        setLectures([...lectures]);
    }

    const deleteLesson = async (lectureIndex: number, lessonIndex: number) => {

        try {

            let lecture = lectures.at(lectureIndex) as ILectureState;

            let lesson = lecture.lessons.at(lessonIndex);

            await courseLessonDeletionService(lesson?.lessonId as string)

            lecture?.lessons.splice(lessonIndex, 1);

            lectures.splice(lectureIndex, 1, { ...lecture })

            toast({
                title: "Course Curriculum",
                description: "Lesson deleted successfully",
                status: "success",
            })

            setLectures([...lectures]);

            localStorage.setItem('curriculum', JSON.stringify(lectures));



        } catch (error) {
            toast({
                title: "Course Curriculum",
                description: "Something went wrong. Try again Later.",
                status: "error",
            })
        }
    }

    const editLesson = (lectureIndex: number, lessonIndex: number) => {

        let lecture = lectures.at(lectureIndex) as ILectureState;

        let lesson = lecture.lessons.at(lessonIndex) as ILessonState;

        lecture?.lessons.splice(lessonIndex, 1, { ...lesson, showEditLessonField: true });

        formikUpdateLesson.setValues({ lectureIndex: lectureIndex, lessonTitle: lesson.title, lessonIndex: lessonIndex });

        lectures.splice(lectureIndex, 1, { ...lecture })

        setLectures([...lectures]);
    }

    const removeEditLesson = (lectureIndex: number, lessonIndex: number) => {

        let lecture = lectures.at(lectureIndex) as ILectureState;

        let lesson = lecture.lessons.at(lessonIndex) as ILessonState;

        lecture?.lessons.splice(lessonIndex, 1, { ...lesson, showEditLessonField: false });

        lectures.splice(lectureIndex, 1, { ...lecture })

        setLectures([...lectures]);
    }

    const formikUpdateLesson = useFormik({
        initialValues: {
            lessonTitle: '',
            lectureIndex: -1,
            lessonIndex: -1,
        },
        validate: (values: any) => {
            const errors: any = {};
            if (!values.lessonTitle) {
                errors.lessonTitle = 'Lesson Title is required';
            }
            return errors;
        },
        onSubmit: async (values) => {
            try {

                let selectedLecture = lectures.at(values.lectureIndex) as ILectureState;

                let selectedLesson = selectedLecture.lessons.at(values.lessonIndex) as ILessonState;

                let courseLesson: ILessonUpdateDto = {
                    title: values.lessonTitle,
                    lessonId: selectedLesson?.lessonId as string
                }

                let updatedCourseLesson = await updateCourseLesson(courseLesson);

                if (updatedCourseLesson) {
                    toast({
                        title: "Lesson Curriculum",
                        description: "Lesson updated successfully",
                        status: "success",
                    })
                }

                selectedLecture.lessons.splice(values.lessonIndex, 1, { ...selectedLesson, title: values.lessonTitle, showEditLessonField: false });

                lectures.splice(values.lectureIndex, 1, { ...selectedLecture });

                setLectures([...lectures]);

                localStorage.setItem('curriculum', JSON.stringify(lectures));

                formikUpdateLesson.resetForm();


            } catch (error) {
                toast({
                    title: "Course Curriculum",
                    description: "Something went Wrong. Try again later",
                    status: "error",
                })
            }
        }
    })


    const addFinalExam = async () => {

        setIsAddingExam(true)

        try {

            let courseId = getCourseIdService();

            if (!courseId)
                throw "Course Id not found"

            let data: IQuizDto = {
                isExam: true,
                title: 'Final Exam',
                courseId: courseId
            }

            const quiz = await createExamService(data);

            let exam: IQuizState = {
                quiz: quiz,
                questions: []
            }

            setExamToStateService(exam)

            setShowAddExamQuestion(true)
        } catch (error) {

            toast({
                title: "Exam Setup",
                description: "Something went wrong, Try again later",
                status: "error",
                duration: 3000
            })
        } finally {
            setIsAddingExam(false)
        }
    }

    const deleteFinalExam = async () => {

        try {

            let exam = getExamFromStateService();

            if (!exam) throw "Exam not found"

            await deleteExamService(exam?.quiz.quiz_id);

            setShowAddExamQuestion(false)

            removeExamFromStateService();

        } catch (error) {
            toast({
                title: "Exam Setup",
                description: "Something went wrong, Try again later",
                status: "error",
                duration: 3000
            })
        }
    }

    //TODO: Remove Quiz API

    //TODO: Remove Article API

    //TODO: Remove Video API

    //TODO: Lecture list in Ascending Order API Modify


    return (
        <Box minHeight="20vh">

            {
                lectures.length > 0 &&

                lectures.map((eachLecture, lectureIndex) => (

                    <Box key={lectureIndex} bg="brand.grey" boxShadow="0px 0px 4px rgba(0, 0, 0, 0.15)" px={6} py={6} mt={3} mb={6}>
                        {
                            eachLecture.showEditLectureField ?

                                <form onSubmit={formikUpdateLecture.handleSubmit}>

                                    <Flex justifyContent="center" alignItems="center">

                                        <Box whiteSpace="nowrap" fontWeight="semibold" mr={6}>
                                            Lecture {lectureIndex + 1}:
                                        </Box>

                                        <FormControl mr={10} isInvalid={(formikUpdateLecture.errors.lectureTitle && formikUpdateLecture.touched.lectureTitle) ? true : false}>
                                            <Input bg="inputText.500" fontSize={14} name="lectureTitle" value={formikUpdateLecture.values.lectureTitle} onChange={formikUpdateLecture.handleChange} />
                                            <FormErrorMessage fontSize={12}> {formikUpdateLecture.errors.lectureTitle} </FormErrorMessage>
                                        </FormControl>

                                        <Input hidden value={formikUpdateLecture.values.lectureIndex} name="lectureIndex" onChange={formikUpdateLecture.handleChange} />

                                        <HStack>

                                            <Button colorScheme="brand" type="submit" size="sm" variant="outline" isLoading={formikUpdateLecture.isSubmitting} fontSize={11} px={6}  >SAVE</Button>

                                            <IconButton
                                                title='Remove'
                                                variant="ghost"
                                                aria-label="trash"
                                                color="brand.subtitle"
                                                borderRadius={20}
                                                size="xs"
                                                icon={<FaTimes />}
                                                onClick={() => removeEditLecture(lectureIndex)}
                                            />

                                        </HStack>

                                    </Flex>

                                </form>

                                :

                                <Flex justifyContent="space-between">
                                    <Text fontSize={15} color="brand.3" fontWeight="semibold">Lecture {lectureIndex + 1}: {eachLecture.title} </Text>
                                    <HStack color="brand.subtitle" >
                                        <IconButton
                                            variant="ghost"
                                            aria-label="pencil"
                                            color="brand.2"
                                            borderRadius={20}
                                            size="xs"
                                            icon={<BsPencilSquare />}
                                            onClick={() => editLecture(lectureIndex)}
                                        />
                                        <IconButton
                                            variant="ghost"
                                            aria-label="trash"
                                            color="brand.2"
                                            borderRadius={20}
                                            size="xs"
                                            icon={<VscTrash />}
                                            onClick={() => deleteLecture(lectureIndex)}
                                        />
                                    </HStack>
                                </Flex>
                        }

                        {
                            eachLecture.lessons.length > 0 &&

                            <Box bg="white" boxShadow="base" mt={4}>

                                <Accordion allowToggle={true} allowMultiple>
                                    {
                                        eachLecture?.lessons?.map((eachLesson, lessonIndex) =>

                                            <Box key={lessonIndex}>
                                                {
                                                    eachLesson?.showEditLessonField ?

                                                        <form onSubmit={formikUpdateLesson.handleSubmit}>

                                                            <Flex justifyContent="center" alignItems="center" px={4} py={3}>

                                                                <Box whiteSpace="nowrap" fontSize={13} mr={4}>
                                                                    Lesson {lectureIndex + 1}.{lessonIndex + 1}:
                                                                </Box>

                                                                <FormControl mr={10} isInvalid={(formikUpdateLesson.errors.lessonTitle && formikUpdateLesson.touched.lessonTitle) ? true : false}>
                                                                    <Input bg="inputText.500" fontSize={14} name="lessonTitle" value={formikUpdateLesson.values.lessonTitle} onChange={formikUpdateLesson.handleChange} />
                                                                    <FormErrorMessage fontSize={12}> {formikUpdateLesson.errors.lessonTitle} </FormErrorMessage>
                                                                </FormControl>

                                                                <Input hidden value={formikUpdateLesson.values.lectureIndex} name="lectureIndex" onChange={formikUpdateLesson.handleChange} />
                                                                <Input hidden value={formikUpdateLesson.values.lessonIndex} name="lessonIndex" onChange={formikUpdateLesson.handleChange} />

                                                                <HStack>

                                                                    <Button colorScheme="brand" type="submit" size="sm" variant="outline" isLoading={formikUpdateLesson.isSubmitting} fontSize={11} px={6}  >SAVE</Button>

                                                                    <IconButton
                                                                        title='Remove'
                                                                        variant="ghost"
                                                                        aria-label="trash"
                                                                        color="brand.subtitle"
                                                                        borderRadius={20}
                                                                        size="xs"
                                                                        icon={<FaTimes />}
                                                                        onClick={() => removeEditLesson(lectureIndex, lessonIndex)}
                                                                    />

                                                                </HStack>

                                                            </Flex>

                                                        </form>

                                                        :

                                                        <AccordionItem key={lessonIndex} >

                                                            <AccordionButton  >
                                                                <Flex justifyContent="space-between" w="100%" py={0.5}>

                                                                    <HStack >
                                                                        <FiMenu color="#7883A7" />
                                                                        <Text fontSize={14} color="brand.3" >{lectureIndex + 1}.{lessonIndex + 1}: {eachLesson.title} <Box as="span" color="brand.subtitle" display="inline-flex" > </Box> </Text>
                                                                    </HStack>

                                                                </Flex>
                                                            </AccordionButton>

                                                            <AccordionPanel>

                                                                <UploadContent lessonId={eachLesson.lessonId} lessonIndex={lessonIndex} lectureIndex={lectureIndex} lectureId={eachLecture.topicId} lessonTitle={eachLesson.title} deleteLesson={deleteLesson} editLesson={editLesson} />

                                                            </AccordionPanel>

                                                        </AccordionItem>
                                                }
                                            </Box>
                                        )
                                    }
                                </Accordion>

                            </Box>
                        }

                        <Box pt={6} >
                            {
                                eachLecture.showAddLessonField ?

                                    <form onSubmit={formikLesson.handleSubmit}>

                                        <Flex justifyContent="center" alignItems="center">

                                            <Box whiteSpace="nowrap" fontWeight="semibold" mr={6}>
                                                {lectureIndex + 1}.{eachLecture.lessons.length + 1}:
                                            </Box>

                                            <FormControl mr={10} isInvalid={(formikLesson.errors.lessonTitle && formikLesson.touched.lessonTitle) ? true : false}>
                                                <Input width={'500px'} bg="inputText.500" fontSize={14} name="lessonTitle" onChange={formikLesson.handleChange} />
                                                <FormErrorMessage fontSize={12}> {formikLesson.errors.lessonTitle} </FormErrorMessage>
                                            </FormControl>
                                            <FormControl mr={10}>
                                                <Input name="duration" id='duration' mr={10} placeholder='Duration(s)' type='time' value={formikLesson.values.duration} onChange={formikLesson.handleChange}></Input>
                                            </FormControl>

                                            <HStack>

                                                <Button colorScheme="brand" type="submit" onClick={() => setFormikLessonFields(lectureIndex, eachLecture)} isLoading={formikLesson.isSubmitting} size="sm" variant="outline" fontSize={11} px={6}  >SAVE</Button>

                                                <IconButton
                                                    title='Remove'
                                                    variant="ghost"
                                                    aria-label="trash"
                                                    color="brand.subtitle"
                                                    borderRadius={20}
                                                    size="xs"
                                                    icon={<FaTimes />}
                                                    onClick={() => removeLesson(lectureIndex)}
                                                />
                                            </HStack>

                                        </Flex>

                                    </form>

                                    :

                                    <Button colorScheme="brand" onClick={() => addLesson(lectureIndex)} size="sm" fontSize={11} borderRadius={3} px={6}>ADD LESSONS</Button>
                            }
                        </Box>

                    </Box>
                ))
            }

            {
                showAddFinalExam &&

                <Box bg="brand.grey" boxShadow="0px 0px 4px rgba(0, 0, 0, 0.15)" px={6} py={6} mt={3} mb={6}>

                    <Flex justifyContent="space-between">
                        <Text fontSize={15} color="brand.3" fontWeight="semibold">Final Exams </Text>
                        <HStack color="brand.subtitle" >
                            <Select
                                variant="outline"

                                size="sm"
                                placeholder="Number of Retakes"
                                value={retestno}
                                name="retest"
                                fontSize={12}
                                onChange={(e) => { setRetestNo(e.target.value) }}
                            >
                                <option value={'1'}>
                                    1
                                </option>
                                <option value={'2'}>
                                    2
                                </option>
                                <option value={'3'}>
                                    3
                                </option>
                                <option value={'4'}>
                                    4
                                </option>
                                <option value={'5'}>
                                    5
                                </option>
                                <option value={'6'}>
                                    6
                                </option>
                                <option value={'7'}>
                                    7
                                </option>
                                <option value={'8'}>
                                    8
                                </option>
                                <option value={'9'}>
                                    9
                                </option>
                                <option value={'10'}>
                                    10
                                </option>
                            </Select>
                            <IconButton
                                variant="ghost"
                                aria-label="trash"
                                color="brand.2"
                                borderRadius={20}
                                size="xs"
                                icon={<VscTrash />}
                                onClick={() => setShowAddFinalExam(false)}
                            />
                        </HStack>
                    </Flex>

                    {
                        !showAddExamQuestion &&

                        <Box bg="white" boxShadow="base" mt={4} minH='12em'>
                            <Flex justifyContent={"center"} height="12em" alignItems={"center"}>

                                <Button colorScheme="brand" isLoading={isAddingExam} onClick={() => addFinalExam()} size="sm" fontSize={11} borderRadius={1} px={6}>ADD QUESTIONS</Button>

                            </Flex>
                        </Box>
                    }

                    {
                        showAddExamQuestion &&

                        <ExamUpload goBack={() => deleteFinalExam()} setCurriculumOkay={setCurriculumOkay} lessonId={0} lessonIndex={0} lectureIndex={0} noOfExams={hasExamFunc} />
                    }

                </Box>
            }

            <Box mt={6} mb={20}>
                {
                    showAddLecture ?

                        <form onSubmit={formikLecture.handleSubmit}>

                            <Flex justifyContent="center" alignItems="center">

                                <Box whiteSpace="nowrap" fontWeight="semibold" mr={6}>
                                    Lecture {lectures.length + 1}:
                                </Box>

                                <FormControl mr={10} isInvalid={(formikLecture.errors.lectureTitle && formikLecture.touched.lectureTitle) ? true : false}>
                                    <Input bg="inputText.500" fontSize={14} name="lectureTitle" onChange={formikLecture.handleChange} />
                                    <FormErrorMessage fontSize={12}> {formikLecture.errors.lectureTitle} </FormErrorMessage>
                                </FormControl>

                                <HStack>

                                    <Button colorScheme="brand" type="submit" size="sm" variant="outline" isLoading={formikLecture.isSubmitting} fontSize={11} px={6}  >SAVE</Button>

                                    <IconButton
                                        title='Remove'
                                        variant="ghost"
                                        aria-label="trash"
                                        color="brand.subtitle"
                                        borderRadius={20}
                                        size="xs"
                                        icon={<FaTimes />}
                                        onClick={() => removeLecture()}
                                    />
                                </HStack>

                            </Flex>

                        </form>

                        :

                        <HStack>
                            <Button colorScheme="brand" onClick={addLecture} size="sm" fontSize={11} borderRadius={3} px={6}>ADD LECTURE</Button>
                            {!showAddFinalExam && <Button colorScheme="brand" onClick={() => setShowAddFinalExam(true)} size="sm" fontSize={11} borderRadius={3} px={4}>ADD FINAL EXAMS</Button>}
                        </HStack>
                }
            </Box>

        </Box>
    );
}

export default Curriculum;