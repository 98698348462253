import {useEffect, useState} from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { ScreenLoader } from '../../components/Common/ScreenLoader';
import { setCourseInfoToStateService, setCourseSettingsToStateService, setCurriculumToStateService } from '../../services/instructor/course.service';
import { getFullCourseData } from '../../services/instructor/courses-http.service';
import CreateCourse from './CreateCourse';


const EditCourse = () => {

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const params = useParams<{courseId: string}>();

    const history = useHistory();


    useEffect(() => {

        const {courseId} = params;

        getFullCourseData(courseId)
        .then(data => {

            localStorage.setItem("course-id", data.course_id);
            
            localStorage.setItem("page_no", '0')

            setCourseInfoToStateService(data);
            
            setCurriculumToStateService(data);
            
            setCourseSettingsToStateService(data);

            setIsLoading(false);
        })
        .catch(e => {
            history.goBack();
        })
    }, []);


    return (
        <>
            {
                isLoading ? 

                <ScreenLoader />

                :

                <CreateCourse />
            }

        </>
    );
}

export default EditCourse;