import { Container, Text, Flex, Button, Spinner, Avatar, Box, Input, FormControl, FormLabel, Spacer, Checkbox, Textarea, InputGroup, InputLeftElement, FormErrorMessage, useToast, Badge, IconButton, useDisclosure, Portal } from "@chakra-ui/react";
import { useFormik } from "formik";
import { useContext, useEffect, useRef, useState } from "react";
import { AiOutlineLinkedin } from "react-icons/ai";
import { RiFacebookCircleLine, RiTwitterLine } from "react-icons/ri";
import { VscDeviceCamera } from "react-icons/vsc";
import { Category } from "../../components/Common/Category";
import { Country } from "../../components/Common/Country";
import { State } from "../../components/Common/State";
import { IUserDto } from "../../models/auth.model";
import { httpStatusCode } from "../../models/httpStatus.model";
import { getProfile, updateProfile, updateProfileImageService } from "../../services/auth.service";
import { updateUserProfileoNStorage } from "../../services/user.service";
import { StoreContext } from "../../store/StoreContext";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertFromRaw, EditorState} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { fetchBlacklistedWords, uploadBlacklistedWords } from "../../services/hr/users-http.service";
import { FaPlusCircle, FaTimesCircle } from "react-icons/fa";
import { AddWordModal } from "../../components/Hr/AddWordModal";





const Profile = () => {

    const toast = useToast();

    const [isUploading, setIsUploading] = useState(false);
    
    const [isDeleting, setIsDeleting] = useState(false);

    const [learningRules, setLearningRules] = useState<any>(EditorState.createEmpty());
    
    const inputRef = useRef<HTMLInputElement | null>(null)

    const {actions, globalState} = useContext(StoreContext)

    const [blackWords, setblackWords] = useState<any[]>([]);
    
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [reload, setAddModal] = useState<boolean>(false)

    useEffect(() => {
        getProfile()
        .then((response) => {

            let data = response.user;

            formik.setValues({
                fullName: data.full_name??'',
                phoneNumber: data.phone??'',
                unit: data.unit?.category_id??'',
                role: data.roleDesc??'',
                description:data.description??'',
                facebookLink: data.facebook_link??'',
                twitterLink: data.twitter_link??'',
                linkedinLink: data.linkedin_link??'',
                newsletter: data.newsletter??false,
                birthday: data.birthday??'',
                country: data.country_id??'',
                state: data.state_id??'',
                learningRules: response.learningRules?.message as string
            })

        })
        .catch((err) => err)
    }, []);

    const formik = useFormik({
        initialValues: {
            fullName: '',
            phoneNumber: '',
            unit: '',
            role: '',
            facebookLink: '',
            twitterLink: '',
            linkedinLink: '',
            description: '',
            newsletter: false,
            birthday:'',
            country:'',
            state: '',
            learningRules: ''
        },
        validate: (values: any) => {
            const errors: any = {};
            if(!values.fullName){
                errors.fullName = 'Full Name is required';
            }else if(
                /^(?=.*\d)/g.test(values.fullName)
            ){
                errors.fullName = 'Only letters are accepted';
            }
            if(!values.phoneNumber){
                errors.phoneNumber = 'Phone Number is required'
            }
            if(!values.unit){
                errors.unit = 'Unit is required'
            }
            return errors;
        },
        onSubmit: async (values) => {
            console.log(values)
            try {

                let data: IUserDto = {
                    fullName: values.fullName,
                    phone: values.phoneNumber,
                    unit: values.unit,
                    role: values.role,
                    facebookLink: values.facebookLink,
                    twitterLink: values.twitterLink,
                    linkedInLink: values.linkedinLink,
                    description: values.description,
                    newsletter: values.newsletter,
                    birthday: values.birthday,
                    countryId: values.country,
                    stateId: values.state,
                    learningRules: values.learningRules
                };

                let response = await updateProfile(data);
                let blackWordResponse = await uploadBlacklistedWords(blackWords)
             
                if(response.statusCode === httpStatusCode.OK){

                    actions?.updateProfile(response.data);
                    
                    

                    toast({
                        title: "Profile Update",
                        description: response.message,
                        status: "success",
                    })
                }           

            }
            catch (error) {
                toast({
                    title: "Profile Update",
                    description: "Something went Wrong. Try again later",
                    status: "error",
                })
            }
        }
    });

   const updateProfileImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
        try {

            let value = e.target.value;
        
            if(value){

                let fileName = e.target.files?.item(0)?.name as string;
                
                let file = e.target.files?.item(0) as File;
                
                if(file.size > 3000000){

                    toast({
                        title: "Profile Image",
                        description: "Maximum Image Size is 3MB",
                        status: "error",
                        duration: 3000,
                    }) 
                    return;
                }
                
                setIsUploading(true);

                let formData = new FormData();

                formData.append('profileImage', file, fileName);

                const profile = await updateProfileImageService(formData);

                if(profile){
                    
                    actions?.updateProfile(profile.data);

                    updateUserProfileoNStorage(profile.data);

                    setIsUploading(false);
                    
                    toast({
                        title: "Profile Image",
                        description: profile.message,
                        status: "success",
                        duration: 2000,
                    })
                }
            }
            
        } catch (error) {
            setIsUploading(false);

            toast({
                title: "Profile Image",
                description: "Something went Wrong. Try again later",
                status: "error",
            })  
        }
    }

   const deleteProfileImage = async () => {
        try {

            setIsDeleting(true);

            const profile = await updateProfileImageService();

            if(profile){
                
                actions?.updateProfile(profile.data);

                setIsDeleting(false);

                updateUserProfileoNStorage(profile.data);
                
                toast({
                    title: "Profile Image",
                    description: "Profile Image has been removed",
                    status: "success",
                    duration: 2000,
                })
            }
            
        } catch (error) { console.log(error)
            setIsDeleting(false);

            toast({
                title: "Profile Image",
                description: "Something went Wrong. Try again later",
                status: "error",
            })  
        }
   }



   useEffect(() => {
    fetchBlacklistedWords()
      .then((data) => {
        setblackWords(data)
      })
      .catch((err) => err)
  },[])

  useEffect(() => {
    fetchBlacklistedWords()
      .then((data) => {
        setblackWords(data)
      })
      .catch((err) => err)
  },[reload])

  const reloadModal = () => {
    setAddModal(!reload)
  }



  
  function removeWord(word: string) {
    const newBlackWords = blackWords.filter((blackword) => blackword !== word);
    setblackWords(newBlackWords)
  }
  

  function addWord(){
    onOpen();
   
  }













    
    return (
        <>
            <Container boxShadow="base" p={3}  maxW="container.xl"  mb={6} bg="white" pb={12}>

                <Text color="brand.3" fontSize={18} mx={6} mb={6} fontWeight="semibold" >Edit Profile</Text>

                <Box mt={3} mx={6}>

                    <Flex alignItems="center" mb={2}>
                        <Box position="relative" cursor="pointer" onClick={() => inputRef?.current?.click()} >
                            <Box position="absolute" color="white" top="4" left="4" zIndex="overlay" >
                                { isUploading? <Spinner size="lg" /> : <VscDeviceCamera fontSize={30} />}
                            </Box>
                            <Box>
                                <Avatar mr={6} size="lg" name={globalState.profile?.full_name} src={globalState.profile?.photo_url}  />
                            </Box>
                        </Box> 
                        <input accept="image/jpg, image/png, image/jpeg" type='file' name="profileImage" ref={inputRef} onChange={(e) => updateProfileImage(e) } style={{ display: 'none' }}></input>
                        { globalState.profile?.photo_url && <Button  variant="outline" color="brand.2" borderColor="brand.2" size="sm" px={6} height={7} fontSize={10} onClick={deleteProfileImage} isLoading={isDeleting} > Remove</Button> }
                    </Flex>

                    <Text color="brand.subtitle" fontSize={12}>Change your profile picture</Text>
                </Box>

                <Box mx={6} mt={6}>

                    <form onSubmit={formik.handleSubmit}>

                        <FormControl isInvalid={(formik.errors.fullName && formik.touched.fullName) ? true : false} >
                            <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>Full Name</FormLabel>
                            <Input name="fullName" id="fullName" placeholder="Full Name" type="text" value={formik.values.fullName} onChange={formik.handleChange} fontSize={12} />
                            <FormErrorMessage fontSize={12} >{formik.errors.fullName}</FormErrorMessage>
                        </FormControl>

                        <Flex mt={6}>
                            <FormControl id="phoneno" isInvalid={(formik.errors.phoneNumber && formik.touched.phoneNumber) ? true : false}>
                                <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>Phone Number</FormLabel>
                                <Input name="phoneNumber" placeholder="Phone Number" type="tel" value={formik.values.phoneNumber} onChange={formik.handleChange} fontSize={12} />
                                <FormErrorMessage fontSize={12} >{formik.errors.phoneNumber}</FormErrorMessage>
                            </FormControl>

                            <Spacer mx={10} />

                            <FormControl id="birthday" isInvalid={(formik.errors.birthday && formik.touched.birthday) ? true : false}>
                                <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>Birthday</FormLabel>
                                <Input placeholder="Birthday" name="birthday" type="date" onChange={formik.handleChange} value={formik.values.birthday} fontSize={12} />
                                <FormErrorMessage fontSize={12} >{formik.errors.birthday}</FormErrorMessage>
                            </FormControl>

                        </Flex>

                        <Flex mt={6}>
                            <FormControl id="unit" isInvalid={(formik.errors.unit && formik.touched.unit) ? true : false}>
                                <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>Unit</FormLabel>
                                <Category unitId={formik.values.unit} setUnitId={formik.handleChange} />
                                <FormErrorMessage fontSize={12} >{formik.errors.unit}</FormErrorMessage>
                            </FormControl>

                            <Spacer mx={10} />

                            <FormControl id="role">
                                <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>Role</FormLabel>
                                <Input name="role" placeholder="Enter your role"  value={formik.values.role} onChange={formik.handleChange} fontSize={12}  />
                            </FormControl>

                        </Flex>

                        <Flex mt={6}>
                            <FormControl id="country">
                                <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>Country</FormLabel>
                                <Country countryId={formik.values.country} setCountryId={formik.handleChange}  />
                            </FormControl>

                            <Spacer mx={10} />

                            <FormControl id="state">
                                <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>State</FormLabel>
                                <State countryId={formik.values.country} stateId={formik.values.state} setStateId={formik.handleChange} />
                            </FormControl>

                        </Flex>
                        
                        <Flex mt={6}>
                            <FormControl id="facebook">
                                <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>Facebook</FormLabel>
                                <InputGroup>
                                    <InputLeftElement pointerEvents="none" children={<RiFacebookCircleLine color="#4E78F5" />} />
                                    <Input name="facebookLink"  value={formik.values.facebookLink} placeholder="www.facebook.com/johnsondavid" onChange={formik.handleChange} fontSize={12} />
                                </InputGroup>
                            </FormControl>

                            <Spacer mx={10} />

                            <FormControl id="twitter">
                                <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>Twitter</FormLabel>
                                <InputGroup>
                                    <InputLeftElement pointerEvents="none" children={<RiTwitterLine color="#1DA1F2" />} />
                                    <Input name="twitterLink"  value={formik.values.twitterLink} placeholder="twitter.com/johnsondavid" onChange={formik.handleChange} fontSize={12} />
                                </InputGroup>
                            </FormControl>

                        </Flex>

                        <FormControl id="linkedin" mt={6}>
                            <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>LinkedIn</FormLabel>
                            <InputGroup>
                                <InputLeftElement pointerEvents="none" children={<AiOutlineLinkedin color="#0077B5" />} />
                                <Input name="linkedinLink"  value={formik.values.linkedinLink} placeholder="www.linkedin.com/in/johnsondavid" onChange={formik.handleChange} fontSize={12} />
                            </InputGroup>
                        </FormControl>

                        <FormControl id="description" mt={6}>
                            <FormLabel fontWeight="normal" color="brand.3" fontSize={13}> Description </FormLabel>
                                
                            <Textarea
                                placeholder="Johnson David is a Financial Analyst at PwC. With more than 20 years of data development experience, she has gained a passion for anaysis and data development -- especially Lean."
                                p = {4}
                                fontSize={12}
                                size="sm"
                                minH="5rem"
                                name="description"
                                onChange={formik.handleChange}
                                value={formik.values.description}
                            />
                        </FormControl>
                        
                        <FormControl id="learningRules" mt={6}>
                            <FormLabel fontWeight="normal" color="brand.3" fontSize={13}> Change Learning Rules </FormLabel>
                                
                            <Textarea
                                placeholder="Learning rules..."
                                p = {4}
                                fontSize={12}
                                size="sm"
                                minH="10rem"
                                name="learningRules"
                                onChange={formik.handleChange} 
                                value={formik.values.learningRules}

                            />
                        </FormControl>

                        <FormControl id="blacklistedwords" mt={6}>
                            <FormLabel fontWeight="normal" color="brand.3" fontSize={13}> Update Blacklisted Words </FormLabel>

                             {blackWords.map((word, index)=>(
                                    <Badge key={index} borderRadius = {20}  m={2} pt ={2} pb={2} pr={5} pl={5} colorScheme="gray" variant={"outline"} textTransform={"capitalize"}>
                                        {word} 
                                        <IconButton
                                            title="Delete"
                                            variant="ghost"
                                            aria-label="delete"
                                            color="warning"
                                            borderRadius={20}
                                            size="xs"
                                            icon={<FaTimesCircle />}
                                            fontSize={14}
                                            onClick={()=> removeWord(word)}
                                        />
                                    </Badge>
                                 
                                    ))
                               }
                              <Button onClick={addWord} borderRadius = {20} bg = "#7883A7" color = "white" fontSize={12} m={2} pt ={2} pb={2} pr={5} pl={5} colorScheme="#7883A7" variant={"outline"} textTransform={"capitalize"}>
                                    Add Word 
                                    <IconButton
                                        title="Add"
                                        variant="ghost"
                                        aria-label="Add"
                                        borderRadius={20}
                                        size="xs"
                                        icon={<FaPlusCircle />}
                                        fontSize={14}
                                    />
                             </Button>
                        </FormControl>

                        <Flex mt={6} >
                            <Checkbox colorScheme="brand" name="newsletter" isChecked={formik.values.newsletter} onChange={formik.handleChange} />
                            <Text ml={3} color="brand.3" fontSize={13}>
                                Subscribe to Newsletter
                            </Text>
                        </Flex>

                        <Flex justifyContent="flex-end">
                            <Button mt={8} colorScheme="brand" type="submit" isLoading={formik.isSubmitting} size="sm" px={6} fontSize={12} > SAVE CHANGES</Button> 
                        </Flex>

                    </form>

                </Box>

                
                <Portal>
                    {' '}
                    <AddWordModal
                        isOpen={isOpen}
                        onClose={onClose}
                        reloadModal={reloadModal}
                        />

                    {' '}
                </Portal>
                
            </Container>
        </>
    );
}

export default Profile;