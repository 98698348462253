import {Button, FormLabel, Text, Box, Center, FormControl, FormErrorMessage, useToast} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { getLocalUserProfile, updateLocalUserProfile, updateRoleSetting } from '../services/auth.service';
import { useFormik } from 'formik';
import RoleSettingLayout from '../components/Common/RoleSettingLayout';
import { Category } from '../components/Common/Category';
import { Level } from '../components/Common/Level';
import { Rank } from '../components/Common/Rank';
import { IRoleSettingDto, IUser } from '../models/auth.model';


function RoleSetting(){

    const toast = useToast();

    const history = useHistory();

     const formik = useFormik({
        initialValues: {
            email: '',
            unit: '',
            level: '',
            rank: '',
        },
        validate: (values: any) => {
            const errors: any = {};
            if(!values.unit){
                errors.unit = 'Unit is required';
            }
            if(!values.level){
                errors.level = 'Level is required';
            }
            if(!values.rank){
                errors.rank = 'Rank is required';
            }
            return errors;
        },
        onSubmit: async (values) => {

            try {
                const data: IRoleSettingDto = {
                    levelId: values.level,
                    rankId: values.rank,
                    unitId: values.unit
                }
                
                const response = await updateRoleSetting(data);

                let user = getLocalUserProfile() as IUser;

                updateLocalUserProfile({...user, rankId: response.data.rankId});
                
                toast({
                    title: "Role Settings",
                    description: response.message,
                    status: "success",
                    duration: 2000,
                    onCloseComplete: () => {
                        history.replace('/')
                    }
                })
                
            } catch (error: any) {
                toast({
                    title: "Role Settings",
                    description: error.data.message,
                    status: "error",
                })
            }
        }
    });

   return (
       <RoleSettingLayout>

           <Box>
                <Text mb={10} fontSize={18} fontWeight="semibold" color="brand.2">Role Settings</Text>

                <Text fontSize={12} color="brand.subtitle" mb={10}>Kindly set your unit, level and rank below</Text>

                <form onSubmit={formik.handleSubmit}>
                    
                    <FormControl id="unit" isInvalid={(formik.errors.unit && formik.touched.unit) ? true : false} mb={6}>
                        <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>UNIT</FormLabel>
                        <Category unitId={formik.values.unit} setUnitId={formik.handleChange} />
                        <FormErrorMessage fontSize={12} >{formik.errors.unit}</FormErrorMessage>
                    </FormControl>
                    
                    <FormControl id="level" isInvalid={(formik.errors.level && formik.touched.level) ? true : false} mb={6}>
                        <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>LEVEL</FormLabel>
                        <Level levelId={formik.values.level} setLevelId={formik.handleChange} />
                        <FormErrorMessage fontSize={12} >{formik.errors.level}</FormErrorMessage>
                    </FormControl>
                    
                    <FormControl id="rank" isInvalid={(formik.errors.rank && formik.touched.rank) ? true : false} mb={14}>
                        <FormLabel fontWeight="normal" color="brand.3" fontSize={13}>RANK</FormLabel>
                        <Rank rankId={formik.values.rank} setRankId={formik.handleChange} levelId={formik.values.level} />
                        <FormErrorMessage fontSize={12} >{formik.errors.rank}</FormErrorMessage>
                    </FormControl>
                    
                    <Center>
                        <Button w="100%" type="submit" bg="brand.2" color="white" mb={6} fontSize={13} isLoading={formik.isSubmitting}> Save Changes </Button>
                    </Center>
                    
                </form>
           </Box>

       </RoleSettingLayout>
    );
}

export default RoleSetting;